import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromRoot from '../../../reducers';
import * as fromCore from '../../../core/reducers';
import * as fromPets from '../../reducers';
import { AppAnimate, fade } from '../../../shared/animations/common.animations';
import * as PetsActions from '../../actions/pets.actions';

@Component({
  selector: 'app-pets-profile-page',
  template: `
    <app-pets
      [isMobile]="isMobile$ | async"
      [pets]="pets$ | async"
      [showPetProfile]="true">
    </app-pets>
  `,
  styles: [],
  animations: [fade]
})
export class PetsProfilePageComponent implements OnInit, OnDestroy {

  @HostBinding('@fade')
  fade = '';

  // @HostBinding('class.app-animated-page')
  // animatedPage = true;

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  pets$ = this.store.pipe(select(fromPets.getPets));

  subscriptions: Subscription[] = [];

  constructor(private store: Store<fromRoot.State>) {
    AppAnimate('fade', this, this.subscriptions);
  }

  ngOnInit() {
    this.store.dispatch(new PetsActions.GetPets());
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
  }


}

