import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { supportList, subList, whatsNewSupportInfo } from '../../../shared/constants/faq';
import { WhatsNewContent } from '../../../api';
@Component({
  selector: 'app-support-faq',
  templateUrl: './support-faq.component.html',
  styleUrls: ['./support-faq.component.scss']
})
export class SupportFaqComponent implements OnInit {

  supportList: Array<supportList> = supportList;  

  selectedFaq : supportList;

  faqList : Array<subList>;

  selectedFaqId : string;           

  selectedFaqName : string;

  selectedPanelId: string;

  @Input() isMobile: boolean | false; 

  @Input() isLoggedIn: boolean | false;

  @Input() whatsNewFlag : boolean | false;

  @Input() whatsNewContent : WhatsNewContent[] | null;
  // WhatsNew Support - Rahul Solanki
  whatsNewSupport : any;
  // End
  constructor(private route: ActivatedRoute, private location: Location, private router: Router) {
    // WhatsNew Support - Rahul Solanki
    this.route.params
        .subscribe(params => {
          this.selectedFaqId = params['id'];

          // here sublist is not required because it is only used while we are printing its list in support.component.html
          // since we are showing completely different banner for it we don't subList over there.
          this.whatsNewSupport = whatsNewSupportInfo;

          if(this.selectedFaqId !== 'whats-new'){
            this.selectedFaq = this.supportList.find((item)=> item.code == this.selectedFaqId);
          }
          else{
            this.selectedFaq = whatsNewSupportInfo;
          }
          this.selectedFaqName = this.selectedFaq.name;
        })
      // End
  }

  ngOnInit() {
    this.supportList = this.supportList.slice(1);   //Removing the default object for select box, specifically added for contact us page  
    // WhatsNew support - Rahul Solanki
    if(this.isLoggedIn){
      this.supportList.unshift(this.whatsNewSupport);
    }
    // End 
  }

  goBack() {
    if(this.isLoggedIn) {
      this.router.navigate(['/user/support']);
    }else{
      this.router.navigate(['/support']);
    }
  }

  goToSelectedFaq(selectedFaqCode) {
    if(this.isLoggedIn) {
      this.router.navigate(['/user/support-faq', selectedFaqCode]);
    }else{
      this.router.navigate(['/support-faq', selectedFaqCode]);
    }
  }

  gotoLogin() {
    this.router.navigate(['/login']);
  }

  openUrl() {
    window.open('https://www.hillspet.com/', '_blank');
  }

}
