import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-not-linked-message',
  templateUrl: './not-linked-message.component.html',
  styleUrls: ['./not-linked-message.component.scss']
})
export class NotLinkedMessageComponent {

  @Input()
  isMobile: boolean | false;

  constructor() {}
}
