import { Pipe, PipeTransform } from '@angular/core';
import { FacetValue } from '../../../api/model/facetValue';

@Pipe({
  name: 'facetValueSelected'
})
export class FacetValueSelectedPipe implements PipeTransform {
  transform(facetValues: FacetValue[], facet?: any, settings?: any): FacetValue[] {
    if (!facetValues || !facet || !settings) {
      return facetValues;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    if (settings.showSelectedOnly && facet.name !== 'Food Type') {
      return facetValues.filter(facetValue => facetValue.selected);
    }
    return facetValues;
  }
}
