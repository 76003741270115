import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'entryCount'
})
export class EntryCountPipe implements PipeTransform {
  transform(value: Number, args?: any): string {
    if (value) {
      return value > 0 ? (value > 1 ? `${value} items` : `1 item` ) : 'No item';
    }

    return 'No item';
  }
}
