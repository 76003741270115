import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromCore from '../../../core/reducers';

@Component({
  selector: 'app-error-page',
  template: `
    <app-error
      [error]="error$ | async"
      [isMobile]="isMobile$ | async">
    </app-error>
  `,
  styles: [],
})
export class ErrorPageComponent implements OnInit {

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));
  error$ = this.store.pipe(select(fromCore.getUnhandledError));

  constructor(private store: Store<fromCore.State>) { }

  ngOnInit() {
  }

}
