import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrdersPageComponent } from './containers/orders-page/orders-page.component';
import { AuthGuard } from '../auth/guards/auth.guard';
import { CartPageComponent } from './containers/cart-page/cart-page.component';
import { PlaceOrderPageComponent } from './containers/place-order-page/place-order-page.component';
import { OrderCompletePageComponent } from './containers/order-complete-page/order-complete-page.component';
import { OrderPageComponent } from './containers/order-page/order-page.component';
import { SubscriptionsPageComponent } from './containers/subscriptions-page/subscriptions-page.component';
import { SubscriptionPageComponent } from './containers/subscription-page/subscription-page.component';
import { LayoutModes } from '../core/constants/layout';
import { ErrorGuard } from '../core/guards/error.guard';
import { NoCartGuard } from './guards/no-cart.guard';
import { EditSubscriptionPageComponent } from './containers/edit-subscription-page/edit-subscription-page.component';
import { StripeRegisteredCheckGuard } from '../home/guards/stripe-registered-check.guard';
import { ReturnPageComponent } from './containers/return-page/return-page.component';

const routes: Routes = [
  {
    path: 'orders',
    component: OrdersPageComponent,
    canActivate: [ErrorGuard, AuthGuard]
  },
  {
    path: 'orders/:id',
    component: OrderPageComponent,
    canActivate: [ErrorGuard, AuthGuard],
    data: { back: true }
  },
  {
    path: 'home-orders/:id',
    component: OrderPageComponent,
    canActivate: [ErrorGuard, AuthGuard],
    data: { fromHome: true, back: true }
  },
  {
    path: 'cart',
    component: CartPageComponent,
    canActivate: [ErrorGuard, AuthGuard, StripeRegisteredCheckGuard, NoCartGuard],
    data: {
      layoutMode: LayoutModes.Alternate2
    }
  },
  {
    path: 'place-order',
    component: PlaceOrderPageComponent,
    canActivate: [ErrorGuard, AuthGuard, NoCartGuard],
    data: { back: true }
  },
  {
    path: 'order-complete',
    component: OrderCompletePageComponent,
    canActivate: [ErrorGuard, AuthGuard]
  },
  {
    path: 'subscriptions',
    component: SubscriptionsPageComponent,
    canActivate: [ErrorGuard, AuthGuard]
  },
  {
    path: 'subscriptions/:id',
    component: SubscriptionPageComponent,
    canActivate: [ErrorGuard, AuthGuard],
    data: { back: true }
  },
  {
    path: 'edit-subscription/:id',
    component: EditSubscriptionPageComponent,
    canActivate: [ErrorGuard, AuthGuard],
    data: { back: true }
  },
  {
    path: 'return/:id',
    component: ReturnPageComponent,
    canActivate: [ErrorGuard, AuthGuard],
    data: {back: true}
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrdersRoutingModule { }

