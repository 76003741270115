export enum PetTypes {
  Cat = 'CAT',
  Dog = 'DOG',
}

export enum PetStatuses {
  Active = 'ACTIVE',
  Deceased = 'DECEASED',
}

export const species: PetTypes[] = [
  PetTypes.Dog, PetTypes.Cat
];
