import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'appPlanStatusFormat'
})
export class PlanStatusFormatPipe implements PipeTransform {

    transform(value: string, args?: any): any {
        let planStatus = value.toLowerCase();
        return planStatus.charAt(0).toUpperCase() + planStatus.slice(1);
    }
}