/**
 * Pet Companion Server
 * This is a sample server Pet Companion server. You can find out more about Swagger at [http://swagger.io](http://swagger.io) or on [irc.freenode.net, #swagger](http://swagger.io/irc/). For this sample, you can use the api key `special-key` to test the authorization filters.
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { TermsAndConditions } from '../model/termsAndConditions';
import { PromotionContent } from '../model/promotionContent'


import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ContentService {

    protected basePath = 'http://localhost:10010/rest';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Fetch the Terms &amp; Conditions from the server
     * 
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTermsAndConditions(t?: string, observe?: 'body', reportProgress?: boolean): Observable<TermsAndConditions>;
    public getTermsAndConditions(t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TermsAndConditions>>;
    public getTermsAndConditions(t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TermsAndConditions>>;
    public getTermsAndConditions(t?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (t !== undefined) {
            queryParameters = queryParameters.set('t', <any>t);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];

        return this.httpClient.get<TermsAndConditions>(`${this.basePath}/rest/v2/V2CSite/getTermsAndConditions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fetch the Terms &amp; Conditions from the server
     * 
     * @param content content for promo service
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     */
    public getPromotionText(content?: string, observe?: 'body'): Observable<PromotionContent>;
    public getPromotionText(content?: string, observe?: 'response'): Observable<HttpResponse<PromotionContent>>;
    public getPromotionText(content?: string, observe?: 'events'): Observable<HttpEvent<PromotionContent>>;
    public getPromotionText(content?: "Some Test Promo Content", observe: any = 'body'): Observable<any> {

        let headers = this.defaultHeaders;

        const response = this.httpClient.get<PromotionContent>(`${this.basePath}/rest/v2/V2CSite/alertMessageComponent`,
        
        {
            headers: headers,
            observe: observe,
        })
        return response;
        

        }

}
