import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromAuth from '../../reducers';
import * as fromCore from '../../../core/reducers';
import * as AuthActions from '../../actions/auth.actions';
import { ResetPasswordChange } from '../../models/resetPasswordChange';

@Component({
  selector: 'app-reset-password-page',
  template: `
    <app-reset-password
      [pending]="pending$ | async"
      [errorMessage]="error$ | async"
      [resetErrorMessage]="resetError$ | async"
      [isMobile]="isMobile$ | async"
      (submitted)="onSubmit($event)">
    </app-reset-password>
  `,
  styles: [],
})
export class ResetPasswordPageComponent implements OnInit, OnDestroy {

  token: string;

  username: string;

  pending$ = this.store.pipe(select(fromAuth.getRegisterPending));

  error$ = this.store.pipe(select(fromAuth.getRegisterError));

  resetError$ = this.store.pipe(select(fromAuth.getResetError));

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  subscriptions: Subscription[] = [];

  constructor(private store: Store<fromAuth.State>,
              private route: ActivatedRoute) {
    this.subscriptions.push(
      route.queryParams.subscribe(params => {
        this.token = params['token'];
      })
    );
  }

  ngOnInit() {
    this.store.dispatch(new AuthActions.GetCSRFToken());
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
  }

  onSubmit(resetPasswordChange: ResetPasswordChange) {
    resetPasswordChange.token = this.token;
    this.store.dispatch(new AuthActions.ResetPassword(resetPasswordChange));
  }
}
