import { AbstractControl } from '@angular/forms';
import { DateUtil } from '../utils/dateUtil';

export class DateValidation {
  static minDate(date: Date | string) {
    return (control: AbstractControl) => {
      const value = control.value;
      if (DateUtil.isBefore(value, date)) {
        return {minDate: true};
      }
      return null;
    };
  }

  static onlyWeekDays() {
    return (control: AbstractControl) => {
      const value = control.value;
      if (!DateUtil.isWeekDay(value)) {
        return {onlyWeekDays: true};
      }
      return null;
    };
  }

  // To check in Edit Subscription - Next Delivery Date should not exceed subscription end date or 12 weeks from today
  static maxDate(date: Date | string) {
    return (control: AbstractControl) => {
      const value = control.value;
      if (DateUtil.isAfter(value, date)) {
        return {maxDateReached: true};
      }
      return null;
    };
  }
}
