import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';

import {
  ErrorActionTypes, ErrorRedirect,
  ReportError
} from '../actions/error.actions';
import { LoginRedirect } from '../../auth/actions/auth.actions';

@Injectable()
export class ErrorEffects {


  
  reportError$ = createEffect(() => this.actions$
    .pipe(
      ofType<ReportError>(ErrorActionTypes.ReportError),
      map((action: ReportError) => action.unhandledError),
      map((unhandledError: any) => {
          if(unhandledError.noRedirect) return new ErrorRedirect(unhandledError.noRedirect);

          if (unhandledError && unhandledError.error && unhandledError.error.errors && unhandledError.error.errors.length) {
            switch (unhandledError.error.errors[0].type) {
              // when there is an invalid token, redirect to login
              case 'InvalidTokenError':
                return new LoginRedirect();
            }
          }
          return new ErrorRedirect();
        }
      )
    ));

  
  errorRedirect$ = createEffect(() => this.actions$
    .pipe(
      ofType(ErrorActionTypes.ErrorRedirect),
      tap((action) => {
        
        // Break redirection if true
        if(action["noRedirect"]) return;
        
        this.router.navigate(['/error']);
      })
    ), {dispatch: false});

  constructor(private actions$: Actions,
              private router: Router) {
  }


  
}


