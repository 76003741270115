import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PetsShopPageComponent } from './containers/pets-shop-page/pets-shop-page.component';
import { AuthGuard } from '../auth/guards/auth.guard';
import { ErrorGuard } from '../core/guards/error.guard';
import { PetPlansPageComponent } from './containers/pet-plans-page/pet-plans-page.component';
import { NutritionPlanPageComponent } from './containers/nutrition-plan-page/nutrition-plan-page.component';
import { EditPetPageComponent } from './containers/edit-pet-page/edit-pet-page.component';
import { PetsProfilePageComponent } from './containers/pets-profiles-page/pets-profile-page.component';
import { PetDetailsPageComponent } from './containers/pet-detail-page/pet-detail-page.component';

const routes: Routes = [
  {
    path: 'pets/shop',
    component: PetsShopPageComponent,
    canActivate: [ErrorGuard, AuthGuard]
  },
  {
    path: 'pets/profile',
    component: PetsProfilePageComponent,
    canActivate: [ErrorGuard, AuthGuard]
  },
  {
    path: 'pets/shop/:id',
    component: PetPlansPageComponent,
    canActivate: [ErrorGuard, AuthGuard],
    data: { back: true }
  },
  {
    path: 'pets/profile/:id',
    component: PetDetailsPageComponent,
    canActivate: [ErrorGuard, AuthGuard],
    data: { back: true }
  },
  {
    path: 'edit-pet/:id',
    component: EditPetPageComponent,
    canActivate: [ErrorGuard, AuthGuard],
    data: { back: true }
  },
  {
    path: 'add-pet',
    component: EditPetPageComponent,
    canActivate: [ErrorGuard, AuthGuard],
    data: { back: true }
  },
  {
    path: 'nutrition-plan/:id',
    component: NutritionPlanPageComponent,
    canActivate: [ErrorGuard, AuthGuard],
    data: { back: true }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PetsRoutingModule { }


