import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { skip, switchMap, take, tap } from 'rxjs/operators';
import * as fromRoot from '../../../reducers';
import * as fromCore from '../../../core/reducers';
import * as fromAuth from '../../../auth/reducers';
import * as fromPets from '../../../pets/reducers';
import * as LayoutActions from '../../../core/actions/layout.actions';
import { LayoutModes } from '../../../core/constants/layout';
import { ActivatedRoute } from '@angular/router';
import { AppAnimate, fade } from '../../../shared/animations/common.animations';
import * as PetsActions from '../../../pets/actions/pets.actions';
@Component({
  selector: 'app-faq-page',
  template: `
    <app-support-faq
      [isMobile]="isMobile$ | async"
      [isLoggedIn]="isLoggedIn$ | async"
      [whatsNewFlag]="whatsNewFlag$ | async"
      [whatsNewContent]="whatsNewContent$ | async">
    </app-support-faq>
  `,
  styles: []
})
export class FaqPageComponent implements OnInit, OnDestroy {

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  isLoggedIn$ = this.store.pipe(select(fromAuth.getIsLoggedIn));

  whatsNewFlag$ = this.store.pipe(select(fromPets.getWhatsNewFlag));

  whatsNewContent$ = this.store.pipe(select(fromPets.getWhatsNewContent));

  subscriptions: Subscription[] = [];

  isLoggedIn : boolean | false;

  constructor(private store: Store<fromRoot.State>, private route: ActivatedRoute) {

    this.isLoggedIn$.subscribe((value) => {
      this.isLoggedIn = value;
      if(this.isLoggedIn) {
        this.store.dispatch(new PetsActions.GetWhatsNewContent());
      }
    });

  }

  ngOnInit() {

    this.subscriptions.push(
      this.isMobile$
        .pipe(
          skip(1), // Skip first time is being take care of
          tap((isMobile: boolean) => {
              if (isMobile) {
                this.store.dispatch(new LayoutActions.ChangeLayoutMode(LayoutModes.Normal));
              } else {
                this.store.dispatch(new LayoutActions.ChangeLayoutMode(LayoutModes.Alternate3));
              }
            }
          ))
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
  }
}

