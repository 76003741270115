<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout fixed-top fixed-bottom d-flex flex-column h-100">
    <div class="d-flex align-self-center h-30">
      <div class="align-self-center text-center">
        <h2 class="text-white">Oops!</h2>
        <p class="font-weight-light text-white">Something Went Wrong</p>
      </div>
    </div>
    <div class="flex-grow-1 d-flex flex-column m-4">
      <p class="text-center text-white">
        An {{ errorName ? errorName: 'unexpected'}} error occurred. Please reload the application.
      </p>
      <a class="btn btn-primary w-100 mt-5 text-white" href="javascript:location.reload()">
        Reload
      </a>
      <textarea *ngIf="!production" class="w-100 flex-grow-1 mt-4" readonly>
      {{error}}
    </textarea>
    </div>
  </div>
</ng-template>

<ng-template #fullLayout>
  <div class="app-full-layout">
    <div class="container pt-5 pb-5 text-center">
      <h2>Oops!</h2>
      <h5>Something Went Wrong</h5>
      <p class="mt-5">
          An {{ errorName ? errorName: 'unexpected'}} error occurred. Please reload the application.
      </p>
      <a class="btn btn-primary pl-5 pr-5 btn-lg m-5" href="javascript:location.reload()">
        Reload
      </a>
      <div>
        <textarea *ngIf="!production" class="mt-5 w-100" readonly>
        {{error}}
      </textarea>
      </div>
    </div>
  </div>
</ng-template>
