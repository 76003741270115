export * from './auth.service';
import { AuthService } from './auth.service';
export * from './content.service';
import { ContentService } from './content.service';
export * from './order.service';
import { OrderService } from './order.service';
export * from './pet.service';
import { PetService } from './pet.service';
export * from './product.service';
import { ProductService } from './product.service';
export * from './profile.service';
import { ProfileService } from './profile.service';
export const APIS = [AuthService, ContentService, OrderService, PetService, ProductService, ProfileService];
