import { NgModule, InjectionToken } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot } from '@angular/router';

import { NotFoundPageComponent } from './containers/not-found-page/not-found-page.component';
import { ErrorPageComponent } from './containers/error-page/error-page.component';
import { TermsPageComponent } from './containers/terms/terms-page.component';
import { ErrorGuard } from './guards/error.guard';
import { NoErrorGuard } from './guards/no-error.guard';
import { NotLinkedMessageComponent } from './components/not-linked-message/not-linked-message.component';
import { ContactUsPageComponent } from '../support/containers/contact-us-page/contact-us-page.component';

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

const routes: Routes = [
  {
    path: '404',
    component: NotFoundPageComponent,
    canActivate: [ErrorGuard]
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    canActivate: [NoErrorGuard]
  },
  {
    path: 'terms',
    component: TermsPageComponent,
    canActivate: [ErrorGuard]
  },
  {
    path: 'stripeNotLinked',
    component: NotLinkedMessageComponent,
    canActivate: [ErrorGuard]
  },
  {
    path: 'externalRedirect',
    canActivate: [externalUrlProvider],
    component: NotFoundPageComponent
  },
  {
    path: 'contact-us',
    component: ContactUsPageComponent,
    canActivate: [ErrorGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalUrl = route.paramMap.get('externalUrl');
        window.open(externalUrl, '_blank');
      }
    }
  ]
})
export class CoreRoutingModule {}
