import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromCore from '../../../core/reducers';
import * as fromOrders from '../../reducers';
import * as OrdersActions from '../../actions/orders.actions';
import { AppAnimate, flyOutIn } from '../../../shared/animations/common.animations';
import { EditingSubscription } from '../../../api';
import { GetEditSubscription } from '../../../api';
import * as CoreActions from '../../../core/actions/core.actions';
//import {GetEditSubscription} from "../../../api/model/getEditSubscription";

@Component({
  selector: 'app-edit-subscription-page',
  template: `
    <app-edit-subscription
      [isMobile]="isMobile$ | async"
      [changePending]="subscriptionChangePending$ | async"
      [subscription]="subscription$ | async"
      (submitted)="onSubmit($event)"
      (succeed)="onSuccess()">
    </app-edit-subscription>
  `,
  styles: [],
  animations: [flyOutIn]
})
export class EditSubscriptionPageComponent implements OnInit, OnDestroy {

  @HostBinding('@flyOutIn')
  flyOutIn = 'in';

  // @HostBinding('class.app-animated-page')
  // animatedPage = true;

  subscriptions: Subscription[] = [];

  getUpdatedSubscriptions = null;

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  subscription$ = this.store.pipe(select(fromOrders.getSubscription));

  subscriptionChangePending$ = this.store.pipe(select(fromOrders.getSubscriptionChangePending));

  constructor(private store: Store<fromOrders.State>, private route: ActivatedRoute, private router: Router) {
    AppAnimate('flyOutIn', this, this.subscriptions);

    this.subscriptions.push(
      route.params
        .subscribe(params => {
          const id = params['id'];
          if (id) {
            this.store.dispatch(new OrdersActions.GetSubscription(id));
          }
        })
    );
  }

  ngOnInit() {
    // On desktop redirect back to master page
    this.subscriptions.push(
      this.isMobile$
        .subscribe(isMobile => {
          if (!isMobile) {
            this.router.navigate(['/subscriptions']);
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
  }

  onSubmit(editingSubscription: EditingSubscription) {
    this.getUpdatedSubscriptions = editingSubscription;
    this.store.dispatch(new OrdersActions.UpdateSubscription(editingSubscription));
  }

  onSuccess() {
    this.store.dispatch(new OrdersActions.GetUpdateSubscription(this.getUpdatedSubscriptions));
    this.store.dispatch(new CoreActions.NavigateBack());
  }
}
