import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromCore from '../../../core/reducers';
import * as fromOrders from '../../reducers';
import * as OrdersActions from '../../actions/orders.actions';
import { AppAnimate, flyOutIn } from '../../../shared/animations/common.animations';
import { EditingSubscription, AutoShipTemplate } from '../../../api';

@Component({
  selector: 'app-subscription-page',
  template: `
    <app-subscription
      [isMobile]="isMobile$ | async"
      [subscription]="subscription$ | async"
      (subscriptionSelected)="onSubscriptionSelect($event)"
      (submitted)="onSubmit($event)">
    </app-subscription>
  `,
  styles: [],
  animations: [flyOutIn]
})
export class SubscriptionPageComponent implements OnInit, OnDestroy {

  @HostBinding('@flyOutIn')
  flyOutIn = 'in';

  // @HostBinding('class.app-animated-page')
  // animatedPage = true;

  subscriptions: Subscription[] = [];

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  subscription$ = this.store.pipe(select(fromOrders.getSubscription));

  constructor(private store: Store<fromOrders.State>, private route: ActivatedRoute, private router: Router) {

    AppAnimate('flyOutIn', this, this.subscriptions);

    this.subscriptions.push(
      route.params
        .subscribe(params => {
          const id = params['id'];
          if (id) {
            this.store.dispatch(new OrdersActions.GetSubscription(id));
          }
        })
    );
  }

  ngOnInit() {
    // On desktop redirect back to master page
    this.subscriptions.push(
      this.isMobile$
        .subscribe(isMobile => {
          if (!isMobile) {
            this.router.navigate(['/subscriptions']);
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
  }

  onSubmit(editingSubscription: EditingSubscription) {
    this.store.dispatch(new OrdersActions.UpdateSubscription(editingSubscription));
  }

  onSubscriptionSelect(subscription: AutoShipTemplate) {                     //Required to refresh selected subscription, after editing a subscription
    this.store.dispatch(new OrdersActions.GetSubscription(subscription.subscriptionId));
  }
}
