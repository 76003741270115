<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout bg-white">
    <!-- <h5 class="app-title text-center m-4">
      Shop {{petName}}&#39;s Nutrition Plan
    </h5> -->
    <form [formGroup]="shopForm" autocomplete="off" (ngSubmit)="search();hideFilterPopup();" class="p-3 custom-font">
      <h3 class="w700 d-flex align-items-center">
        <span>Filter <em>({{filtersCount}})</em></span>
        <a (click)="search();hideFilterPopup();" class="fa fa-times"></a>
      </h3>
      <!-- <div class="d-flex flex-wrap mt-3">
          <button type="button"
                  *ngFor="let filter of headFilters"
                  class="btn btn-primary btn-sm font-weight-light rounded-0 mr-2 mb-2"
                  (click)="removeFilter(filter)">
            {{filter.name}}
            <i class="fas fa-times app-title ml-2"></i>
          </button>
        </div> -->
      <div class="app-scrollable filters">
        <div class="mt-3 mb-4">
        <ngb-accordion #acc="ngbAccordion">
            <ngb-panel [id]="'ngb-panel-'+ i" *ngFor="let facet of displayFacets ; let i=index">
              <ng-template ngbPanelTitle>
                <div [ngClass]="{'panel-open': acc.isExpanded('ngb-panel-'+i)}" class="panel-title-container" >
                  <h6>
                    {{facet.name === 'Food Type'? 'Product Form' : facet.name }}
                  </h6>
                  <i [ngClass]="acc.isExpanded('ngb-panel-'+i) ? 'fa fa-minus' : 'fa fa-plus'"></i>
                  <!-- <i class="fa fa-plus"></i>
                  <i class="fa fa-minus"></i> -->
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                
                <div class="card-body">
                  <div [formArrayName]="formatFacet(facet)" *ngFor="let facetValue of facet.values ; let j=index"
                    class="d-flex">
                    <div class="filter-item">
                      <input type="checkbox" [id]="'facet_'+ i + '_' + j"
                        [value]="facetValue.selected"
                        (click)="preventSelect($event,facet,facetValue);updateFilters($event,facet,facetValue);"
                        [checked]="updateChecked(facet,facetValue.name,facetValue)">
                      <label [for]="'facet_'+ i + '_' + j">
                        {{facetValue.name | facetValueNameMap }}
                      </label>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
        </ngb-accordion>
      </div>
      </div>
      <button type="submit" class="btn btn-primary btn-blue w-100 mt-4">
        Apply
      </button>
    </form>
  </div>
</ng-template>

<ng-template #fullLayout>
  <div class="app-full-layout">
    <form [formGroup]="shopForm" autocomplete="off" (ngSubmit)="search()" class="mr-3 pt-3 mb-4">
      <h5 class="filter-title pb-4">Filter <em>({{filtersApplied.length}})</em></h5>
      <!-- <div class="d-flex flex-wrap mt-3">
        <button type="button"
                *ngFor="let filter of headFilters"
                class="btn btn-primary btn-sm font-weight-light rounded-0 mr-2 mb-2"
                (click)="removeFilter(filter)">
          {{filter.name}}
          <i class="fas fa-times app-title ml-2"></i>
        </button>
      </div> -->
      <div class="app-scrollable filters">
        <div class="mt-3 mb-4">
        <ngb-accordion #acc="ngbAccordion">
       
            <ngb-panel [id]="'ngb-panel-'+ i" *ngFor="let facet of displayFacets ; let i=index">
              <ng-template ngbPanelTitle>
                <div [ngClass]="{'panel-open': acc.isExpanded('ngb-panel-'+i)}" class="panel-title-container">
                  <h6 class="panel-title">
                    {{facet.name === 'Food Type'? 'Product Form' : facet.name }}
                  </h6>
                  <i [ngClass]="acc.isExpanded('ngb-panel-'+i) ? 'fa fa-minus' : 'fa fa-plus'"></i>
                  <!-- <i class="fa fa-plus"></i>
                  <i class="fa fa-minus"></i> -->
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="card-body">
                  <div [formArrayName]="formatFacet(facet)" *ngFor="let facetValue of facet.values ; let j=index" class="d-flex">
                    <div class="filter-item">
                      <input type="checkbox" [id]="'facet_'+ i + '_' + j"
                        [value]="facetValue.selected"
                        (click)="preventSelect($event,facet,facetValue);updateFilters($event,facet,facetValue);search();"
                        [checked]="updateChecked(facet,facetValue.name,facetValue)">
                      <label [for]="'facet_'+ i + '_' + j">
                        {{facetValue.name | facetValueNameMap }}
                      </label>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
         
        </ngb-accordion>
      </div>
      </div>
      <!-- <button type="submit" class="btn btn-primary" [disabled]="viewBtnDisable">
        View Results
      </button> -->
    </form>
    <!-- <div class="custom-font active-promotion d-flex align-items-center">
      <p>2 Active Promotions</p>
      <a href="#" class="btn py-2">View Now</a>
    </div>
    <a class="btn btn-multiline mb-4" href="">View Healthy Advantage<br>& Science Diet Products</a> -->
  </div>
</ng-template>