import { getSelectedPlans } from './../../pets/reducers/index';

import { SearchedProducts } from './../../api/model/searchedProducts';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, from } from 'rxjs';
import { switchMap, map, take, mergeMap } from 'rxjs/operators';

import * as fromProducts from '../reducers';
import * as fromPets from '../../pets/reducers';
import * as fromOrders from '../../orders/reducers';
import {
  SearchPlan,
  SearchPlanSuccess,
  GetProductDetail,
  GetProductDetailSuccess,
  ProductsActionTypes,
  SetPlanFilters,
  GetPlanFacets,
  GetPlanFacetsSuccess,
  ResetSearchPlan,
  FilterProduct,
  FilterProductSuccess,
  GetReferenceProducts,
  GetReferenceProductsSuccess,
  SearchProductsByName,
  SearchProductsByNameSuccess
} from '../actions/products.actions';
import {
  GetSubscriptionSuccess,
  OrdersActionTypes
} from '../../orders/actions/orders.actions';
import {
  Brand,
  NutritionPlan,
  ProductDetail,
  ProductLine,
  ProductService,
  Facet,
  FacetValue,
  AutoShipTemplate,
  SearchedProduct
} from '../../api';
import { ProductFilters } from '../models/productFilters';
import {
  GetNutritionPlanDetailSuccess,
  PetsActionTypes
} from '../../pets/actions/pets.actions';
import { ShopUtil } from '../../shared/utils/shopUtil';

@Injectable()
export class ProductsEffects {
  constructor(
    private store: Store<fromProducts.State>,
    private actions$: Actions,
    private router: Router,
    private productService: ProductService
  ) { }
  pageSize = 9;

  
  searchPlanProducts$ = createEffect(() => this.actions$.pipe(
    ofType<SearchPlan>(ProductsActionTypes.SearchPlan),
    mergeMap((action: SearchPlan) =>
      this.getPlanFilters().pipe(

        mergeMap((filters: ProductFilters) =>
          this.getPlanSort().pipe(
            mergeMap((sort: string) =>
              this.productService
                .searchPlanProducts(
                  action.clinicId,
                  this.getSearchQuery(filters, sort, action.name),
                  this.pageSize,
                  action.pageNumber
                )
                .pipe(
                  switchMap(
                    (searchedProducts: SearchedProducts) => [
                      new SearchPlanSuccess(searchedProducts, action.stack),
                      new GetPlanFacetsSuccess(searchedProducts.facets)
                    ]
                  )
                )
            )
          )
        )
      )
    )
  ));

  
  filterProducts$ = createEffect(() => this.actions$.pipe(
    ofType<FilterProduct>(ProductsActionTypes.FilterProduct),
    mergeMap((action: FilterProduct) =>
      this.getPlanFilters().pipe(

        mergeMap((filters: ProductFilters) =>
          this.getPlanSort().pipe(
            mergeMap((sort: string) =>
              this.productService
                .searchPlanProducts(
                  action.clinicId,
                  this.getSearchQuery(filters, sort),
                  this.pageSize,
                  action.pageNumber
                )
                .pipe(
                  map(
                    (searchedProducts: SearchedProducts) =>
                      new FilterProductSuccess(searchedProducts, action.stack),
                  )
                )
            )
          )
        )
      )
    )
  ));

  
  searchProductsByName$ = createEffect(() => this.actions$.pipe(
    ofType<SearchProductsByName>(ProductsActionTypes.SearchProductsByName),
    mergeMap((action: SearchProductsByName) =>
      this.getPlanFilters().pipe(

        mergeMap((filters: ProductFilters) =>
          this.getPlanSort().pipe(
            mergeMap((sort: string) =>
              this.productService
                .searchPlanProducts(
                  action.clinicId,
                  this.getSearchQuery(filters, sort, action.name),
                  this.pageSize,
                  action.pageNumber
                )
                .pipe(
                  map(
                    (searchedProducts: SearchedProducts) =>
                      new SearchProductsByNameSuccess(searchedProducts, action.stack),
                  )
                )
            )
          )
        )
      )
    )
  ));


  
  getPlanFacets$ = createEffect(() => this.actions$.pipe(
    ofType<GetPlanFacets>(ProductsActionTypes.GetPlanFacets),
    switchMap((action: GetPlanFacets) => {
      // subscriptonId supplied, get filters from that
      if (action.subscriptionId) {
        return this.getSubscriptionDetail().pipe(
          map((sb: AutoShipTemplate) => {
            return this.getSearchQuery(this.getProductFilters(null, sb));
          }),
          switchMap((query: string) => {
            return this.productService
              .searchPlanProducts(action.clinicId, query, 1, 0)
              .pipe(map((planProducts: SearchedProducts) => new GetPlanFacetsSuccess(planProducts.facets))
              );
          })
        )
        // otherwise, get filters from nutritionPlan object
      } else {
        return this.getNutritionPlanDetail().pipe(
          map((np: NutritionPlan) => {
            return this.getSearchQuery(this.getProductFilters(np));
          }),
          switchMap((query: string) => {
            return this.productService
              .searchPlanProducts(action.clinicId, query, 1, 0)
              .pipe(map((planProducts: SearchedProducts) => new GetPlanFacetsSuccess(planProducts.facets))
              );
          })
        );
      }
    })
  ));

  
  getNutritionPlanDetailSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<GetNutritionPlanDetailSuccess>(
      PetsActionTypes.GetNutritionPlanDetailSuccess
    ),
    switchMap((action: GetNutritionPlanDetailSuccess) =>
      from([
        new ResetSearchPlan(),
        new SetPlanFilters(this.getProductFilters(action.nutritionPlan))
      ])
    )
  ));

  
  GetSubscriptionSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<GetSubscriptionSuccess>(
      OrdersActionTypes.GetSubscriptionSuccess
    ),
    switchMap((action: GetSubscriptionSuccess) =>
      from([
        new ResetSearchPlan(),
        new SetPlanFilters(this.getProductFilters(null, action.subscription))
      ])
    )
  ));

  
  getProductDetail$ = createEffect(() => this.actions$.pipe(
    ofType<GetProductDetail>(ProductsActionTypes.GetProductDetail),
    switchMap((action: GetProductDetail) =>
      this.getSelectedPlans().pipe(
        switchMap((plans) =>
          this.productService
            .getProductDetail(action.clinicId, action.id, plans, action.petCode)
            .pipe(
              map(
                (productDetail: ProductDetail) =>
                  new GetProductDetailSuccess(productDetail)
              )
            )
        )
      )
    )
  ));

  getSelectedPlans = (): Observable<string[]> =>
    this.store.pipe(
      select(fromPets.getSelectedPlans),
      take(1)
    )

  getSubscriptionDetail = (): Observable<AutoShipTemplate> =>
    this.store.pipe(
      select(fromOrders.getSubscription),
      take(1)
    )

  getNutritionPlanDetail = (): Observable<NutritionPlan> =>
    this.store.pipe(
      select(fromPets.getNutritionPlanDetail),
      take(1)
    )

  getPlanFilters = (): Observable<ProductFilters> =>
    this.store.pipe(
      select(fromProducts.getPlanFilters),
      take(1)
    )

  getPlanSort = (): Observable<string> =>
    this.store.pipe(
      select(fromProducts.getPlanSort),
      take(1)
    )

  getProductFilters(np: NutritionPlan, sb?: AutoShipTemplate): ProductFilters {
    let filters: ProductFilters = {};
    let sbUnit, petname, brandNames;
    if (np !== null && np !== undefined && Object.keys(np).length) {
      filters = {
        brand: np.brands ? np.brands.map((b: Brand) => b.code) : [],
        species: [ShopUtil.mapPetTypeFilter(np.pet.petType)],
        productLine: np.productLines.map((pl: ProductLine) => pl.code),
        foodType: [],
        petName: np.pet.petName,
        appliedFilter: []
      }
    } else {
      let nutritionId = sb ? sb.cart.entries[0].subscriptionUnit.nutritionPlanId : null;
      if (nutritionId !== null && nutritionId !== undefined) {
        sbUnit = sb ? sb.cart.entries[0].subscriptionUnit : null;
        petname = sb ? sb.cart.entries[0].pet : null;
        brandNames = sb.cart.entries[0].subscriptionUnit.brands;
        filters = {
          brand: brandNames ? brandNames.map((b: Brand) => b.code) : [],
          species: [ShopUtil.mapPetTypeFilter(sbUnit.species)],
          productLine: sbUnit.productLines.map((pl: ProductLine) => pl.code),
          foodType: [],
          petName: petname,
          appliedFilter: []
        }
      }
    }

    let _filters = {};

    if (Object.keys(filters).length) {
      _filters = {
        brand: Array.from(new Set(filters.brand)),
        species: Array.from(new Set(filters.species)),
        productLine: Array.from(new Set(filters.productLine)),
        foodType: Array.from(new Set(filters.foodType)),
        appliedFilter: Array.from(new Set(filters.appliedFilter)),
        petName: filters.petName
      }
    }

    return _filters;
  }

  getKey(name: string) {
    if (name) {
      return name.replace(/ /g, '');
    }
    return '';
  }

  getCamelKey(name: string) {
    if (name) {
      const words = name.split(/ /g);
      if (words.length > 1) {
        let key: string = words[0].toLowerCase();
        key += words[1];
        return key;
      } else {
        return words[0].toLowerCase();
      }
    }
    return '';
  }

  getSearchQuery(filters: ProductFilters = {}, sort: string = '', name?: string): string {
    console.log('filters from search query', filters)
    let query = '';
    
    if (name) {
      query += name;        //for wellness products search
    }

    query += sort ? ':' + sort : ':name-asc';

    if (filters) {
      if (filters.brand) {
        filters.brand.forEach(r => {
          query += r ? ':brand:' + r : '';
        });
      }
      if (filters.species) {
        filters.species.forEach(r => {
          query += r ? ':species:' + r : '';
        });
        query += ':species:CanineorFeline';
      }
      if (filters.productLine) {
        filters.productLine.forEach(r => {
          query += r ? ':productLine:' + r : '';
        });
      }
      if (filters.foodType) {
        filters.foodType.forEach(r => {
          query += r ? ':foodType:' + r : '';
        });
      }
    }

    return query;
  }
}
