import { Pipe, PipeTransform } from '@angular/core';
import {environment} from '../../../../environments/environment'

@Pipe({
  name: 'appImgBaseUrl'
})
export class AppImgBaseUrlPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let imageUrl = value;
    if (value) {
      imageUrl = environment.img_base_path + imageUrl;
    }
    return imageUrl;
  }

}

