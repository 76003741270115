<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>
<ng-template #mobileLayout>
  <ngb-carousel #whatsNewCarousel (slide)="onSlideChange($event)" data-interval="false">
    <ng-template ngbSlide *ngFor="let content of whatsNewContent; let i = index" [id]="'slide_'+i">
      <div class="d-flex flex-column">
        <div class="feature-image p-5" *ngIf="content?.image">
          <div class="d-flex justify-content-center">
            <img src="{{content?.image | appImgBaseUrl }}" class="img-fluid w-100" alt="What's New">
          </div>
        </div>
        <div class="p-3 feature-text">
          <div class="d-flex my-4 justify-content-center" *ngIf="content?.title">
            <h1>{{content?.title}}</h1>
          </div>
          <div class="d-flex pb-5 justify-content-center" *ngIf="content?.text">
            <div [innerHTML]="content?.text"></div>
          </div>
        </div>
      </div>
    </ng-template>
  </ngb-carousel>
  <div class="d-flex mb-3 justify-content-center">
    <button type="button" class="btn btn-secondary btn-lg mr-2 ml-3 cust-btn flex-grow-1" (click)="callPrevSlide()" *ngIf="!isFirstSlide">
      BACK
    </button>
    <button type="button" class="btn btn-secondary btn-lg mr-2 ml-3 cust-btn flex-grow-1" (click)="onGotItClick()" *ngIf="isFirstSlide">
      GOT IT
    </button>
    <button type="button" class="btn btn-primary btn-lg ml-2 mr-3 flex-grow-1 " (click)="callNextSlide()" *ngIf="!isLastSlide">
      NEXT
    </button>
    <button type="button" class="btn btn-primary btn-lg ml-2 mr-3 flex-grow-1" (click)="onGotItClick()" *ngIf="isLastSlide">
      GOT IT
    </button>
  </div>
</ng-template>
<ng-template #fullLayout>
  <ngb-carousel #whatsNewCarousel (slide)="onSlideChange($event)" data-interval="false">
    <ng-template ngbSlide *ngFor="let content of whatsNewContent; let i = index" [id]="'slide_'+i">
      <div class="d-flex flex-column">
        <div class="feature-image p-5" *ngIf="content?.image">
          <div class="d-flex justify-content-center">
            <img src="{{content?.image | appImgBaseUrl }}" class="img-fluid" style="width: 48%;" alt="What's New">
          </div>
        </div>
        <div class="p-5 feature-text">
          <div class="d-flex mb-4 justify-content-center" *ngIf="content?.title">
            <h1>{{content?.title}}</h1>
          </div>
          <div class="d-flex mx-4 justify-content-center" *ngIf="content?.text">
            <div [innerHTML]="content?.text"></div>
          </div>
        </div>
      </div>
    </ng-template>
  </ngb-carousel>
  <div class="d-flex mb-3 justify-content-center">
    <button type="button" class="btn btn-secondary pl-5 pr-5 btn-lg mr-4 cust-btn" (click)="callPrevSlide()" *ngIf="!isFirstSlide">
      BACK
    </button>
    <button type="button" class="btn btn-secondary pl-5 pr-5 btn-lg mr-4 cust-btn" (click)="onGotItClick()" *ngIf="isFirstSlide">
      GOT IT
    </button>
    <button type="button" class="btn btn-primary pl-5 pr-5 btn-lg ml-2" (click)="callNextSlide()" *ngIf="!isLastSlide">
      NEXT
    </button>
    <button type="button" class="btn btn-primary pl-5 pr-5 btn-lg ml-2" (click)="onGotItClick()" *ngIf="isLastSlide">
      GOT IT
    </button>
  </div>
</ng-template>
