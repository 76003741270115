import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NutritionPlan } from '../../../api';
import { PetStatuses } from '../../../shared/constants/pet';

@Component({
  selector: 'app-nutrition-plan',
  templateUrl: './nutrition-plan.component.html',
  styleUrls: ['./nutrition-plan.component.scss']
})
export class NutritionPlanComponent implements OnInit {

  PetStatuses = PetStatuses;

  @Input() isMobile: boolean | false;

  @Input() nutritionPlan: NutritionPlan | null;

  constructor() {
  }

  ngOnInit() {
  }

}

