import { Component, OnInit, Input, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromCore from '../../../../core/reducers';

@Component({
  selector: 'app-pet-parent-registration-success',
  templateUrl: './pet-parent-registration-success.component.html',
  styleUrls: ['./pet-parent-registration-success.component.scss']
})
export class PetParentRegistrationSuccessComponent implements OnInit {

  @Input()
  isMobile: boolean | false;

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  constructor(private store: Store<fromCore.State>) {
    this.isMobile$.subscribe(value => {
      this.isMobile = value;
    });
   }

  ngOnInit() {
  }

}
