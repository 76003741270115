import { Component, Input, OnInit } from '@angular/core';
import { Order, Profile } from '../../../api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @Input() profile: Profile | null;

  @Input() isMobile: boolean | false;

  @Input() orders: Order[] | null;

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  select(order: Order) {
    if (this.isMobile) {
      this.router.navigate(['/home-orders', order.code]);
    } else {
      this.router.navigate(['/orders']);
    }
  }
}
