import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/index';
import { Store, select } from '@ngrx/store';
import * as fromCore from '../../../core/reducers';
import * as fromOrders from '../../reducers';
import * as OrdersActions from '../../actions/orders.actions';
import { AutoShipTemplate, EditingSubscription } from '../../../api';
import { AppAnimate, fade } from '../../../shared/animations/common.animations';

@Component({
  selector: 'app-subscriptions-page',
  template: `
    <app-subscriptions
      [isMobile]="isMobile$ | async"
      [subscriptionChangePending]="subscriptionChangePending$ | async"
      [subscriptions]="subscriptions$ | async"
      [subscriptionDetail]="subscriptionDetail$ | async"
      (subscriptionSelected)="onSubscriptionSelect($event)"
      (subscriptionSubmitted)="onSubmit($event)"
      (succeed)="onSuccess($event)">
    </app-subscriptions>
  `,
  styles: [],
  animations: [fade]
})
export class SubscriptionsPageComponent implements OnInit, OnDestroy {

  @HostBinding('@fade')
  fade = '';

  // @HostBinding('class.app-animated-page')
  // animatedPage = true;

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  subscriptionChangePending$ = this.store.pipe(select(fromOrders.getSubscriptionChangePending));

  subscriptions$ = this.store.pipe(select(fromOrders.getSearchedSubscriptions));

  subscriptionDetail$ = this.store.pipe(select(fromOrders.getSubscription));

  subscriptions: Subscription[] = [];

  showInactiveSub: boolean = true;

  constructor(private store: Store<fromOrders.State>, private router: Router) {
    AppAnimate('fade', this, this.subscriptions);
  }

  ngOnInit() {
    this.store.dispatch(new OrdersActions.SearchSubscriptions({showInactiveSub : this.showInactiveSub}));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
  }

  onSubscriptionSelect(subscription: AutoShipTemplate) {
    this.store.dispatch(new OrdersActions.GetSubscription(subscription.subscriptionId));
  }

  onSubmit(editingSubscription: EditingSubscription) {
    this.store.dispatch(new OrdersActions.UpdateSubscription(editingSubscription));
  }
  onSuccess(showInactiveSub: boolean) {
    this.showInactiveSub = showInactiveSub;
    this.ngOnInit();
  }
}

