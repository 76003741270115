import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NutritionPlan, Pet } from '../../../api';
import { PetStatuses, PetTypes } from '../../../shared/constants/pet';

@Component({
  selector: 'app-pet',
  templateUrl: './pet.component.html',
  styleUrls: ['./pet.component.scss']
})
export class PetComponent implements OnInit, OnChanges {

  selectedPanelId: string;

  petForm: UntypedFormGroup;

  PetTypes = PetTypes;

  PetStatuses = PetStatuses;

  selectedNutritionPlans: Array<string> = [];

  _nutritionPlans = [];

  showInactiveNutritionPlans: boolean = false;

  ottoRegisteredFlag: boolean = false;

  /** showPetDetail | If true then show pet details(add/edit) else show pet plans */
  @Input() showPetDetail: boolean = false;

  @Input() isMobile: boolean | false;

  @Input() pending: boolean | false;

  @Input() pet: Pet | null;

  @Input() nutritionPlans: NutritionPlan[] | null;

  @Input() inActiveNutritionPlans: NutritionPlan[] | null;

  @Input() wellnessPlan: NutritionPlan[] | null;

  @Input() nutritionPlan: NutritionPlan | null;

  @Input() defaultSelectedPlan: NutritionPlan | null;

  @Output() submitted = new EventEmitter<Pet>();

  @Output() succeed = new EventEmitter<any>();

  @Output() nutritionPlanSelected = new EventEmitter<any>();

  @Output() navigatedBack = new EventEmitter();

  @ViewChild('successModal', { static: true })
  successModal: any;
  selectedNutritionPlansLength: number;
  _wellnessPlans: any;

  // Google Analytics - Capture selected Nutrition plans - Rahul Solanki
  selectedNutritionPlanNames: Array<string> = [];
  windowRef: any; 
  // End

  constructor(private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private router: Router) {
    this.createForm();
    // Google Analytics - Capture selected Nutrition plans - Rahul Solanki
    this.windowRef = window;
    // End
  }

  createForm() {
    this.petForm = this.fb.group({
      name: ['', Validators.required],
      petType: ['', Validators.required]
    });
  }

  resetForm() {
    if (this.pet) {
      this.petForm.reset({
        name: this.pet.petName,
        petType: this.pet.petType,
      });
    }
  }

  ngOnInit() {
    const isOttoCustomer = sessionStorage.getItem('ottoCustomer');
    const ottoStatus = sessionStorage.getItem('ottoStatus');
    const ottoRegisteredmsg = sessionStorage.getItem('ottoRegisteredmsg');
    // Check if the user is an Otto customer with pending status and the message has not been shown yet
    if (isOttoCustomer === 'true' && ottoStatus === 'pending' && ottoRegisteredmsg !== 'true') {
      this.ottoRegisteredFlag = true;
      sessionStorage.setItem('ottoRegisteredmsg', 'true');
    } else {
      this.ottoRegisteredFlag = false;
    }
  
  }

  ngOnChanges(changes: SimpleChanges) {

    const petChange: SimpleChange = changes['pet'];
    const defaultSelectedPlanChange: SimpleChange = changes['defaultSelectedPlan'];
    const nutritionPlansChange: SimpleChange = changes['nutritionPlans'];
    const nutritionPlanChange: SimpleChange = changes['nutritionPlan'];


    // Mark check the first nutrition plan on UI 
    if (nutritionPlansChange && !nutritionPlansChange.firstChange) {
      if (this.nutritionPlans.length) {
        this._nutritionPlans = JSON.parse(JSON.stringify(this.nutritionPlans));
        this._nutritionPlans.forEach((plan) => plan.isChecked = false);
        this._nutritionPlans[0].isChecked = true;
      }
    }


    if (petChange) {
      this.resetForm();
    }

    // Google Analytics - Capture selected Nutrition plans - Rahul Solanki 
    if (defaultSelectedPlanChange && !defaultSelectedPlanChange.firstChange) {
      this.selectedNutritionPlans.push(this.defaultSelectedPlan.id);
      this.selectedNutritionPlanNames = this.selectedNutritionPlanNames.concat(this.defaultSelectedPlan.planName);
    }
    

    const pendingChange: SimpleChange = changes['pending'];
    if (pendingChange && !pendingChange.firstChange) {

      if (pendingChange.currentValue) {
        this.petForm.disable();
      } else {
        this.petForm.enable();
        this.success();
      }
    }


  }

  // select(np: NutritionPlan) {
  //   if (np) {
  //     if (this.isMobile) {
  //       this.router.navigate(['/nutrition-plan', np.id]);
  //     } else {
  //       this.nutritionPlanSelected.emit(np.id);
  //     }
  //   }
  // }

  save() {
    if (this.petForm.valid) {
      const model = this.petForm.value;
      const pet: Pet = {
        ...this.pet,
        petName: model.name,
        petType: model.petType
      };
      this.submitted.emit(pet);
    }
  }

  success() {
    // Using timeout because of issue here:
    // https://github.com/ng-bootstrap/ng-bootstrap/issues/1775
    setTimeout(() => {
      this.modalService.open(this.successModal).result.then(
        () => {
          this.succeed.emit();
        },
        () => {
          this.succeed.emit();
        }
      );
    });
  }

  // Google Analytics - Capture selected Nutrition plans - Rahul Solanki
  search(np: NutritionPlan, event) {


    if (event.target.checked) {
      this.selectedNutritionPlans = this.selectedNutritionPlans.concat(np.id)
      this.selectedNutritionPlanNames = this.selectedNutritionPlanNames.concat(np.planName);
      this.selectedNutritionPlansLength = this.selectedNutritionPlans.length;
      this._nutritionPlans.forEach((plan) => {
        if (plan.id === np.id) {
          plan.isChecked = true;
        }
      });
    } else {
      this.selectedNutritionPlans = this.selectedNutritionPlans.filter(planId => planId !== np.id);
      this.selectedNutritionPlanNames = this.selectedNutritionPlanNames.filter(planName => planName !== np.planName);
      this.selectedNutritionPlansLength = this.selectedNutritionPlans.length;
      this._nutritionPlans.forEach((plan) => {
        if (plan.id === np.id) {
          plan.isChecked = false;
        }
      });
    }

    this.nutritionPlanSelected.emit({ np, selectedNutritionPlans: this.selectedNutritionPlans });
  }
  // End

  toggleInactiveNutritionPlans() {
    this.showInactiveNutritionPlans = !this.showInactiveNutritionPlans;
  }

  goBack() {
    this.navigatedBack.emit();
    // this.router.navigate([this.back]);
  }
}
