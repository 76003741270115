/**
 * Pet Companion Server
 * This is a sample server Pet Companion server. You can find out more about Swagger at [http://swagger.io](http://swagger.io) or on [irc.freenode.net, #swagger](http://swagger.io/irc/). For this sample, you can use the api key `special-key` to test the authorization filters.
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Brand } from './brand';
import { Clinic } from './clinic';
import { Pet } from './pet';
import { PetParentUser } from './petParentUser';
import { ProductLine } from './productLine';
import { VetUser } from './vetUser';


export interface NutritionPlan {
    id?: string;
    brands?: Array<Brand>;
    clinic?: Clinic;
    endDate?: string;
    notes?: string;
    pet?: Pet;
    petParent?: PetParentUser;
    planLength?: string;
    planName?: string;
    productLines?: Array<ProductLine>;
    startDate?: string;
    status?: string;
    vetUser?: VetUser;
}
