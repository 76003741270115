import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { skip, switchMap, take, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromCore from '../../../core/reducers';
import * as fromProfile from '../../../profile/reducers';
import * as fromOrders from '../../../orders/reducers';
import * as OrdersActions from '../../../orders/actions/orders.actions';
import * as LayoutActions from '../../../core/actions/layout.actions';
import { ScrollChange } from '../../../core/models/scrollChange';
import { LayoutModes } from '../../../core/constants/layout';
import { AppAnimate, fade } from '../../../shared/animations/common.animations';

@Component({
  selector: 'app-home-page',
  template: `
    <app-home
      [isMobile]="isMobile$ | async"
      [profile]="profile$ | async"
      [orders]="orders$ | async">
    </app-home>
  `,
  styles: [],
  animations: [fade]
})
export class HomePageComponent implements OnInit, OnDestroy {

  @HostBinding('@fade')
  fade = '';

  // @HostBinding('class.app-animated-page')
  // animatedPage = true;

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  scrollChange$ = this.store.pipe(select(fromCore.getScrollChange));

  profile$ = this.store.pipe(select(fromProfile.getProfile));

  orders$ = this.store.pipe(select(fromOrders.getOrders));

  subscriptions: Subscription[] = [];

  constructor(private store: Store<fromCore.State>) {
    AppAnimate('fade', this, this.subscriptions);
  }

  ngOnInit() {
    this.store.dispatch(new OrdersActions.GetOrders());

    this.subscriptions.push(
      this.scrollChange$
        .pipe(
          skip(1),
          switchMap((scrollChange: ScrollChange) => this.isMobile$
            .pipe(
              take(1),
              tap((isMobile: boolean) => {
                if (isMobile && scrollChange) {
                  if (scrollChange.yOffset > 400) {
                    this.store.dispatch(new LayoutActions.ChangeLayoutMode(LayoutModes.Normal));
                  } else {
                    this.store.dispatch(new LayoutActions.ChangeLayoutMode(LayoutModes.Alternate2));
                  }
                }
              }
              ))
          ))
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
  }
}
