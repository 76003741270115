<div class="app-full-layout">

  <div class="notLinkedMessage">
    <div class="container align-items-start app-title-img">
    </div>
    <div class="mobileMessage">
      Oops! Something went wrong
    </div>
    <div class="container pt-5 pb-5 text-left font-weight-normal app-content-text testing">
      <p>Sorry this account is no longer active. Please reach out to your veterinarian to sign up again or sign in to a different account.</p>
      <p>If you have any questions, please contact Hill's to Home Customer
        Service at 1-800-235-6877 or email us at <a style="color: rgb(6, 6, 153)" href="mailto:hillstohome@hillspet.com">hillstohome@hillspet.com.</a></p>
    </div>
  </div>

</div>
