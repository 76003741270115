export interface PhoneTypes {
  name: string;
}

export const phoneTypes: PhoneTypes[] = [
  {
    name: 'Mobile'
  },
  {
    name: 'Work'
  },
  {
    name: 'Home'
  }
  ];
