import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import * as AuthActions from '../../auth/actions/auth.actions';
import * as fromError from '../reducers';

@Injectable()
export class NoErrorGuard  {

  constructor(private store: Store<fromError.State>) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.store.pipe(
      select(fromError.getUnhandledError),
      map(error => {
        // If there is no error present, it might be a refresh
        if (!error) {
          this.store.dispatch(new AuthActions.LoginRedirect());
          return false;
        }

        return true;
      })
    );
  }
}
