import { Payment } from '../../api';

export interface ProfilePayments {
    payments?: Array<Payment>;
}

export function sortProfilePayments(payments) {
    let profilePayments = {};
    profilePayments['payments'] = payments.payments.slice().sort(function(x, y) { return y.defaultPayment - x.defaultPayment })
    return profilePayments;
}
