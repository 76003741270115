import {
  trigger,
  animate, style, transition
} from '@angular/animations';

import { timer, Subscription } from 'rxjs';
import { filter, skip, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { appInjector } from '../../app.globals';
import * as fromCore from '../../core/reducers';

/*export const fade = trigger('fade', [
  transition('* => *', [
    query(
      ':enter',
      [style({ opacity: 0 })],
      { optional: true }
    ),
    query(
      ':leave',
      [style({ opacity: 1 }), animate('1s', style({ opacity: 0 }))],
      { optional: true }
    ),
    query(
      ':enter',
      [style({ opacity: 0 }), animate('1s', style({ opacity: 1 }))],
      { optional: true }
    )
  ])
]);*/

export const flyInOut =
  trigger('flyInOut', [
    transition('void => in', [
      style({
        opacity: 0,
        transform: 'translateX(-100%)'
      }),
      animate('0.2s ease-in', style({
        opacity: 1, transform: 'translateX(0)'
      }))
    ]),
    transition('in => void', [
      animate('0.2s 0.1s ease-out', style({
        opacity: 0,
        transform: 'translateX(-100%)'
      }))
    ]),
    transition('void => out', [
      style({
        opacity: 0,
        transform: 'translateX(20%)'
      }),
      animate('0.2s ease-in', style({
        opacity: 1, transform: 'translateX(0)'
      }))
    ]),
    transition('out => void', [
      animate('0.2s 0.1s ease-out', style({
        opacity: 0,
        transform: 'translateX(20%)'
      }))
    ]),
  ]);

export const flyOutIn =
  trigger('flyOutIn', [
    transition('void => in', [
      style({
        opacity: 0,
        transform: 'translateX(100%)'
      }),
      animate('0.2s ease-in', style({
        opacity: 1, transform: 'translateX(0)'
      }))
    ]),
    transition('in => void', [
      animate('0.2s 0.1s ease-out', style({
        opacity: 0,
        transform: 'translateX(100%)'
      }))
    ]),
    transition('void => out', [
      style({
        opacity: 0,
        transform: 'translateX(-20%)'
      }),
      animate('0.2s ease-in', style({
        opacity: 1, transform: 'translateX(0)'
      }))
    ]),
    transition('out => void', [
      animate('0.2s 0.1s ease-out', style({
        opacity: 0,
        transform: 'translateX(-20%)'
      }))
    ])
  ]);

export const fade =
  trigger('fade', [
    transition('void => in', [
      style({
        opacity: 0,
      }),
      animate('0.2s ease-in', style({
        opacity: 1
      }))
    ]),
    transition('in => void', [
      animate('0.2s 0.1s ease-out', style({
        opacity: 0
      }))
    ]),
    transition('void => out', [
      style({
        opacity: 0,
      }),
      animate('0.2s ease-in', style({
        opacity: 1
      }))
    ]),
    transition('out => void', [
      animate('0.2s 0.1s ease-out', style({
        opacity: 0
      }))
    ])
  ]);


export function AppAnimate(animation: string, component: any, subscriptions: Subscription[]) {

  const _isMobile = '__appAnimationIsMobile__';

  const injector = appInjector();
  const store = injector.get(Store);

  const isMobile$ = store.pipe(select(fromCore.getIsMobile));
  const isNavigatingBack$ = store.pipe(select(fromCore.getIsNavigatingBack));
  const isNavigatedBack$ = store.pipe(select(fromCore.getIsNavigatedBack));


  subscriptions.push(
    isMobile$
      .subscribe(isMobile => {
        component[_isMobile] = isMobile;
        // only for mobile we do the animation
        component[animation] = isMobile ? 'in' : '';
      })
  );

  subscriptions.push(
    isNavigatingBack$
      .pipe(
        skip(1),
        filter(() => component[_isMobile])
      )
      .subscribe(isNavigatingBack => {
          component[animation] = isNavigatingBack ? 'in' : component[animation];
        }
      )
  );

  subscriptions.push(
    isNavigatedBack$
      .pipe(
        skip(1),
        filter(() => component[_isMobile])
      )
      .subscribe(isNavigatedBack => {
          component[animation] = isNavigatedBack ? 'out' : component[animation];
        }
      )
  );

  subscriptions.push(
    timer(500)
      .pipe(
        take(1),
        filter(() => component[_isMobile])
      )
      .subscribe(() => {
          component[animation] = 'out';
        }
      )
  );

}

