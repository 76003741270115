import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgbAccordionModule, NgbCarouselModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '../shared/shared.module';
import { PetsRoutingModule } from './pets-routing.module';
import { PetsShopPageComponent } from './containers/pets-shop-page/pets-shop-page.component';
import { PetsComponent } from './components/pets/pets.component';
import { WhatsNewComponent } from './components/whats-new/whats-new.component';

import { reducers } from './reducers';
import { PetsEffects } from './effects/pets.effects';
import { PetPlansPageComponent } from './containers/pet-plans-page/pet-plans-page.component';
import { PetDetailsPageComponent } from './containers/pet-detail-page/pet-detail-page.component';

import { PetComponent } from './components/pet/pet.component';
import { NutritionPlanPageComponent } from './containers/nutrition-plan-page/nutrition-plan-page.component';
import { NutritionPlanComponent } from './components/nutrition-plan/nutrition-plan.component';
import { EditPetPageComponent } from './containers/edit-pet-page/edit-pet-page.component';
import { EditPetComponent } from './components/edit-pet/edit-pet.component';
import { NutritionPlanGuard } from './guards/nutrition-plan.guard';
import { NpProductsPageComponent } from '../products/containers/np-products-page/np-products-page.component';
import { NpProductsComponent } from '../products/components/np-products/np-products.component';
import { NpFiltersComponent } from '../products/components/np-filters/np-filters.component';
import { NpFiltersPageComponent } from '../products/containers/np-filters-page/np-filters-page.component';
import { PetsProfilePageComponent } from './containers/pets-profiles-page/pets-profile-page.component';
import { EditOrderDeliveryDatePage } from '../orders/containers/edit-order-delivery-date-page/edit-order-delivery-date-page.component';
import { EditOrderDeliveryDate } from '../orders/components/edit-order-delivery-date/edit-order-delivery-date.component';
import { EditOrderDeliveryModule } from '../orders/containers/edit-order-delivery-date-page/edit-order-delivery.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbAccordionModule,
    NgbCarouselModule,
    EditOrderDeliveryModule.forRoot(),
    SharedModule,
    PetsRoutingModule,
    NgbTooltipModule,
    StoreModule.forFeature('pets', reducers),
    EffectsModule.forFeature([PetsEffects])
  ],
  declarations: [
    PetsShopPageComponent,
    PetsProfilePageComponent,
    PetDetailsPageComponent,
    PetsComponent,
    PetPlansPageComponent,
    PetComponent,
    NutritionPlanPageComponent,
    NutritionPlanComponent,
    EditPetPageComponent,
    EditPetComponent,
    NpProductsComponent,
    NpProductsPageComponent,
    NpFiltersComponent,
    NpFiltersPageComponent,
    WhatsNewComponent

  ]
})
export class PetsModule {
  static forRoot(): ModuleWithProviders<PetsModule> {
    return {
      ngModule: PetsModule,
      providers: [NutritionPlanGuard],
    };
  }
}
