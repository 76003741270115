<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout d-flex flex-column">
    <div class="header-banner">
    <div class="container d-flex align-items-start pt-4">
      <div class="home-logo">
        <img src="assets/images/060118_HillsToHomeLogo.png">
      </div>
      <button class="visit-btn mt-3" (click)="openUrl()">
        <span class="w700">VISIT HILLSPET.COM</span>
        <i class="fa fa-external-link-square-alt pl-2"></i>
      </button>
    </div>
    <div class="container pt-4 mt-2">
      <!-- <div class="d-flex align-items-center">
        <i class="app-logo"></i>
      </div> -->
      <div class="password-conditions ml-4 mr-4">
        <h1 class="text-white reset-heading">Reset your password </h1>
      </div>
    </div>
 
    <form [formGroup]="authForm" autocomplete="off" class="m-4" (ngSubmit)="reset()">
      <div class="form-group"
           [class.is-invalid]="authForm.controls['newPassword'].dirty && authForm.controls['newPassword'].invalid">
        <label class="small">New password</label>
        <input name="password"
               [type]="isNewPasswordVisible ? 'text' : 'password'"
               class="form-control"
               placeholder="Password"
               formControlName="newPassword">
        <span (click)="toggleVisibility('newPassword')" [ngClass]="isNewPasswordVisible ? 'fa fas fa-eye-slash showSpan' : 'fa fas fa-eye showSpan'"></span>
      </div>
      <ul *ngIf="authForm.controls['newPassword'].dirty &&
                 authForm.controls['newPassword'].errors && authForm.controls['newPassword'].errors['pattern']"
          class="list-unstyled app-invalid-feedback small">
        <li *ngIf="authForm.controls['newPassword'].errors['pattern']">
          Must be between 8-16 characters
        </li>
        <li *ngIf="authForm.controls['newPassword'].errors['pattern']">
          Must contain at least one upper case alphabetical character
        </li>
        <li *ngIf="authForm.controls['newPassword'].errors['pattern']">
          Must contain at least one lower case alphabetical character
        </li>
        <li *ngIf="authForm.controls['newPassword'].errors['pattern']">
          Must contain at least one numeric character
        </li>
        <li *ngIf="authForm.controls['newPassword'].errors['pattern']">
          Must contain at least one of the following characters: @, #, $, %, ^, &
        </li>
        <i class="app-bottom-arrow"></i>
      </ul>
      <div class="form-group"
           [class.is-invalid]="authForm.controls['confirmPassword'].dirty && authForm.controls['confirmPassword'].invalid">
        <label for="confirmPassword" class="small">Confirm new password</label>
        <input id="confirmPassword"
               name="confirmPassword"
               [type]="isConfirmNewPasswordVisible ? 'text' : 'password'"
               class="form-control"
               placeholder="Password"
               formControlName="confirmPassword">
          <span (click)="toggleVisibility('confirmNewPassword')" [ngClass]="isConfirmNewPasswordVisible ? 'fa fas fa-eye-slash showSpan' : 'fa fas fa-eye showSpan'"></span>
      </div>

      <ul *ngIf="authForm.controls['confirmPassword'].dirty &&
                 authForm.controls['confirmPassword'].errors &&
                 authForm.controls['confirmPassword'].errors['matchPassword']"
          class="list-unstyled app-invalid-feedback small">
        <li *ngIf="authForm.controls['confirmPassword'].errors['matchPassword']">
          Password does not match
        </li>
        <i class="app-bottom-arrow"></i>
      </ul>
      <!-- <div class="google-captcha">
        <re-captcha
          name="captcha"
          required
          siteKey="{{siteKey}}"
          formControlName="recaptchaReactive"
          (resolved)="resolved($event)"
          (captchaExpired)="handleCaptchaExpiry()">
        </re-captcha>
      </div> -->


      <div *ngIf="errorMessage"
           class="alert alert-danger mt-4 small text-center">
        {{errorMessage}}
      </div>
      <div *ngIf="resetErrorMessage"
           class="alert alert-danger mt-4 small text-center">
        {{resetErrorMessage}}
      </div>
      <div class="mt-4">
        <button type="submit"
                class="btn btn-primary w-100 btn-lg"
                [disabled]="authForm.invalid">
          Save
        </button>
      </div>
    </form>
  </div>
  </div>
</ng-template>

<ng-template #fullLayout>
  <div class="app-full-layout">
    <div class="header-banner">
      <div class="container d-flex align-items-start pt-4">
        <div class="home-logo">
          <img src="assets/images/060118_HillsToHomeLogo.png">
        </div>
        <button class="visit-btn mt-3" (click)="openUrl()">
          <span class="w700">VISIT HILLSPET.COM</span>
          <i class="fa fa-external-link-square-alt pl-2"></i>
        </button>
      </div>
    <div class="container pt-6 pb-6">
      <div>
        <h2 class="text-white">Reset your password</h2>
      </div>
      <div class="d-flex">
        <form [formGroup]="authForm" class="pt-4 w-30" (ngSubmit)="reset()">
          <div class="form-group"
               [class.is-invalid]="authForm.controls['newPassword'].dirty && authForm.controls['newPassword'].invalid">
            <label for="password" class="small">New password</label>
            <input id="password"
                   name="password"
                   [type]="isNewPasswordVisible ? 'text' : 'password'"
                   class="form-control"
                   placeholder="Password"
                   formControlName="newPassword">
              <span (click)="toggleVisibility('newPassword')" [ngClass]="isNewPasswordVisible ? 'fa fas fa-eye-slash showSpan' : 'fa fas fa-eye showSpan'"></span>
          </div>
          <ul *ngIf="authForm.controls['newPassword'].dirty &&
                     authForm.controls['newPassword'].errors && authForm.controls['newPassword'].errors['pattern']"
              class="list-unstyled app-invalid-feedback small">
            <li *ngIf="authForm.controls['newPassword'].errors['pattern']">
              Must be between 8-16 characters
            </li>
            <li *ngIf="authForm.controls['newPassword'].errors['pattern']">
              Must contain at least one upper case alphabetical character
            </li>
            <li *ngIf="authForm.controls['newPassword'].errors['pattern']">
              Must contain at least one lower case alphabetical character
            </li>
            <li *ngIf="authForm.controls['newPassword'].errors['pattern']">
              Must contain at least one numeric character
            </li>
            <li *ngIf="authForm.controls['newPassword'].errors['pattern']">
              Must contain at least one of the following characters: @, #, $, %, ^, &
            </li>
            <i class="app-bottom-arrow"></i>
          </ul>
          <div class="form-group"
               [class.is-invalid]="authForm.controls['confirmPassword'].dirty && authForm.controls['confirmPassword'].invalid">
            <label for="confirmPassword" class="small">Confirm new password</label>
            <input id="confirmPassword"
                   name="confirmPassword"
                   [type]="isConfirmNewPasswordVisible ? 'text' : 'password'"
                   class="form-control"
                   placeholder="Password"
                   formControlName="confirmPassword">
            <span (click)="toggleVisibility('confirmNewPassword')" [ngClass]="isConfirmNewPasswordVisible ? 'fa fas fa-eye-slash showSpan' : 'fa fas fa-eye showSpan'"></span>
          </div>
          <ul *ngIf="authForm.controls['confirmPassword'].dirty &&
                     authForm.controls['confirmPassword'].errors &&
                     authForm.controls['confirmPassword'].errors['matchPassword']"
              class="list-unstyled app-invalid-feedback small">
            <li *ngIf="authForm.controls['confirmPassword'].errors['matchPassword']">
              Password does not match
            </li>
            <i class="app-bottom-arrow"></i>
          </ul>

          <!-- <div class="google-captcha">
            <re-captcha
              name="captcha"
              required
              siteKey="{{siteKey}}"
              formControlName="recaptchaReactive"
              (resolved)="resolved($event)">
            </re-captcha>
          </div> -->

        </form>
        <div class="flex-grow-1 d-flex flex-column align-self-center">
        </div>
      </div>
      <div *ngIf="errorMessage"
           class="alert alert-danger mt-4 small text-center">
        {{errorMessage}}
      </div>
      <div *ngIf="resetErrorMessage"
           class="alert alert-danger mt-4 small text-center">
        {{resetErrorMessage}}
      </div>
      <div>
        <button type="button"
                class="btn btn-primary pl-5 pr-5 btn-lg mr-4 mt-4 mb-4"
                (click)="reset()"
                [disabled]="authForm.invalid">
          Create Password
        </button>
      </div>
    </div>
  </div>
  </div>
</ng-template>


<ng-template #modal let-closeSuccess="close" let-dismissSuccess="dismiss">
  <div class="modal-body text-center">
    <i class="mt-4 far fa-check-circle fa-6x txt-success"></i>
    <p class="m-4 h4 text-center">
      You have successfully updated your password
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary pl-5 pr-5 btn-lg ml-2" (click)="closeSuccess()">
      Ok
    </button>
  </div>
</ng-template>