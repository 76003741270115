import { Component, Input, OnInit, OnChanges, SimpleChange, SimpleChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Registration } from '../../models/registration';
import { PasswordValidation } from '../../../shared/validation/passwordValidation';
import { Patterns } from '../../../shared/validation/patterns';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnChanges {

  authForm: UntypedFormGroup;

  @Input() username: string | null;

  @Input() errorMessage: string | null;

  @Input() isMobile: boolean | false;

  @Input() pending: boolean | false;

  @Input() isCreatePasswordVisible: boolean | false;
           isConfirmPasswordVisible: boolean | false;

  @Output() submitted = new EventEmitter<Registration>();

  @ViewChild("registerFailedModal", { static: true })
  registerFailedModal: any;

  constructor(private fb: UntypedFormBuilder, private modalService: NgbModal, private router: Router) {
    this.createForm();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    const pendingChange: SimpleChange = changes['pending'];
    if (pendingChange && !pendingChange.firstChange) {
      if (pendingChange.currentValue) {
        this.authForm.disable();
      } else {
        this.showRegisterFailDialog();
        this.authForm.enable();
        this.authForm.get('username').disable();
      }
    }
    const usernameChange: SimpleChange = changes['username'];
    if (usernameChange) {
      this.resetForm();
    }
  }

  createForm() {
    this.authForm = this.fb.group({
        username: [{ value: '', disabled: true }, [Validators.required, Validators.pattern(Patterns.emailPattern)]],
        newPassword: ['', {
          validators: [Validators.required, Validators.pattern(Patterns.pwdpattern)],
        }],
        confirmPassword: ['', {
          validators: [Validators.required],
        }]
      },
      {
        validator: PasswordValidation.matchPassword
      });
  }

  resetForm() {
    this.authForm.reset({
      username: this.username
    });
  }

  register() {
    if (this.authForm.valid && this.username) {
      const model = this.authForm.value;
      const registration: Registration = {
        newPassword: model.newPassword
      };
      this.submitted.emit(registration);
    }
  }

  toggleVisibility(passwordType){
    switch (passwordType) {
      case 'createPassword':
        this.isCreatePasswordVisible = !this.isCreatePasswordVisible;
        break;
      case 'confirmPassword':
        this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible;
        break;
    }
  }

  openUrl() {
    window.open('https://www.hillspet.com/', '_blank');
  }
/**
 * Function to show error message modal in case of PP fails to create password
 */
  showRegisterFailDialog() {
    // Using timeout because of issue here:
    // https://github.com/ng-bootstrap/ng-bootstrap/issues/1775
    setTimeout(() => {
      if (this.errorMessage) {
        this.modalService.open(this.registerFailedModal, { centered: true, size: 'sm' }).result.then(
          () => {
            this.router.navigate(['/login']);
          });
       }
    });
  }
}

