import { Pipe, PipeTransform } from '@angular/core';
import { Pet } from '../../../api';
import { PetTypes } from '../../constants/pet';

@Pipe({
  name: 'appPetImg'
})
export class PetImgPipe implements PipeTransform {

  transform(value: Pet, args?: any): any {
    let path = '';
    if (value) {
      if (value.petType === PetTypes.Dog) {
        path = 'assets/images/dog.svg';
      }
      if (value.petType === PetTypes.Cat) {
        path = 'assets/images/cat.svg';
      }
    }

    return path;
  }
}
