import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromCore from '../../../core/reducers';
import * as fromPets from '../../reducers';
import * as PetsActions from '../../actions/pets.actions';
import * as CoreActions from '../../../core/actions/core.actions';
import { Pet } from '../../../api';
import { flyOutIn } from '../../../shared/animations/common.animations';

@Component({
  selector: 'app-pet-page',
  template: `
    <app-edit-pet
      [isMobile]="isMobile$ | async"
      [pet]="petDetail$ | async"
      [pending]="changePending$ | async"
      (submitted)="onSubmit($event)"
      (succeed)="onSuccess()"
      (navigatedBack)="onNavigateBack()">
    </app-edit-pet>
  `,
  styles: [],
  animations: [flyOutIn]
})
export class EditPetPageComponent implements OnInit, OnDestroy {

  @HostBinding('@flyOutIn')
  flyOutIn = 'in';

  // @HostBinding('class.app-animated-page')
  // animatedPage = true;

  subscriptions: Subscription[] = [];

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  petDetail$ = this.store.pipe(select(fromPets.getPetDetail));

  changePending$ = this.store.pipe(select(fromPets.getPetChangePending));

  constructor(private store: Store<fromPets.State>,
    private route: ActivatedRoute) {
    this.subscriptions.push(
      route.params
        .subscribe(params => {
          const id = params['id'];
          if (id) {
            this.store.dispatch(new PetsActions.GetPetDetail(id));
          } else {
            this.store.dispatch(new PetsActions.NewPet());
          }
        })
    );
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
  }

  onSubmit(pet: Pet) {
    if (pet.petCode) {
      this.store.dispatch(new PetsActions.UpdatePet(pet));
    } else {
      this.store.dispatch(new PetsActions.AddPet(pet));
    }
  }

  onSuccess() {
    this.store.dispatch(new CoreActions.NavigateBack());
  }

  onNavigateBack() {
    this.store.dispatch(new CoreActions.NavigateBack(true));
  }
}
