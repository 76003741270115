import { DatePipe } from '@angular/common';
import * as moment from 'moment';

export class DateUtil {

  static getDateStr(date: Date | string, format: string = 'y-MM-dd'): string { // YYYY-MM-DD
    try {
      const dp = new DatePipe(navigator.language);
      const dtr = dp.transform(date, format);
      return dtr;
    } catch {
      return '';
    }
  }

  static add(date: Date | string, amount: number, unit: 'd' | 'M' | 'y' = 'd'): Date {
    const momentDate = moment(date).add(amount, unit);
    return momentDate.toDate();
  }

  static addWeekDay(date: Date | string, amount: number): Date {
    const weeks = Math.floor(amount / 5) ;
    const days = Math.floor(amount % 5);
    const momentDate = moment(date).add((weeks * 7) + days, 'd');
    const isoWeekDay = momentDate.isoWeekday();
    if (isoWeekDay === 6) {
      momentDate.add(2, 'd');
    }
    if (isoWeekDay === 7) {
      momentDate.add(1, 'd');
    }
    return momentDate.toDate();
  }

  static isBetween(date: Date | string, before: Date | string, after: Date | string): boolean {
    const momentDate = moment(date);
    const momentBefore = moment(before);
    const momentAfter = moment(after);
    return momentDate.isSameOrAfter(momentBefore, 'day') &&
      momentDate.isSameOrBefore(momentAfter, 'day');
  }

  static isAfter(date: Date | string, after: Date | string): boolean {
    const momentDate = moment(date);
    const momentAfter = moment(after);
    return momentDate.isAfter(momentAfter, 'day');
  }

  static isBefore(date: Date | string, before: Date | string): boolean {
    const momentDate = moment(date);
    const momentBefore = moment(before);
    return momentDate.isBefore(momentBefore, 'day');
  }

  static isWeekDay(date: Date | string): boolean {
    const momentDate = moment(date);
    return momentDate.isoWeekday() < 6;
  }

  static nextWeekDay(date: Date | string): Date {
    const momentDate = moment(date);
    const isoWeekDay = momentDate.isoWeekday();
    if (isoWeekDay === 6) {
      return momentDate.add(2, 'd').toDate();
    }
    if (isoWeekDay === 7) {
      return momentDate.add(1, 'd').toDate();
    }
    return momentDate.toDate();
  }

  static getMaxDate(dates: Array<Date>): Date {
    let maxDate: Date = dates.length ? dates[0] : null;
    dates.forEach(date => {
      if (DateUtil.isBefore(maxDate, date)) {
        maxDate = date;
      }
    });
    return maxDate;
  }

  static getMinDate(dates: Array<Date>): Date {
    let minDate: Date = dates.length ? dates[0] : null;
    dates.forEach(date => {
      if (DateUtil.isAfter(minDate, date)) {
        minDate = date;
      }
    });
    return minDate;
  }
}
