import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, map, switchMap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';


import {
  CoreActionTypes,
  NavigateBack,
  GetTermsAndConditions,
  GetTermsAndConditionsSuccess,
  GetPromotionContent,
  GetPromotionContentSuccess,
  GetPromotionContentFailure
} from '../actions/core.actions';

import { ContentService, TermsAndConditions} from '../../api';
import { PromotionContent } from '../../api/model/promotionContent';
import { of } from 'rxjs';

@Injectable()
export class CoreEffects {
  
  navigateBack$ = createEffect(() => this.actions$.pipe(
    ofType<NavigateBack>(CoreActionTypes.NavigateBack),
    tap((action: NavigateBack) => {
      if (action.isNavigatingBack) {
        this.location.back();
      }
    })
  ), { dispatch: false });

  
  getTermsAndConditions$ = createEffect(() => this.actions$.pipe(
    ofType<GetTermsAndConditions>(CoreActionTypes.GetTermsAndConditions),
    switchMap(() =>
      this.contentService.getTermsAndConditions()
        .pipe(
          map((terms: TermsAndConditions) =>
              new GetTermsAndConditionsSuccess(terms)
          )
        )
    )
  ));

  //catch error here with action
  
  getPromotionContent$ = createEffect(() => this.actions$.pipe(
    ofType<GetPromotionContent>(CoreActionTypes.GetPromotionContent),
    switchMap(() =>
      this.contentService.getPromotionText()
        .pipe(
          map((content: PromotionContent) =>{
            return new GetPromotionContentSuccess(content)
          }),
          catchError((error) => {
            return of(new GetPromotionContentFailure(error))
            })
        )
    )
  ))


  constructor(
    private actions$: Actions,
    private location: Location,
    private contentService: ContentService
  ) {}
}
