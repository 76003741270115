import { Component, OnInit, Input } from '@angular/core';
import { supportList } from '../../../shared/constants/faq';
import { Router } from '@angular/router';
@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  supportList: Array<supportList> = supportList;  

  selectedPanelId: string;

  @Input() isMobile: boolean | false;

  @Input() isLoggedIn: boolean | false;

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.supportList = this.supportList.slice(1);     //Removing the default object for select box, specifically added for contact us page
  }

  goToSelectedFaq(selectedFaqCode) {
    if(this.isLoggedIn) {
      this.router.navigate(['/user/support-faq', selectedFaqCode]);
    }else{
      this.router.navigate(['/support-faq', selectedFaqCode]);
    }
  }

  gotoLogin() {
    this.router.navigate(['/login']);
  }

  openUrl() {
    window.open('https://www.hillspet.com/', '_blank');
  }

}
