import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromCore from '../../../core/reducers';
import * as fromPets from '../../reducers';
import * as PetsActions from '../../actions/pets.actions';
import * as CoreActions from '../../../core/actions/core.actions';
import { AppAnimate, flyOutIn } from '../../../shared/animations/common.animations';

@Component({
  selector: 'app-nutrition-plan-page',
  template: `
    <app-nutrition-plan
      [isMobile]="isMobile$ | async"
      [nutritionPlan]="nutritionPlanDetail$ | async">
    </app-nutrition-plan>
  `,
  styles: [],
  animations: [flyOutIn]
})
export class NutritionPlanPageComponent implements OnInit, OnDestroy {

  @HostBinding('@flyOutIn')
  flyOutIn = '';

  @HostBinding('class.app-animated-page')
  animatedPage = true;

  subscriptions: Subscription[] = [];

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  nutritionPlanDetail$ = this.store.pipe(select(fromPets.getNutritionPlanDetail));

  constructor(private store: Store<fromPets.State>, private route: ActivatedRoute, private router: Router) {

    AppAnimate('flyOutIn', this, this.subscriptions);

    this.subscriptions.push(
      route.params
        .subscribe(params => {
          const id = params['id'];
          if (id) {
            this.store.dispatch(new PetsActions.GetNutritionPlanDetail(id));
          }
        })
    );
  }

  ngOnInit() {
    this.subscriptions.push(
      this.isMobile$
        .subscribe(isMobile => {
          if (!isMobile) {
            this.store.dispatch(new CoreActions.NavigateBack());
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
  }
}
