import { SupportActionTypes, SupportActionsUnion} from '../actions/support.actions';
import { Session } from '../../api';
import { ContactUsDetail } from '../models/contact-us-detail';

export interface State {
  session: Session | null;
  contactUsEmail: ContactUsDetail | null;
  contactUsEmailError: string | null;
  contactUsEmailPending: boolean;
}

export const initialState: State = {
  session: null,
  contactUsEmail: null,
  contactUsEmailError: null,
  contactUsEmailPending: false,

};

export function reducer(state = initialState, action: SupportActionsUnion): State {
  switch (action.type) {

    case SupportActionTypes.ContactUsEmail: {
      return {
        ...state,
        contactUsEmail: action.contactUsEmail,
        contactUsEmailError: null,
        contactUsEmailPending: true
      };
    }

    case SupportActionTypes.ContactUsEmailSuccess: {
      return {
        ...state,
        contactUsEmail: null,
        contactUsEmailError: null,
        contactUsEmailPending: false,
      };
    }

    case SupportActionTypes.ContactUsEmailFailure: {
      return {
        ...state,
        contactUsEmail: null,
        contactUsEmailError: action.contactUsEmailError,
        contactUsEmailPending: false,
      };
    }

    default: {
      return state;
    }
  }
}
export const getSession = (state: State) => state.session;
export const getContactUsEmail = (state: State) => state.contactUsEmail;
export const getContactUsEmailError = (state: State) => state.contactUsEmailError;
export const getContactUsEmailPending = (state: State) => state.contactUsEmailPending;