import { Pipe, PipeTransform } from '@angular/core';
import {
  DeliveryDuration,
  DeliveryDurations,
  deliveryDurations
} from '../../../../constants/payment';

@Pipe({
  name: 'toDeliveryDuration'
})
export class ToDeliveryDurationPipe implements PipeTransform {
  transform(items: Array<string>, args?: any): DeliveryDuration[] {
    if (!items) {
      return [];
    }
    return items.map(item => {
      const retVal = deliveryDurations.filter(
        aDuration => aDuration.code === item
      );
      return { name: retVal[0].name, code: retVal[0].code } as DeliveryDuration;
    });
  }
}
