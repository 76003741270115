import { Component, HostBinding, OnDestroy, OnInit, OnChanges, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromCore from '../../../core/reducers';
import * as fromPets from '../../reducers';
import * as PetsActions from '../../actions/pets.actions';
import * as CoreActions from '../../../core/actions/core.actions';
import { Pet, NutritionPlan } from '../../../api';
import { AppAnimate, flyOutIn } from '../../../shared/animations/common.animations';
import * as ProductsActions from '../../../products/actions/products.actions';

@Component({
   selector: 'app-pet-page',
   templateUrl: 'pet-plans-page.component.html',
   styles: [],
   animations: [flyOutIn]
})
export class PetPlansPageComponent implements OnInit, OnDestroy, AfterContentChecked {

   @HostBinding('@flyOutIn')
   flyOutIn = '';

   // @HostBinding('class.app-animated-page')
   // animatedPage = true;


   defaultSelectedPlan: NutritionPlan;

   subscriptions: Subscription[] = [];

   isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

   petDetail$ = this.store.pipe(select(fromPets.getPetDetail));

   nutritionPlans$ = this.store.pipe(select(fromPets.getNutritionPlans));

   wellnessPlan$ = this.store.pipe(select(fromPets.getWellnessPlan));

   inActiveNutritionPlans$ = this.store.pipe(select(fromPets.getInactiveNutritionPlans));

   nutritionPlanDetail$ = this.store.pipe(select(fromPets.getNutritionPlanDetail));

   changePending$ = this.store.pipe(select(fromPets.getPetChangePending));

   constructor(private store: Store<fromPets.State>, private route: ActivatedRoute, private router: Router, private cdr: ChangeDetectorRef) {

      AppAnimate('flyOutIn', this, this.subscriptions);

      this.subscriptions.push(
         route.params
            .subscribe(params => {
               const id = params['id'];
               if (id && id !== '0') {
                  this.store.dispatch(new PetsActions.GetPetDetail(id));
                  this.store.dispatch(new PetsActions.GetNutritionPlans(id));
               }
            })
      );
   }
   ngAfterContentChecked() {
     this.cdr.detectChanges();
    }

   ngOnInit() {
      this.subscriptions.push(this.nutritionPlans$.subscribe((plans: NutritionPlan[]) => {
         if (plans) {
               this.defaultSelectedPlan = plans[0];
               this.store.dispatch(new PetsActions.SetSelectedPlans([this.defaultSelectedPlan.id]));
         }
      }));

   }

   ngOnDestroy() {
      this.subscriptions.forEach(
         subscription => subscription.unsubscribe()
      );
   }

   onSubmit(pet: Pet) {
      if (pet.petCode) {
         this.store.dispatch(new PetsActions.UpdatePet(pet));
      } else {
         this.store.dispatch(new PetsActions.AddPet(pet));
      }
   }

   onSuccess() {
      this.store.dispatch(new CoreActions.NavigateBack());
   }

   onNutritionPlanSelect({ np, selectedNutritionPlans }) {
      let selecedPlansId = selectedNutritionPlans;
      this.store.dispatch(new PetsActions.SetSelectedPlans(selecedPlansId));

      if (selectedNutritionPlans.length) {
         // Setting selected plans 
         // setting filters and searching accordingly
         this.store.dispatch(new PetsActions.SetNutritionPlansFilter(selectedNutritionPlans));
         this.store.dispatch(new ProductsActions.SearchPlan(np.clinic.uid));
      } else {
         this.store.dispatch(new PetsActions.SetNutritionPlansFilter(selectedNutritionPlans));
         this.store.dispatch(new ProductsActions.SearchPlan(null));
      }

   }

   onNavigateBack() {
      this.store.dispatch(new CoreActions.NavigateBack(true));
   }
}
