import { Pipe, PipeTransform } from '@angular/core';
import { productSortTypes } from '../../constants/shop';

@Pipe({
  name: 'appProductSort'
})
export class ProductSortPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    let name = value;
    if (value) {
      const sortType = productSortTypes.find(ps => ps.code === value);
      if (sortType) {
        name = sortType.name;
      }
    }
    return name;
  }
}
