import { environment } from '../../../environments/environment';
import { CoreActionsUnion, CoreActionTypes } from '../actions/core.actions';
import { TermsAndConditions } from '../../api';
// import { PromotionContent } from 'src/app/api/model/promotionContent';
import { PromotionContent } from 'src/app/api/model/promotionContent';
import { ActionSequence } from 'protractor';


export interface State {
  environment: string;
  loading: boolean;
  isNavigatingBack: boolean;
  isNavigatedBack: boolean;
  coreTermsAndConditions: TermsAndConditions;
  corePromotionContent: PromotionContent
  error : string | null // track errors
}

const initialState: State = {
  environment: environment.name,
  loading: false,
  isNavigatingBack: false,
  isNavigatedBack: false,
  coreTermsAndConditions: null,
  corePromotionContent: null,
  error: null // default error state
};

export function reducer(state: State = initialState, action: CoreActionsUnion): State {
  switch (action.type) {

    case CoreActionTypes.StartLoader:
      return {
        ...state,
        loading: true,
      };


    case CoreActionTypes.StopLoader:
      return {
        ...state,
        loading: false,
      };

    case CoreActionTypes.NavigateBack: {
      return {
        ...state,
        isNavigatingBack: action.isNavigatingBack,
      };
    }

    case CoreActionTypes.NavigatedBack: {
      return {
        ...state,
        isNavigatedBack: action.isNavigatedBack,
      };
    }

    case CoreActionTypes.GetTermsAndConditions: {
      return {
        ...state,
        coreTermsAndConditions: null,
      };
    }

    case CoreActionTypes.GetTermsAndConditionsSuccess: {
      return {
        ...state,
        coreTermsAndConditions: action.terms,
      };
    }

    case CoreActionTypes.GetPromotionContent: {
      return {
        ...state,
        corePromotionContent: null
      };
    }

    case CoreActionTypes.GetPromotionContentSuccess: {
      return {
        ...state, 
        corePromotionContent: action.content,
      }
    }

    case CoreActionTypes.GetPromotionContentFailure: {
      return {
        ...state, 
        error: action.payload
      }
    }

    default:
      return state;
  }
}


export const getLoading = (state: State) => state.loading;
export const getIsNavigatingBack = (state: State) => state.isNavigatingBack;
export const getIsNavigatedBack = (state: State) => state.isNavigatedBack;
export const getTermsAndConditions = (state: State) => state.coreTermsAndConditions;
export const getPromotionText  = (state: State) => state.corePromotionContent;

