<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout p-3 px-4 custom-font">

    <!-- <h5 class="app-title text-center m-4">
      My Account
    </h5> -->

    <div class="d-flex align-items-center">
      <div class="d-flex align-items-center">
        <h3 class="mb-0">{{payment?.id ? 'Edit' : 'Add'}} Payment</h3>
      </div>
      <form *ngIf="payment?.id" novalidate [formGroup]="profileForm" class="flex-grow-1 d-flex justify-content-end ml-3" (ngSubmit)="save(!payment?.id)">
        <div class="btn-group-toggle">
          <label class="btn btn-outline-primary btn-sm pl-2" ngbButtonLabel>
            <input type="checkbox" formControlName="defaultPayment" (click)="togglePrimary()" ngbButton>Set as Primary
          </label>
        </div>
      </form>
    </div> 

    <form novalidate [formGroup]="profileForm" class="form-payment" (ngSubmit)="save(!payment?.id)">
      <p class="cards-label w500 py-1 m-0 mb-2 mt-4">We accept Visa, MasterCard and American Express</p>
      <div class="c-cards pt-3">
        <img src="assets/images/c-cards.png" alt="Credit cards" />
      </div>
      <div *ngIf="!payment?.id" class="stripe-wrapper">
        <div id="example2-card-number"></div>
        <div id="example2-card-expiry"></div>
        <div id="example2-card-cvc"></div>
        <!-- <div id="card-element-mobile" class="field pl-2 pr-2"></div> -->
      </div>
      <div *ngIf="!payment?.id" class="app-card-panel-shadow">
        <div id="card-element-mobile" class="field pl-2 pr-2"></div>
      </div>
      <div  *ngIf="payment?.id">
        <div class="d-flex border p-3 align-items-center mt-2" [class.is-primary]="payment?.defaultPaymentInfo">
          <div>
            <i [class]="payment.cardType  | cardTypeToImage: 'app-icon app-credit-card'"></i>
          </div>
          <div class="pl-3 d-flex flex-column flex-grow-1">
            <span>
              <span class="text-capitalize">{{payment.cardType}}</span>
              {{' Ending in - ' + payment.cardNumber}}
            </span>
          </div>
          <div>
            {{payment?.defaultPaymentInfo ? '(Primary)' : ''}}
          </div>
        </div>
      </div>
      <div *ngIf="changeError" class="alert alert-warning alert-dismissible mt-2 pt-2" role="alert">
        <button type="button" class="close"  aria-label="Close" (click)="clearPaymentError()"><span aria-hidden="true">&times;</span></button>
        Unable to add card. Reason - {{changeError}}
      </div>
      <div class="mt-3">
        <button type="button" class="btn btn-secondary app-outline w-100" *ngIf="payment?.id" (click)="profilePayments.payments.length > 1 ? confirm(confirmModal) : confirm(alertDeletePaymentModal)">
          Delete
        </button>
      </div>
      <div class="mt-3 mb-3">
        <button type="submit" class="btn btn-primary w-100" [disabled]="isButtonDisabled">
          Save
        </button>
        <div class="mt-3 mb-3" *ngIf="payment?.id && !isButtonDisabled">
          <p class="text-center">{{'*Saving will make the ' + payment.cardType.toUpperCase() + ' credit card ending in ' +  payment.cardNumber + ' the payment selection for all future orders on the account.'}}</p>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #fullLayout>
  <form novalidate (ngSubmit)="save(!payment?.id)" class="form-payment" [formGroup]="profileForm">
    <p class="cards-label w500 py-1 m-0 mb-2">We accept Visa, MasterCard and American Express</p>
    <div class="c-cards pt-1 border-0">
      <img src="assets/images/c-cards.png" alt="Credit cards" />
    </div>
    <div class="app-card-panel mb-n3">
      <div id="example2-card-number"></div>
      <div id="example2-card-expiry"></div>
      <div id="example2-card-cvc"></div>
      <!-- <div id="card-element" class="field pl-2 pr-2"></div> -->
    </div>
    <div *ngIf="changeError" class="alert alert-warning alert-dismissible mt-2 pt-2" role="alert">
      <button type="button" class="close" aria-label="Close" (click)="clearPaymentError()"><span
          aria-hidden="true">&times;</span></button>
      Unable to add card. Reason - {{changeError}}
    </div>
  </form>
  <div *ngIf="errorMsg" class="alert alert-error">{{errorMsg}}</div>
</ng-template>

<ng-template #successModal let-closeSuccess="close" let-dismissSuccess="dismiss">
  <div class="modal-body text-center">
    <i class="mt-4 far fa-check-circle fa-6x txt-success"></i>
    <p class="m-4 h4 text-center">
      You have successfully {{payment?.id? 'changed' : 'added'}} your payment
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary pl-5 pr-5 btn-lg ml-2" (click)="closeSuccess()">
      OK
    </button>
  </div>
</ng-template>

<ng-template #confirmModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-body">
    <p class="m-4 h4 text-center">
      Are you sure you want to delete this credit card?
    </p>
  </div>
  <div class="modal-footer">

    <button type="button" class="btn btn-primary pl-5 pr-5 btn-lg ml-2" (click)="closeConfirm()">
      I'm Sure
    </button>
    <button type="button" class="btn btn-secondary pl-5 pr-5 btn-lg" (click)="dismissConfirm()">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #alertDeletePaymentModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-header">
    <div class="flex-grow-1">
      <h4 class="modal-title text-center">Delete Payment Method</h4>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="dismissConfirm()">
      <i class="fa fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <p class="m-4 h4 text-center">
      Please add a new payment method before removing this one.
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary pl-5 pr-5 btn-lg" (click)="dismissConfirm()" ngbAutofocus>
      Ok
    </button>
  </div>
</ng-template>