import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  years,
  months,
  cardTypes,
  Month,
  Year,
  CardType
} from '../../../shared/constants/payment';
import { Payment } from '../../../api';
import { ProfilePayments } from '../../models/ProfilePayments';
import { StripeService } from "ngx-stripe";
import {
  StripeElements,
  StripeCardElement,
  StripeCardElementOptions,
  StripeElementsOptions,
  StripeCardNumberElement,
  StripeCardExpiryElement,
  StripeCardCvcElement,
  Token
} from '@stripe/stripe-js';
import { PaymentBag } from '../../models/paymentBag';
import { map } from 'rxjs/internal/operators/map';

@Component({
  selector: 'app-edit-payment',
  templateUrl: './edit-payment.component.html',
  styleUrls: ['./edit-payment.component.scss']
})
export class EditPaymentComponent implements OnChanges, AfterViewInit, OnInit {
 // elements: Elements;
  elements: StripeElements;
  card: StripeCardElement;
//  public cardView: StripeCardElement;
  public cardNumberView: StripeCardNumberElement;
  public cardExpiryView: StripeCardExpiryElement;
  public cardCvcView: StripeCardCvcElement;

    elementsOptions: StripeElementsOptions = {
      locale: 'en'
    };
  
    // @ViewChild('card-element-mobile', { read: ElementRef })
    // mobileControl: ElementRef;
  
    @ViewChild('example2-card-number', { read: ElementRef})
    mobileControlCardNumber: ElementRef;
  
    @ViewChild('example2-card-expiry', { read: ElementRef })
    mobileControlCardExpiry: ElementRef;
  
    @ViewChild('example2-card-cvc', { read: ElementRef })
    mobileControlCardCVC: ElementRef;
  
    profileForm: UntypedFormGroup;
  
    months: Month[] = months;
  
    years: Year[] = years;
  
    showPaymentPopup: boolean = true;
  
    @Input()
    isMobile: boolean | false;
  
    @Input()
    isCart = false;
  
    @Input()
    changeError: string | null;
  
    @Input()
    changePending: boolean | false;
  
    @Input()
    removalPending: boolean | false;
  
    @Input()
    payment: Payment | null;
  
    @Input()
    profilePayments: ProfilePayments | null;
  
    @Input()
    accountHolder: string | null;
  
    @Output()
    submitted = new EventEmitter<PaymentBag>();
  
    @Output()
    removed = new EventEmitter<Payment>();
  
    @Output() navigatedBack = new EventEmitter();
  
    @ViewChild('successModal', { static: true })
    successModal: any;
  
    @ViewChild('alertDeletePaymentModal', { static: true })
    alertDeletePaymentModal: any;
  
    token: Token | null;
    //To prevent fast click - Save payment card.
    isButtonDisabled: boolean;
  
    elementStyles = {
      base: {
        color: '#32325D',
        fontWeight: 500,
        fontFamily: 'Montserrat',
        fontSize: '1.2rem',
        fontSmoothing: 'antialiased',
  
        '::placeholder': {
          color: '#CFD7DF',
        },
        ':-webkit-autofill': {
          color: '#e39f48',
        },
      },
      invalid: {
        color: '#E25950',
  
        '::placeholder': {
          color: '#FFCCA5',
        },
      },
    }
  
    elementClasses = {
      focus: 'focused',
      empty: 'empty',
      invalid: 'invalid',
    };
  
    constructor(
      private fb: UntypedFormBuilder,
      private modalService: NgbModal,
      private router: Router,
      private stripeService: StripeService
    ) {
      this.profileForm = this.fb.group({
        defaultPayment: fb.control(false, Validators.nullValidator)
      });
    }
  
    ngAfterViewInit() {
      this.stripeService.elements(this.elementsOptions).subscribe(elements => {
        this.elements = elements;
        // Only mount the element the first time
        if (!this.cardNumberView) {
          this.cardNumberView = this.elements.create('cardNumber', {
            style: this.elementStyles,
          });
        }
  
        if (!this.cardExpiryView) {
          this.cardExpiryView = this.elements.create('cardExpiry', {
            style: this.elementStyles,
          });
        }
  
        if (!this.cardCvcView) {
          this.cardCvcView = this.elements.create('cardCvc', {
            style: this.elementStyles,
          });
        }
  
        if (this.isMobile) {
          if (this.payment && !this.payment.id) {
            //  this.cardView.mount('#card-element-mobile');
            this.cardNumberView.mount('#example2-card-number');
            this.cardExpiryView.mount('#example2-card-expiry');
            this.cardCvcView.mount('#example2-card-cvc');
          }
        } else if (!this.isMobile) {
          // this.cardView.mount('#card-element');
          this.cardNumberView.mount('#example2-card-number');
          this.cardExpiryView.mount('#example2-card-expiry');
          this.cardCvcView.mount('#example2-card-cvc');
        }
  
      });
    }
  
    ngOnInit() { }
  
    ngOnChanges(changes: SimpleChanges) {
      const paymentChange: SimpleChange = changes['payment'];
      if (paymentChange) {
        this.resetForm();
      }
      const changePendingChange: SimpleChange = changes['changePending'];
      if (changePendingChange && !changePendingChange.firstChange) {
        if (changePendingChange.currentValue) {
          this.profileForm.disable();
        } else {
          this.profileForm.enable();
          if (!this.changeError) {
            this.success();
          }
        }
      }
      const removalPendingChange: SimpleChange = changes['removalPending'];
      if (
        removalPendingChange &&
        !removalPendingChange.firstChange &&
        !removalPendingChange.currentValue
      ) {
        this.router.navigate([!this.isCart ? '/profile' : '/place-order']);
      }
      // On desktop redirect back to master page
      const isMobileChange: SimpleChange = changes['isMobile'];
      if (
        isMobileChange &&
        !isMobileChange.firstChange &&
        !isMobileChange.currentValue
      ) {
        this.router.navigate([!this.isCart ? '/profile' : '/place-order']);
      }
    }
  
    resetForm() {
      if (this.payment) {
        this.profileForm.reset({
          defaultPayment: this.payment.defaultPaymentInfo
        });
        // When a credit card, disable, otherwise enable
        if (this.payment.id) {
          this.profileForm.disable();
          this.isButtonDisabled = true;
  
          // Only allowed to set the payment when editing
          if (!this.payment.defaultPaymentInfo) {
            this.profileForm.get('defaultPayment').enable();
          }else{
            this.profileForm.get('defaultPayment').disable();
          }
        } else {
          this.profileForm.enable();
        }
      }
    }
  
    save(isAdd) {
      if (isAdd) {
        this.isButtonDisabled = true;
        if (this.accountHolder) {
          const name = this.accountHolder;
          this.stripeService
            .createToken(this.cardNumberView, {
              name: this.accountHolder
            })
            .subscribe(result => {
              this.isButtonDisabled = false;
              if (result.token) {
                this.token = result.token;
                const bag: PaymentBag = {
                  payment: this.payment,
                  tokenId: this.token.id
                };
                this.submitted.emit(bag);
                this.showPaymentPopup = false;
                // 'Duplicate payment info getting saved' issue fixed - Rahul Solanki  
                this.changeError = null; // so that the error message is removed and if details are correct then success function is called.
                // if details are incorrect then this changeError variable is set to suitable error message.
                // END
              } else if (result.error) {
                this.changeError = result.error.message;
              }
            });
        } else {
          this.stripeService
            .createToken(this.cardNumberView, { name: '' })
            .subscribe(result => {
              this.isButtonDisabled = false;
              if (result.token) {
                this.token = result.token;
                const bag: PaymentBag = {
                  payment: this.payment,
                  tokenId: this.token.id
                };
                this.submitted.emit(bag);
                this.showPaymentPopup = false;
                // 'Duplicate payment info getting saved' issue fixed - Rahul Solanki  
                this.changeError = null; // so that the error message is removed and if details are correct then success function is called.
                // if details are incorrect then this changeError variable is set to suitable error message.
                // END
              } else if (result.error) {
                this.changeError = result.error.message;
              }
            });
        }
      } else {
        /* Mark Card as Primary only if its not a default payment method */
        if(!this.payment.defaultPaymentInfo){
          const pBag: PaymentBag = {
            payment: this.payment
          };
          this.submitted.emit(pBag);
        }
        this.showPaymentPopup = false
      }
    }
  
    success() {
      // Using timeout because of issue here:
      // https://github.com/ng-bootstrap/ng-bootstrap/issues/1775
      setTimeout(() => {
        this.modalService.open(this.successModal).result.then(
          () => {
            this.router.navigate([!this.isCart ? '/profile' : '/place-order']);
          },
          () => {
            this.router.navigate([!this.isCart ? '/profile' : '/place-order']);
          }
        );
      });
    }
  
    confirm(content) {
      this.modalService.open(content).result.then(
        () => {
          if(this.profilePayments.payments.length > 1)
            this.remove();
        },
        () => { }
      );
    }
  
    remove() {
      this.removed.emit(this.payment);
    }
  
    clearPaymentError() {
      this.changeError = null;
    }
  
    hideAddressPopup() {
      this.showPaymentPopup = false;
      this.navigatedBack.emit();
    }
  
    /**
   * Enabling/Disabling of Save button based on Primary Button click
   */
    togglePrimary(){
      if(this.payment.id){
        if(this.isButtonDisabled)
          this.isButtonDisabled = false;
        else
          this.isButtonDisabled = true;
      }
    }
  }