export interface Month {
  name: string;
  code: string;
}

export interface Year {
  name: string;
  code: string;
}

export interface CardType {
  name: string;
  code: string;
}

export interface DeliveryFrequency {
  name: string;
  code: string;
}

export interface DeliveryDuration {
  name: string;
  code: string;
}

export interface OrderStatus {
  name: string;
  code: string;
}

export interface NextDeliveryDateSelection {
  name: string;
  code: string;
}

export const months: Month[] = [
  {
    name: '(1) January',
    code: '01'
  },
  {
    name: '(2) February',
    code: '02'
  },
  {
    name: '(3) March',
    code: '03'
  },
  {
    name: '(4) April',
    code: '04'
  },
  {
    name: '(5) May',
    code: '05'
  },
  {
    name: '(6) June',
    code: '06'
  },
  {
    name: '(7) July',
    code: '07'
  },
  {
    name: '(8) August',
    code: '08'
  },
  {
    name: '(9) September',
    code: '09'
  },
  {
    name: '(10) October',
    code: '10'
  },
  {
    name: '(11) November',
    code: '11'
  },
  {
    name: '(12) December',
    code: '12'
  }
];

export const getYears = (amount: number): Year[] => {
  const year = new Date().getFullYear();
  const start = year;
  const end = year + amount;
  const result: Year[] = [];
  for (let i = end; i > start; i--) {
    result.push({
      name: '' + i,
      code: ('' + i).substr(2, 2)
    });
  }
  return result;
};

export const years: Year[] = getYears(10);

export enum CardTypeCodes {
  Visa = 'visa',
  MasterCard = 'master',
  Amex = 'amex',
}

export const cardTypes: CardType[] = [
  {
    name: 'Visa',
    code: CardTypeCodes.Visa
  },
  {
    name: 'Mastercard',
    code: CardTypeCodes.MasterCard
  },
  {
    name: 'Amex',
    code: CardTypeCodes.Amex
  }
];

export enum LabelTypes {
  Frequency = 'frequency',
  Duration  = 'duration'
}

export enum DeliveryModeCodes {
  SelectedDate = 'selecteddate-gross',
  Standard = 'standard-gross',
  NextDay = 'nextday-gross',
}

export enum DeliveryFrequencies {
  OneTime = 'ONETIME',
  Week1 = 'WEEK_1',
  Week2 = 'WEEK_2',
  Week3 = 'WEEK_3',
  Week4 = 'WEEK_4',
  Week5 = 'WEEK_5',
  Week6 = 'WEEK_6',
  Week7 = 'WEEK_7',
  Week8 = 'WEEK_8',
  Week9 = 'WEEK_9',
  Week10 = 'WEEK_10',
  Week11 = 'WEEK_11',
  Week12 = 'WEEK_12',
}

export enum DeliveryDurations {
  Unlimited = 'UNLIMITED',
  Month1 = 'MONTH_1',
  Month2 = 'MONTH_2',
  Month3 = 'MONTH_3',
  Month4 = 'MONTH_4',
  Month5 = 'MONTH_5',
  Month6 = 'MONTH_6',
  Month7 = 'MONTH_7',
  Month8 = 'MONTH_8',
  Month9 = 'MONTH_9',
  Month10 = 'MONTH_10',
  Month11 = 'MONTH_11',
  Month12 = 'MONTH_12',
}

export enum NextDeliveryDateSelections {
  ShipinNow = 'Week_0',
  Shipin1 = 'WEEK_1',
  Shipin2 = 'WEEK_2',
  Shipin3 = 'WEEK_3',
  ShipinCustom = 'WEEK_n',
}

export const deliveryFrequencies: DeliveryFrequency[] = [
  {
    name: 'One time shipment',
    code: DeliveryFrequencies.OneTime
  },
  {
    name: 'Every 1 week',
    code: DeliveryFrequencies.Week1
  },
  {
    name: 'Every 2 weeks',
    code: DeliveryFrequencies.Week2
  },
  {
    name: 'Every 3 weeks',
    code: DeliveryFrequencies.Week3
  },
  {
    name: 'Every 4 weeks',
    code: DeliveryFrequencies.Week4
  },
  {
    name: 'Every 5 weeks',
    code: DeliveryFrequencies.Week5
  },
  {
    name: 'Every 6 weeks',
    code: DeliveryFrequencies.Week6
  },
  {
    name: 'Every 7 weeks',
    code: DeliveryFrequencies.Week7
  },
  {
    name: 'Every 8 weeks',
    code: DeliveryFrequencies.Week8
  },
  {
    name: 'Every 9 weeks',
    code: DeliveryFrequencies.Week9
  },
  {
    name: 'Every 10 weeks',
    code: DeliveryFrequencies.Week10
  },
  {
    name: 'Every 11 weeks',
    code: DeliveryFrequencies.Week11
  },
  {
    name: 'Every 12 weeks',
    code: DeliveryFrequencies.Week12
  },
];

export const deliveryDurations: DeliveryDuration[] = [
  {
    name: 'Unlimited',
    code: DeliveryDurations.Unlimited
  },
  {
    name: '1 month',
    code: DeliveryDurations.Month1
  },
  {
    name: '2 months',
    code: DeliveryDurations.Month2
  },
  {
    name: '3 months',
    code: DeliveryDurations.Month3
  },
  {
    name: '4 months',
    code: DeliveryDurations.Month4
  },
  {
    name: '5 months',
    code: DeliveryDurations.Month5
  },
  {
    name: '6 months',
    code: DeliveryDurations.Month6
  },
  {
    name: '7 months',
    code: DeliveryDurations.Month7
  },
  {
    name: '8 months',
    code: DeliveryDurations.Month8
  },
  {
    name: '9 months',
    code: DeliveryDurations.Month9
  },
  {
    name: '10 months',
    code: DeliveryDurations.Month10
  },
  {
    name: '11 months',
    code: DeliveryDurations.Month11
  },
  {
    name: '12 months',
    code: DeliveryDurations.Month12
  },
];

export enum OrderStatuses {
  Active = 'ACTIVE',
  Cancelling = 'CANCELLING',
  CheckedValid = 'CHECKED_VALID',
  Created = 'CREATED',
  Open = 'OPEN',
  SentToFulfillment = 'SENT_TO_FULFILLMENT',
  CheckedInvalid = 'CHECKED_INVALID',
  OnValidation = 'ON_VALIDATION',
  PendingApproval = 'PENDING_APPROVAL',
  SentToFulfillmentError = 'SENT_TO_FULFILLMENT_ERROR',
  Suspended = 'SUSPENDED',
  Completed = 'COMPLETED',
  OutForDelivery = 'OUT_FOR_DELIVERY',
  PaymentAuthorized = 'PAYMENT_AUTHORIZED',
  PendingApprovalFromMerchant = 'PENDING_APPROVAL_FROM_MERCHANT',
  Cancelled = 'CANCELLED',
  Hold = 'ON_HOLD',
  PaymentNotAuthorized = 'PAYMENT_NOT_AUTHORIZED',
  PendingQuote = 'PENDING_QUOTE',
  ApprovedQuote = 'APPROVED_QUOTE',
  PaymentAmountReserved = 'PAYMENT_AMOUNT_RESERVED',
  Pending = 'PENDING',
  InProgress = 'IN_PROGRESS',
  PaymentAmountNotReserved = 'PAYMENT_AMOUNT_NOT_RESERVED',
  RejectedQuote = 'REJECTED_QUOTE',
  Approved = 'APPROVED',
  PaymentCaptured = 'PAYMENT_CAPTURED',
  Shipped = 'SHIPPED',
  PaymentNotCaptured = 'PAYMENT_NOT_CAPTURED',
  Rejected = 'REJECTED',
  ApprovedByMerchant = 'APPROVED_BY_MERCHANT',
  FraudCheck = 'FRAUD_CHECKED',
  OrderSplit = 'ORDER_SPLIT',
  RejectedByMerchant = 'REJECTED_BY_MERCHANT',
  AssignedToAdmin = 'ASSIGNED_TO_ADMIN',
  ProcessingError = 'PROCESSING_ERROR',
  B2BProcessingError = 'B2B_PROCESSING_ERROR',
  WaitFraudManualCheck = 'WAIT_FRAUD_MANUAL_CHECK',
  SkipNextDelivery = 'SKIP_NEXT_DELIVERY',
  PartialReturn = 'PARTIAL_RETURN',
  Return = 'RETURN',
  ReturnInitiated = 'RETURN_INITIATED',
  OutOfStock = 'OUTOFSTOCK',
  OrderProcessing = 'ORDER_PROCESSING',   //when order is generated in SAP
  CancelledUnavailable = 'CANCELLED_UNAVAILABLE',
  PaymentIssue = 'PAYMENT_ISSUE',
  PartialShipped= 'PARTIAL_SHIPPED'
}

export const orderStatuses: OrderStatus[] = [
  {
    name: 'Active',
    code: OrderStatuses.Active
  },
  {
    name: 'Cancelling',
    code: OrderStatuses.Cancelling
  },
  {
    name: 'Cancelled',
    code: OrderStatuses.Cancelled
  },
  {
    name: 'Created',
    code: OrderStatuses.Created
  },
  {
    name: 'Open',
    code: OrderStatuses.Open
  },
  {
    name: 'Pending Approval',
    code: OrderStatuses.PendingApproval
  },
  {
    name: 'Suspended',
    code: OrderStatuses.Suspended
  },
  {
    name: 'Completed',
    code: OrderStatuses.Completed
  },
  {
    name: 'Out For Delivery',
    code: OrderStatuses.OutForDelivery
  },
  {
    name: 'On Hold',
    code: OrderStatuses.Hold
  },
  {
    name: 'Pending',
    code: OrderStatuses.Pending
  },
  {
    name: 'In Progress',
    code: OrderStatuses.InProgress
  },
  {
    name: 'Approved',
    code: OrderStatuses.Approved
  },
  {
    name: 'Shipped',
    code: OrderStatuses.Shipped
  },
  {
    name: 'Rejected',
    code: OrderStatuses.Rejected
  },
  {
    name: 'Partial Return',
    code: OrderStatuses.PartialReturn
  },
  {
    name: 'Return',
    code: OrderStatuses.Return
  },
  {
    name: 'Skip Next Delivery',
    code: OrderStatuses.SkipNextDelivery
  },
  {
    name: 'Return Initiated',
    code: OrderStatuses.ReturnInitiated
  },
  {
    name: 'Temporarily Unavailable',
    code: OrderStatuses.OutOfStock
  },
  {
    name: 'Order Processing',
    code: OrderStatuses.OrderProcessing
  },
  {
    name: 'Cancelled Unavailable',
    code: OrderStatuses.CancelledUnavailable
  },
  {
    name: 'Payment Issue',
    code: OrderStatuses.PaymentIssue
  },
  {
    name: 'Partial Shipped',
    code: OrderStatuses.PartialShipped
  }
];

export const nextDeliveryDateSelection: NextDeliveryDateSelection[] = [
  {
    name: 'Ship Now',
    code: NextDeliveryDateSelections.ShipinNow
  },
  {
    name: 'Ship in 1 week',
    code: NextDeliveryDateSelections.Shipin1
  },
  {
    name: 'Ship in 2 week',
    code: NextDeliveryDateSelections.Shipin2
  },
  {
    name: 'Ship in 3 week',
    code: NextDeliveryDateSelections.Shipin3
  },
  {
    name: 'Choose Custome Date:',
    code: NextDeliveryDateSelections.ShipinCustom
  },
];
