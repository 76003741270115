import { Component, HostBinding, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromProfile from '../../reducers';
import * as fromCore from '../../../core/reducers';
import * as ProfileActions from '../../actions/profile.actions';
import { NameChange } from '../../models/nameChange';
import { flyOutIn } from '../../../shared/animations/common.animations';

@Component({
  selector: 'app-edit-name-page',
  template: `
    <app-edit-name
      [isMobile]="isMobile$ | async"
      [pending]="pending$ | async"
      [profile]="profile$ | async"
      (submitted)="onSubmit($event)">
    </app-edit-name>
  `,
  styles: [],
  animations: [flyOutIn]
})
export class EditNamePageComponent implements OnInit {

  @HostBinding('@flyOutIn')
  flyOutIn = 'in';

  // @HostBinding('class.app-animated-page')
  // animatedPage = true;

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  pending$ = this.store.pipe(select(fromProfile.getProfileNameChangePending));

  profile$ = this.store.pipe(select(fromProfile.getProfile));

  constructor(private store: Store<fromProfile.State>) {
  }

  ngOnInit() {
  }

  onSubmit(nameChange: NameChange) {
    this.store.dispatch(new ProfileActions.UpdateName(nameChange));
  }

}
