import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromRoot from '../../../reducers';
import * as fromCore from '../../../core/reducers';
import * as fromPets from '../../reducers';
import { AppAnimate, fade } from '../../../shared/animations/common.animations';
import * as PetsActions from '../../actions/pets.actions';
import * as AuthActions from '../../../auth/actions/auth.actions';

// Whats-new pop up with dynamic content - Rahul Solanki
@Component({
  selector: 'app-pets-page',
  template: `
    <app-pets
      [isMobile]="isMobile$ | async"
      [pets]="pets$ | async"
      [showPetProfile]="false"
      [whatsNewContent]="whatsNewContent$ | async"
      [whatsNewFlag]="whatsNewFlag$ | async"
      (gotItButtonClick)="setWhatsNewFlag()"
      (resetWhatsNewFlag)="resetWhatsNewFlag()">
    </app-pets>
  `,
  styles: [],
  animations: [fade]
})
// End

export class PetsShopPageComponent implements OnInit, OnDestroy {

  @HostBinding('@fade')
  fade = '';

  // @HostBinding('class.app-animated-page')
  // animatedPage = true;

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  pets$ = this.store.pipe(select(fromPets.getPets));

  // Whats-new pop up with dynamic content - Rahul Solanki
  whatsNewFlag$ = this.store.pipe(select(fromPets.getWhatsNewFlag));
  whatsNewContent$ = this.store.pipe(select(fromPets.getWhatsNewContent));
  // End

  subscriptions: Subscription[] = [];

  constructor(private store: Store<fromRoot.State>) {
    AppAnimate('fade', this, this.subscriptions);
  }

  // Whats-new pop up with dynamic content - Rahul Solanki
  ngOnInit() {
    this.store.dispatch(new PetsActions.GetPets());
    this.store.dispatch(new AuthActions.GetCSRFToken());
    this.store.dispatch(new PetsActions.GetWhatsNewContent());
  }

  setWhatsNewFlag(){
    this.store.dispatch(new PetsActions.SetWhatsNewFlag());
  }
  // End

  resetWhatsNewFlag(){
    this.store.dispatch(new PetsActions.ResetWhatsNewFlag());   //Reset Flag to true, after showing the popup - To avoid the issue of PreviousState on Logout
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
  }
}

