import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import * as ErrorActions from './core/actions/error.actions';

@Injectable()
export class AppErrorHandler extends ErrorHandler {

  constructor(private injector: Injector) {
    super();
  }

  handleError(error) {
    super.handleError(error);

    const store = this.injector.get(Store);
    store.dispatch(new ErrorActions.ReportError(error));
  }
}
