import { Pipe, PipeTransform } from '@angular/core';
import {
  DeliveryFrequency,
  DeliveryFrequencies
} from '../../../../constants/payment';

@Pipe({
  name: 'toDeliveryFrequency'
})
export class ToDeliveryFrequencyPipe implements PipeTransform {
  transform(items: Array<string>, args?: any):  DeliveryFrequency[] {
    if (!items) {
      return [];
    }
    return this.getAvailableFrequencies(items);
  }

  createNameFrom(code: string) {
    if (code.startsWith('WEEK')) {
      const weeknum = Number(code.split(/_/g)[1]);
      const plural = weeknum > 1 ? 's' : '';
      return `Every ${weeknum} week${plural}`;
    } else if (code === DeliveryFrequencies.OneTime) {
      return 'One time shipment';
    }
    return code;
  }

  getAvailableFrequencies(availableDFs: Array<string>) {
    const filteredFrequencies: DeliveryFrequency[] = availableDFs.map(
      availableDF => {
        const availDF = availableDF.toUpperCase();
        return {
          code: availDF,
          name: this.createNameFrom(availDF)
        } as DeliveryFrequency;
      });
    return filteredFrequencies;
  }
}
