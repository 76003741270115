import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appPlanLength'
})
export class PlanLengthPipe implements PipeTransform {

  planLength = {
    'M1': '1 month',
    'M2': '2 months',
    'M3': '3 months',
    'M4': '4 months',
    'M5': '5 months',
    'M6': '6 months',
    'M7': '7 months',
    'M8': '8 months',
    'M9': '9 months',
    'M10': '10 months',
    'M11': '11 months',
    'M12': '12 months',
  };

  transform(value: string, args?: any): any {
    let name = value;
    if (value) {
      name = this.planLength[value];
    }
    return name;
  }
}
