import { Action } from '@ngrx/store';
import { TermsAndConditions } from '../../api';
import { PromotionContent } from '../../api/model/promotionContent';

export enum CoreActionTypes {
  Noop = '[Core] Noop',
  StartLoader = '[Core] Start Loader',
  StopLoader = '[Core] Stop Loader',
  NavigateBack = '[Core] Navigate Back',
  NavigatedBack = '[Core] Navigated Back',
  GetTermsAndConditions = '[Core] Get Terms And Conditions',
  GetTermsAndConditionsSuccess =  '[Core] Get Terms And Conditions Success',
  GetPromotionContent = '[Core] Get Promotion Content',
  GetPromotionContentSuccess = '[Core] Get Promotion Content Success',
  GetPromotionContentFailure = '[Core] Get Promotion Content Failure',
}

export class Noop implements Action {
  readonly type = CoreActionTypes.Noop;

  constructor() { }
}

export class StartLoader implements Action {
  readonly type = CoreActionTypes.StartLoader;

  constructor() { }
}

export class StopLoader implements Action {
  readonly type = CoreActionTypes.StopLoader;

  constructor() { }
}

export class NavigateBack implements Action {
  readonly type = CoreActionTypes.NavigateBack;

  constructor(public isNavigatingBack: boolean = true) {}
}

export class NavigatedBack implements Action {
  readonly type = CoreActionTypes.NavigatedBack;

  constructor(public isNavigatedBack: boolean = true) {}
}

export class GetTermsAndConditions implements Action {
  readonly type = CoreActionTypes.GetTermsAndConditions;

  constructor() {}
}

export class GetTermsAndConditionsSuccess implements Action {
  readonly type = CoreActionTypes.GetTermsAndConditionsSuccess;

  constructor(public terms: TermsAndConditions) {}
}

export class GetPromotionContent implements Action {
  readonly type = CoreActionTypes.GetPromotionContent;  
  constructor(){}

}

export class GetPromotionContentSuccess implements Action {
  readonly type = CoreActionTypes.GetPromotionContentSuccess;
  
  constructor(public content: PromotionContent) {}
}


export class GetPromotionContentFailure implements Action {
  readonly type = CoreActionTypes.GetPromotionContentFailure;
  
  constructor(public payload: any) {}
}

export type CoreActionsUnion =
  | Noop
  | StartLoader
  | StopLoader
  | NavigateBack
  | NavigatedBack
  | GetTermsAndConditions
  | GetTermsAndConditionsSuccess
  | GetPromotionContent
  | GetPromotionContentSuccess
  | GetPromotionContentFailure;

