import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, take, tap, exhaustMap, catchError } from 'rxjs/operators';

import * as fromSupport from '../reducers';
import * as fromAuth from '../../auth/reducers';
import {
    ContactUsEmail, ContactUsEmailFailure, ContactUsEmailSuccess, SupportActionTypes
} from '../actions/support.actions';
import { Authenticate } from '../../auth/models/authenticate';
import { SupportService } from 'src/app/api/api/support.service';
import { ContactUsDetail } from '../models/contact-us-detail';
import { ReportError } from 'src/app/core/actions/error.actions';
import { ContactUsError } from 'src/app/api/model/contactUsError';

@Injectable()
export class SupportEffects {

  getAuthenticate = ((): Observable<Authenticate> =>
    this.store.pipe(
      select(fromAuth.getAuthenticate),
      take(1)
    ));

    
    contactUsEmail$ = createEffect(() => this.actions$
      .pipe(
        ofType<ContactUsEmail>(SupportActionTypes.ContactUsEmail),
        map((action: ContactUsEmail) => {
          return action.contactUsEmail;
        }),
        exhaustMap((auth: ContactUsDetail) =>
          this.supportService
            .contactUsEmail(auth)
            .pipe(
              map(() => new ContactUsEmailSuccess()),
              catchError((payload: { error: {errors: Array<ContactUsError> } }) => {
                let errorMsg = '';
                if (payload && payload.error && payload.error.errors && payload.error.errors.length) {
                  errorMsg = payload.error.errors[0].message;
                }
                return of(new ContactUsEmailFailure(errorMsg));
              })
            )
        )
      ));

  
  contactUsEmailSuccess$ = createEffect(() => this.actions$
    .pipe(
      ofType<ContactUsEmailSuccess>(SupportActionTypes.ContactUsEmailSuccess),
      tap(() => {
        this.router.navigate(['/contact-us-response'], { queryParams: { success: true}});
      })
    ), {dispatch: false});

  
  ContactUsEmailFailure$ = createEffect(() => this.actions$
    .pipe(
      ofType<ContactUsEmailFailure>(SupportActionTypes.ContactUsEmailFailure),
      tap(() => {
        this.router.navigate(['/contact-us-response'], { queryParams: { success: false}});
      })
    ), {dispatch: false});

    constructor(private store: Store<fromSupport.State>,
        private actions$: Actions,
        private router: Router,
        private supportService: SupportService) {
    }
}


