import { Pipe, PipeTransform } from '@angular/core';
import { AutoShipTemplate } from '../../../api';

@Pipe({
  name: 'appSubscriptionCancellationLink'
})
export class SubscriptionCancellationLinkPipe implements PipeTransform {

  transform(value: AutoShipTemplate, args?: any): any {
    let result = '';
    if (value) {
      result = 'mailto:hillstohome@hillspet.com?subject=Subscription Cancellation Request for Subscription #' +
        value.subscriptionId +
        '&body=' +
        'Please cancel my Hill\'s to Home subscription.  I no longer want to receive this product.';
    }
    return result;
  }
}
