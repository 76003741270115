import {AbstractControl} from "@angular/forms";

export function checkAddressValidator(control: AbstractControl ) { 
    let selectedValue = ''+control.value;
    
    const mainString = (selectedValue.toString()).replace(/\s/g, '').replace(/\./g, '').toLowerCase();

    if(mainString.includes('pobox')) {
    return  {
      poBox:  true
      }; 
    }
    return null;
    
  }
  export function checkRegionValidator(control: AbstractControl ) { 
    let selectedValue = ''+control.value;
    const mainString = (selectedValue.toString()).replace(/\s/g, '').replace(/\./g, '').toLowerCase();
    if(mainString == 'default') {
      return  {
        defaultVal:  true
        };
    }
    return null;
    
  }