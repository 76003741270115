import { Action } from '@ngrx/store';

export enum ErrorActionTypes {
  ReportError = '[Error] Report Error',
  ErrorRedirect = '[Error] Error Redirect',
  ClearError = '[Error] Clear Error'
}

export class ReportError implements Action {
  readonly type = ErrorActionTypes.ReportError;

  constructor(public unhandledError: any) { }
}

export class ErrorRedirect implements Action {
  readonly type = ErrorActionTypes.ErrorRedirect;

  constructor(public noRedirect?: boolean) { }
}

export class ClearError implements Action {
  readonly type = ErrorActionTypes.ClearError;
}

export type ErrorActionsUnion =
  | ReportError
  | ErrorRedirect 
  | ClearError
  ;
