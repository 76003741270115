import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { skip, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import * as fromCore from '../../../core/reducers';
import * as fromOrders from '../../reducers';
import * as OrdersActions from '../../actions/orders.actions';
import { flyOutIn } from '../../../shared/animations/common.animations';
import { OrderDetail, Order } from '../../../api';

@Component({
  selector: 'app-order-page',
  template: `
    <app-order
      [isHome]="isHome"
      [isMobile]="isMobile$ | async"
      [order]="orderDetail$ | async"      
      (submitted)="onSubmit($event)"
      (returning)="onReturn($event)">
    </app-order>
  `,
  styles: [],
  animations: [flyOutIn]
})
export class OrderPageComponent implements OnInit, OnDestroy {

  @HostBinding('@flyOutIn')
  flyOutIn = 'in';

  // @HostBinding('class.app-animated-page')
  // animatedPage = true;

  subscriptions: Subscription[] = [];

  isHome = false;

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  orderDetail$ = this.store.pipe(select(fromOrders.getOrderDetail));

  orderChangePending$ = this.store.pipe(select(fromOrders.getOrderChangePending));

  constructor(private store: Store<fromOrders.State>, private route: ActivatedRoute, private router: Router) {
    this.subscriptions.push(
      route.params
        .subscribe(params => {
          const id = params['id'];
          if (id) {
            this.store.dispatch(new OrdersActions.GetOrderDetail(id));
          }
        })
    );

    this.subscriptions.push(
      route.data
        .subscribe(params => {
          this.isHome = params['fromHome'];
        })
    );
  }

  ngOnInit() {
    // On desktop redirect back to master page
    this.subscriptions.push(
      this.isMobile$
        .subscribe(isMobile => {
          if (!isMobile) {
            this.router.navigate([!this.isHome ? '/orders' : '/home']);
          }
        })
    );
    // Changing the order navigates to orders
    this.subscriptions.push(
      this.orderChangePending$
        .pipe(
          skip(1),
          tap((changePending: boolean) => {
            if (!changePending) {
              this.router.navigate([!this.isHome ? '/orders' : '/home']);
            }
          })
        ).subscribe()
    );
  }

  onSubmit(order: OrderDetail) {
    this.store.dispatch(new OrdersActions.CancelOrder(order));
  }

  onReturn(order: Order) {
    this.router.navigate(['/return', order.code]);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
  }

}
