import { Component, Input, OnInit } from '@angular/core';
import {
  OrderDetail
} from '../../../api';
import { DeliveryFrequencies, LabelTypes } from '../../../shared/constants/payment';
import { orderDetail } from './place-order';

@Component({
  selector: 'app-order-complete',
  templateUrl: './order-complete.component.html',
  styleUrls: ['./order-complete.component.scss']
})
export class OrderCompleteComponent implements OnInit {

  DeliveryFrequencies = DeliveryFrequencies;

  LabelTypes = LabelTypes;

  @Input() isMobile: boolean | false;

  @Input() placedOrder: OrderDetail | null;

  windowRef: any; 

  constructor() {
    this.windowRef = window;
  }

  ngOnInit() {
    this.setGTMForOrderConfirmation();
  }

  removeDollarSign(formattedValue : string) {
    return formattedValue.replace(/\$|,/g, "");
  }

  mapPetType(petType: string): string {
    switch (petType.toUpperCase()) {
      case "CANINE":
        return "Dog";
      case "FELINE":
        return "Cat";
    }
    return petType;
  }

  setGTMForOrderConfirmation()
  {
    if(this.placedOrder.code) {
      let dataLayer = this.windowRef['dataLayer'] || [];
      
      let purchaseitems = this.placedOrder.entries.map(entry => ({
        item_id: entry.product.code,
        item_name: entry.product.name, 
        coupon:  this.placedOrder.appliedVouchers ? this.placedOrder.appliedVouchers[0].code : '',
        discount: parseFloat((entry.basePrice.value - (entry.totalPrice.value/ entry.quantity)).toFixed(2)),
        price: entry.basePrice.value,
        quantity: entry.quantity,
        item_variant: entry.product.baseOptions[0].selected.size
      }));
      const ga4Tag = {
        event: 'purchase',
        ecommerce: {
          transaction_id: this.placedOrder.code,
          total_value: parseFloat(this.placedOrder.totalPrice.value.toFixed(2)),//Order total revenue incl. Tax and shipping and minus order_discount (total_value = value-order_discount)+tax+shipping])
          value: this.placedOrder.subTotal.value, // Subtotal revenue excl. Tax and shipping please see table above for calculation formula.    
          tax: parseFloat(this.placedOrder.totalTax.value.toFixed(2)),
          shipping:  this.placedOrder.deliveryCost.value,
          currency:  this.placedOrder.totalPrice.currencyIso,
          coupon:  this.placedOrder.appliedVouchers ? this.placedOrder.appliedVouchers[0].code : '',
          order_discount:  parseFloat(this.placedOrder.productDiscounts.value.toFixed(2)), 
          items: [{purchaseitems}]
        }
      };
      dataLayer.push(ga4Tag);
    }
  }

}
