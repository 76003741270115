/**
 * Pet Companion Server
 * This is a sample server Pet Companion server. You can find out more about Swagger at [http://swagger.io](http://swagger.io) or on [irc.freenode.net, #swagger](http://swagger.io/irc/). For this sample, you can use the api key `special-key` to test the authorization filters.
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Country } from './country';
import { Region } from './region';


export interface Address {
    addressError?: string;
    country?: Country;
    defaultAddress?: boolean;
    firstName?: string;
    formattedAddress?: string;
    id?: string;
    lastName?: string;
    line1?: string;
    line2?: string;
    postalCode?: string;
    region?: Region;
    shippingAddress?: boolean;
    billingAddress?: boolean;
    town?: string;
    visibleInAddressBook?: boolean;
    phone?: string;
}
