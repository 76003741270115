import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiModule } from '../api';
import { LoaderComponent } from './components/loader/loader.component';
import { ZoomableComponent } from './components/zoomable/zoomable.component';
import { ImgPipe } from './pipes/img/img.pipe';
import { RegionPipe } from './pipes/region/region.pipe';
import { CardTypeToImage } from './pipes/cardType/card-type-to-image.pipe';
import { SubscriptionPipe } from './pipes/subscription/subscription.pipe';
import { OrderStatusPipe } from './pipes/orderStatus/orderStatus.pipe';
import { SubscriptionLengthPipe } from './pipes/subscriptionlength/subscriptionLength.pipe';
import { SubscriptionFrequencyPipe } from './pipes/subscriptionfrequency/subscriptionFrequency.pipe';
import { EndSubscriptionDatePipe } from './pipes/endSubscriptionDate/endSubscriptionDate.pipe';
import { TrackingPipe } from './pipes/tracking/tracking.pipe';
import { SubscriptionCancellationLinkPipe } from './pipes/subscriptionCancellationLink/subscriptionCancellationLink.pipe';
import { PetImgPipe } from './pipes/petImg/petImg.pipe';
import { ProductSortPipe } from './pipes/productSort/productSort.pipe';
import { PlanLengthPipe } from './pipes/planLength/planLength.pipe';
import { FacetFilterPipe } from './pipes/facetFilter/facet-filter.pipe';
import { FacetValueSelectedPipe } from './pipes/facetFilter/facet-value-selected.pipe';
import { ToDeliveryFrequencyPipe } from './pipes/cart/entry/subscriptionUnit/to-delivery-frequency.pipe';
import { ToDeliveryDurationPipe } from './pipes/cart/entry/subscriptionUnit/to-delivery-duration.pipe';
import { EntryCountPipe } from './pipes/cart/entry/entry-count.pipe';
import { ExternalUrlDirective } from './directives/external-url.directive';
import { TolabelPipe } from './pipes/cart/entry/subscriptionUnit/tolabel.pipe';
import { CardTypeToNamePipe } from './pipes/cardType/card-type-to-name.pipe';
import { FacetValueNameMapPipe } from './pipes/facetFilter/facet-value-name-map.pipe';
import { PlanDateFormatterPipe } from './pipes/planDateFormatter/planDateFormatter.pipe';
import { PlanStatusFormatPipe } from './pipes/planStatus/planStatus.pipe';
import { OrderPromotionDescriptionPipe } from './pipes/cart/entry/potentialOrderPromotion/order-promotion.pipe';
import { PromotionsComponent } from './components/promotions/promotions.component';
import { AppImgBaseUrlPipe } from './pipes/appImgBaseUrl/app-img-base-url.pipe';
import { AutoshipComponent } from './components/autoship/autoship.component';

@NgModule({
  imports: [CommonModule, ApiModule],
  declarations: [
    LoaderComponent,
    ImgPipe,
    RegionPipe,
    CardTypeToImage,
    CardTypeToNamePipe,
    SubscriptionPipe,
    EndSubscriptionDatePipe,
    OrderStatusPipe,
    SubscriptionLengthPipe,
    SubscriptionFrequencyPipe,
    TrackingPipe,
    SubscriptionCancellationLinkPipe,
    PetImgPipe,
    ProductSortPipe,
    PlanLengthPipe,
    PlanDateFormatterPipe,
    FacetFilterPipe,
    FacetValueSelectedPipe,
    ToDeliveryFrequencyPipe,
    ToDeliveryDurationPipe,
    EntryCountPipe,
    ExternalUrlDirective,
    TolabelPipe,
    FacetValueNameMapPipe,
    PlanStatusFormatPipe,
    OrderPromotionDescriptionPipe,
    PromotionsComponent,
    ZoomableComponent,
    AppImgBaseUrlPipe,
    AutoshipComponent
  ],
  exports: [
    LoaderComponent,
    ImgPipe,
    RegionPipe,
    CardTypeToImage,
    CardTypeToNamePipe,
    SubscriptionPipe,
    EndSubscriptionDatePipe,
    OrderStatusPipe,
    SubscriptionLengthPipe,
    SubscriptionFrequencyPipe,
    TrackingPipe,
    SubscriptionCancellationLinkPipe,
    PetImgPipe,
    ProductSortPipe,
    PlanLengthPipe,
    PlanDateFormatterPipe,
    FacetFilterPipe,
    FacetValueSelectedPipe,
    ToDeliveryFrequencyPipe,
    ToDeliveryDurationPipe,
    EntryCountPipe,
    ExternalUrlDirective,
    TolabelPipe,
    FacetValueNameMapPipe,
    PlanStatusFormatPipe,
    OrderPromotionDescriptionPipe,
    PromotionsComponent,
    ZoomableComponent,
    AppImgBaseUrlPipe,
    AutoshipComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: []
    };
  }
}
