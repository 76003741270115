import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/index';
import { select, Store } from '@ngrx/store';
import * as fromCore from '../../../core/reducers';
import * as fromOrders from '../../reducers';
import { AppAnimate, fade } from '../../../shared/animations/common.animations';

@Component({
  selector: 'app-order-complete-page',
  template: `
    <app-order-complete
      [isMobile]="isMobile$ | async"
      [placedOrder]="placedOrder$ | async">
    </app-order-complete>
  `,
  styles: [],
  animations: [fade]
})
export class OrderCompletePageComponent implements OnInit, OnDestroy {

  @HostBinding('@fade')
  fade = '';

  // @HostBinding('class.app-animated-page')
  // animatedPage = true;

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  placedOrder$ = this.store.pipe(select(fromOrders.getPlacedOrderDetail));

  subscriptions: Subscription[] = [];

  constructor(private store: Store<fromOrders.State>) {
    AppAnimate('fade', this, this.subscriptions);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
  }
}
