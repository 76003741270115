import { Pipe, PipeTransform } from '@angular/core';
import { CardType } from '../../constants/payment';

@Pipe({
  name: 'cardTypeToName'
})
export class CardTypeToNamePipe implements PipeTransform {
  transform(value: CardType, type: string): string {
    if (type && type === 'name') {
      if (value && value.name) {
        return value.name.toLowerCase();
      }
    } else if (type && type === 'code') {
      if (value && value.code) {
        return value.code.toLowerCase();
      }
    }

    return '';
  }
}
