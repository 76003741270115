import { Component, Input } from '@angular/core';
import { ProductDetail } from 'src/app/api';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-product-modal',
  templateUrl: './product-modal.component.html',
  styleUrls: ['./product-modal.component.css']
})
export class ProductModalComponent {

  @Input()
  product: ProductDetail | null;
  
  constructor(public activeModal: NgbActiveModal) {
  }

  dismiss() {
    this.activeModal.dismiss();
  }

}
 
