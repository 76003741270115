import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as CoreActions from './core/actions/core.actions';

/**
 * Pass untouched request through to the next request handler.
 * Keep count of pending requests, and call the store when loading indicator necessary
 * */
@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {

  pending = 0;

  constructor(private store: Store<any>) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.pending++;

    if (this.pending === 1) {
      this.store.dispatch(new CoreActions.StartLoader());
    }

    return next.handle(req)
      .pipe(
        finalize(() => {
            if (this.pending > 0) {
              this.pending--;
            }
            if (this.pending <= 0) {
              this.store.dispatch(new CoreActions.StopLoader());
            }
          }
        )
      );
  }
}
