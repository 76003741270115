import { OrdersActionsUnion, OrdersActionTypes, ReturnOrder } from '../actions/orders.actions';
import {
  Cart,
  DeliveryMode,
  Order,
  OrderDetail,
  Subscriptions,
  SubscriptionFilters,
  AutoShipTemplate, EditingResultSubscription, Entry, NutritionPlan, Pet,
} from '../../api';
import { GetEditSubscription } from "../../api/model/getEditSubscription";
import { OrderFilters } from '../models/orderFilters';
import { ReturnOrderDetail } from 'src/app/api/model/returnOrderDetail';

export interface State {
  // cart
  cart: Cart;
  cartDetail: Cart;
  deliveryModes: DeliveryMode[];
  placedOrderDetail: OrderDetail;
  cartAddressChangePending: boolean;
  cartPaymentChangePending: boolean;
  cartDeliveryModeChangePending: boolean;
  cartProductChangePending: boolean;
  // orders
  orders: Order[];
  orderFilters: OrderFilters;
  searchedOrders: Order[];
  ordersSearchPending: boolean;
  orderDetail: OrderDetail;
  orderChangePending: boolean;
  upcomingOrders: AutoShipTemplate[],
  returnOrderDetail: ReturnOrderDetail;
  orderReturnPending: boolean;
  // subscriptions
  subscriptionFilters: SubscriptionFilters;
  searchedSubscriptions: Subscriptions;
  subscriptionsSearchPending: boolean;
  subscription: AutoShipTemplate;
  subscriptionChangePending: boolean;
  editingResultSubscription: EditingResultSubscription;
  nutritionPlanDetail: NutritionPlan;
  petDetail: Pet;
}

export const initialState: State = {
  // cart
  cart: null,
  cartDetail: null,
  deliveryModes: null,
  placedOrderDetail: null,
  cartAddressChangePending: false,
  cartPaymentChangePending: false,
  cartDeliveryModeChangePending: false,
  cartProductChangePending: false,
  // orders
  orders: null,
  orderFilters: null,
  searchedOrders: null,
  ordersSearchPending: false,
  orderDetail: null,
  orderChangePending: false,
  upcomingOrders: null,
  returnOrderDetail: null,
  orderReturnPending: false,
  // subscriptions
  subscriptionFilters: null,
  searchedSubscriptions: null,
  subscriptionsSearchPending: false,
  subscription: null,
  subscriptionChangePending: false,
  editingResultSubscription: null,
  nutritionPlanDetail: null,
  petDetail: null
  //autoShipCode: null
};

export function reducer(state = initialState, action: OrdersActionsUnion): State {
  switch (action.type) {

    case OrdersActionTypes.LoadCart: {
      return {
        ...state,
        cart: action.cart,
      };
    }

    case OrdersActionTypes.GetCartSuccess: {
      return {
        ...state,
        cart: action.cart,
      };
    }

    case OrdersActionTypes.GetCartDetailSuccess: {
      return {
        ...state,
        cartDetail: action.cartDetail,
      };
    }

    case OrdersActionTypes.GetDeliveryModesSuccess: {
      return {
        ...state,
        deliveryModes: action.deliveryModes,
      };
    }

    case OrdersActionTypes.GetDeliveryModesAndCartDetailSuccess: {
      return {
        ...state,
        deliveryModes: action.deliveryModes,
        cartDetail: action.cartDetail,
      };
    }

    case OrdersActionTypes.PlaceOrderSuccess: {
      return {
        ...state,
        cart: null,
        cartDetail: null,
        placedOrderDetail: action.placedOrderDetail,
      };
    }

    case OrdersActionTypes.AddProductToCart: {
      return {
        ...state,
        cartProductChangePending: true,
      };
    }

    case OrdersActionTypes.AddProductToCartSuccess: {
      return {
        ...state,
        cartProductChangePending: false,
      };
    }

    case OrdersActionTypes.UpdateCartProduct: {
      return {
        ...state,
        cartProductChangePending: true,
      };
    }

    case OrdersActionTypes.UpdateCartProductSuccess: {
      return {
        ...state,
        cartProductChangePending: false,
      };
    }

    case OrdersActionTypes.GetOrdersSuccess: {
      return {
        ...state,
        orders: action.orders,
      };
    }

    case OrdersActionTypes.SearchOrders: {
      return {
        ...state,
        orderFilters: action.orderFilters,
        ordersSearchPending: true
      };
    }

    case OrdersActionTypes.SearchOrdersSuccess: {
      return {
        ...state,
        searchedOrders: action.searchedOrders,
        ordersSearchPending: false
      };
    }

    case OrdersActionTypes.GetOrderDetailSuccess: {
      return {
        ...state,
        orderDetail: action.orderDetail,
      };
    }

    case OrdersActionTypes.CancelOrder: {
      return {
        ...state,
        orderChangePending: true,
      };
    }

    case OrdersActionTypes.CancelOrderSuccess: {
      return {
        ...state,
        orderChangePending: false,
        orderDetail: action.orderDetail,
        // updating the status for the parent orders
        searchedOrders: state.searchedOrders != null ? state.searchedOrders.map(order => {
          if (order.code === action.orderDetail.code) {
            return {
              ...order,
              status: action.orderDetail.status,
              statusDisplay: action.orderDetail.statusDisplay,
            };
          }
          return order;
        }) : null
      };
    }

    case OrdersActionTypes.UpdateCartAddress: {
      return {
        ...state,
        cartAddressChangePending: true,
      };
    }

    case OrdersActionTypes.UpdateCartAddressSuccess: {
      return {
        ...state,
        cartAddressChangePending: false,
      };
    }

    case OrdersActionTypes.UpdateCartPayment: {
      return {
        ...state,
        cartPaymentChangePending: true,
      };
    }

    case OrdersActionTypes.UpdateCartPaymentSuccess: {
      return {
        ...state,
        cartPaymentChangePending: false,
      };
    }

    case OrdersActionTypes.UpdateCartDeliveryMode: {
      return {
        ...state,
        cartDeliveryModeChangePending: true,
      };
    }

    case OrdersActionTypes.UpdateCartDeliveryModeSuccess: {
      return {
        ...state,
        cartDeliveryModeChangePending: false,
      };
    }

    case OrdersActionTypes.SearchSubscriptions: {
      return {
        ...state,
        subscriptionFilters: action.subscriptionFilters,
        subscriptionsSearchPending: true
      };
    }

    case OrdersActionTypes.SearchSubscriptionsSuccess: {
      return {
        ...state,
        searchedSubscriptions: action.searchedSubscriptions,
        subscriptionsSearchPending: false
      };
    }

    case OrdersActionTypes.GetSubscriptionSuccess: {
      return {
        ...state,
        subscription: action.subscription, 
        nutritionPlanDetail: {            //For Change Product Filters
          ...state.nutritionPlanDetail,
          productLines : action.subscription.subscriptionItem.subscriptionUnit.productLines,
        },
        petDetail : {                     //For Change Product Listing Page
          ...state.petDetail,
          petName : action.subscription.subscriptionItem.subscriptionUnit.pets[0].petName,
          petCode : action.subscription.subscriptionItem.subscriptionUnit.pets[0].petCode
        }
      };
    }

    case OrdersActionTypes.UpdateSubscription: {
      return {
        ...state,
        subscriptionChangePending: true,
      };
    }

    case OrdersActionTypes.GetUpdateSubscription: {
      return {
        ...state,
        subscriptionChangePending: true,
      };
    }

    case OrdersActionTypes.GetUpdateSubscriptionSuccess: {
      return {
        ...state,
        subscriptionChangePending: false,
        editingResultSubscription: action.editingResultSubscription,
        // since we are not getting the updates values from service, we need to update our state with the right info
        searchedSubscriptions: {
          ...state.searchedSubscriptions,
          autoShipTemplates: state.searchedSubscriptions.autoShipTemplates.map(
            (subscription: AutoShipTemplate) => {
              if (action.getEditSubscription.autoShipCode === subscription.autoShipCode) {
                return {
                  ...subscription,
                  recurringOrderStatus: action.getEditSubscription.subscriptionOrderStatus,
                  nextScheduledDeliveryDate: action.getEditSubscription.nextDeliveryDate,
                  endDate: action.editingResultSubscription.endDate,
                  enableShipNow : action.editingResultSubscription.enableShipNow,
                  subscriptionItem: {
                    ...subscription.subscriptionItem,
                    quantity: parseInt(action.getEditSubscription.quantity, 10),
                    subscriptionUnit: {
                      ...subscription.subscriptionItem.subscriptionUnit,
                      deliveryFrequency: action.getEditSubscription.deliveryFrequency,
                      duration: action.getEditSubscription.duration
                    }
                  },
                  cart: {
                    ...subscription.cart,
                    totalPrice : action.editingResultSubscription.cart.totalPrice,         //For Mobile View : after editing a subscription, get the updated totalprice, since get subscription action picks up data from searchedSubscriptions state
                    entries: subscription.cart.entries.map((entry: Entry) => {
                      // we can only have one entry per subscription
                      return {
                        ...entry,
                        quantity: parseInt(action.getEditSubscription.quantity, 10),
                        subscriptionUnit: {
                          ...entry.subscriptionUnit,
                          deliveryFrequency: action.getEditSubscription.deliveryFrequency,
                          duration: action.getEditSubscription.duration
                        },
                        product: {
                          ...entry.product,
                          code: action.getEditSubscription.product.code
                        },
                        totalPrice : action.editingResultSubscription.cart.entries[0] ? action.editingResultSubscription.cart.entries[0].totalPrice : action.editingResultSubscription.cart.totalPrice    //For Desktop View : after editing a subscription, get the updated totalprice
                      };
                    })
                  }
                };
              }
              return subscription;
            })
        },
        subscription: {
          ...state.subscription,
          recurringOrderStatus: action.getEditSubscription.subscriptionOrderStatus,
          nextScheduledDeliveryDate: action.getEditSubscription.nextDeliveryDate,
          endDate: action.editingResultSubscription.endDate,
          subscriptionItem: {
            ...state.subscription.subscriptionItem,
            quantity: parseInt(action.getEditSubscription.quantity, 10),
            subscriptionUnit: {
              ...state.subscription.subscriptionItem.subscriptionUnit,
              deliveryFrequency: action.getEditSubscription.deliveryFrequency,
              duration: action.getEditSubscription.duration,
            }
          },
          cart: {
            ...state.subscription.cart,
            entries: state.subscription.cart.entries.map((entry: Entry) => {
              // we can only have one entry per subscription
              return {
                ...entry,
                quantity: parseInt(action.getEditSubscription.quantity, 10),
                subscriptionUnit: {
                  ...entry.subscriptionUnit,
                  deliveryFrequency: action.getEditSubscription.deliveryFrequency,
                  duration: action.getEditSubscription.duration
                },
                product: {
                  ...entry.product,
                  code: action.getEditSubscription.product.code
                }
              };
            })
          }
        }

      };
    }

    case OrdersActionTypes.UpdateSubscriptionSuccess: {
      return {
        ...state,
        subscriptionChangePending: false,
        editingResultSubscription: action.editingResultSubscription,
        // since we are not getting the updates values from service, we need to update our state with the right info
        searchedSubscriptions: {
          ...state.searchedSubscriptions,
          autoShipTemplates: state.searchedSubscriptions.autoShipTemplates.map(
            (subscription: AutoShipTemplate) => {
              if (action.editingSubscription.autoShipCode === subscription.autoShipCode) {
                return {
                  ...subscription,
                  recurringOrderStatus: action.editingSubscription.subscriptionOrderStatus,
                  nextScheduledDeliveryDate: action.editingSubscription.nextDeliveryDate,
                  subscriptionItem: {
                    ...subscription.subscriptionItem,
                    quantity: parseInt(action.editingSubscription.quantity, 10),
                    subscriptionUnit: {
                      ...subscription.subscriptionItem.subscriptionUnit,
                      deliveryFrequency: action.editingSubscription.deliveryFrequency,
                      duration: action.editingSubscription.duration
                    }
                  },
                  cart: {
                    ...subscription.cart,
                    entries: subscription.cart.entries.map((entry: Entry) => {
                      // we can only have one entry per subscription
                      return {
                        ...entry,
                        quantity: parseInt(action.editingSubscription.quantity, 10),
                        subscriptionUnit: {
                          ...entry.subscriptionUnit,
                          deliveryFrequency: action.editingSubscription.deliveryFrequency,
                          duration: action.editingSubscription.duration
                        },
                        product: {
                          ...entry.product,
                          code: action.editingSubscription.product.code
                        }
                      };
                    })
                  }
                };
              }
              return subscription;
            })
        },
        subscription: {
          ...state.subscription,
          recurringOrderStatus: action.editingSubscription.subscriptionOrderStatus,
          nextScheduledDeliveryDate: action.editingSubscription.nextDeliveryDate,
          subscriptionItem: {
            ...state.subscription.subscriptionItem,
            quantity: parseInt(action.editingSubscription.quantity, 10),
            subscriptionUnit: {
              ...state.subscription.subscriptionItem.subscriptionUnit,
              deliveryFrequency: action.editingSubscription.deliveryFrequency,
              duration: action.editingSubscription.duration,
            }
          },
          cart: {
            ...state.subscription.cart,
            entries: state.subscription.cart.entries.map((entry: Entry) => {
              // we can only have one entry per subscription
              return {
                ...entry,
                quantity: parseInt(action.editingSubscription.quantity, 10),
                subscriptionUnit: {
                  ...entry.subscriptionUnit,
                  deliveryFrequency: action.editingSubscription.deliveryFrequency,
                  duration: action.editingSubscription.duration
                },
                product: {
                  ...entry.product,
                  code: action.editingSubscription.product.code
                }
              };
            })
          }
        }
      };
    }

    case OrdersActionTypes.GetUpcomingOrdersSuccess:
      return {
        ...state,
        upcomingOrders: action.upcomingOrders
      }

    case OrdersActionTypes.UpdateOrderDeliveryDate: {
      return {
        ...state,
        subscriptionChangePending: true,

      }
    }

    case OrdersActionTypes.UpdateOrderDeliveryDateSuccess: {

      return {
        ...state,
        subscriptionChangePending: false
      }
    }

    case OrdersActionTypes.GetReturnOrderDetailSuccess: {
      return {
        ...state,
        returnOrderDetail: action.returnOrderDetail,
      };
    }

    case OrdersActionTypes.ReturnOrder: {
      return {
        ...state,
        orderReturnPending: true,
      };
    }

    case OrdersActionTypes.ReturnOrderSuccess: {
      return {
        ...state,
        orderReturnPending: false,
        returnOrderDetail: action.returnOrderDetail,
        // updating the status for the parent orders
        searchedOrders: state.searchedOrders!=null ? state.searchedOrders.map(order => {
          if(order.code === action.returnOrderDetail.order.code) {
            return {
              ...order,
              status: action.returnOrderDetail.order.status,
              statusDisplay: action.returnOrderDetail.order.status
            };
          }
          return order;
        }) : null
      };
    }

    default:
      return state;
  }
}

// cart
export const getCart = (state: State) => state.cart;
export const updateApplyPromo = (state: State) => state.cart;
export const removeApplyPromo = (state: State) => state.cart;
export const getCartDetail = (state: State) => state.cartDetail;
export const getDeliveryModes = (state: State) => state.deliveryModes;
export const getPlacedOrderDetail = (state: State) => state.placedOrderDetail;
export const getCartAddressChangePending = (state: State) => state.cartAddressChangePending;
export const getCartPaymentChangePending = (state: State) => state.cartPaymentChangePending;
export const getCartDeliveryModeChangePending = (state: State) => state.cartDeliveryModeChangePending;
export const getCartProductChangePending = (state: State) => state.cartProductChangePending;
// orders
export const getOrders = (state: State) => state.orders;
export const getOrderFilters = (state: State) => state.orderFilters;
export const getSearchedOrders = (state: State) => state.searchedOrders;
export const getOrdersSearchPending = (state: State) => state.ordersSearchPending;
export const getOrderDetail = (state: State) => state.orderDetail;
export const getOrderChangePending = (state: State) => state.orderChangePending;
export const getUpcomingOrders = (state: State) => state.upcomingOrders;
export const getOrderReturnPending = (state: State) => state.orderReturnPending;
export const getReturnOrderDetail = (state: State) => state.returnOrderDetail;
// subscriptions
export const getSubscriptionFilters = (state: State) => state.subscriptionFilters;
export const getSearchedSubscriptions = (state: State) => state.searchedSubscriptions;
export const getSubscriptionsSearchPending = (state: State) => state.subscriptionsSearchPending;
export const getSubscription = (state: State) => state.subscription;
export const getSubscriptionChangePending = (state: State) => state.subscriptionChangePending;
export const getEditingResultSubscription = (state: State) => state.editingResultSubscription;
export const getNutritionPlanDetailSubscription = (state: State) => state.nutritionPlanDetail;     //For Change Product in subscription
export const getPetDetailSubscription = (state: State) => state.petDetail;                        //For Change Product in subscription