import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'appPlanDate'
})
export class PlanDateFormatterPipe implements PipeTransform {

    transform(value: string, args?: any): any {
        if (value) {
            return new Date(value).toLocaleDateString()
        }
        return 'N/A';
    }
}