<app-pet [isMobile]="isMobile$ | async" [pet]="petDetail$ | async" [nutritionPlans]="nutritionPlans$ | async"
  [inActiveNutritionPlans]="inActiveNutritionPlans$ | async" [nutritionPlan]="nutritionPlanDetail$ | async"
  [pending]="changePending$ | async" (submitted)="onSubmit($event)" (succeed)="onSuccess()"
  (nutritionPlanSelected)="onNutritionPlanSelect($event)" [defaultSelectedPlan]="defaultSelectedPlan"
  [showPetDetail]="false" (navigatedBack)="onNavigateBack()" [wellnessPlan]="wellnessPlan$ | async">
</app-pet>

<div>
  <app-np-products-page [defaultSelectedPlan]="defaultSelectedPlan">
  </app-np-products-page>
</div>