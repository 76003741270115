/**
 * Pet Companion Server
 * This is a sample server Pet Companion server. You can find out more about Swagger at [http://swagger.io](http://swagger.io) or on [irc.freenode.net, #swagger](http://swagger.io/irc/). For this sample, you can use the api key `special-key` to test the authorization filters.
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { AddingCart } from '../model/addingCart';
import { AllCarts } from '../model/allCarts';
import { AllDeliveryModes } from '../model/allDeliveryModes';
import { AllOrders } from '../model/allOrders';
import { Cart } from '../model/cart';
import { EditingCart } from '../model/editingCart';
import { EditingResultSubscription } from '../model/editingResultSubscription';
import { EditingSubscription } from '../model/editingSubscription';
import { GetEditSubscription } from '../model/getEditSubscription';
import { OrderDetail } from '../model/orderDetail';
import { SubscriptionFilters } from '../model/subscriptionFilters';
import { Subscriptions } from '../model/subscriptions';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { ReturnOrderDetail } from '../model/returnOrderDetail';


@Injectable()
export class OrderService {

    protected basePath = 'http://localhost:10010/rest';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Adds product to cart
     *
     * @param username The username
     * @param vetUsername The veterinarian username
     * @param body The product added
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addProductToCart(username: string, vetUsername: string, body: AddingCart, t?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public addProductToCart(username: string, vetUsername: string, body: AddingCart, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public addProductToCart(username: string, vetUsername: string, body: AddingCart, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public addProductToCart(username: string, vetUsername: string, body: AddingCart, t?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling addProductToCart.');
        }
        if (vetUsername === null || vetUsername === undefined) {
            throw new Error('Required parameter vetUsername was null or undefined when calling addProductToCart.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addProductToCart.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (t !== undefined) {
            queryParameters = queryParameters.set('t', <any>t);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        if (this.configuration.apiKeys["csrf_token"]) {
            headers = headers.set('CSRFToken',this.configuration.apiKeys['csrf_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        let httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }
        const pk = sessionStorage.getItem('pk');

        return this.httpClient.post<any>(`${this.basePath}/rest/v2/V2CSite/carts/users/${encodeURIComponent(String(pk))}/vetUser/${encodeURIComponent(String(vetUsername))}/entries_1/appV2`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentialsAndCookies,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );

    }

    /**
     * Cancel Order
     *
     * @param username The username
     * @param orderCode The order code
     * @param vetUserId The vet user id
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cancelOrder(username: string, orderCode: string, vetUserId: string, t?: string, observe?: 'body', reportProgress?: boolean): Observable<OrderDetail>;
    public cancelOrder(username: string, orderCode: string, vetUserId: string, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderDetail>>;
    public cancelOrder(username: string, orderCode: string, vetUserId: string, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderDetail>>;
    public cancelOrder(username: string, orderCode: string, vetUserId: string, t?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling cancelOrder.');
        }
        if (orderCode === null || orderCode === undefined) {
            throw new Error('Required parameter orderCode was null or undefined when calling cancelOrder.');
        }
        if (vetUserId === null || vetUserId === undefined) {
            throw new Error('Required parameter vetUserId was null or undefined when calling cancelOrder.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (orderCode !== undefined) {
            queryParameters = queryParameters.set('orderCode', <any>orderCode);
        }
        if (vetUserId !== undefined) {
            queryParameters = queryParameters.set('vetUserPk', <any>vetUserId);
        }
        if (t !== undefined) {
            queryParameters = queryParameters.set('t', <any>t);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        if (this.configuration.apiKeys["csrf_token"]) {
            headers = headers.set('CSRFToken',this.configuration.apiKeys['csrf_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.post<OrderDetail>(`${this.basePath}/rest/v2/V2CSite/users/${encodeURIComponent(String(pk))}/orders/cancelOrder`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentialsAndCookies,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the parent cart by id
     *
     * @param username The username
     * @param id The cart id
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCart(username: string, id: string, t?: string, observe?: 'body', reportProgress?: boolean): Observable<Cart>;
    public getCart(username: string, id: string, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Cart>>;
    public getCart(username: string, id: string, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Cart>>;
    public getCart(username: string, id: string, t?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling getCart.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getCart.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (t !== undefined) {
            queryParameters = queryParameters.set('t', <any>t);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.get<Cart>(`${this.basePath}/rest/v2/V2CSite/carts/users/${encodeURIComponent(String(pk))}/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the parent carts
     *
     * @param username The username
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCarts(username: string, t?: string, observe?: 'body', reportProgress?: boolean): Observable<AllCarts>;
    public getCarts(username: string, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AllCarts>>;
    public getCarts(username: string, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AllCarts>>;
    public getCarts(username: string, t?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling getCarts.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (t !== undefined) {
            queryParameters = queryParameters.set('t', <any>t);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.get<AllCarts>(`${this.basePath}/rest/v2/V2CSite/carts/users/${encodeURIComponent(String(pk))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
 
    /**
     * Get the shipping speed for the carts order
     *
     * @param username The username
     * @param id The cart id
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDeliveryModes(username: string, id: string, t?: string, observe?: 'body', reportProgress?: boolean): Observable<AllDeliveryModes>;
    public getDeliveryModes(username: string, id: string, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AllDeliveryModes>>;
    public getDeliveryModes(username: string, id: string, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AllDeliveryModes>>;
    public getDeliveryModes(username: string, id: string, t?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling getDeliveryModes.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getDeliveryModes.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (t !== undefined) {
            queryParameters = queryParameters.set('t', <any>t);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.get<AllDeliveryModes>(`${this.basePath}/rest/v2/V2CSite/carts/users/${encodeURIComponent(String(pk))}/${encodeURIComponent(String(id))}/deliverymodes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets order details
     *
     * @param username The username
     * @param id The order id
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrder(username: string, id: string, t?: string, observe?: 'body', reportProgress?: boolean): Observable<OrderDetail>;
    public getOrder(username: string, id: string, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderDetail>>;
    public getOrder(username: string, id: string, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderDetail>>;
    public getOrder(username: string, id: string, t?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling getOrder.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getOrder.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (t !== undefined) {
            queryParameters = queryParameters.set('t', <any>t);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.get<OrderDetail>(`${this.basePath}/rest/v2/V2CSite/users/${encodeURIComponent(String(pk))}/orders/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets orders
     *
     * @param username The username
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrders(username: string, t?: string, observe?: 'body', reportProgress?: boolean): Observable<AllOrders>;
    public getOrders(username: string, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AllOrders>>;
    public getOrders(username: string, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AllOrders>>;
    public getOrders(username: string, t?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling getOrders.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (t !== undefined) {
            queryParameters = queryParameters.set('t', <any>t);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.get<AllOrders>(`${this.basePath}/rest/v2/V2CSite/users/${encodeURIComponent(String(pk))}/orders`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets upcoming orders
     *
     * @param username The username
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUpcomingOrders(username: string, t?: string, observe?: 'body', reportProgress?: boolean): Observable<AllOrders>;
    public getUpcomingOrders(username: string, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AllOrders>>;
    public getUpcomingOrders(username: string, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AllOrders>>;
    public getUpcomingOrders(username: string, t?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling getUpcomingOrders.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (t !== undefined) {
            queryParameters = queryParameters.set('t', <any>t);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.get<Subscriptions>(`${this.basePath}/rest/v2/V2CSite/users/${encodeURIComponent(String(pk))}/subscription/appV2`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets subscriptions
     *
     * @param username The username
     * @param body The subscriptions filter
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSubscriptions(username: string, body: SubscriptionFilters, t?: string, observe?: 'body', reportProgress?: boolean): Observable<Subscriptions>;
    public getSubscriptions(username: string, body: SubscriptionFilters, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Subscriptions>>;
    public getSubscriptions(username: string, body: SubscriptionFilters, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Subscriptions>>;
    public getSubscriptions(username: string, body: SubscriptionFilters, t?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling getSubscriptions.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling getSubscriptions.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (t !== undefined) {
            queryParameters = queryParameters.set('t', <any>t);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        let httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        const pk = sessionStorage.getItem('pk');
        return this.httpClient.get<Subscriptions>(`${this.basePath}/rest/v2/V2CSite/users/${encodeURIComponent(String(pk))}/getsubscriptions/show/${encodeURIComponent(String(body.showInactiveSub))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Place Order
     *
     * @param username The username
     * @param cartId The cart id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public placeOrder(username: string, cartId: string, observe?: 'body', reportProgress?: boolean): Observable<OrderDetail>;
    public placeOrder(username: string, cartId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderDetail>>;
    public placeOrder(username: string, cartId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderDetail>>;
    public placeOrder(username: string, cartId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling placeOrder.');
        }
        if (cartId === null || cartId === undefined) {
            throw new Error('Required parameter cartId was null or undefined when calling placeOrder.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (cartId !== undefined) {
            queryParameters = queryParameters.set('cartId', <any>cartId);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        if (this.configuration.apiKeys["csrf_token"]) {
            headers = headers.set('CSRFToken', this.configuration.apiKeys['csrf_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.post<OrderDetail>(`${this.basePath}/rest/v2/V2CSite/users/${encodeURIComponent(String(pk))}/orders`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentialsAndCookies,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the cart address
     *
     * @param username The username
     * @param id The cart id
     * @param addressId The address id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCartAddress(username: string, id: string, addressId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateCartAddress(username: string, id: string, addressId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateCartAddress(username: string, id: string, addressId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateCartAddress(username: string, id: string, addressId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling updateCartAddress.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateCartAddress.');
        }
        if (addressId === null || addressId === undefined) {
            throw new Error('Required parameter addressId was null or undefined when calling updateCartAddress.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (addressId !== undefined) {
            queryParameters = queryParameters.set('addressId', <any>addressId);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        if (this.configuration.apiKeys["csrf_token"]) {
            headers = headers.set('CSRFToken', this.configuration.apiKeys['csrf_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.put<any>(`${this.basePath}/rest/v2/V2CSite/carts/users/${encodeURIComponent(String(pk))}/${encodeURIComponent(String(id))}/addresses/delivery`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentialsAndCookies,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the cart delivery mode
     *
     * @param username The username
     * @param id The cart id
     * @param deliveryModeId The delivery mode id
     * @param selectedDeliveryDate The delivery date
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCartDeliveryMode(username: string, id: string, deliveryModeId: string, selectedDeliveryDate?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateCartDeliveryMode(username: string, id: string, deliveryModeId: string, selectedDeliveryDate?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateCartDeliveryMode(username: string, id: string, deliveryModeId: string, selectedDeliveryDate?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateCartDeliveryMode(username: string, id: string, deliveryModeId: string, selectedDeliveryDate?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling updateCartDeliveryMode.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateCartDeliveryMode.');
        }
        if (deliveryModeId === null || deliveryModeId === undefined) {
            throw new Error('Required parameter deliveryModeId was null or undefined when calling updateCartDeliveryMode.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (deliveryModeId !== undefined) {
            queryParameters = queryParameters.set('deliveryModeId', <any>deliveryModeId);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        if (this.configuration.apiKeys["csrf_token"]) {
            headers = headers.set('CSRFToken', this.configuration.apiKeys['csrf_token']);
        }

        if (selectedDeliveryDate !== undefined) {
            queryParameters = queryParameters.set('selectedDeliveryDate', <any>selectedDeliveryDate);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.put<any>(`${this.basePath}/rest/v2/V2CSite/carts/users/${encodeURIComponent(String(pk))}/${encodeURIComponent(String(id))}/deliverymode`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentialsAndCookies,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the cart payment
     *
     * @param username The username
     * @param id The cart id
     * @param paymentDetailsId The payment id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCartPayment(username: string, id: string, paymentDetailsId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateCartPayment(username: string, id: string, paymentDetailsId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateCartPayment(username: string, id: string, paymentDetailsId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateCartPayment(username: string, id: string, paymentDetailsId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling updateCartPayment.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateCartPayment.');
        }
        if (paymentDetailsId === null || paymentDetailsId === undefined) {
            throw new Error('Required parameter paymentDetailsId was null or undefined when calling updateCartPayment.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (paymentDetailsId !== undefined) {
            queryParameters = queryParameters.set('paymentDetailsId', <any>paymentDetailsId);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        if (this.configuration.apiKeys["csrf_token"]) {
            headers = headers.set('CSRFToken', this.configuration.apiKeys['csrf_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.put<any>(`${this.basePath}/rest/v2/V2CSite/carts/users/${encodeURIComponent(String(pk))}/${encodeURIComponent(String(id))}/paymentdetails`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentialsAndCookies,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the cart product
     *
     * @param username The username
     * @param vetUsername The veterinarian username
     * @param entryNumber The entry number
     * @param body The product updated
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCartProduct(username: string, vetUsername: string, entryNumber: string, body: EditingCart, t?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateCartProduct(username: string, vetUsername: string, entryNumber: string, body: EditingCart, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateCartProduct(username: string, vetUsername: string, entryNumber: string, body: EditingCart, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateCartProduct(username: string, vetUsername: string, entryNumber: string, body: EditingCart, t?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling updateCartProduct.');
        }
        if (vetUsername === null || vetUsername === undefined) {
            throw new Error('Required parameter vetUsername was null or undefined when calling updateCartProduct.');
        }
        if (entryNumber === null || entryNumber === undefined) {
            throw new Error('Required parameter entryNumber was null or undefined when calling updateCartProduct.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateCartProduct.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (t !== undefined) {
            queryParameters = queryParameters.set('t', <any>t);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        if (this.configuration.apiKeys["csrf_token"]) {
            headers = headers.set('CSRFToken', this.configuration.apiKeys['csrf_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        let httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.put<any>(`${this.basePath}/rest/v2/V2CSite/carts/users/${encodeURIComponent(String(pk))}/vetUser/${encodeURIComponent(String(vetUsername))}/entries/${encodeURIComponent(String(entryNumber))}`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentialsAndCookies,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Edit Subscription
     *
     * @param username The username
     * @param body The subscription data
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSubscription(username: string, body: EditingSubscription, t?: string, observe?: 'body', reportProgress?: boolean): Observable<EditingResultSubscription>;
    public updateSubscription(username: string, body: EditingSubscription, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EditingResultSubscription>>;
    public updateSubscription(username: string, body: EditingSubscription, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EditingResultSubscription>>;
    public updateSubscription(username: string, body: EditingSubscription, t?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling updateSubscription.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateSubscription.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (t !== undefined) {
            queryParameters = queryParameters.set('t', <any>t);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        if (this.configuration.apiKeys["csrf_token"]) {
            headers = headers.set('CSRFToken', this.configuration.apiKeys['csrf_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        let httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.put<EditingResultSubscription>(`${this.basePath}/rest/v2/V2CSite/users/${encodeURIComponent(String(pk))}/changesubscription`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentialsAndCookies,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


    /**
     * Edit Subscription
     *
     * @param username The username
     * @param body The subscription data
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */

    //username: string, id: string, t?: string, observe?: 'body', reportProgress?: boolean
    public getUpdateSubscription(username: string, body: GetEditSubscription, t?: string, observe?: 'body', reportProgress?: boolean): Observable<EditingResultSubscription>;
    public getUpdateSubscription(username: string, body: GetEditSubscription, t?: string, observe?: 'body', reportProgress?: boolean): Observable<HttpResponse<EditingResultSubscription>>;
    public getUpdateSubscription(username: string, body: GetEditSubscription, t?: string, observe?: 'body', reportProgress?: boolean): Observable<HttpEvent<EditingResultSubscription>>;
    public getUpdateSubscription(username: string, body: GetEditSubscription, t?: string, observe?: 'body', reportProgress?: boolean): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling getupdateSubscription.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling getupdateSubscription.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (t !== undefined) {
            queryParameters = queryParameters.set('t', <any>t);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        let httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.get<GetEditSubscription>(`${this.basePath}/rest/v2/V2CSite/users/${encodeURIComponent(String(pk))}/getsubscriptions/${(String(body.autoShipCode))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    
    public updateAppliedPromo(username: string, id: string, code: string, t?: string, observe?: 'body', reportProgress?: boolean): Observable<Cart>;
    public updateAppliedPromo(username: string, id: string, code: string, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Cart>>;
    public updateAppliedPromo(username: string, id: string, code: string, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Cart>>;
    public updateAppliedPromo(username: string, id: string, code: string, t?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling updateAppliedPromo.');
        }
        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling updateAppliedPromo.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });


        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        if (this.configuration.apiKeys["csrf_token"]) {
            headers = headers.set('CSRFToken', this.configuration.apiKeys['csrf_token']);
        }

        var json = { 'code': '' + code };
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.post<Cart>(`${this.basePath}/rest/v2/V2CSite/carts/users/${encodeURIComponent(String(pk))}/${encodeURIComponent(String(id))}/applycoupon`, json,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentialsAndCookies,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            });
    }

    public removeAppliedPromo(username: string, id: string, code: string, t?: string, observe?: 'body', reportProgress?: boolean): Observable<Cart>;
    public removeAppliedPromo(username: string, id: string, code: string, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Cart>>;
    public removeAppliedPromo(username: string, id: string, code: string, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Cart>>;
    public removeAppliedPromo(username: string, id: string, code: string, t?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling removeAppliedPromo.');
        }
        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling removeAppliedPromo.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });


        let headers = this.defaultHeaders;
        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        if (this.configuration.apiKeys["csrf_token"]) {
            headers = headers.set('CSRFToken', this.configuration.apiKeys['csrf_token']);
        }

        var json = { 'code': '' + code };
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        localStorage.removeItem("promoCode");
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.post<Cart>(`${this.basePath}/rest/v2/V2CSite/carts/users/${encodeURIComponent(String(pk))}/${encodeURIComponent(String(id))}/removecoupon`, json,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentialsAndCookies,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
 * Gets return order details
 *
 * @param username The username
 * @param id The return order id
 * @param t [Ignore] Code Gen Bug - Query parameters
 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
 * @param reportProgress flag to report request and response progress.
 */
public getReturnOrderDetail(username: string, id: string, t?: string, observe?: 'body', reportProgress?: boolean): Observable<ReturnOrderDetail>;
public getReturnOrderDetail(username: string, id: string, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReturnOrderDetail>>;
public getReturnOrderDetail(username: string, id: string, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReturnOrderDetail>>;
public getReturnOrderDetail(username: string, id: string, t?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (username === null || username === undefined) {
        throw new Error('Required parameter username was null or undefined when calling getReturnOrderDetail.');
    }
    if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined when calling getReturnOrderDetail.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (t !== undefined) {
        queryParameters = queryParameters.set('t', <any>t);
    }

    let headers = this.defaultHeaders;

    // authentication (petcompanion_auth) required
    if (this.configuration.apiKeys["access_token"]) {
        headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
        'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
        'application/octet-stream',
        'application/json'
    ];
    let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
        headers = headers.set("Content-Type", httpContentTypeSelected);
    }
    const pk = sessionStorage.getItem('pk');
    return this.httpClient.get<ReturnOrderDetail>(`${this.basePath}/rest/v2/V2CSite/returns/users/${encodeURIComponent(String(pk))}/returnOrders/${encodeURIComponent(String(id))}`,
        {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        }
    );
}

/**
 * Return Order
 *
 * @param username The username
 * @param orderCode The return order code
 * @param vetUserId The vet user id
 * @param t [Ignore] Code Gen Bug - Query parameters
 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
 * @param reportProgress flag to report request and response progress.
 */
public returnOrder(username: string, body: ReturnOrderDetail, t?: string, observe?: 'body', reportProgress?: boolean): Observable<ReturnOrderDetail>;
public returnOrder(username: string, body: ReturnOrderDetail, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReturnOrderDetail>>;
public returnOrder(username: string, body: ReturnOrderDetail, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReturnOrderDetail>>;
public returnOrder(username: string, body: ReturnOrderDetail, t?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (username === null || username === undefined) {
        throw new Error('Required parameter username was null or undefined when calling returnOrder.');
    }
    if (body === null || body === undefined) {
        throw new Error('Required parameter body was null or undefined when calling returnOrder.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (t !== undefined) {
        queryParameters = queryParameters.set('t', <any>t);
    }

    let headers = this.defaultHeaders;
    
    // authentication (petcompanion_auth) required
    if (this.configuration.apiKeys["access_token"]) {
        headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
    }

    if (this.configuration.apiKeys["csrf_token"]) {
        headers = headers.set('CSRFToken', this.configuration.apiKeys['csrf_token']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
        'application/json'
    ];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = [
        'application/octet-stream',
        'application/json'
    ];
    let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
        headers = headers.set("Content-Type", httpContentTypeSelected);
    }
    return this.httpClient.post<ReturnOrderDetail>(`${this.basePath}/rest/v2/V2CSite/returns/${encodeURIComponent(String(body.returnOrder.code))}/submit`,
        body,
        {
            params: queryParameters,
            withCredentials: this.configuration.withCredentialsAndCookies,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
    }
}
