import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgbAccordionModule, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '../shared/shared.module';
import { ProfileModule } from '../profile/profile.module';
import { OrdersRoutingModule } from './orders-routing.module';
import { OrdersComponent } from './components/orders/orders.component';
import { OrderComponent } from './components/order/order.component';
import { CartComponent } from './components/cart/cart.component';
import { PlaceOrderComponent } from './components/place-order/place-order.component';
import { OrderCompleteComponent } from './components/order-complete/order-complete.component';
import { OrderSummaryComponent } from './components/order-summary/order-summary.component';
import { SubscriptionsComponent } from './components/subscriptions/subscriptions.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { CartPageComponent } from './containers/cart-page/cart-page.component';
import { OrdersPageComponent } from './containers/orders-page/orders-page.component';
import { OrderPageComponent } from './containers/order-page/order-page.component';
import { PlaceOrderPageComponent } from './containers/place-order-page/place-order-page.component';
import { OrderCompletePageComponent } from './containers/order-complete-page/order-complete-page.component';
import { SubscriptionsPageComponent } from './containers/subscriptions-page/subscriptions-page.component';
import { SubscriptionPageComponent } from './containers/subscription-page/subscription-page.component';
import { reducers } from './reducers';
import { OrdersEffects } from './effects/orders.effects';
import { NoCartGuard } from './guards/no-cart.guard';
import { CartGuard } from './guards/cart.guard';
import { EditSubscriptionComponent } from './components/edit-subscription/edit-subscription.component';
import { EditSubscriptionPageComponent } from './containers/edit-subscription-page/edit-subscription-page.component';
import { CartSubscription } from './components/cart/subscriptionForm/subscription.component';
import { EditOrderDeliveryDatePage } from './containers/edit-order-delivery-date-page/edit-order-delivery-date-page.component';
import { EditOrderDeliveryDate } from './components/edit-order-delivery-date/edit-order-delivery-date.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ReturnPageComponent } from './containers/return-page/return-page.component';
import { ReturnComponent } from './components/return/return.component';

@NgModule({
  imports: [
    ReactiveFormsModule,
    NgbAccordionModule,
    NgbDropdownModule,
    CommonModule,
    SharedModule,
    ProfileModule,
    OrdersRoutingModule,
    NgbTooltipModule,
    StoreModule.forFeature('orders', reducers),
    EffectsModule.forFeature([OrdersEffects])
  ],
  declarations: [
    OrdersComponent,
    OrderComponent,
    CartComponent,
    PlaceOrderComponent,
    OrderCompleteComponent,
    OrderSummaryComponent,
    SubscriptionsComponent,
    SubscriptionComponent,
    EditSubscriptionComponent,
    CartPageComponent,
    OrdersPageComponent,
    OrderPageComponent,
    PlaceOrderPageComponent,
    OrderCompletePageComponent,
    SubscriptionsPageComponent,
    SubscriptionPageComponent,
    EditSubscriptionPageComponent,
    CartSubscription,
    ReturnPageComponent,
    ReturnComponent,
  ]
})
export class OrdersModule {
  static forRoot(): ModuleWithProviders<OrdersModule> {
    return {
      ngModule: OrdersModule,
      providers: [CartGuard, NoCartGuard, DeviceDetectorService, TitleCasePipe],
    };
  }
}

