import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SupportPageComponent } from './containers/support-page/support-page.component';
import { FaqPageComponent } from './containers/faq-page/faq-page.component';
import { AuthGuard } from '../auth/guards/auth.guard';
import { ErrorGuard } from '../core/guards/error.guard';
import { ContactUsResponseComponent } from './components/contact-us/contact-us-response/contact-us-response.component';

const routes: Routes = [
  {
    path: 'user/support',
    component: SupportPageComponent,
    canActivate: [ErrorGuard, AuthGuard],
  },
  {
    path: 'user/support-faq/:id',
    component: FaqPageComponent,
    canActivate: [ErrorGuard, AuthGuard],
  },
  {

    path: 'support',
    component: SupportPageComponent,
    canActivate: [ErrorGuard],
  },
  {
    path: 'support-faq/:id',
    component: FaqPageComponent,
    canActivate: [ErrorGuard],
  },
  {
    path: 'contact-us-response',
    component: ContactUsResponseComponent,
    canActivate: [ErrorGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SupportRoutingModule { }


