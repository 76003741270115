import { Component, HostBinding, OnDestroy, OnInit, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromCore from '../../../core/reducers';
import * as fromPets from '../../reducers';
import * as PetsActions from '../../actions/pets.actions';
import * as CoreActions from '../../../core/actions/core.actions';
import { Pet, NutritionPlan } from '../../../api';
import { AppAnimate, flyOutIn } from '../../../shared/animations/common.animations';
import * as ProductsActions from '../../../products/actions/products.actions';

@Component({
   selector: 'app-pet-detail-page',
   templateUrl: 'pet-detail-page.component.html',
   styles: [],
   animations: [flyOutIn]
})
export class PetDetailsPageComponent implements OnInit, OnDestroy {

   @HostBinding('@flyOutIn')
   flyOutIn = '';

   // @HostBinding('class.app-animated-page')
   // animatedPage = true;


   subscriptions: Subscription[] = [];

   isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

   petDetail$ = this.store.pipe(select(fromPets.getPetDetail));

   nutritionPlans$ = this.store.pipe(select(fromPets.getNutritionPlans));

   changePending$ = this.store.pipe(select(fromPets.getPetChangePending));

   inActiveNutritionPlans$ = this.store.pipe(select(fromPets.getInactiveNutritionPlans));

   constructor(private store: Store<fromPets.State>, private route: ActivatedRoute, private router: Router) {

      AppAnimate('flyOutIn', this, this.subscriptions);

      this.subscriptions.push(
         route.params
            .subscribe(params => {
               const id = params['id'];
               if (id && id !== '0') {
                  this.store.dispatch(new PetsActions.GetPetDetail(id));
                  this.store.dispatch(new PetsActions.GetNutritionPlans(id));
               }
            })
      );
   }

   ngOnInit() {
   }

   ngOnDestroy() {
      this.subscriptions.forEach(
         subscription => subscription.unsubscribe()
      );
   }

   onSubmit(pet: Pet) {
      if (pet.petCode) {
         this.store.dispatch(new PetsActions.UpdatePet(pet));
      } else {
         this.store.dispatch(new PetsActions.AddPet(pet));
      }
   }

   onNavigateBack() {
      this.store.dispatch(new CoreActions.NavigateBack(true));
   }
}
