import { Component, OnInit, EventEmitter, Input, ViewChild, Output, SimpleChange, SimpleChanges} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Pet, WhatsNewContent } from '../../../api';

@Component({
  selector: 'app-pets',
  templateUrl: './pets.component.html',
  styleUrls: ['./pets.component.scss']
})
export class PetsComponent implements OnInit {
  whatsNewModalRef: NgbModalRef;
  
  @Input() isMobile: boolean | false;

  @Input() pets: Pet[] | null;
  
  /**showPetProfile | If true show my pets listing  else show pets to shop for */
  @Input() showPetProfile: boolean = false;

  @Input() whatsNewContent : WhatsNewContent[] | null;
  
  @Input() whatsNewFlag : boolean | true;

  @ViewChild('whatsNewModal', { static: true })
  whatsNewModal: any;
  
  // Whats-new pop up with dynamic content - Rahul Solanki
  @Output() gotItButtonClick = new EventEmitter();
  // End

  @Output() resetWhatsNewFlag = new EventEmitter();

  constructor(private modalService: NgbModal) {
    
  }
  ngOnInit() {
  }

  // Whats-new pop up with dynamic content - Rahul Solanki

  ngOnChanges(changes: SimpleChanges) {
    // For whatsNew Flag 
    const whatsNewFlagChange: SimpleChange = changes['whatsNewFlag'];
    if (whatsNewFlagChange && whatsNewFlagChange.currentValue != 'undefined' && localStorage.getItem("whatsNewSessionFlag") == undefined) {
        if(!this.whatsNewFlag) {   
          this.callWhatsNewPopUp();
        }
    }
  }

  callWhatsNewPopUp(){
    if(!this.showPetProfile) {      
      setTimeout(() => {
        this.whatsNewModalRef = this.modalService.open(this.whatsNewModal, {size: 'lg'});
        localStorage.setItem("whatsNewSessionFlag", "true");            //whats new popup is shown only once per session
        this.resetWhatsNewFlag.emit();        //Reset Flag to true, after showing the popup - To avoid the issue of PreviousState on Logout
      }, 100);
    }
  }

  closeModal(){
    this.whatsNewModalRef.close();
    this.gotItButtonClick.emit();
  }
  // End

}


