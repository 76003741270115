import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import * as ErrorActions from '../../core/actions/error.actions';
import * as AuthActions from '../actions/auth.actions';
import * as fromAuth from '../reducers';
import { AuthService } from '../../api';
import { ServerResponseException } from '../../shared/exceptions/custom';


@Injectable()
export class CheckTokenGuard  {
  constructor(
    private store: Store<fromAuth.State>,
    private authService: AuthService
  ) {}

  checkToken(token: string): Observable<boolean> {
    return this.authService.isTokenValid(token).pipe(
      map((res: HttpResponse<any>) => {
        // if token is invalid redirect to login page with modal dialog
        const isTokenExpired: boolean = !JSON.parse(
          res.headers.get('token-validity')
        );
        if (isTokenExpired) {
          this.store.dispatch(new AuthActions.CheckTokenExpiry(isTokenExpired));
          return false;
        }
        return true;
      }),
      catchError(error => {
        if (error instanceof SyntaxError) {
          this.store.dispatch(new ErrorActions.ReportError(new ServerResponseException(error.message, error)));
        } else {
          this.store.dispatch(new ErrorActions.ReportError(error));
        }
        return of(false);
      })
    );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const token: string = next.queryParams['token'];
    return this.checkToken(token);
  }
}
