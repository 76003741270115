import {
  Component, EventEmitter,
  Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { quantities } from '../../../shared/constants/shop';
import {
  // DeliveryDurations,
  // deliveryDurations,
  DeliveryFrequencies,
  deliveryFrequencies
} from '../../../shared/constants/payment';
import { AutoShipTemplate, EditingSubscription } from '../../../api';
import { DateValidation } from '../../../shared/validation/dateValidation';
import { DateUtil } from '../../../shared/utils/dateUtil';

@Component({
  selector: 'app-edit-subscription',
  templateUrl: './edit-subscription.component.html',
  styleUrls: ['./edit-subscription.component.scss']
})
export class EditSubscriptionComponent implements OnInit, OnChanges {

  orderForm: UntypedFormGroup;

  dtr: string;

  minDate: string;

  maxDateForDisplay: Date;

  max12Weeks: Date;

  quantities = quantities;

  DeliveryFrequencies = DeliveryFrequencies;

  //deliveryDurations = deliveryDurations.filter(dd => dd.code !== DeliveryDurations.Unlimited);

  deliveryFrequencies = deliveryFrequencies.filter(df => df.code !== DeliveryFrequencies.OneTime);

  @Input() isMobile: boolean | false;

  @Input() changePending: boolean | false;

  @Input() oldDate: boolean | false;

  @Input() subscription: AutoShipTemplate | null;

  @Output() submitted = new EventEmitter<EditingSubscription>();

  @Output() succeed = new EventEmitter<any>();

  @ViewChild('successModal', { static: true })
  successModal: any;

  totalItems: number = 0;

  constructor(private fb: UntypedFormBuilder,
              private modalService: NgbModal,
              private router: Router) {
    this.createForm();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    const subscriptionChange: SimpleChange = changes['subscription'];
    if (subscriptionChange) {
        this.resetForm();
    }
    const changePendingChange: SimpleChange = changes['changePending'];
    if (changePendingChange && !changePendingChange.firstChange) {
      if (changePendingChange.currentValue) {
        this.orderForm.disable();
      } else {
        this.orderForm.enable();
        this.success();
      }
    }
  }

  //To set the Min Date for selection
  dateConvertion(date){
    let dtToday = date;
    let month = dtToday.getMonth() + 1;
    let day = dtToday.getDate();
    let year = dtToday.getFullYear();
    let currentMon : string | number = month,
      today : string | number = day;
    if(month < 10){
      currentMon = '0' + month.toString();
    }
    if(day < 10) {
      today = '0' + day.toString();
    }
    return year + '-' + currentMon + '-' + today;
  }

  createForm() {
    let dtToday = new Date();
    let dt = DateUtil.addWeekDay(dtToday, 6);
    this.minDate = this.dateConvertion(dt);     //for calender

    let maxWeeksDate = DateUtil.add(dtToday, 84, 'd');    //Calculating 12 weeks from today
    this.max12Weeks = new Date(maxWeeksDate);

    this.orderForm = this.fb.group({
      quantity: [1, Validators.required],
      frequency: [DeliveryFrequencies.Week4, Validators.required],
      //duration: [DeliveryDurations.Month12, Validators.required],
      deliveryDate: ['', {
        validators: [Validators.required, DateValidation.onlyWeekDays()],
      }],
    });
  }

  resetForm() {
    if (this.subscription) {

      if(this.subscription.endDate) {             //Checking if subscription has end date
        let subscriptionEndDate = new Date(this.subscription.endDate);
        let maxDate = DateUtil.getMinDate([subscriptionEndDate, this.max12Weeks]);      //Calculating the minimum date from sub end date and 12 weeks date
        this.maxDateForDisplay = new Date(maxDate);
        this.orderForm.get('deliveryDate').setValidators([Validators.required, DateValidation.onlyWeekDays(), DateValidation.maxDate(maxDate), DateValidation.minDate(this.minDate)]);
      }else {
        this.maxDateForDisplay = new Date(this.max12Weeks);
        this.orderForm.get('deliveryDate').setValidators([Validators.required, DateValidation.onlyWeekDays(), DateValidation.maxDate(this.max12Weeks), DateValidation.minDate(this.minDate)]);
      }
      
      const next = this.subscription.nextScheduledDeliveryDate ?
        DateUtil.add(DateUtil.getDateStr(this.subscription.nextScheduledDeliveryDate), 0) :
        DateUtil.addWeekDay(new Date(), 6);

      this.dtr = DateUtil.getDateStr(next);

      const entry = this.subscription && this.subscription.cart.entries.length ?
        this.subscription.cart.entries[0] : null;

      if (entry) {
        this.totalItems = entry.quantity;
        //By Default maximum count of dropdown is 99
        let quantityNum: number = 99;
        if(this.totalItems > quantityNum){
          let newQuantity = this.getQuantitiesFn(this.totalItems);
          this.quantities = newQuantity;
        } else{
          this.quantities = quantities;
        }
        if(this.subscription.availableDeliveryFrequencies && this.subscription.availableDeliveryFrequencies.length == 1)
          this.orderForm.controls['frequency'].disable({onlySelf: true});
        else
          this.orderForm.controls['frequency'].enable({onlySelf: true});

        this.orderForm.reset({
          quantity: entry.quantity,
        //  frequency: this.subscription.availableDeliveryFrequencies.includes(entry.subscriptionUnit.deliveryFrequency) ? entry.subscriptionUnit.deliveryFrequency :  this.subscription.availableDeliveryFrequencies[0],
        frequency:  this.frequencyValue(this.subscription),  
        //duration: entry.subscriptionUnit.duration,
          deliveryDate: this.dtr,
        });
         }
    }
  }

  save() {
    if (this.orderForm.valid) {

      const entry = this.subscription && this.subscription.cart.entries.length ?
        this.subscription.cart.entries[0] : null;

      const model = this.orderForm.value;

      const editingSubscription: EditingSubscription = {
        autoShipCode: this.subscription.autoShipCode,
        subscriptionOrderStatus: this.subscription.recurringOrderStatus,
        product: {
          code: entry.product.code
        },
        nutritionPlanId: entry.subscriptionUnit.nutritionPlanId,
        nextDeliveryDate: DateUtil.getDateStr(model.deliveryDate, 'MM/dd/y'),
        quantity: '' + model.quantity,
        deliveryFrequency: model.frequency,
        duration: "",
        subscriptionId : this.subscription.subscriptionId               //For Desktop View : subscription ID required to fire get subscription detail action, that refreshes subscription with updated values
      };
      this.submitted.emit(editingSubscription);
    }
  }

  success() {
    // Using timeout because of issue here:
    // https://github.com/ng-bootstrap/ng-bootstrap/issues/1775
    setTimeout(() => {
      this.modalService.open(this.successModal).result.then(
        () => {
          this.succeed.emit();
        },
        () => {
          this.succeed.emit();
        }
      );
    });
  }

  //To fetch default value for Frequency Select Box
  fetchFrequency(subscriptionObj) {
    let availableFreq = subscriptionObj.availableDeliveryFrequencies;
    let deliveryFrequency = subscriptionObj.cart.entries[0].subscriptionUnit.deliveryFrequency;
    let faqValue = '';
    availableFreq.forEach((item, index) => {
      faqValue = item;
      if(deliveryFrequency.toLowerCase() === item.toLowerCase()){
        return deliveryFrequency;
      }
    });
    return faqValue.toUpperCase();
  }

  //To fetch the value of Frequency dropdown
  frequencyValue(subscriptionObj){
    if(subscriptionObj.availableDeliveryFrequencies !== undefined) {
      let frequencyValue = '';
      let frequencyObj = subscriptionObj.availableDeliveryFrequencies;
      let freqLen = frequencyObj.length;
      let flag = false;
      for (let i = 0; i < freqLen; i++) {
        let currentFrequency = frequencyObj[i];
        if (currentFrequency.toLowerCase() === subscriptionObj.cart.entries[0].subscriptionUnit.deliveryFrequency.toLowerCase()) {
          frequencyValue = currentFrequency;
          flag = true;
          break;
        } else {
          flag = false;
        }
      }
      if(!flag){
        let durationLen = subscriptionObj.availableDuration.length;
        if(durationLen > 1){
          frequencyValue = 'WEEK_4';
        } else{
          frequencyValue = frequencyObj[freqLen-1].toUpperCase();
        }
      }
      return frequencyValue.toUpperCase();
    }
  }

  //To fetch the value of Duration dropdown
  // durationValue(subscriptionObj){
  //   let durationValue = '';
  //   let len;
  //   let flag = false;
  //   if(subscriptionObj.availableDuration !== undefined){
  //     len = subscriptionObj.availableDuration.length;
  //     for(let i=0; i<len; i++ ){
  //       let currentDuration = subscriptionObj.availableDuration[i];
  //       if(currentDuration.toLowerCase() === subscriptionObj.cart.entries[0].subscriptionUnit.duration.toLowerCase()){
  //         durationValue = currentDuration;
  //         flag = true;
  //         break;
  //       } else{
  //         flag = false;
  //       }
  //     }
  //     if(!flag){
  //       durationValue = subscriptionObj.availableDuration[len-1];
  //     }
  //     return durationValue;
  //   }
  // }

  //To Display Available Duration
  // displayDuration(subscriptionObj){
  //   let len;
  //   let newDurationObj = [];
  //   if(subscriptionObj.availableDuration !== undefined){
  //     len = subscriptionObj.availableDuration.length;
  //     for(let i=0; i<len; i++ ){
  //       let currentDuration = subscriptionObj.availableDuration[i];
  //       if(currentDuration.toLowerCase() === subscriptionObj.cart.entries[0].subscriptionUnit.duration.toLowerCase()){
  //         newDurationObj.push(subscriptionObj.availableDuration[i]);
  //         break;
  //       } else{
  //         newDurationObj.push(subscriptionObj.availableDuration[i]);
  //       }
  //     }
  //     return newDurationObj;
  //   }
  // }
  //Update the quantities with high number
  getQuantitiesFn(max: number){
    const result = [];
    for (let i = 1; i <= max; i++) {
      result.push(i);
    }
    return result;
  }

  onQuantityRemoved() {
    let currentQuantity = this.orderForm.get('quantity').value;
    if (currentQuantity > 1) {
      let updatedQuantity = currentQuantity - 1;
      this.orderForm.controls['quantity'].setValue(updatedQuantity);
      this.orderForm.controls['quantity'].markAsDirty();
    }
  }

  onQuantityAdded() {
    let currentQuantity = this.orderForm.get('quantity').value;
    if (currentQuantity <= 99) {
      let updatedQuantity = currentQuantity + 1;
      this.orderForm.controls['quantity'].setValue(updatedQuantity);
      this.orderForm.controls['quantity'].markAsDirty();
    }
  }

 }
