import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbToastModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileComponent } from './components/profile/profile.component';
import { ProfilePageComponent } from './containers/profile-page/profile-page.component';
import { EditNameComponent } from './components/edit-name/edit-name.component';
import { EditNamePageComponent } from './containers/edit-name-page/edit-name-page.component';
import { EditEmailPageComponent } from './containers/edit-email-page/edit-email-page.component';
import { EditPhonePageComponent } from './containers/edit-phone-page/edit-phone-page.component';
import { EditPasswordPageComponent } from './containers/edit-password-page/edit-password-page.component';
import { EditAddressPageComponent } from './containers/edit-address-page/edit-address-page.component';
import { EditPaymentPageComponent } from './containers/edit-payment-page/edit-payment-page.component';
import { EditPaymentComponent } from './components/edit-payment/edit-payment.component';
import { EditAddressComponent } from './components/edit-address/edit-address.component';
import { EditPasswordComponent } from './components/edit-password/edit-password.component';
import { EditPhoneComponent } from './components/edit-phone/edit-phone.component';
import { EditEmailComponent } from './components/edit-email/edit-email.component';

import { reducers } from './reducers';
import { ProfileGuard } from './guards/profile.guard';
import { ProfileEffects } from './effects/profile.effects';
import { NgxStripeModule } from 'ngx-stripe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CoreModule,
    NgbToastModule,
    NgbModule,
    ProfileRoutingModule,
    StoreModule.forFeature('profile', reducers),
    EffectsModule.forFeature([ProfileEffects]),
    NgxStripeModule
  ],
  declarations: [
    ProfileComponent,
    ProfilePageComponent,
    EditNameComponent,
    EditNamePageComponent,
    EditEmailPageComponent,
    EditPhonePageComponent,
    EditPasswordPageComponent,
    EditAddressPageComponent,
    EditPaymentPageComponent,
    EditPaymentComponent,
    EditAddressComponent,
    EditPasswordComponent,
    EditPhoneComponent,
    EditEmailComponent
  ],
  exports: [
    EditAddressComponent,
    EditPaymentComponent,
  ]
})
export class ProfileModule {
  static forRoot(): ModuleWithProviders<ProfileModule> {
    return {
      ngModule: ProfileModule,
      providers: [ProfileGuard],
    };
  }
}
