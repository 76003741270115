import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/guards/auth.guard';
import { ErrorGuard } from '../core/guards/error.guard';
import { NpProductsPageComponent } from './containers/np-products-page/np-products-page.component';
import { NpFiltersPageComponent } from './containers/np-filters-page/np-filters-page.component';
import { ProductPageComponent } from './containers/product-page/product-page.component';

const routes: Routes = [
  {
    path: 'np-products/:clinicId/:wellnessId',
    component: NpProductsPageComponent,
    canActivate: [ErrorGuard, AuthGuard],
    data: { back: true }
  },
  {
    path: 'np-filters/:clinicId',
    component: NpFiltersPageComponent,
    canActivate: [ErrorGuard, AuthGuard],
    data: { back: true }
  },
  {
    path: 'np-product/:clinicId/:id/:petCode',
    component: ProductPageComponent,
    canActivate: [ErrorGuard, AuthGuard],
    data: { back: true }
  },
  /*{
    path: 'np-products-change/:clinicId/:petCode',
    component: NpProductsPageComponent,
    canActivate: [ErrorGuard, AuthGuard],
    data: { back: true, changeProduct: true }
  },*/
  {
    path: 'np-products-change/:clinicId/:subscriptionId',
    component: NpProductsPageComponent,
    canActivate: [ErrorGuard, AuthGuard],
    data: { back: true, changeProduct: true }
  },
  {
    path: 'np-product-change/:clinicId/:id/:petCode',
    component: ProductPageComponent,
    canActivate: [ErrorGuard, AuthGuard],
    data: { back: true, changeProduct: true }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule { }


