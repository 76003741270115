import { CorrectedAddress } from './../../models/correctedAddress';
import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output,
  SimpleChange, SimpleChanges, ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Region, Address, ProfileAddresses } from '../../../api';
import { states } from '../../../shared/constants/address';
import { Patterns } from '../../../shared/validation/patterns';
import { checkAddressValidator } from './custom-address-validator';
import { checkRegionValidator } from './custom-address-validator';

@Component({
  selector: 'app-edit-address',
  templateUrl: './edit-address.component.html',
  styleUrls: ['./edit-address.component.scss']
})
export class EditAddressComponent implements OnInit, OnChanges {

  profileForm: UntypedFormGroup;

  addressSuggestionform: UntypedFormGroup;

  modalRef: NgbModalRef;

  states: Region[] = states;

  showRadioError: boolean = false;

  showAddressPopup: boolean = true;

  fromCart: boolean = false;

  modalOptions: NgbModalOptions;

  @Input() isMobile: boolean | false;

  @Input() isCart = false;

  @Input() changePending: boolean | false;

  @Input() removalPending: boolean | false;

  @Input() address: Address | null;

  @Input() profileAddressChangeError: boolean | false;

  @Input() profileCorrectedAddress: CorrectedAddress | null;

  @Input() profileAddresses: ProfileAddresses | null;

  @Output() submitted = new EventEmitter<Address>();

  @Output() submitAddress = new EventEmitter<Address>();

  @Output() removed = new EventEmitter<Address>();

  @Output() navigatedBack = new EventEmitter();

  @ViewChild('successModal', { static: true })
  successModal: any;

  @ViewChild('confirmAddressModal', { static: true })
  confirmAddressModal: any;
  fullName: string;

  @ViewChild('alertAddressDeleteModal', { static: true })
  alertAddressDeleteModal: any;

  constructor(private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.createForm();
    this.createAddressForm();
    this.route.data
        .subscribe(params => {
          this.fromCart = params['fromCart'] ? true : false;
        });

        this.modalOptions = {
          backdrop : 'static',
          keyboard : false
    };
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {

    const addressChange: SimpleChange = changes['address'];
    if (addressChange) {
      this.resetForm();
    }
    const changePendingChange: SimpleChange = changes['changePending'];
    if (changePendingChange && !changePendingChange.firstChange) {
      if (changePendingChange.currentValue) {
        this.profileForm.disable();
      } else {
        this.profileForm.enable();
        this.success();
      }
    }

    const profileAddressChangeError: SimpleChange = changes['profileAddressChangeError'];
    if (profileAddressChangeError && !profileAddressChangeError.firstChange &&
      profileAddressChangeError.currentValue && this.isMobile) {
      this.showAddressModal();
      this.showRadioError = false;
    }

    const removalPendingChange: SimpleChange = changes['removalPending'];
    if (removalPendingChange && !removalPendingChange.firstChange &&
      !removalPendingChange.currentValue) {
      this.router.navigate([!this.isCart ? '/profile' : '/place-order']);
    }
    // On desktop redirect back to master page
    const isMobileChange: SimpleChange = changes['isMobile'];
    if (isMobileChange && !isMobileChange.firstChange &&
      !isMobileChange.currentValue) {
      this.router.navigate([!this.isCart ? '/profile' : '/place-order']);
    }
  }

  createForm() {
    this.profileForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      line1: ['', [Validators.required, checkAddressValidator]],
      line2: ['', [checkAddressValidator]],
      town: ['', Validators.required],
      region: ['', [Validators.required, checkRegionValidator]],
      postalCode: ['', [Validators.required, Validators.pattern(Patterns.zipCodePattern)]],
      defaultAddress: [false],
      // phoneNumber: ['', {
      //   validators: [Validators.required, Validators.pattern(Patterns.phoneNumberPattern)],
      // }]
    });
  }

  createAddressForm() {
    this.addressSuggestionform = this.fb.group({
      AddressSuggestionRadio: ['', Validators.required]
    });
  }

  resetForm() {
    if (this.address) {
      this.fullName = this.address.firstName + ' ' + this.address.lastName;
      this.profileForm.reset({
        firstName: this.address.firstName,
        lastName: this.address.lastName,
        line1: this.address.line1,
        line2: this.address.line2,
        town: this.address.town,
        region: this.address.region.isocode,
        postalCode: this.address.postalCode,
        defaultAddress: this.address.defaultAddress
        //phoneNumber: this.address.phone
      });

      if (this.address.defaultAddress) {
        this.profileForm.get('defaultAddress').disable();
      } else {
        this.profileForm.get('defaultAddress').enable();
      }
    }
  }
  save() {
    const address: Address = {
      ...this.address,
      country: {
        ...this.address.country
      },
      region: {
        ...this.address.region
      }
    };

    const model = this.profileForm.value;
    address.firstName = model.firstName;
    address.lastName = model.lastName;
    address.line1 = model.line1;

    if (model.line2 == null || model.line2 == '') {
      model.line2 = '';
    }

    address.line2 = model.line2;
    address.town = model.town;
    //address.phone = model.phoneNumber;
    address.region.isocode = model.region;
    address.postalCode = '' + model.postalCode;
    address.defaultAddress = this.address.defaultAddress ? true : !!model.defaultAddress;

    if (!this.profileAddressChangeError) {
      if (this.profileForm.valid) {
        this.submitted.emit(address);
        this.showAddressPopup = false;
      }
    } else {
      if (this.addressSuggestionform.valid) {
        if (this.addressSuggestionform.value.AddressSuggestionRadio == "keep_corrected_address") {
          let str = this.profileCorrectedAddress.city;
          str = str ? str.charAt(0).toUpperCase() + str.substr(1).toLowerCase() : '';
          address.town = str;
          address.region.isocode = this.profileCorrectedAddress.state;
          address.postalCode = '' + this.profileCorrectedAddress.zipcode;
        }
        if (this.isMobile) {
          this.modalRef.close();
        }
        this.submitAddress.emit(address);
        this.showAddressPopup = false;
      } else {
        this.showRadioError = true;
      }
    }

  }

  /**
   * Automatically insert '-' in phone number as per format
   * @param event Trigger from input key 
   */
  onKey(event: any) {
    let chIbn = event.target.value.split('-').join('');
    if (chIbn.length > 0 && event.target.value.length < 10) {
      console.log(chIbn);
      chIbn = chIbn.match(new RegExp('.{1,3}', 'g')).join('-');
      this.profileForm.controls['phoneNumber'].setValue(chIbn,{onlySelf: true});
    }
  }

  success() {
    // Using timeout because of issue here:
    // https://github.com/ng-bootstrap/ng-bootstrap/issues/1775
    setTimeout(() => {
      this.modalService.open(this.successModal).result.then(
        () => {
          this.router.navigate([!this.isCart ? '/profile' : '/place-order']);
        },
        () => {
          this.router.navigate([!this.isCart ? '/profile' : '/place-order']);
        }
      );
    });
  }

  showAddressModal() {
    setTimeout(() => {
      this.modalRef = this.modalService.open(this.confirmAddressModal, this.modalOptions);
      this.modalRef.result.then((result) => {
      }, (reason) => {
      });
    });
  }

  resetAddressError() {
    this.profileAddressChangeError = false;
  }
  confirm(content) {
    this.modalService.open(content).result.then(
      () => {
        if(this.profileAddresses.addresses.length > 1)
          this.remove();
      },
      () => {
      }
    );
  }

  remove() {
    this.removed.emit(this.address);
  }

  hideAddressPopup() {
    this.showAddressPopup = false;
    this.navigatedBack.emit();
    //this.router.navigate(['/place-order']);
  }

}
