import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output,
  SimpleChange, SimpleChanges, ViewChild, AfterViewInit
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  OrderDetail,Order, Entry
} from '../../../api';
import {
  DeliveryFrequencies, OrderStatuses
} from '../../../shared/constants/payment';
import { Router } from '@angular/router';

import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { of, from } from 'rxjs';
import { ReturnOrderDetail } from '../../../api/model/returnOrderDetail';
import { ReturnOrderEntry } from 'src/app/api/model/returnOrderEntries';

@Component({
  selector: 'app-return',
  templateUrl: './return.component.html',
  styleUrls: ['./return.component.css']
})
export class ReturnComponent implements OnInit, OnChanges {
  
  returnOrderForm: UntypedFormGroup;
  ordersData:Array<ReturnOrderEntry>;
  
  DeliveryFrequencies = DeliveryFrequencies;

  OrderStatuses = OrderStatuses;

  @Input() isMobile: boolean | false;

  @Input() isHome = false;

  @Input() returnOrderDetail: ReturnOrderDetail | null;

  @Input() orderReturnPending: boolean | false;

  @Output() submitted = new EventEmitter<ReturnOrderDetail>();

  @Output() succeed = new EventEmitter<any>();

  @ViewChild('successModal', { static: true })
  successModal: any;
  itemModel: any[] = [];
  items: UntypedFormArray;
  refundReason: UntypedFormArray;
  submit_button:boolean = false;

  constructor(private formBuilder: UntypedFormBuilder,private modalService: NgbModal,private router: Router) { 
    
  }

  return_order_submit(){
    if(this.returnOrderForm.invalid){
      return;
    }
    const model = this.returnOrderForm.value;
    
    const selectedProductIds = model.return_orders_array
    .map((v, i) => v.return_product_checked == true ? model.return_orders_array[i] : null)
    .filter(v => v !== null);

    if(selectedProductIds.length === 0){
      this.submit_button = true;
      return;
    }

    const returnOrderObj :ReturnOrderDetail = ({
      ...this.returnOrderDetail,
      returnEntries: selectedProductIds
    });

    const returnOrderJson:any = {};

    returnOrderJson.returnOrder = {
      code:returnOrderObj.returnOrder.code
    }
    returnOrderJson.returnEntries = [];
    returnOrderObj.returnEntries.filter( entry => {
      let  returnEntries= {
        product:{
          code: entry.product.code
        },
        expectedQuantity: entry.expectedQuantity,
        orderEntry : {
            entryNumber: entry.entryNumber
        },
        refundReason : entry.refundReason,
        notes: entry.notes
      }
      returnOrderJson.returnEntries.push(returnEntries);     
    });
    
    // for sending data to parent component 
    setTimeout(() => {
      this.submitted.emit(returnOrderJson);  
    }, 500);
        
  }

  minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: UntypedFormArray) => {
      const totalSelected = formArray.value
        .map(control => control.return_product_checked.value)
        .reduce((prev, next) => next ? prev + next : prev, 0);
      return totalSelected >= min ? null : { required: true };
    };
    return validator;
  }

  // set formcontrols to returnOrderEntries data for each object
  setReturnOrderData(Order_Products:any[]){
    const d = Order_Products.map(el => {
      let obj = {};
      obj["entryNumber"] = el.entryNumber;
      obj["product"] = el.product;
      obj["quantity"] = el.quantity;
      obj["basePrice"] = el.basePrice;
      obj["expectedQuantity"] = [el.expectedQuantity];
      obj["quantityList"] = this.formBuilder.array(el.quantityList);
      obj["refundReason"] = [el.refundReason];
      obj["refundReasonArray"] = this.formBuilder.array(el.refundReasonsMap.entry);
      obj["notes"] = [el.notes];
      obj["orderEntry"] = { "entryNumber": el.entryNumber };
      obj["return_product_checked"] = [el.return_product_checked];
      return this.formBuilder.group(obj);
    })
    const countArray = this.formBuilder.array(d);
    this.returnOrderForm.setControl('return_orders_array', countArray);
  }

  ngOnInit() {
    setTimeout(() => {
      this.returnOrderDetailObj();
    }, 2000)

    this.returnOrderForm = this.formBuilder.group({
      return_orders_array: this.formBuilder.array([])
    });
  }
  returnOrderDetailObj(){
    // Set default values for returnOrderEntries data on Each Object for below mentioned attributes(properties)
    of(this.returnOrderDetail.returnOrder).subscribe(orders => {
      orders.returnOrderEntries.map(item => {
        this.itemModel.push({
          ...item,
          orderEntry: { "entryNumber" : null },
          expectedQuantity: 1,
          refundReason: "",
          return_product_checked: false
        })
      })
      this.ordersData = this.itemModel;
    });
    this.setReturnOrderData(this.ordersData);
  }

  getQuantitiesFn = (max: number) => {
    const result = [];
    for (let i = 1; i <= max; i++) {
      result.push(i);
    }
    return result;
  };

  success() {
    // Using timeout because of issue here:
    // https://github.com/ng-bootstrap/ng-bootstrap/issues/1775
    setTimeout(() => {
      this.modalService.open(this.successModal).result.then(
        () => {
          this.succeed.emit();
        },
        () => {
          this.succeed.emit();
        }
      );
    });
  }

  checkboxValidator(item) {
    const checkbox_checked_validator = item.controls.return_product_checked;
    const refund_reason_validator = item.controls.refundReason;
    const notes_validator = item.controls.notes;
      if(item.value.return_product_checked){
        refund_reason_validator.setValidators([Validators.required])
        if(refund_reason_validator.value == "H10"){
          notes_validator.setValidators([Validators.required])
        }
      }else{
        refund_reason_validator.setValidators(null)
        notes_validator.setValidators(null)
      }

      const model = this.returnOrderForm.value;
      const selectedProductIds = model.return_orders_array
        .map((v, i) => v.return_product_checked == true ? model.return_orders_array[i] : null)
        .filter(v => v !== null);
      
      if(selectedProductIds.length === 0){
        this.submit_button = true;
      }else{
        this.submit_button = false;
      }
      checkbox_checked_validator.updateValueAndValidity();
      refund_reason_validator.updateValueAndValidity();
      notes_validator.updateValueAndValidity();
  }

  dropdownValidator(item) {
    const refund_reason_validator = item.controls.refundReason;
    const notes_validator = item.controls.notes;
    if(refund_reason_validator.value == "H10"){
      notes_validator.setValidators([Validators.required])
    }else{
      notes_validator.setValidators(null);
    }
    notes_validator.updateValueAndValidity();
  }
  requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
    return function validate (formGroup: UntypedFormGroup) {
      let checked = 0;
      Object.keys(formGroup.controls).forEach(key => {
        const control = formGroup.controls[key].value.return_product_checked;
        if (control.value === true) {
          checked ++;
        }
      });
  
      if (checked < minRequired) {
        return {
          requireOneCheckboxToBeChecked: true,
        };
      }
  
      return {
        requireOneCheckboxToBeChecked: false,
      };
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    const orderReturnPendingChange: SimpleChange = changes['orderReturnPending'];
    if (orderReturnPendingChange && !orderReturnPendingChange.firstChange) {
      if (!orderReturnPendingChange.currentValue) {
        this.returnOrderForm.disable();
        this.success();
      } else {
        this.returnOrderForm.enable();
      }
    }
  }

}
