
import { Action } from '@ngrx/store';
import {
  ProductDetail, SearchedProducts, Facet, SearchedProduct
} from '../../api';
import { ProductFilters } from '../models/productFilters';

export enum ProductsActionTypes {
  SearchPlan = '[Products] Search Plan',
  SearchPlanSuccess = '[Products] Search Plan Success',
  GetPlanFacets = '[Products] Search Plan Filters',
  GetPlanFacetsSuccess = '[Products] Search Plan Filters Success',
  SetPlanFilters = '[Products] Set Plan Filters',
  SetPlanSort = '[Products] Set Plan Sort',
  ResetSearchPlan = '[Products] Reset Search Plan',
  GetProductDetail = '[Products] Get Product Detail',
  GetProductDetailSuccess = '[Products] Get Product Detail Success',
  FilterProduct = '[Products] Filter Products',
  FilterProductSuccess = '[Products] Filter Products Success',
  GetReferenceProducts = '[Products] Get Reference Products',
  GetReferenceProductsSuccess = '[Products] Get Reference Products Success',
  SearchProductsByName = '[Products] Search Products By Name',
  SearchProductsByNameSuccess = '[Products] Search Products By Name Success',
}

export class SearchProductsByName implements Action {
  readonly type = ProductsActionTypes.SearchProductsByName;
  constructor(public clinicId, public name: string, public pageNumber: number = 0, public stack: boolean = false) { }
}

export class SearchProductsByNameSuccess implements Action {
  readonly type = ProductsActionTypes.SearchProductsByNameSuccess;
  constructor(public searchedPlanProducts: SearchedProducts, public stack: boolean = false) { }
}

export class SearchPlan implements Action {
  readonly type = ProductsActionTypes.SearchPlan;

  constructor(public clinicId, public pageNumber: number = 0, public name: string = '', public stack: boolean = false) {
  }
}

export class FilterProduct implements Action {
  readonly type = ProductsActionTypes.FilterProduct;

  constructor(public clinicId, public pageNumber: number = 0, public stack: boolean = false) {
  }
}


export class SearchPlanSuccess implements Action {
  readonly type = ProductsActionTypes.SearchPlanSuccess;

  constructor(public searchedPlanProducts: SearchedProducts, public stack: boolean = false) {
  }
}

export class FilterProductSuccess implements Action {
  readonly type = ProductsActionTypes.FilterProductSuccess;

  constructor(public searchedPlanProducts: SearchedProducts, public stack: boolean = false) {
  }
}


export class GetPlanFacets implements Action {
  readonly type = ProductsActionTypes.GetPlanFacets;

  constructor(public clinicId, public subscriptionId) {
  }
}

export class GetPlanFacetsSuccess implements Action {
  readonly type = ProductsActionTypes.GetPlanFacetsSuccess;

  constructor(public planFacets: Array<Facet>) {
  }
}

export class SetPlanFilters implements Action {
  readonly type = ProductsActionTypes.SetPlanFilters;

  constructor(public planFilters: ProductFilters) {
  }
}

export class SetPlanSort implements Action {
  readonly type = ProductsActionTypes.SetPlanSort;

  constructor(public planSort: string) {
  }
}

export class ResetSearchPlan implements Action {
  readonly type = ProductsActionTypes.ResetSearchPlan;

  constructor() {
  }
}

export class GetProductDetail implements Action {
  readonly type = ProductsActionTypes.GetProductDetail;

  constructor(public clinicId, public id: string, public petCode: string) {
  }
}

export class GetProductDetailSuccess implements Action {
  readonly type = ProductsActionTypes.GetProductDetailSuccess;

  constructor(public productDetail: ProductDetail) {
  }
}


export class GetReferenceProducts implements Action {
  readonly type = ProductsActionTypes.GetReferenceProducts;

  constructor(public id: string) {
  }
}


export class GetReferenceProductsSuccess implements Action {
  readonly type = ProductsActionTypes.GetReferenceProductsSuccess;

  constructor(public referenceProducts: SearchedProduct[]) {
  }
}



export type ProductsActionsUnion =
  | SearchPlan
  | SearchPlanSuccess
  | GetPlanFacets
  | GetPlanFacetsSuccess
  | SetPlanFilters
  | SetPlanSort
  | ResetSearchPlan
  | GetProductDetail
  | GetProductDetailSuccess
  | FilterProduct
  | FilterProductSuccess
  | GetReferenceProducts
  | GetReferenceProductsSuccess
  | SearchProductsByName
  | SearchProductsByNameSuccess

  ;
