import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { skip, switchMap, take, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as fromCore from '../../../core/reducers';
import * as fromOrders from '../../reducers';
import * as fromProfile from '../../../profile/reducers';
import * as OrdersActions from '../../actions/orders.actions';
import * as LayoutActions from '../../../core/actions/layout.actions';
import { ScrollChange } from '../../../core/models/scrollChange';
import { LayoutModes } from '../../../core/constants/layout';
import { AppAnimate, fade } from '../../../shared/animations/common.animations';
import { CartChange } from '../../models/cartChange';

@Component({
  selector: 'app-cart-page',
  template: `
    <app-cart
      [isMobile]="isMobile$ | async"
      [cart]="cart$ | async"
      [pending]="cartProductChangePending$ | async"
      (submitted)="onSubmit($event)"
      (submitOrder)="submitOrder()"
       [profileAddresses]="profileAddresses$ | async"
      [profilePayments]="profilePayments$ | async">
    </app-cart>

  `,
  styles: [],
  animations: [fade]
})
export class CartPageComponent implements OnInit, OnDestroy {

  @HostBinding('@fade')
  fade = '';

  // @HostBinding('class.app-animated-page')
  // animatedPage = true;

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  scrollChange$ = this.store.pipe(select(fromCore.getScrollChange));

  cart$ = this.store.pipe(select(fromOrders.getCartDetail));

  cartProductChangePending$ = this.store.pipe(select(fromOrders.getCartProductChangePending));

  profileAddresses$ = this.store.pipe(select(fromProfile.getProfileAddresses));

  profilePayments$ = this.store.pipe(select(fromProfile.getProfilePayments));

  subscriptions: Subscription[] = [];

  constructor(private store: Store<fromOrders.State>) {
    AppAnimate('fade', this, this.subscriptions);
  }

  ngOnInit() {
    this.store.dispatch(new OrdersActions.GetCartDetail());

    this.subscriptions.push(
      this.scrollChange$
        .pipe(
          skip(1),
          switchMap((scrollChange: ScrollChange) => this.isMobile$
            .pipe(
              take(1),
              tap((isMobile: boolean) => {
                if (isMobile && scrollChange) {
                  if (scrollChange.yOffset > 425) {
                    this.store.dispatch(new LayoutActions.ChangeLayoutMode(LayoutModes.Normal));
                  } else {
                    this.store.dispatch(new LayoutActions.ChangeLayoutMode(LayoutModes.Alternate2));
                  }
                }
              }
              ))
          ))
        .subscribe()
    );

    // Changing the cart refreshes the cart
    this.subscriptions.push(
      this.cartProductChangePending$
        .pipe(
          skip(1),
          tap((changePending: boolean) => {
            if (!changePending) {
              this.store.dispatch(new OrdersActions.GetCartDetail());
            }
          })
        ).subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
  }

  onSubmit(cartChange: CartChange) {
    this.store.dispatch(new OrdersActions.UpdateCartProduct(cartChange));
  }

  submitOrder() {

    this.store.dispatch(new OrdersActions.PlaceOrder());
  }
}
