import { Pipe, PipeTransform } from '@angular/core';
import { Region } from '../../../api';
import { states } from '../../constants/address';

@Pipe({
  name: 'appRegion'
})
export class RegionPipe implements PipeTransform {

  states: Region[] = states;

  transform(value: any, args?: any): any {
    let state = '';
    if (value) {
      const region = this.states.find(r => r.isocode === value);
      if (region) {
        state = region.name;
      }
    }
    return state;
  }
}
