import { Component, HostBinding, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromCore from '../../../core/reducers';
import * as fromProfile from '../../reducers';
import * as ProfileActions from '../../actions/profile.actions';
import { PasswordChange } from '../../models/passwordChange';
import { flyOutIn } from '../../../shared/animations/common.animations';

@Component({
  selector: 'app-edit-password-page',
  template: `
    <app-edit-password
      [isMobile]="isMobile$ | async"
      [error]="error$ | async"
      [pending]="pending$ | async"
      [profile]="profile$ | async"
      (submitted)="onSubmit($event)">
    </app-edit-password>
  `,
  styles: [],
  animations: [flyOutIn]
})
export class EditPasswordPageComponent implements OnInit {

  @HostBinding('@flyOutIn')
  flyOutIn = 'in';

  // @HostBinding('class.app-animated-page')
  // animatedPage = true;

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  error$ = this.store.pipe(select(fromProfile.getProfilePasswordChangeError));

  pending$ = this.store.pipe(select(fromProfile.getProfilePasswordChangePending));

  profile$ = this.store.pipe(select(fromProfile.getProfile));

  constructor(private store: Store<fromProfile.State>) {
  }

  ngOnInit() {
    this.store.dispatch(new ProfileActions.UpdatePasswordReset());
  }

  onSubmit(passwordChange: PasswordChange) {
    this.store.dispatch(new ProfileActions.UpdatePassword(passwordChange));
  }

}
