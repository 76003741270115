<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <div class="flex-grow-1 text-center">
        <h4 class="modal-title">{{ entry.product.name }}</h4>
      </div>
      <button (click)="closeSubscriptionModal()" aria-label="Close" class="close" type="button">
        <i class="fa fa-times"></i>
      </button>
    </div>
    <div class="modal-body text-center">
      <label class="w500" [for]="'frequency'+entry.product.code">Frequency</label>
      <div class="form-group mt-1 mb-1" *ngIf="entry.isEditable">
        <select [id]="'frequency'+entry.product.code" name="frequency" class="form-control custom-select"
          [ngValue]="entry.frequency.selected || DeliveryFrequencies.Week1"
          (change)="onFrequencyChanged($event.target.value);">
          <option *ngFor="
                            let frequency of entry.frequency.available
                              | toDeliveryFrequency
                          " [value]="frequency.code" [selected]="entry.frequency.selected === frequency.code || DeliveryFrequencies.Week1 === frequency.code ? true : false">
            {{ frequency.code | appSubscriptionFrequency }}
          </option>
        </select>
      </div>

      <!-- <label class="w500 pt-3" for="duration"
        *ngIf="entry.isEditable && entry.duration.available  && !isOneTimeShipment">Length</label>
      <div class="form-group mt-1 mb-2" *ngIf="entry.isEditable  && !isOneTimeShipment">
        <select id="duration" name="duration" class="form-control custom-select"
          [value]="entry.duration.selected || DeliveryFrequencies.Week1"
          (change)="onDurationChanged($event.target.value);">
          <option *ngFor="
                            let duration of entry.duration.available
                              | toDeliveryDuration
                          " [value]="duration.code">{{ duration.name }}</option>
        </select>
      </div> -->
      <!-- <div class="mt-4 d-flex align-items-center justify-content-center">
                    <span class="w500 mr-3">Total Price: </span>
                    <h4 class="m-0">{{ entry.totalPrice.formattedValue}}</h4>
                </div> -->
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary pl-5 pr-5 btn-lg ml-2"
        (click)="closeSubscriptionModal();onSubmit(entry);">
        DONE
      </button>
    </div>
  </div>
</div>
