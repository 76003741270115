import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {NgbCarousel, NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';
import { WhatsNewContent } from 'src/app/api';

@Component({
  selector: 'app-whats-new',
  templateUrl: './whats-new.component.html',
  styleUrls: ['./whats-new.component.scss']
})
export class WhatsNewComponent implements OnInit {

  @ViewChild('whatsNewCarousel', {read: NgbCarousel}) whatsNewCarousel: NgbCarousel;
  @Input() isMobile: boolean | false;
  @Input() whatsNewContent : WhatsNewContent[] | null;

  @Output() slide = new EventEmitter();
  @Output() gotItClicked = new EventEmitter();

  isFirstSlide : boolean | true;
  isLastSlide : boolean | false;

  constructor(config:NgbCarouselConfig) {
    config.showNavigationArrows = false;
   }

  ngOnInit() {
    this.isFirstSlide = true;
  }

  callNextSlide() {
    this.whatsNewCarousel.next();
  }
  callPrevSlide() {
    this.whatsNewCarousel.prev();
  }

  onSlideChange($event){
    this.isFirstSlide = false;
    this.isLastSlide = false;
    if($event.current == 'slide_0'){
      this.isFirstSlide = true;
    }
    if($event.current == 'slide_2'){
      this.isLastSlide = true;
    }
  }
  onGotItClick(){
    this.gotItClicked.emit();
  }
}
