// modules
import { Component, OnInit } from '@angular/core';

//reducers
import * as fromCore from '../../../core/reducers';
import * as fromRoot from '../../../reducers';
import * as fromOrders from '../../reducers';

//actions
import * as OrdersActions from '../../actions/orders.actions';

//store
import { Store, select } from '@ngrx/store';
import { EditingSubscription } from 'src/app/api';

@Component({
    selector: 'app-edit-order-delivery-page',
    template: `
    <app-edit-order-delivery-date
        [isMobile]="isMobile$ | async"
        [upcomingOrders] = "upcomingOrders$ | async"
        [subscriptionChangePending]="subscriptionChangePending$ | async"
        (submitted)="onSubmit($event)"
        (fetchOrders)="fetchUpcomingOrders()"
    >
    </app-edit-order-delivery-date>
    `
})
export class EditOrderDeliveryDatePage implements OnInit {

    isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

    upcomingOrders$ = this.store.pipe(select(fromOrders.getUpcomingOrders));

    subscriptionChangePending$ = this.store.pipe(select(fromOrders.getSubscriptionChangePending));

    ngOnInit() {
        this.store.dispatch(new OrdersActions.GetUpcomingOrders());
    }

    constructor(private store: Store<fromRoot.State>) {
    }

    onSubmit(editingSubscription: EditingSubscription) {

        this.store.dispatch(new OrdersActions.UpdateOrderDeliveryDate(editingSubscription));
    }


    fetchUpcomingOrders() {
        this.store.dispatch(new OrdersActions.GetUpcomingOrders());
    }
}

