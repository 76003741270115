import { OnDestroy } from '@angular/core';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { Component, OnChanges, OnInit, Input, Output, EventEmitter, SimpleChange, SimpleChanges } from '@angular/core';
import { Entry } from './../../../../api/model/entry';
import { DeliveryFrequencies } from 'src/app/shared/constants/payment';

@Component({
    selector: 'cart-subs-form',
    templateUrl: './subscription.component.html'
})
export class CartSubscription implements OnInit, OnChanges {


    @Input() entry: any;

    @Output()
    submitted = new EventEmitter();

    @Output()
    closeCartInfoModal = new EventEmitter();

    DeliveryFrequencies = DeliveryFrequencies;

    deliveryFrequency: string = '';
    duration: string = '';
    isOneTimeShipment: boolean;

    constructor(private fb: UntypedFormBuilder) {
    }

    ngOnInit() {
        this.deliveryFrequency = this.entry.frequency.selected;
        this.duration = this.entry.duration.selected;
        this.isOneTimeShipment = this.entry.frequency.selected === DeliveryFrequencies.OneTime ? true : false;
    }

    ngOnChanges(changes: SimpleChanges) {

        const entryChange: SimpleChange = changes['entry'];
        if (entryChange && !entryChange.firstChange) {
            this.deliveryFrequency = entryChange.currentValue.frequency.selected;
            this.duration = entryChange.currentValue.duration.selected;
            this.isOneTimeShipment = entryChange.currentValue.frequency.selected === DeliveryFrequencies.OneTime ? true : false;
        }
    }

    onDurationChanged(duration) {
        this.duration = duration;
    }

    onFrequencyChanged(frequency) {
        if (frequency === DeliveryFrequencies.OneTime) {
            this.isOneTimeShipment = true;
        } else {
            this.isOneTimeShipment = false;
        }
        this.deliveryFrequency = frequency;
    }

    closeSubscriptionModal() {
        this.closeCartInfoModal.emit();
    }


    onSubmit(entry: Entry) {
        let entrySubscription = { duration: this.duration, deliveryFrequency: this.deliveryFrequency, entry: entry }
        this.submitted.emit(entrySubscription);
    }


}