<div *ngIf="isMobile; then mobileLayout; else fullLayout"></div>

<ng-template #mobileLayout>
 
    <div class="atuoship-container custom-font p-3 ">
      <h6>Why Choose a Subscription?</h6>
      <ul>
        <li><p>Save up to {{product?.hthPromo}} on all Hill's products for your first order</p></li>
        <li><p>{{product?.discountedInfo}}</p></li>
        <li><p>Set your schedule based on the needs of your pet</p></li>
        <li><p>No fees or commitments, skip, change or cancel anytime</p></li>
      </ul>  
    </div>
  
</ng-template>


<ng-template #fullLayout>
 
    <div class="atuoship-container custom-font p-3 ">
      <h6>Why Choose a Subscription?</h6>
      <ul>
        <li><p>Save up to {{product?.hthPromo}}  on all Hill's products for your first order</p></li>
        <li><p>{{product?.discountedInfo}}</p></li>
        <li><p>Set your schedule based on the needs of your pet</p></li>
        <li><p>No fees or commitments, skip, change or cancel anytime</p></li>
      </ul>  
     </div>

</ng-template>

