import {AbstractControl} from "@angular/forms";

  export function checkTopicValidator(control: AbstractControl ) { 
    let selectedValue = ''+control.value;
    const mainString = (selectedValue.toString()).replace(/\s/g, '').replace(/\./g, '').toLowerCase();
    if(mainString == 'default') {
      return  {
        defaultVal:  true
        };
    }
    return null; 
  }