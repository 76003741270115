import { Pipe, PipeTransform } from '@angular/core';;
import { orderStatuses } from '../../constants/payment';

@Pipe({
  name: 'appOrderStatus'
})
export class OrderStatusPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    let orderStatusStr = value;
    if (value) {
      const orderStatus = orderStatuses.find(os => os.code === value);
      if (orderStatus) {
        orderStatusStr = orderStatus.name;
      }
    }
    return orderStatusStr;
  }
}
