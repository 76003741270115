import { Region } from '../../api';

export const states: Region[] = [
  {
    name: 'State',
    isocode: 'Default'
  },
  {
    name: 'Alabama',
    isocode: 'US-AL'
  },
  {
    name: 'Alaska',
    isocode: 'US-AK'
  },
  {
    name: 'American Samoa',
    isocode: 'US-ASM'
  },
  {
    name: 'Arizona',
    isocode: 'US-AZ'
  },
  {
    name: 'Arkansas',
    isocode: 'US-AR'
  },
  {
    name: 'California',
    isocode: 'US-CA'
  },
  {
    name: 'Colorado',
    isocode: 'US-CO'
  },
  {
    name: 'Connecticut',
    isocode: 'US-CT'
  },
  {
    name: 'Delaware',
    isocode: 'US-DE'
  },
  {
    name: 'District Of Columbia',
    isocode: 'US-DC'
  },
  {
    name: 'Federated States Of Micronesia',
    isocode: 'US-FM'
  },
  {
    name: 'Florida',
    isocode: 'US-FL'
  },
  {
    name: 'Georgia',
    isocode: 'US-GA'
  },
  {
    name: 'Guam',
    isocode: 'US-GU'
  },
  {
    name: 'Hawaii',
    isocode: 'US-HI'
  },
  {
    name: 'Idaho',
    isocode: 'US-ID'
  },
  {
    name: 'Illinois',
    isocode: 'US-IL'
  },
  {
    name: 'Indiana',
    isocode: 'US-IN'
  },
  {
    name: 'Iowa',
    isocode: 'US-IA'
  },
  {
    name: 'Kansas',
    isocode: 'US-KS'
  },
  {
    name: 'Kentucky',
    isocode: 'US-KY'
  },
  {
    name: 'Louisiana',
    isocode: 'US-LA'
  },
  {
    name: 'Maine',
    isocode: 'US-ME'
  },
  {
    name: 'Marshall Islands',
    isocode: 'US-MH'
  },
  {
    name: 'Maryland',
    isocode: 'US-MD'
  },
  {
    name: 'Massachusetts',
    isocode: 'US-MA'
  },
  {
    name: 'Michigan',
    isocode: 'US-MI'
  },
  {
    name: 'Minnesota',
    isocode: 'US-MN'
  },
  {
    name: 'Mississippi',
    isocode: 'US-MS'
  },
  {
    name: 'Missouri',
    isocode: 'US-MO'
  },
  {
    name: 'Montana',
    isocode: 'US-MT'
  },
  {
    name: 'Nebraska',
    isocode: 'US-NE'
  },
  {
    name: 'Nevada',
    isocode: 'US-NV'
  },
  {
    name: 'New Hampshire',
    isocode: 'US-NH'
  },
  {
    name: 'New Jersey',
    isocode: 'US-NJ'
  },
  {
    name: 'New Mexico',
    isocode: 'US-NM'
  },
  {
    name: 'New York',
    isocode: 'US-NY'
  },
  {
    name: 'North Carolina',
    isocode: 'US-NC'
  },
  {
    name: 'North Dakota',
    isocode: 'US-ND'
  },
  {
    name: 'Northern Mariana Islands',
    isocode: 'US-MP'
  },
  {
    name: 'Ohio',
    isocode: 'US-OH'
  },
  {
    name: 'Oklahoma',
    isocode: 'US-OK'
  },
  {
    name: 'Oregon',
    isocode: 'US-OR'
  },
  {
    name: 'Palau',
    isocode: 'US-PW'
  },
  {
    name: 'Pennsylvania',
    isocode: 'US-PA'
  },
  {
    name: 'Puerto Rico',
    isocode: 'US-PR'
  },
  {
    name: 'Rhode Island',
    isocode: 'US-RI'
  },
  {
    name: 'South Carolina',
    isocode: 'US-SC'
  },
  {
    name: 'South Dakota',
    isocode: 'US-SD'
  },
  {
    name: 'Tennessee',
    isocode: 'US-TN'
  },
  {
    name: 'Texas',
    isocode: 'US-TX'
  },
  {
    name: 'Utah',
    isocode: 'US-UT'
  },
  {
    name: 'Vermont',
    isocode: 'US-VT'
  },
  {
    name: 'Virgin Islands',
    isocode: 'US-VI'
  },
  {
    name: 'Virginia',
    isocode: 'US-VA'
  },
  {
    name: 'Washington',
    isocode: 'US-WA'
  },
  {
    name: 'West Virginia',
    isocode: 'US-WV'
  },
  {
    name: 'Wisconsin',
    isocode: 'US-WI'
  },
  {
    name: 'Wyoming',
    isocode: 'US-WY'
  }
];
