import { Pipe, PipeTransform } from '@angular/core';
import { FacetValue } from 'src/app/api';
import { ShopUtil } from '../../utils/shopUtil';

@Pipe({
  name: 'facetValueNameMap'
})
export class FacetValueNameMapPipe implements PipeTransform {

  transform(name: string, args?: any): string {
    if (name) {
      return ShopUtil.mapFoodTypeFilter(name);
    }
    return name;
  }

}
