import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromCore from '../../../core/reducers';

@Component({
  selector: 'app-not-found-page',
  template: `
    <app-not-found
      [isMobile]="isMobile$ | async">
    </app-not-found>
  `,
  styles: [],
})
export class NotFoundPageComponent implements OnInit {

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  constructor(private store: Store<fromCore.State>) { }

  ngOnInit() {
  }

}
