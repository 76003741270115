import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription, Observable } from 'rxjs';
import { skip, tap, map, take } from 'rxjs/operators';

import * as ProfileActions from '../../actions/profile.actions';
import * as fromProfile from '../../reducers';
import * as fromCore from '../../../core/reducers';
import * as fromAuth from '../../../auth/reducers';
import { Address, Payment } from '../../../api';
import { EmailChange } from '../../models/emailChange';
import { ProfileChange } from '../../models/profileChange';
import { PasswordChange } from '../../models/passwordChange';

import { AppAnimate, fade } from '../../../shared/animations/common.animations';
import { PaymentBag } from '../../models/paymentBag';

@Component({
  selector: 'app-profile-page',
  template: `
    <app-profile
      [isMobile]="isMobile$ | async"
      [isLoggedIn]="isLoggedIn$ | async"
      [profile]="profile$ | async"
      [pending]="profileChangePending$ | async"
      (submitted)="onProfileSubmit($event)"
      [profileAddresses]="profileAddresses$ | async"
      [profilePayments]="profilePayments$ | async"
      (profileEmailSubmitted)="onProfileEmailSubmit($event)"
      [profileEmailChangeError]="profileEmailChangeError$ | async"
      [profileEmailChangePending]="profileEmailChangePending$ | async"
      [profilePasswordChangeError]="profilePasswordChangeError$ | async"
      [profilePasswordChangePending]="profilePasswordChangePending$ | async"
      [isPasswordSuccessfullyReset]="isPasswordSuccessfullyReset$ | async"
      (profilePasswordSubmitted)="onProfilePasswordSubmit($event)"
      [profileAddressChangePending]="profileAddressChangePending$ | async"
      [profileAddressRemovalPending]="profileAddressRemovalPending$ | async"
      [profileAddressChangeError]="profileAddressChangeError$ | async"
      [profileCorrectedAddress]="profileCorrectedAddress$ | async"
      [profileAddress]="profileAddress$ | async"
      (profileAddressOpened)="onProfileAddressOpen($event)"
      (profileAddressSubmitted)="onProfileAddressSubmit($event)"
      (suggestedAddressSubmitted)="onSuggestedAddressSubmit($event)"
      (profileAddressRemoved)="onProfileAddressRemove($event)"
      (profileAddressSetPrimary)="onProfileAddressSetPrimary($event)"
      [profilePaymentChangeError]="profilePaymentChangeError$ | async"
      [profilePaymentChangePending]="profilePaymentChangePending$ | async"
      [profilePaymentRemovalPending]="profilePaymentRemovalPending$ | async"
      [profilePayment]="profilePayment$ | async"
      (profilePaymentOpened)="onProfilePaymentOpen($event)"
      (profilePaymentSubmitted)="onProfilePaymentSubmit($event)"
      (profilePaymentRemoved)="onProfilePaymentRemove($event)"
      (profilePaymentSetPrimary)="onProfilePaymentSetPrimary($event)"
      (profileOptedInSubmitted)="onProfileOptedInSubmit($event)">
    </app-profile>
  `,
  styles: [],
  animations: [fade]
})
export class ProfilePageComponent implements OnInit, OnDestroy {
  @HostBinding('@fade')
  fade = '';

  // @HostBinding('class.app-animated-page')
  // animatedPage = true;

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  isLoggedIn$ = this.store.pipe(select(fromAuth.getIsLoggedIn));

  profile$ = this.store.pipe(select(fromProfile.getProfile));

  profileChangePending$ = this.store.pipe(select(fromProfile.getProfileChangePending));

  profileAddresses$ = this.store.pipe(select(fromProfile.getProfileAddresses));

  profilePayments$ = this.store.pipe(select(fromProfile.getProfilePayments));

  // Email

  profileEmailChangeError$ = this.store.pipe(
    select(fromProfile.getProfileEmailChangeError)
  );

  profileEmailChangePending$ = this.store.pipe(
    select(fromProfile.getProfileEmailChangePending)
  );

  // Password

  profilePasswordChangeError$ = this.store.pipe(
    select(fromProfile.getProfilePasswordChangeError)
  );

  profilePasswordChangePending$ = this.store.pipe(
    select(fromProfile.getProfilePasswordChangePending)
  );

  isPasswordSuccessfullyReset$ = this.store.pipe(select(fromProfile.getPasswordSuccessfullyUpdated));

  // Address

  profileAddressChangePending$ = this.store.pipe(
    select(fromProfile.getProfileAddressChangePending)
  );

  profileAddressRemovalPending$ = this.store.pipe(
    select(fromProfile.getProfileAddressRemovalPending)
  );

  profileAddress$ = this.store.pipe(select(fromProfile.getProfileAddress));

  profileAddressChangeError$ = this.store.pipe(select(fromProfile.getProfileAddressChangeError));

  profileCorrectedAddress$ = this.store.pipe(select(fromProfile.getProfileCorrectedAddress));

  // Payment

  profilePaymentChangeError$ = this.store.pipe(
    select(fromProfile.getProfilePaymentChangeError)
  );

  profilePaymentChangePending$ = this.store.pipe(
    select(fromProfile.getProfilePaymentChangePending)
  );

  profilePaymentRemovalPending$ = this.store.pipe(
    select(fromProfile.getProfilePaymentRemovalPending)
  );

  profilePayment$ = this.store.pipe(select(fromProfile.getProfilePayment));

  subscriptions: Subscription[] = [];

  constructor(private store: Store<fromProfile.State>) {
    AppAnimate('fade', this, this.subscriptions);
  }

  ngOnInit() {
    this.store.dispatch(new ProfileActions.GetAddresses());
    this.store.dispatch(new ProfileActions.GetPayments());

    // Address

    this.subscriptions.push(
      this.profileAddressChangePending$
        .pipe(
          skip(1),
          tap(changePending => {
            if (!changePending) {
              this.store.dispatch(new ProfileActions.GetAddresses());
            }
          })
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.profileAddressRemovalPending$
        .pipe(
          skip(1),
          tap(changePending => {
            if (!changePending) {
              this.store.dispatch(new ProfileActions.GetAddresses());
            }
          })
        )
        .subscribe()
    );

    // Payment

    this.subscriptions.push(
      this.profilePaymentChangePending$
        .pipe(
          skip(1),
          tap(changePending => {
            if (!changePending) {
              this.store.dispatch(new ProfileActions.GetPayments());
            }
          })
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.profilePaymentRemovalPending$
        .pipe(
          skip(1),
          tap(changePending => {
            if (!changePending) {
              this.store.dispatch(new ProfileActions.GetPayments());
            }
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onProfileSubmit(profileChange: ProfileChange) {
    this.store.dispatch(new ProfileActions.UpdateProfile(profileChange));
  }

  // Email

  onProfileEmailSubmit(emailChange: EmailChange) {
    this.store.dispatch(new ProfileActions.UpdateEmail(emailChange));
  }

  // Password

  onProfilePasswordSubmit(passwordChange: PasswordChange) {
    this.store.dispatch(new ProfileActions.UpdatePassword(passwordChange));
  }

  // Address

  onProfileAddressOpen(address: Address) {
    if (address) {
      this.store.dispatch(new ProfileActions.GetAddress(address.id));
    } else {
      this.store.dispatch(new ProfileActions.NewAddress());
    }
  }

  onProfileAddressSubmit(address: Address) {
    //desktop side save button
    this.store.dispatch(new ProfileActions.CheckAddress(address));
  }

  onSuggestedAddressSubmit(address: Address) {
    //desktop side save suggested address button
    if (address.id) {
      this.store.dispatch(new ProfileActions.UpdateAddress(address.id, address));
    } else {
      this.store.dispatch(new ProfileActions.AddAddress(address));
    }
  }

  onProfileAddressRemove(address: Address) {
    if (address.id) {
      this.store.dispatch(new ProfileActions.DeleteAddress(address.id));
    }
  }

  onProfileAddressSetPrimary(address: Address) {
    if (address.id) {
      this.store.dispatch(new ProfileActions.SetAddressPrimary(address.id));
    }
  }

  // Payment

  onProfilePaymentOpen(payment: Payment) {
    if (payment) {
      this.store.dispatch(new ProfileActions.GetPayment(payment.id));
    } else {
      this.store.dispatch(new ProfileActions.NewPayment());
    }
  }

  onProfilePaymentSubmit(bag: PaymentBag) {
    if (bag.tokenId) {
      // Only happens when there is a add new card operation
      this.store.dispatch(new ProfileActions.AddPayment(bag.tokenId));
      if (bag.payment && bag.payment.id) {
        this.store.dispatch(new ProfileActions.UpdatePayment(bag.payment.id, bag.payment));
      }
    } else if (bag.payment && bag.payment.id) {
      this.store.dispatch(new ProfileActions.UpdatePayment(bag.payment.id, bag.payment));
    }
  }

  onProfilePaymentRemove(payment: Payment) {
    if (payment.id) {
      this.store.dispatch(new ProfileActions.DeletePayment(payment.id));
    }
  }

  onProfilePaymentSetPrimary(payment: Payment) {
    if (payment.id) {
      this.store.dispatch(new ProfileActions.SetPaymentPrimary(payment.id));
    }
  }

  // Opted In

  onProfileOptedInSubmit(optedIn: boolean) {
    this.store.dispatch(new ProfileActions.UpdateOptedIn(optedIn));
  }
}
