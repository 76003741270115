/**
 * Pet Companion Server
 * This is a sample server Pet Companion server. You can find out more about Swagger at [http://swagger.io](http://swagger.io) or on [irc.freenode.net, #swagger](http://swagger.io/irc/). For this sample, you can use the api key `special-key` to test the authorization filters.
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Facet } from './facet';
import { Pagination } from './pagination';
import { SearchedProduct } from './searchedProduct';
import { Sort } from './sort';


export interface SearchedProducts {
    type?: string;
    freeTextSearch?: string;
    pagination?: Pagination;
    products?: Array<SearchedProduct>;
    facets?: Array<Facet>;
    sorts?: Array<Sort>;
}
