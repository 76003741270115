import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Profile } from '../../../api';
import { EmailChange } from '../../models/emailChange';
import { Patterns } from '../../../shared/validation/patterns';
import { EmailValidation } from '../../../shared/validation/emailValidation';

@Component({
  selector: 'app-edit-email',
  templateUrl: './edit-email.component.html',
  styleUrls: ['./edit-email.component.scss']
})
export class EditEmailComponent implements OnInit, OnChanges {

  profileForm: UntypedFormGroup;

  @Input() isLoggedIn: boolean | false;

  @Input() isMobile: boolean | false;

  @Input() error: string | false;

  @Input() pending: boolean | false;

  @Input() profile: Profile | null;

  @Input() isVisible: boolean | false;

  @Output() submitted = new EventEmitter<EmailChange>();

  @ViewChild('successModal', { static: true })
  successModal: any;

  constructor(private fb: UntypedFormBuilder,
              private modalService: NgbModal,
              private router: Router) {
    this.createForm();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    const profileChange: SimpleChange = changes['profile'];
    if (profileChange) {
      this.resetForm();
    }
    const pendingChange: SimpleChange = changes['pending'];
    if (pendingChange && !pendingChange.firstChange) {
      if (pendingChange.currentValue) {
        this.profileForm.disable();
      } else {
        // if there is an error shortcut the process
        if (this.error) {
          this.profileForm.enable();
        }
      }
    }
    // changing the email will require the application to login in again,
    // wait on the next time is logged in to show the success message
    const isLoggedInChange: SimpleChange = changes['isLoggedIn'];
    if (isLoggedInChange && !isLoggedInChange.firstChange) {
      if (isLoggedInChange.currentValue) {
        this.profileForm.enable();
        this.success();
      }
    }
    // On desktop redirect back to master page
    const isMobileChange: SimpleChange = changes['isMobile'];
    if (isMobileChange && !isMobileChange.firstChange &&
      !isMobileChange.currentValue) {
      this.router.navigate(['/profile']);
    }
  }

  createForm() {
    // build up the form
    this.profileForm = this.fb.group({
        email: [{value: '', disabled: true}, {
          validators: [Validators.required, Validators.pattern(Patterns.emailPattern)]
        }],
        newEmail: ['', {
          validators: [Validators.required, Validators.pattern(Patterns.emailPattern)],
        }],
        confirmEmail: ['', {
          validators: [Validators.required],
        }],
        password: ['', {
          validators: [Validators.required]
        }]
      },
      {
        validator: EmailValidation.matchEmail
      });
  }

  resetForm() {
    this.profileForm.reset({
      email: this.profile.uid,
      newEmail: '',
      confirmEmail: '',
      password: ''
    });
  }

  save() {
    if (this.profileForm.valid) {
      const model = this.profileForm.value;
      const emailChange: EmailChange = {
        newEmail: model.newEmail.toLowerCase(),
        password: model.password
      };
      this.submitted.emit(emailChange);
    }
  }

  toggleVisibility(){
    this.isVisible = !this.isVisible;
  }

  success() {
    // Using timeout because of issue here:
    // https://github.com/ng-bootstrap/ng-bootstrap/issues/1775
    setTimeout(() => {
      this.modalService.open(this.successModal).result.then(
        () => {
          this.router.navigate(['/profile']);
        },
        () => {
          this.router.navigate(['/profile']);
        }
      );
    });
  }
}
