import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { supportList } from '../../../shared/constants/faq';
import { NgbModal, NgbAccordion } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-faq-content',
  templateUrl: './faq-content.component.html',
  styleUrls: ['./faq-content.component.scss']
})
export class FaqContentComponent implements OnInit {

  @Input() isMobile: boolean | false;

  @Input() selectedFaq: supportList; 

  @Input() isLoggedIn: boolean | false;
  // WhatsNew Support - Rahul Solanki
  @Input() whatsNewContent: any;
  // End

  @ViewChild('creditCardHolds', { static: true })
  creditCardHoldsModal: any;

  @ViewChild('faqsAccordion') 
  accordionComponent: NgbAccordion;

  selectedPanelId: string;

  constructor(private route: ActivatedRoute, private router: Router, private modalService: NgbModal) {
    this.route.params
    .subscribe(params => {
      this.selectedPanelId = null;
    })
  }

  ngOnInit() {
  }

  goToShopPlanFAQPage() { 
    this.isLoggedIn ? this.router.navigate(['/user/support-faq/pet-profile']) : this.router.navigate(['/support-faq/pet-profile']);
    setTimeout(() => {
      this.accordionComponent.toggle('petProfilePanel_2');
    },500);
  }

  goToReturnsFAQPage() {
    this.isLoggedIn ? this.router.navigate(['/user/support-faq/returns-refunds']) : this.router.navigate(['/support-faq/returns-refunds']);
  }

  goToSubscriptionFAQPage() {
    this.isLoggedIn ? this.router.navigate(['/user/support-faq/subscriptions']) : this.router.navigate(['/support-faq/subscriptions']);
  }

  goToChangeSubscriptionFrequencyFAQPage() {
    this.isLoggedIn ? this.router.navigate(['/user/support-faq/subscriptions']) : this.router.navigate(['/support-faq/subscriptions']);
    setTimeout(() => {
      this.accordionComponent.toggle('subscriptionsPanel_2');
    },500);
  }

  goToCancelSubscriptionFAQPage() {
    this.isLoggedIn ? this.router.navigate(['/user/support-faq/subscriptions']) : this.router.navigate(['/support-faq/subscriptions']);
    setTimeout(() => {
      this.accordionComponent.toggle('subscriptionsPanel_1');
    },500);
  }

  goToNewSubscriptionFAQPage() {
    this.isLoggedIn ? this.router.navigate(['/user/support-faq/subscriptions']) : this.router.navigate(['/support-faq/subscriptions']);
    setTimeout(() => {
      this.accordionComponent.toggle('subscriptionsPanel_0');
    },500);
  }

  goToMyAccountPage() {
    this.isLoggedIn ? this.router.navigate(['/profile']) : this.router.navigate(['/login']);
  }
  // Description of Shops and Pets is changed according to UAT UI and function design - Rahul Solanki
  goToMyPetsPage() {
    this.isLoggedIn ? this.router.navigate(['/pets/profile']) : this.router.navigate(['/login']);
  }
  goToShopPage() {
    this.isLoggedIn ? this.router.navigate(['/pets/shop']) : this.router.navigate(['/login']);
  }
  // End
  goToOrdersPage() {
    this.isLoggedIn ? this.router.navigate(['/orders']) : this.router.navigate(['/login']);
  }

  goToSubscriptionsPage() {
    this.isLoggedIn ? this.router.navigate(['/subscriptions']) : this.router.navigate(['/login']);
  }

  
  goToForgetPasswordPage() {
    this.router.navigate(['/forgot']);
  }

  goToContactUsPage() {
    this.router.navigate(['/contact-us']);
  }

  goToCreditCardHoldsPage() {
    // Using timeout because of issue here:
    // https://github.com/ng-bootstrap/ng-bootstrap/issues/1775
    setTimeout(() => {
      this.modalService.open(this.creditCardHoldsModal).result.then(
        () => {
        },
        () => {
        }
      );
    });
  }

}
