import { CorrectedAddress } from './../models/correctedAddress';
import { ProfileActionsUnion, ProfileActionTypes} from '../actions/profile.actions';
import {
  Profile,
  Address,
  ProfileAddresses,
  Payment
} from '../../api';
import { cardTypes, months, years } from '../../shared/constants/payment';
import { states } from '../../shared/constants/address';
import { ProfilePayments, sortProfilePayments } from '../models/ProfilePayments';

export interface State {
  profile: Profile;
  profileAddress: Address;
  profileAddresses: ProfileAddresses;
  profilePayment: Payment;
  profilePayments: ProfilePayments;
  profileChangePending: boolean;
  profileNameChangePending: boolean;
  profileEmailChangeError: string;
  profileEmailChangePending: boolean;
  profilePasswordChangeError: string;
  profilePasswordChangePending: boolean;
  profilePhoneChangePending: boolean;
  profileOptedInChangePending: boolean;
  profileAddressChangePending: boolean;
  profileAddressRemovalPending: boolean;
  profileAddressChangeError: boolean;
  profileCorrectedAddress: CorrectedAddress;
  profileAddressAdded: Address;
  profilePaymentAdded: string;
  profilePaymentChangeError: string;
  profilePaymentChangePending: boolean;
  profilePaymentRemovalPending: boolean;
  passwordSuccessfullyUpdated: boolean;
}

export const initialState: State = {
  profile: null,
  profileAddress: null,
  profileAddresses: null,
  profilePayment: null,
  profilePayments: null,
  profileChangePending: false,
  profileNameChangePending: false,
  profileEmailChangeError: '',
  profileEmailChangePending: false,
  profilePasswordChangeError: '',
  profilePasswordChangePending: false,
  profilePhoneChangePending: false,
  profileOptedInChangePending: false,
  profileAddressChangePending: false,
  profileAddressRemovalPending: false,
  profileAddressChangeError:false,
  profileCorrectedAddress:null,
  profileAddressAdded: null,
  profilePaymentAdded: null,
  profilePaymentChangeError: '',
  profilePaymentChangePending: false,
  profilePaymentRemovalPending: false,
  passwordSuccessfullyUpdated: false
};

export function reducer(state = initialState, action: ProfileActionsUnion): State {
  switch (action.type) {

    case ProfileActionTypes.Load: {
      return {
        ...state,
        profile: action.profile,
      };
    }

    case ProfileActionTypes.UpdateProfile: {
      return {
        ...state,
        profileChangePending: true,
      };
    }

    case ProfileActionTypes.UpdateProfileSuccess: {
      return {
        ...state,
        profileChangePending: false,
      };
    }

    case ProfileActionTypes.UpdateName: {
      return {
        ...state,
        profileNameChangePending: true,
      };
    }

    case ProfileActionTypes.UpdateNameSuccess: {
      return {
        ...state,
        profileNameChangePending: false,
      };
    }

    case ProfileActionTypes.UpdateEmail: {
      return {
        ...state,
        profileEmailChangeError: '',
        profileEmailChangePending: true,
      };
    }

    case ProfileActionTypes.UpdateEmailSuccess: {
      return {
        ...state,
        profileEmailChangeError: '',
        profileEmailChangePending: false,
        profile : {
          ...state.profile,
          uid: action.emailChange.newEmail
        }
      };
    }

    case ProfileActionTypes.UpdateEmailFailure: {
      return {
        ...state,
        profileEmailChangeError: action.updateEmailError,
        profileEmailChangePending: false,
      };
    }

    case ProfileActionTypes.UpdateEmailReset: {
      return {
        ...state,
        profileEmailChangeError: '',
        profileEmailChangePending: false,
      };
    }

    case ProfileActionTypes.UpdatePassword: {
      return {
        ...state,
        profilePasswordChangeError: '',
        profilePasswordChangePending: true,
        passwordSuccessfullyUpdated: false
      };
    }

    case ProfileActionTypes.UpdatePasswordSuccess: {
      return {
        ...state,
        profilePasswordChangeError: '',
        profilePasswordChangePending: false,
        passwordSuccessfullyUpdated: true,
      };
    }

    case ProfileActionTypes.UpdatePasswordFailure: {
      return {
        ...state,
        profilePasswordChangeError: action.updatePasswordError,
        profilePasswordChangePending: false,
        passwordSuccessfullyUpdated: false
      };
    }

    case ProfileActionTypes.UpdateIsPasswordSuccessfullyUpdated: {
      return {
        ...state,
        passwordSuccessfullyUpdated: action.payload
      }
    }

    case ProfileActionTypes.UpdatePasswordReset: {
      return {
        ...state,
        profilePasswordChangeError: '',
        profilePasswordChangePending: false,
      };
    }

    case ProfileActionTypes.UpdatePhone: {
      return {
        ...state,
        profilePhoneChangePending: true,
      };
    }

    case ProfileActionTypes.UpdatePhoneSuccess: {
      return {
        ...state,
        profilePhoneChangePending: false,
      };
    }

    case ProfileActionTypes.UpdateOptedIn: {
      return {
        ...state,
        profileOptedInChangePending: true,
      };
    }

    case ProfileActionTypes.UpdateOptedInSuccess: {
      return {
        ...state,
        profileOptedInChangePending: false,
      };
    }

    case ProfileActionTypes.UpdateTermsAcceptedStatusSuccess: {
      return {
        ...state,
        profile: {
          ...state.profile,
          termsAndConditions: action.termsAndConditions
        }
      };
    }

    case ProfileActionTypes.NewAddress: {
      return {
        ...state,
        profileAddress: {
          // adding profile first name and last name by default
          firstName: state.profile.firstName,
          lastName: state.profile.lastName,
          region: {
            isocode: states[0].isocode
          },
          country: {
            isocode: 'US'
          }
        },
        profileAddressChangeError: false
      };
    }

    case ProfileActionTypes.GetAddressSuccess: {
      return {
        ...state,
        profileAddress: action.address,
        profileAddressChangeError: false
      };
    }

    case ProfileActionTypes.GetAddressesSuccess: {
      return {
        ...state,
        profileAddresses: action.addresses,
      };
    }

    case ProfileActionTypes.UpdateAddress:
    case ProfileActionTypes.AddAddress:
    case ProfileActionTypes.SetAddressPrimary: {
      return {
        ...state,
        profileAddressChangePending: true,
      };
    }

    case ProfileActionTypes.CheckAddress: {
      return {
        ...state,
        profileAddressChangeError: false
      };
    }

    case ProfileActionTypes.CheckAddressFailure: {
      return {
        ...state,
        profileAddressChangeError: action.updateAddressError,
        profileCorrectedAddress: action.correctedAddress
      };
    }

    case ProfileActionTypes.UpdateAddressSuccess:
    case ProfileActionTypes.SetAddressPrimarySuccess: {
      return {
        ...state,
        profileAddressChangePending: false,
      };
    }

    case ProfileActionTypes.CheckAddressSuccess:
    case ProfileActionTypes.AddAddressSuccess: {
      return {
        ...state,
        profileAddressAdded: action.address,
        profileAddressChangePending: false,
      };
    }

    case ProfileActionTypes.AddAddressReset: {
      return {
        ...state,
        profileAddressAdded: null,
        profileAddressChangePending: false,
      };
    }

    case ProfileActionTypes.NewPayment: {
      return {
        ...state,
        profilePayment: {
          cardType: {
            code: cardTypes[0].code,
          }
        },
        profilePaymentChangeError: '',
      };
    }

    case ProfileActionTypes.GetPayment: {
      return {
        ...state,
        profilePaymentChangeError: '',
      };
    }

    case ProfileActionTypes.GetPaymentSuccess: {
      return {
        ...state,
        profilePayment: action.payment,
      };
    }

    case ProfileActionTypes.GetPaymentsSuccess: {
      return {
        ...state,
        profilePayments: sortProfilePayments(action.payments),
      };
    }

    case ProfileActionTypes.UpdatePayment:
    case ProfileActionTypes.AddPayment:
    case ProfileActionTypes.SetPaymentPrimary: {
      return {
        ...state,
        profilePaymentChangeError: '',
        profilePaymentChangePending: true,
      };
    }

    case ProfileActionTypes.AddPaymentSuccess: {
      return {
        ...state,
        profilePaymentChangeError: '',
        profilePaymentAdded: action.paymentId,
        profilePaymentChangePending: false,
      };
    }
    case ProfileActionTypes.UpdatePaymentSuccess:
    case ProfileActionTypes.SetPaymentPrimarySuccess: {
      return {
        ...state,
        profilePaymentChangeError: '',
        profilePaymentChangePending: false,
        // profilePayment: { ...action.primaryPayment, defaultPayment: true },
      };
    }

    case ProfileActionTypes.AddPaymentFailure: {
      return {
        ...state,
        profilePaymentChangeError: action.addPaymentError,
        profilePaymentChangePending: false,
      };
    }

    case ProfileActionTypes.AddPaymentReset: {
      return {
        ...state,
        profilePaymentChangeError: '',
        profilePaymentAdded : null,
        profilePaymentChangePending: false,
      };
    }

    case ProfileActionTypes.DeleteAddress: {
      return {
        ...state,
        profileAddressRemovalPending: true,
      };
    }

    case ProfileActionTypes.DeleteAddressSuccess: {
      return {
        ...state,
        profileAddressRemovalPending: false,
      };
    }

    case ProfileActionTypes.DeletePayment: {
      return {
        ...state,
        profilePaymentRemovalPending: true,
      };
    }

    case ProfileActionTypes.DeletePaymentSuccess: {
      return {
        ...state,
        profilePaymentRemovalPending: false,
      };
    }

    default:
      return state;
  }
}

export const getProfile = (state: State) => state.profile;
export const getProfileAddress = (state: State) => state.profileAddress;
export const getProfileAddresses = (state: State) => state.profileAddresses;
export const getProfilePayment = (state: State) => state.profilePayment;
export const getProfilePayments = (state: State) => state.profilePayments;
export const getProfileChangePending = (state: State) => state.profileChangePending;
export const getProfileNameChangePending = (state: State) => state.profileNameChangePending;
export const getProfileEmailChangeError = (state: State) => state.profileEmailChangeError;
export const getProfileEmailChangePending = (state: State) => state.profileEmailChangePending;
export const getProfilePasswordChangeError = (state: State) => state.profilePasswordChangeError;
export const getProfilePasswordChangePending = (state: State) => state.profilePasswordChangePending;
export const getProfilePhoneChangePending = (state: State) => state.profilePhoneChangePending;
export const getProfileOptedInChangePending = (state: State) => state.profileOptedInChangePending;
export const getProfileAddressChangePending = (state: State) => state.profileAddressChangePending;
export const getProfileAddressRemovalPending = (state: State) => state.profileAddressRemovalPending;
export const getProfileAddressChangeError = (state: State) => state.profileAddressChangeError;
export const getProfileCorrectedAddress = (state: State) => state.profileCorrectedAddress;
export const getProfileAddressAdded = (state: State) => state.profileAddressAdded;
export const getProfilePaymentAdded = (state: State) => state.profilePaymentAdded;
export const getProfilePaymentChangeError = (state: State) => state.profilePaymentChangeError;
export const getProfilePaymentChangePending = (state: State) => state.profilePaymentChangePending;
export const getProfilePaymentRemovalPending = (state: State) => state.profilePaymentRemovalPending;
export const getPasswordSuccessfullyUpdated = (state: State) => state.passwordSuccessfullyUpdated;