/**
 * Pet Companion Server
 * This is a sample server Pet Companion server. You can find out more about Swagger at [http://swagger.io](http://swagger.io) or on [irc.freenode.net, #swagger](http://swagger.io/irc/). For this sample, you can use the api key `special-key` to test the authorization filters.
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Address } from './address';
import { Consignment } from './consignment';
import { Cost } from './cost';
import { DeliveryOrderGroups } from './deliveryOrderGroups';
import { Entry } from './entry';
import { Mode } from './mode';
import { Payment } from './payment';
import { PetParentUser } from './petParentUser';
import { User } from './user';
import { VetUser } from './vetUser';


export interface OrderDetail {
    appliedVouchers: any;
    calculated?: boolean;
    code?: string;
    consignments?: Array<Consignment>;
    deliveryAddress?: Address;
    deliveryCost?: Cost;
    deliveryItemsQuantity?: number;
    deliveryMode?: Mode;
    description?: string;
    name?: string;
    deliveryOrderGroups?: DeliveryOrderGroups;
    entries?: Array<Entry>;
    estimatedDeliveryDate?: string;
    net?: string;
    orderDiscounts?: Cost;
    paymentInfo?: Payment;
    petParentUser?: PetParentUser;
    pickupItemsQuantity?: number;
    productDiscounts?: Cost;
    sapOrderTotalValue?: string;
    sapTotalTaxValue?: string;
    site?: string;
    store?: string;
    subTotal?: Cost;
    totalDiscounts?: Cost;
    totalItems?: string;
    totalPrice?: Cost;
    totalPriceWithTax?: Cost;
    totalSurcharge?: Cost;
    totalTax?: Cost;
    user?: User;
    v2cOrderEstimateDate?: string;
    created?: string;
    guestCustomer?: boolean;
    status?: string;
    statusDisplay?: string;
    vetUser?: VetUser;
    isOutOFStockCartItems?: boolean;
    retailDeliveryFee?: Cost;
}
