/**
 * Base class for Custom Error conditions
 *
 * @export
 */
export declare class Error {
  public name: string;
  public message: string;
  public stack: string;
  constructor(message?: string);
}

export class Exception extends Error {
  constructor(public message: string, error: any) {
    super(message);
    this.name = 'Exception';
    this.message = message;
    this.stack = error ? error.stack : (<any>new Error()).stack;
  }
  toString() {
    return JSON.stringify(
      { Type: this.name, Cause: this.message, Stack: this.stack },
      null,
      2
    );
  }
}

/**
 * Is generated against invalid server response recieved from Hybris Backend
 *
 * @export
 * @extends {Error}
 */
export class ServerResponseException extends Exception {
  constructor(public message: string, error: any) {
    super(message, error);
    this.name = 'ServerResponseException';
    this.message = 'Response Object contained: ' + message;
    // Fixes instance of check failure
    Object.setPrototypeOf(this, ServerResponseException.prototype);
  }
}
