import {
  createSelector,
  createFeatureSelector,
  ActionReducerMap,
} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromOrders from './orders.reducer';

export interface OrdersState {
  status: fromOrders.State;
}

export interface State extends fromRoot.State {
  orders: OrdersState;
}

export const reducers: ActionReducerMap<OrdersState> = {
  status: fromOrders.reducer,
};

export const getOrdersState = createFeatureSelector<OrdersState>('orders');

// Orders Status

export const getOrdersStatusState = createSelector(
  getOrdersState,
  (state: OrdersState) => state.status
);

// cart

export const getCart = createSelector(
  getOrdersStatusState,
  fromOrders.getCart
);
export const updateApplyPromo = createSelector(
  getOrdersStatusState,
  fromOrders.updateApplyPromo
);
export const removeApplyPromo = createSelector(
  getOrdersStatusState,
  fromOrders.removeApplyPromo
);
export const getCartDetail = createSelector(
  getOrdersStatusState,
  fromOrders.getCartDetail
);

export const getDeliveryModes = createSelector(
  getOrdersStatusState,
  fromOrders.getDeliveryModes
);

export const getCartPaymentChangePending = createSelector(
  getOrdersStatusState,
  fromOrders.getCartPaymentChangePending
);

export const getCartAddressChangePending = createSelector(
  getOrdersStatusState,
  fromOrders.getCartAddressChangePending
);

export const getCartDeliveryModeChangePending = createSelector(
  getOrdersStatusState,
  fromOrders.getCartDeliveryModeChangePending
);

export const getCartProductChangePending = createSelector(
  getOrdersStatusState,
  fromOrders.getCartProductChangePending
);

export const getPlacedOrderDetail = createSelector(
  getOrdersStatusState,
  fromOrders.getPlacedOrderDetail
);

// orders

export const getOrders = createSelector(
  getOrdersStatusState,
  fromOrders.getOrders
);

export const getOrderFilters = createSelector(
  getOrdersStatusState,
  fromOrders.getOrderFilters
);

export const getSearchedOrders = createSelector(
  getOrdersStatusState,
  fromOrders.getSearchedOrders
);

export const getOrdersSearchPending = createSelector(
  getOrdersStatusState,
  fromOrders.getOrdersSearchPending
);

export const getOrderDetail = createSelector(
  getOrdersStatusState,
  fromOrders.getOrderDetail
);

export const getOrderChangePending = createSelector(
  getOrdersStatusState,
  fromOrders.getOrderChangePending
);

export const getUpcomingOrders = createSelector(
  getOrdersStatusState,
  fromOrders.getUpcomingOrders
);

export const getOrderReturnPending = createSelector(
  getOrdersStatusState,
  fromOrders.getOrderReturnPending
);

export const getReturnOrderDetail = createSelector(
  getOrdersStatusState,
  fromOrders.getReturnOrderDetail
);

// subscriptions

export const getSubscriptionFilters = createSelector(
  getOrdersStatusState,
  fromOrders.getSubscriptionFilters
);

export const getSearchedSubscriptions = createSelector(
  getOrdersStatusState,
  fromOrders.getSearchedSubscriptions
);

export const getSubscriptionsSearchPending = createSelector(
  getOrdersStatusState,
  fromOrders.getSubscriptionsSearchPending
);

export const getSubscription = createSelector(
  getOrdersStatusState,
  fromOrders.getSubscription
);

export const getSubscriptionChangePending = createSelector(
  getOrdersStatusState,
  fromOrders.getSubscriptionChangePending
);

export const getEditingResultSubscription = createSelector(
  getOrdersStatusState,
  fromOrders.getEditingResultSubscription
);
// For Change Product in Subscription
export const getNutritionPlanDetailSubscription = createSelector(
  getOrdersStatusState,
  fromOrders.getNutritionPlanDetailSubscription
);

export const getPetDetailSubscription = createSelector(
  getOrdersStatusState,
  fromOrders.getPetDetailSubscription
);