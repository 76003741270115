import { Pipe, PipeTransform } from '@angular/core';
import { Cart } from '../../../api';
import { DateUtil } from '../../utils/dateUtil';
import { DeliveryFrequencies } from '../../constants/payment';

@Pipe({
  name: 'appEndSubscriptionDate'
})
export class EndSubscriptionDatePipe implements PipeTransform {

  transform(value: Cart, args?: any): any {
    let maxDateStr = '';
    if (value) {

      const maxDate = DateUtil.getMaxDate(
        value.entries
          .filter(e =>
            e.subscriptionUnit &&
            e.subscriptionUnit.deliveryFrequency !== DeliveryFrequencies.OneTime &&
            e.subscriptionUnit.endDate)
          .map(e => {
            const dateStr = DateUtil.getDateStr(e.subscriptionUnit.endDate);
            const date = DateUtil.addWeekDay(dateStr, 3);
            return date;
          })
      );

      maxDateStr = DateUtil.getDateStr(maxDate, 'MMMM d, y');
    }

    return maxDateStr;
  }
}
