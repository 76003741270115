import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgbAccordionModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { ProductsRoutingModule } from './products-routing.module';
import { reducers } from './reducers';
import { ProductsEffects } from './effects/products.effects';

import { NpProductsComponent } from './components/np-products/np-products.component';
import { NpProductsPageComponent } from './containers/np-products-page/np-products-page.component';
import { NpFiltersComponent } from './components/np-filters/np-filters.component';
import { NpFiltersPageComponent } from './containers/np-filters-page/np-filters-page.component';
import { ProductComponent } from './components/product/product.component';
import { ProductPageComponent } from './containers/product-page/product-page.component';
import { ProductModalComponent } from './components/product-modal/product-modal.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgbAccordionModule,
        NgbPaginationModule,
        SharedModule,
        ProductsRoutingModule,
        StoreModule.forFeature('products', reducers),
        EffectsModule.forFeature([ProductsEffects])
    ],
    declarations: [
        // NpProductsComponent,
        // NpProductsPageComponent,
        // NpFiltersComponent,
        // NpFiltersPageComponent,
        ProductComponent,
        ProductModalComponent,
        ProductPageComponent
    ]
})
export class ProductsModule {
  static forRoot(): ModuleWithProviders<ProductsModule> {
    return {
      ngModule: ProductsModule
    };
  }
}
