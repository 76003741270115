import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, of, noop, PartialObserver } from 'rxjs';
import {
  switchMap,
  map,
  take,
  catchError,
  tap,
  switchMapTo,
  filter,
  find,
  first
} from 'rxjs/operators';

import * as fromProfile from '../reducers';
import * as fromAuth from '../../auth/reducers';
import {
  ProfileActionTypes,
  GetAddress,
  GetAddressSuccess,
  GetAddresses,
  GetAddressesSuccess,
  GetPayment,
  GetPaymentSuccess,
  GetPayments,
  GetPaymentsSuccess,
  AddPayment,
  AddPaymentSuccess,
  UpdatePayment,
  UpdatePaymentSuccess,
  CheckAddressFailure,
  CheckAddressSuccess,
  CheckAddress,
  AddAddress,
  AddAddressSuccess,
  UpdateAddress,
  UpdateAddressSuccess,
  UpdateEmail,
  UpdateEmailSuccess,
  UpdateName,
  UpdateNameSuccess,
  UpdatePassword,
  UpdatePasswordSuccess,
  UpdatePhone,
  UpdatePhoneSuccess,
  DeleteAddress,
  DeleteAddressSuccess,
  DeletePayment,
  DeletePaymentSuccess,
  UpdateOptedIn,
  UpdateOptedInSuccess,
  UpdateProfile,
  UpdateProfileSuccess,
  UpdatePasswordFailure,
  SetPaymentPrimary,
  SetPaymentPrimarySuccess,
  SetAddressPrimary,
  SetAddressPrimarySuccess,
  UpdateEmailFailure,
  AddPaymentFailure,
  UpdateTermsAcceptedStatus,
  UpdateTermsAcceptedStatusSuccess
} from '../actions/profile.actions';
import {
  ProfileService,
  Address,
  ProfileAddresses,
  Payment,
  ProfileCards,
  ServerError,
  Profile,
  CardInfo
} from '../../api';
import { Authenticate } from '../../auth/models/authenticate';
import { SetLogin } from '../../auth/actions/auth.actions';
import { ReportError } from '../../core/actions/error.actions';
import { PaymentUtil } from '../../shared/utils/paymentUtil';
import { EmailChange } from '../models/emailChange';
import { CorrectedAddress } from './../models/correctedAddress';
import { ProfilePayments } from '../models/ProfilePayments';
import { isArray } from 'util';

@Injectable()
export class ProfileEffects {
  constructor(
    private store: Store<fromProfile.State>,
    private actions$: Actions,
    private profileService: ProfileService
  ) {}

  
  getAddress$ = createEffect(() => this.actions$.pipe(
    ofType<GetAddress>(ProfileActionTypes.GetAddress),
    switchMap((action: GetAddress) =>
      this.getAuthenticate().pipe(
        switchMap((authenticate: Authenticate) =>
          this.profileService
            .getProfileAddress(authenticate.username, action.addressId)
            .pipe(
              switchMap((address: Address) =>
                this.getAddresses().pipe(
                  map((addresses: ProfileAddresses) => {
                    const addressFound = addresses.addresses.find(
                      a => a.id === address.id
                    );
                    address.defaultAddress = addressFound
                      ? addressFound.defaultAddress
                      : false;
                    return new GetAddressSuccess(address);
                  })
                )
              )
            )
        )
      )
    )
  ));

  
  getAddresses$ = createEffect(() => this.actions$.pipe(
    ofType<GetAddresses>(ProfileActionTypes.GetAddresses),
    switchMap(() =>
      this.getAuthenticate().pipe(
        switchMap((authenticate: Authenticate) =>
          this.profileService
            .getProfileAddresses(authenticate.username)
            .pipe(
              map(
                (addresses: ProfileAddresses) =>
                  new GetAddressesSuccess(addresses)
              )
            )
        )
      )
    )
  ));

  
  checkAddress$ = createEffect(() => this.actions$.pipe(
    ofType<CheckAddress>(ProfileActionTypes.CheckAddress),
    switchMap((action: CheckAddress) =>
      this.getAuthenticate().pipe(
        switchMap((authenticate: Authenticate) =>
          this.profileService
          .validateProfileAddress(action.address)
          .pipe(map((response: any) => {
            if (response.addressValidationResponse.response.responseStatusCode == 1) {
              //checking for success status code - 1, if error in state - 0
              let AddressValidationResult = response.addressValidationResponse.addressValidationResult;
                //ups returns one result, if the city matches somewhat
                let valid_city = AddressValidationResult[0].address.city;
                let valid_zipcode = AddressValidationResult[0].postalCodeLowEnd;
                let valid_state = "US-"+AddressValidationResult[0].address.stateProvinceCode;
                if (valid_city.toLowerCase() == action.address.town.toLowerCase() && valid_zipcode == action.address.postalCode && valid_state == action.address.region.isocode) 
                {
                  //checking for a perfect match between city and zipcode
                  if (action.address.id) {
                  return new UpdateAddress(action.address.id, action.address);
                  } else {
                  return new AddAddress(action.address);
                  }
                }else {
                  let correctedAddress :CorrectedAddress = {
                    city : valid_city,
                    state : valid_state,
                    zipcode : valid_zipcode
                  };
                  return new CheckAddressFailure(true,correctedAddress);
                }
            }else {
              return new CheckAddressFailure(true);
            }
          }), catchError((error) => {
            return of(new CheckAddressFailure(true));
          }))
        )
      )
    )
  ));

  
  addAddress$ = createEffect(() => this.actions$.pipe(
    ofType<AddAddress>(ProfileActionTypes.AddAddress),
    switchMap((action: AddAddress) =>
      this.getAuthenticate().pipe(
        switchMap((authenticate: Authenticate) =>
          this.profileService
            .addProfileAddress(authenticate.username, action.address)
            .pipe(map((address: Address) => new AddAddressSuccess(address)))
        )
      )
    )
  ));

  
  updateAddress$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateAddress>(ProfileActionTypes.UpdateAddress),
    switchMap((action: UpdateAddress) =>
      this.getAuthenticate().pipe(
        switchMap((authenticate: Authenticate) =>
          this.profileService
            .updateProfileAddress(
              authenticate.username,
              action.addressId,
              action.address
            )
            .pipe(map(() => new UpdateAddressSuccess()))
        )
      )
    )
  ));

  
  deleteAddress$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteAddress>(ProfileActionTypes.DeleteAddress),
    switchMap((action: DeleteAddress) =>
      this.getAuthenticate().pipe(
        switchMap((authenticate: Authenticate) =>
          this.profileService
            .deleteProfileAddress(authenticate.username, action.addressId)
            .pipe(map(() => new DeleteAddressSuccess()))
        )
      )
    )
  ));

  
  setAddressPrimary$ = createEffect(() => this.actions$.pipe(
    ofType<SetAddressPrimary>(ProfileActionTypes.SetAddressPrimary),
    switchMap((action: SetAddressPrimary) =>
      this.getAuthenticate().pipe(
        switchMap((authenticate: Authenticate) =>
          this.getAddresses().pipe(
            map((addresses: ProfileAddresses) => {
              const address = addresses.addresses.find(
                a => a.id === action.addressId
              );
              const changedAddress = {
                ...address,
                defaultAddress: true // change the primary address
              };
              return changedAddress;
            }),
            switchMap((address: Address) =>
              this.profileService
                .updateProfileAddress(
                  authenticate.username,
                  action.addressId,
                  address
                )
                .pipe(map(() => new SetAddressPrimarySuccess()))
            )
          )
        )
      )
    )
  ));

  
  getPayments$ = createEffect(() => this.actions$.pipe(
    ofType<GetPayments>(ProfileActionTypes.GetPayments),
    switchMap(() =>
      this.getAuthenticate().pipe(
        switchMap((authenticate: Authenticate) =>
          this.profileService.getProfilePayments(authenticate.username).pipe(
            map((profileCards: ProfileCards) => {
              const paymentArr: Array<Payment> = [];
              if (profileCards && profileCards.stripePaymentInfos) {
                for (const cardInfo of profileCards.stripePaymentInfos) {
                  const aPayment: Payment = {};
                  aPayment.id = cardInfo.id;
                  aPayment.cardNumber = cardInfo.cardNumber;
                  aPayment.cardType = { code: cardInfo.cardType };
                  aPayment.defaultPaymentInfo = cardInfo.defaultPaymentInfo;
                  paymentArr.push(aPayment);
                }
              }
              return paymentArr;
            }),
            map(
              (pArr: Array<Payment>) =>
                new GetPaymentsSuccess({ payments: pArr }),
              catchError((payload: any) => {
                return of(new ReportError(payload));
              })
            )
          )
        )
      )
    )
  ));

  
  getPayment$ = createEffect(() => this.actions$.pipe(
    ofType<GetPayment>(ProfileActionTypes.GetPayment),
    switchMap((action: GetPayment) =>
      this.getAuthenticate().pipe(
        switchMap((authenticate: Authenticate) =>
          this.profileService
            .getProfilePayment(authenticate.username, action.paymentId)
            .pipe(map((payment: Payment) => new GetPaymentSuccess(payment)))
        )
      )
    )
  ));

  
  addPayments$ = createEffect(() => this.actions$.pipe(
    ofType<AddPayment>(ProfileActionTypes.AddPayment),
    switchMap((action: AddPayment) =>
      this.getAuthenticate().pipe(
        switchMap((authenticate: Authenticate) =>
          // Step 1: Add new card to service using stripe token
          this.profileService
            .addProfileCard(authenticate.username, action.tokenId)
            .pipe(
              switchMap((card: CardInfo) => {
                if (card) {
                  if(card.cardNumber!==undefined) {
                  return this.getPayments().pipe(
                    map(
                      (pPayments: ProfilePayments) =>
                        pPayments.payments
                          ? true 
                          : true // if there are no other payments
                    ),
                    switchMap((fireUpdate: boolean) => {
                      return this.profileService
                        .changeProfilePayment(
                          authenticate.username,
                          card.id,
                          fireUpdate
                        )
                        .pipe(map(() => new AddPaymentSuccess(card.id))); // Set this Card as the default
                    })
                  );
                  } else {
                    return of(
                      new AddPaymentFailure(card.detailMessage)
                    );
                  }
                } else {
                  return of(
                    new AddPaymentFailure('Not accepted by payment provider!')
                  );
                }
              }),
              catchError(
                (payload: { error: { errors: Array<ServerError> } }) => {
                  let errorMsg = '';
                  if (
                    payload &&
                    payload.error &&
                    payload.error.errors &&
                    payload.error.errors.length
                  ) {
                    switch (payload.error.errors[0].type) {
                      case '':
                        errorMsg = 'Unable to register the card! Is it valid?';
                        break;
                    }
                  } else if (payload && payload['message']) {
                    errorMsg = payload['message'];
                  }
                  if (errorMsg) {
                    return of(new AddPaymentFailure(errorMsg));
                  }
                  return of(new ReportError(payload));
                }
              )
            )
        ),
        catchError((payload: { errors: Array<ServerError> }) => {
          let errorMsg = '';
          if (payload && payload.errors && payload.errors.length) {
            switch (payload.errors[0].type) {
              case 'UnknownResourceError':
                errorMsg = 'Unable to register the card! Is it valid?';
                break;
            }
          }
          if (errorMsg) {
            return of(new AddPaymentFailure(errorMsg));
          }
          return of(new ReportError(payload));
        })
      )
    )
  ));

  
  updatePayments$ = createEffect(() => this.actions$.pipe(
    ofType<UpdatePayment>(ProfileActionTypes.UpdatePayment),
    switchMap((action: UpdatePayment) =>
      this.getAuthenticate().pipe(
        switchMap((authenticate: Authenticate) =>
          this.profileService
            .updateProfilePayment(
              authenticate.username,
              action.paymentId,
              action.payment
            )
            .pipe(map(() => new UpdatePaymentSuccess()))
        )
      )
    )
  ));

  
  deletePayment$ = createEffect(() => this.actions$.pipe(
    ofType<DeletePayment>(ProfileActionTypes.DeletePayment),
    switchMap((action: DeletePayment) =>
      this.getAuthenticate().pipe(
        switchMap((authenticate: Authenticate) =>
          this.profileService.deleteProfilePayment(authenticate.username, action.paymentId) .pipe(
              switchMap(() => {
              return this.getPayments().pipe(
                map((profilePayments: ProfilePayments) => profilePayments.payments),
                map((payments: Array<Payment>) => {
                  const curPayment: Payment = payments.find((p: Payment) => p.id === action.paymentId);
                  if (curPayment.defaultPaymentInfo && payments.length > 1) {
                    return payments.filter((p: Payment) =>   p.id !== action.paymentId)[0];
                  } else {
                    return null;
                  }
                }),
                tap((p: Payment) => {
                  if (p) {
                     // tslint:disable-next-line:max-line-length
                    this.profileService.changeProfilePayment(authenticate.username, p.id, true).subscribe((observer: PartialObserver<any>) => {
                      this.store.dispatch(new GetPayments());
                  });
                  }
                }),
                map(() => new DeletePaymentSuccess()),
                catchError((payload: { errors: Array<ServerError> }) => {
                  let errorMsg = '';
                  if (payload && payload.errors && payload.errors.length) {
                    switch (payload.errors[0].type) {
                      case 'UnknownResourceError':
                        errorMsg = 'Unable to register the card! Is it valid?';
                        break;
                    }
                  }
                  return of(new ReportError(payload));
                })
              );
            })
          )
        )
      )
    )));

  
  setPaymentPrimary$ = createEffect(() => this.actions$.pipe(
    ofType<SetPaymentPrimary>(ProfileActionTypes.SetPaymentPrimary),
    switchMap((action: SetPaymentPrimary) =>
      this.getAuthenticate().pipe(
        switchMap((authenticate: Authenticate) =>
          this.profileService
            .changeProfilePayment(authenticate.username, action.paymentId, true)
            .pipe(
              switchMap(() =>
                this.getPayments().pipe(
                  map((profilePayments: ProfilePayments) => {
                    return profilePayments.payments.find(
                      (p: Payment) => p.id === action.paymentId
                    );
                  }),
                  map((p: Payment) => new SetPaymentPrimarySuccess(p))
                )
              )
            )
        )
      )
    )
  ));

  
  updateProfile$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateProfile>(ProfileActionTypes.UpdateProfile),
    switchMap((action: UpdateProfile) =>
      this.getAuthenticate().pipe(
        switchMap((authenticate: Authenticate) =>
          this.profileService
            .changeProfile(
              authenticate.username,
              action.profileChange.firstName,
              action.profileChange.lastName,
              action.profileChange.phoneNumber
            )
            .pipe(map(() => new UpdateProfileSuccess()))
        )
      )
    )
  ));

  
  updateName$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateName>(ProfileActionTypes.UpdateName),
    switchMap((action: UpdateName) =>
      this.getAuthenticate().pipe(
        switchMap((authenticate: Authenticate) =>
          this.profileService
            .changeProfile(
              authenticate.username,
              action.nameChange.firstName,
              action.nameChange.lastName
            )
            .pipe(map(() => new UpdateNameSuccess()))
        )
      )
    )
  ));

  
  updatePhone$ = createEffect(() => this.actions$.pipe(
    ofType<UpdatePhone>(ProfileActionTypes.UpdatePhone),
    switchMap((action: UpdatePhone) =>
      this.getAuthenticate().pipe(
        switchMap((authenticate: Authenticate) =>
          this.profileService
            .changeProfile(
              authenticate.username,
              undefined,
              undefined,
              action.phoneChange.phoneNumber
            )
            .pipe(map(() => new UpdatePhoneSuccess()))
        )
      )
    )
  ));

  
  updateOptedIn$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateOptedIn>(ProfileActionTypes.UpdateOptedIn),
    switchMap((action: UpdateOptedIn) =>
      this.getAuthenticate().pipe(
        switchMap((authenticate: Authenticate) =>
          this.profileService
            .changeProfile(
              authenticate.username,
              undefined,
              undefined,
              undefined,
              action.optedIn
            )
            .pipe(map(() => new UpdateOptedInSuccess()))
        )
      )
    )
  ));

  
  updateTermsAcceptedStatus$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateTermsAcceptedStatus>(
      ProfileActionTypes.UpdateTermsAcceptedStatus
    ),
    switchMap((action: UpdateTermsAcceptedStatus) =>
      this.getAuthenticate().pipe(
        switchMap((authenticate: Authenticate) =>
          this.profileService
            .changeProfile(
              authenticate.username,
              undefined,
              undefined,
              undefined,
              undefined,
              action.termsAndConditions
            )
            .pipe(
              map(
                () =>
                  new UpdateTermsAcceptedStatusSuccess(
                    action.termsAndConditions
                  )
              ),
              catchError(payload => of(new ReportError(payload)))
            )
        )
      )
    )
  ));

  
  updatePassword$ = createEffect(() => this.actions$.pipe(
    ofType<UpdatePassword>(ProfileActionTypes.UpdatePassword),
    switchMap((action: UpdatePassword) =>
      this.getAuthenticate().pipe(
        switchMap((authenticate: Authenticate) =>
          this.profileService
            .updateProfilePassword(
              authenticate.username,
              action.passwordChange.password,
              action.passwordChange.newPassword
            )
            .pipe(
              map(() => new UpdatePasswordSuccess()),
              catchError(
                (payload: { error: { errors: Array<ServerError> } }) => {
                  let errorMsg = '';
                  if (
                    payload &&
                    payload.error &&
                    payload.error.errors &&
                    payload.error.errors.length
                  ) {
                    switch (payload.error.errors[0].type) {
                      case 'PasswordMismatchError':
                        errorMsg = 'Current password is incorrect';
                        break;
                      case 'ValidationError':
                        errorMsg = (!!payload.error.errors[0].message) ? payload.error.errors[0].message : 'Password has already been used before.';
                        break;   
                    }
                  }
                  if (errorMsg) {
                    return of(new UpdatePasswordFailure(errorMsg));
                  }
                  return of(new ReportError(payload));
                }
              )
            )
        )
      )
    )
  ));

  
  updateEmail$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateEmail>(ProfileActionTypes.UpdateEmail),
    map((action: UpdateEmail) => {
      return action.emailChange;
    }),
    switchMap((emailChange: EmailChange) =>
      this.getAuthenticate().pipe(
        switchMap((authenticate: Authenticate) =>
          this.profileService
            .updateProfileLogin(
              authenticate.username,
              emailChange.newEmail,
              emailChange.password
            )
            .pipe(
              map(() => new UpdateEmailSuccess(emailChange)),
              catchError(
                (payload: { error: { errors: Array<ServerError> } }) => {
                  let errorMsg = '';
                  if (
                    payload &&
                    payload.error &&
                    payload.error.errors &&
                    payload.error.errors.length
                  ) {
                    switch (payload.error.errors[0].type) {
                      case 'DuplicateUidError':
                        errorMsg =
                          'User with email ' +
                          emailChange.newEmail +
                          ' already exists';
                        break;
                      case 'PasswordMismatchError':
                        errorMsg = 'Current password is incorrect';
                        break;
                    }
                  }
                  if (errorMsg) {
                    return of(new UpdateEmailFailure(errorMsg));
                  }
                  return of(new ReportError(payload));
                }
              )
            )
        )
      )
    )
  ));

  
  updateEmailSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ProfileActionTypes.UpdateEmailSuccess),
    map((action: UpdateEmailSuccess) => new SetLogin(action.emailChange))
  ));

  getAuthenticate = (): Observable<Authenticate> =>
    this.store.pipe(
      select(fromAuth.getAuthenticate),
      take(1) // only take one, don't wait to listen for further updates
    )

  getAddresses = (): Observable<ProfileAddresses> =>
    this.store.pipe(
      select(fromProfile.getProfileAddresses),
      take(1)
    )

  getPayments = (): Observable<ProfilePayments> =>
    this.store.pipe(
      select(fromProfile.getProfilePayments),
      take(1)
    )
}
