
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Facet, FacetValue, NutritionPlan } from '../../../api';
import { ProductHeadFilter, ProductFilters } from '../../models/productFilters';
import { ShopUtil } from 'src/app/shared/utils/shopUtil';
import { NgbAccordion, NgbAccordionConfig, NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-np-filters',
  templateUrl: './np-filters.component.html',
  styleUrls: ['./np-filters.component.scss'],
  providers: [NgbAccordionConfig]
})
export class NpFiltersComponent implements OnInit, OnDestroy, OnChanges {
  shopForm: UntypedFormGroup;

  headFilters: ProductHeadFilter[];

  viewSettings = { showVisibleOnly: true, showSelectedOnly: true };

  foodTypeSelected = { Dry: true, Wet: false };

  @Input()
  filtersApplied = [];

  @Input()
  isMobile: boolean | false;

  @Input()
  pending: boolean | false;

  @Input()
  checkedFlag: boolean | false;

  @Input()
  viewBtnDisable: boolean | false;

  @Input()
  facets: Facet[] | null;

  @Input()
  filters: ProductFilters;

  petName: string = '';

  @Input() nutritionPlan: NutritionPlan;


  @Output()
  submitted = new EventEmitter<ProductFilters>();

  @Output()
  showFilterPopup = new EventEmitter();

  displayFacets: Array<Facet>;

  tempFacetsFlags = {};


  formChangesSubscription: Subscription;

  activeFoodFilters: string[] = [];
  activeBrandFilters: any[] = [];
  activeProductLineFilters: any[] = [];

  initialFoodFilters: string[] = [];
  initialBrandFilters: any[] = [];
  initialProductLineFilters: any[] = [];
  filtersCount: number = 0;

  constructor(private fb: UntypedFormBuilder, config: NgbAccordionConfig ) {
    this.displayFacets = [];
    config.closeOthers = true;
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.formChangesSubscription) {
      this.formChangesSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    const pendingChange: SimpleChange = changes['pending'];
    if (pendingChange && !pendingChange.firstChange) {
      if (pendingChange.currentValue) {
        this.shopForm.disable();
      } else {
        this.shopForm.enable();
      }
    }
    const facetsChange: SimpleChange = changes['facets'];
    const nutritionPlanChange: SimpleChange = changes['nutritionPlan'];

    if (facetsChange && nutritionPlanChange) {
      this.initialFoodFilters = [];
      this.initialProductLineFilters = [];
      this.initialBrandFilters = [];
      this.createForm();
    }
  }

  createForm() {
    //this.buildShadowFacets();
    this.shopForm = this.fb.group(this.buildConfig());
    if (this.isMobile) {
      this.setHeadFilters();
      this.petName = this.filters.petName;
    } else {
      this.setHeadFilters();
    }
    if (this.formChangesSubscription) {
      this.formChangesSubscription.unsubscribe();
    }
    this.formChangesSubscription = this.shopForm.valueChanges
      .pipe()
      .subscribe(() => {
        this.setHeadFilters();
      });
  }

  filterVisibleValues(key: string, facetValues: FacetValue[]) {
    if (this.viewSettings.showSelectedOnly && key !== 'foodType') {
      return facetValues.filter(facetValue => facetValue.selected);
    }
    return facetValues;
  }

  filterVisibleFacets(facets: Facet[]) {
    // Except for species every other facet follows server side setting
    if (this.viewSettings.showVisibleOnly) {
      return facets.filter(facet => this.formatFacet(facet) !== 'species' ? facet.visible : false);
    }
  }

  buildConfig() {
    let config = {};
    this.displayFacets = [];
    const nutritionPlan: NutritionPlan = JSON.parse(JSON.stringify(this.nutritionPlan));
    if (this.facets) {
      for (const facet of this.filterVisibleFacets(this.facets)) {
        const arr = [];
        const displayedValues: FacetValue[] = [];
        const key: string = this.formatFacet(facet);
        for (const facetVal of this.filterVisibleValues(key, facet.values)) {
          const chkControl: UntypedFormControl = this.fb.control({
            value: facetVal.selected
            // value: facetVal.selected, disabled: key !== 'foodType'
          });
          arr.push(chkControl);
          displayedValues.push(facetVal);

          if (key === 'foodType' && facetVal.selected) {
            this.initialFoodFilters.push(ShopUtil.mapFoodTypeFilter(facetVal.name));
          } else if (key === 'brand' && facetVal.selected) {
            this.initialBrandFilters.push(ShopUtil.mapBrandTypeFilter(facetVal.name));
          } else if (key === 'productLine' && facetVal.selected) {

            this.initialProductLineFilters.push(ShopUtil.mapProductLineFilter(facetVal.name, nutritionPlan ? nutritionPlan.productLines : []));
          }
        }

        const displayFacet: Facet = { ...facet, values: displayedValues };
        this.displayFacets.push(displayFacet);

        config[this.formatFacet(facet)] = this.fb.array(arr);
      }
    }

    return config;
  }

  formatFacet(facet: Facet) {
    let result = facet.name.replace(/ /g, '');
    result = result.length
      ? result.charAt(0).toLowerCase() + result.slice(1)
      : result;
    return result;
  }

  formatFacetValue(facet: Facet, facetValue: FacetValue): string {
    let result = '';
    const key = this.formatFacet(facet);
    const index = facetValue.query.query.value.lastIndexOf(key + ':');
    if (index >= 0) {
      result = facetValue.query.query.value.substring(index + key.length + 1);
      const endIndex = result.indexOf(':');
      if (endIndex >= 0) {
        result = result.substring(0, endIndex);
      }
      result = decodeURIComponent(result);
    }
    return result;
  }

  preventSelect(e, facet: Facet, facetValue: FacetValue) {

    if (this.foodTypeSelected[facetValue.name]) {
      if (e.target.value === 'false') {
        e.preventDefault();
      }
    }
  }

  updateChecked(facet, name, facetValue) {
    if (Object.keys(this.tempFacetsFlags).length > 0 && !this.checkedFlag) {
      if (name === 'Dry Food') {
        name = 'Dry';
      }
      return this.tempFacetsFlags[name];
    } else {
      if(facet.name == "Brand") {
        if(this.activeBrandFilters.length > 0) {

          if(this.activeBrandFilters.includes(facetValue.code)) {
            return true;
          }
        }
      }
      if(facet.name == "Product Line") {
        if(this.activeProductLineFilters.length > 0) {

          if(this.activeProductLineFilters.includes(facetValue.code)) {
            return true;
          }
        }
      }
      if(facet.name == "Food Type") {
        if(this.activeFoodFilters.length > 0) {
          if (name === 'Dry Food') {
            name = 'Dry';
          }
          if(this.activeFoodFilters.includes(name)) {
            return true;
          }
        }
      }
      return false;
    }
  }

  // brand: np.brands ? np.brands.map((b: Brand) => b.code) : [],
  // species: [ShopUtil.mapPetTypeFilter(np.pet.petType)],
  // productLine: np.productLines.map((pl: ProductLine) => pl.code),

  updateFilters(e: MouseEvent, facet: Facet, facetValue: FacetValue) {

    this.checkedFlag = true;

    if (this.shopForm.valid && !this.shopForm.disabled && e.currentTarget['checked'] !== undefined) {
      const key = this.formatFacet(facet);
      if (key === 'foodType') {
        // // for each value, check if present in selections reset selection
        const selected: boolean = e.currentTarget['checked'];
        if (!selected) {
          const currentFilter = ShopUtil.mapFoodTypeFilter(facetValue.name);
          this.activeFoodFilters = this.activeFoodFilters.filter((aFilter: string) => aFilter !== currentFilter);

        } else {
          const currentFilter = ShopUtil.mapFoodTypeFilter(facetValue.name);
          const existsFilter = this.activeFoodFilters.find(((aFilter: string) => aFilter === currentFilter));
          if (!existsFilter) {
            const tempFilters = JSON.parse(JSON.stringify(this.activeFoodFilters));
            tempFilters.push(currentFilter);
            this.activeFoodFilters = tempFilters;
          }
        }
      }

      if (key === 'brand') {
        const selected: boolean = e.currentTarget['checked'];
        if (!selected) {
          const currentFilter = ShopUtil.mapBrandTypeFilter(facetValue.name);
          this.activeBrandFilters = this.activeBrandFilters.filter((aFilter: string) => aFilter !== currentFilter);
        } else {
          const currentFilter = facetValue.name.replace(/ /g, '');
          const existsFilter = this.activeBrandFilters.find(((aFilter: string) => aFilter === currentFilter));
          if (!existsFilter) {
            const tempFilters = JSON.parse(JSON.stringify(this.activeBrandFilters));
            tempFilters.push(currentFilter);
            this.activeBrandFilters = tempFilters;
          }
        }
      }

      if (key === 'productLine') {
        const selected: boolean = e.currentTarget['checked'];
        if (!selected) {
          const currentFilter = ShopUtil.mapProductLineFilter(facetValue.name, this.nutritionPlan.productLines);
          this.activeProductLineFilters = this.activeProductLineFilters.filter((aFilter: string) => aFilter !== currentFilter);
        } else {
          const currentFilter = ShopUtil.mapProductLineFilter(facetValue.name, this.nutritionPlan.productLines);
          const existsFilter = this.activeProductLineFilters.find(((aFilter: string) => aFilter === currentFilter));
          if (!existsFilter) {
            const tempFilters = JSON.parse(JSON.stringify(this.activeProductLineFilters));
            tempFilters.push(currentFilter);
            this.activeProductLineFilters = tempFilters;
          }
        }
      }
    }
    this.filtersCount = this.activeBrandFilters.length + this.activeFoodFilters.length + this.activeProductLineFilters.length;
    //Disable button when user de-selects all options
    // if (this.activeFoodFilters.length > 0) {
    //   this.viewBtnDisable = false;
    // } else {
    //   this.viewBtnDisable = true;
    // }

  }

  search() {
    if (this.shopForm.valid) {
      this.filtersApplied = [];
      if (this.activeFoodFilters.length > 0) {

        this.activeFoodFilters.forEach((value, index: number) => {
          this.filtersApplied.push(value);
        });
      }

      if (this.activeBrandFilters.length > 0) {

        this.activeBrandFilters.forEach((value, index: number) => {
          this.filtersApplied.push(value);
        });
      }

      if (this.activeProductLineFilters.length > 0) {

        this.activeProductLineFilters.forEach((value, index: number) => {
          this.filtersApplied.push(value);
        });
      }


      // Merge the filters selected by UI action to be merged to other filters
      const mergedFilters: ProductFilters = {
        ...this.filters,
        foodType: this.activeFoodFilters.length ? this.activeFoodFilters : this.initialFoodFilters,
        brand: this.activeBrandFilters.length ? this.activeBrandFilters : this.initialBrandFilters,
        productLine: this.activeProductLineFilters.length ? this.activeProductLineFilters : this.initialProductLineFilters,
        appliedFilter: this.filtersApplied,
        petName: this.petName
      };
      this.submitted.emit(mergedFilters);

    }
  }


  /**
   * Returns all the filters selections on top
   */
  setHeadFilters() {
    let appliedFlag = false;
    if (this.filters.appliedFilter !== undefined) {
      appliedFlag = true;
    } else {
      appliedFlag = false;
    }
    const filters: ProductHeadFilter[] = [];
    const tempFilters: ProductHeadFilter[] = [];
    if (this.shopForm.valid && !this.shopForm.disabled) {
      const model = this.shopForm.value;
      if (this.facets) {
        this.facets.forEach((facet: Facet) => {
          const key = this.formatFacet(facet);

          if (key === 'foodType') {
            const modelArray = model[key];
            facet.values.forEach((value: FacetValue, index: number) => {
              if (key === 'foodType') {
                if (modelArray[index]) {
                  filters.push({
                    index: index,
                    facetName: key,
                    value: ShopUtil.mapFoodTypeFilter(value.name),
                    name: ShopUtil.mapFoodTypeFilter(value.name),
                  });
                }
              }
            });
          }

          if (key === 'brand') {
            const modelArray = model[key];
            facet.values.forEach((value: FacetValue, index: number) => {
              if (key === 'brand' && value.selected) {
                if (modelArray[index]) {
                  filters.push({
                    index: index,
                    facetName: key,
                    value: value.name.replace(/ /g, ''),
                    name: value.name.replace(/ /g, ''),
                  });
                }
              }
            });
          }

          if (key === 'productLine') {
            const modelArray = model[key];
            facet.values.forEach((value: FacetValue, index: number) => {
              if (key === 'productLine' && value.selected) {
                if (modelArray[index]) {
                  filters.push({
                    index: index,
                    facetName: key,
                    value: ShopUtil.mapProductLineFilter(value.name, this.nutritionPlan.productLines),
                    name: ShopUtil.mapProductLineFilter(value.name, this.nutritionPlan.productLines),
                  });
                }
              }
            });
          }
        });
      }
      //For Desktop
      if (!this.isMobile) {
        this.headFilters = filters;
      } else {
        //For Mobile
        if (appliedFlag && !this.checkedFlag && this.filters.appliedFilter.length > 0) {
          for (let i = 0; i < this.filters.appliedFilter.length; i++) {
            filters.forEach((key, index: number) => {
              if (key.value.toLowerCase() === this.filters.appliedFilter[i].toLowerCase()) {
                tempFilters.push(filters[index]);
                this.tempFacetsFlags[key.value] = true;
              }
            });
          }
          this.headFilters = tempFilters;
        } else {
          this.headFilters = filters;
        }
      }
    }
  }

  removeFilter(headFilter: ProductHeadFilter) {
    if (this.shopForm.valid) {
      this.activeFoodFilters = [];
      // for each facet we get the corresponding form array control
      this.facets.forEach((facet: Facet) => {
        const key = this.formatFacet(facet);
        const formArray = this.shopForm.controls[key] as UntypedFormArray;
        if (key.toLowerCase() === 'foodtype') {
          facet.values.map((value: FacetValue, index: number) => {
            const currentFilter = ShopUtil.mapFoodTypeFilter(value.name);
            const existsFilter = this.activeFoodFilters.find(((aFilter: string) => aFilter === currentFilter));

            if (headFilter.value === currentFilter) {
              formArray.at(index).setValue(false);
            } else {
              const tempFilters = []; // Needed to sort out immutability issues.
              this.activeFoodFilters.forEach((filter: string) => tempFilters.push(filter));
              tempFilters.push(currentFilter);
              this.activeFoodFilters = tempFilters;
            }
          });
        }
      });
      //Disable button when user de-selects all options
      if (this.headFilters.length > 0) {
        this.viewBtnDisable = false;
      } else {
        this.activeFoodFilters = [];
        this.viewBtnDisable = true;
      }
    }
  }


  hideFilterPopup() {
    this.showFilterPopup.emit();
  }

  noop() {
    // Does nothing
  }
}
