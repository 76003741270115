import { Action } from '@ngrx/store';
import { Session } from '../../api';
import { ContactUsDetail } from '../models/contact-us-detail';

export enum SupportActionTypes {
    ContactUsEmail = '[Support] Contact Us Email',
    ContactUsEmailSuccess = '[Support] Contact Us Email Success',
    ContactUsEmailFailure = '[Support] Contact Us Email Failure',
  }

  export class ContactUsEmail implements Action {
    readonly type = SupportActionTypes.ContactUsEmail;
  
    constructor(public contactUsEmail: ContactUsDetail) {
    }
  }
  
  export class ContactUsEmailSuccess implements Action {
    readonly type = SupportActionTypes.ContactUsEmailSuccess;
  
    constructor() {
    }
  }
  
  export class ContactUsEmailFailure implements Action {
    readonly type = SupportActionTypes.ContactUsEmailFailure;
  
    constructor(public contactUsEmailError: any) {
    }
  }

  export type SupportActionsUnion =
  | ContactUsEmail
  | ContactUsEmailSuccess
  | ContactUsEmailFailure
  ;