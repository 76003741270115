/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { appInjector } from './app/app.globals';

const scriptEl = window.document.createElement('script');
if (environment.production) {
  enableProdMode();
  scriptEl.src = 'https://x441.hillstohome.com/script.js';
  document.head.insertBefore(scriptEl, document.head.firstChild);
} else {
  scriptEl.src = 'https://cdn.blueconic.net/petcare-na-sb.js';
  document.head.insertBefore(scriptEl, document.head.firstChild);
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then((moduleRef) => {
    // store a reference to the application injector
    appInjector(moduleRef.injector);
  })
  .catch(err => console.log(err));
