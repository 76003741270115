<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout custom-font pt-3 pb-5 px-2">
    <div class="container px-1">
      <h4 class="text-center mt-3">
        My Orders
      </h4>
      <div class="d-flex align-items-center mt-4">
        <div class="d-flex align-items-center">
          <h6 class="mb-0 sharp-blue">#{{order?.code}}</h6>
        </div>
        <div class="flex-grow-1 d-flex justify-content-end ml-3">
          <a [href]="order?.consignments[0].carrierTrackingURL" target="_blank" class="btn btn-primary btn-sm pl-4 pr-4"
            *ngIf="order && order.consignments && order.consignments.length && order.consignments[0].trackingID">
            Track Order
          </a>
        </div>
      </div>
      <div class="pt-3 pb-3 w500" *ngIf="order">
        <div class="d-flex">
          <b class="w-50 mr-2">Order Date:</b>
          <span class="flex-grow-1">{{order.created | date}}</span>
        </div>
        <div class="d-flex mt-2">
          <b class="w-50 mr-2">Estimated Delivery Date:</b>
          <span class="flex-grow-1" *ngIf="order.status.toUpperCase() !== 'CANCELLED'">
            {{order?.v2cOrderEstimateDate | date: 'MM/dd/yyyy'}}
          </span>
          <span class="flex-grow-1" *ngIf="order.status.toUpperCase() === 'CANCELLED'">
            -
          </span>
        </div>
        <div class="d-flex mt-2">
          <b class="w-50 mr-2">Status:</b>
          <span class="flex-grow-1 app-status">{{order.status | appOrderStatus}}</span>
        </div>
        <div class="d-flex mt-2">
          <b class="w-50 mr-2">Shipping Address:</b>
          <div class="flex-grow-1">
            <div>{{order.deliveryAddress.line1}}</div>
            <div>
              <!--state info added as requested by business - Rahul Solanki-->
              {{order.deliveryAddress.line2 ? order.deliveryAddress.line2 + ', ' : ''}} {{order.deliveryAddress.town  + ', '}} {{order.deliveryAddress.region.name}}
              {{order.deliveryAddress.postalCode}}
              <!--END-->
            </div>
          </div>
        </div>
        <div *ngIf="order && (order.status === OrderStatuses.Shipped || order.status === OrderStatuses.PartialShipped)">
          <button class="btn btn-primary btn-sm mt-3" (click)="downloadInvoice(order)">
            Invoice
          </button>
          <!--Fix for HTH-1958-->
          <button *ngIf="order.status !== OrderStatuses.PartialShipped" class="btn btn-primary btn-sm mt-3 ml-3 hiddenx" (click)="returnOrder(order)">
            Return
          </button> 
        </div>
        <div class="d-flex mt-2"
          *ngIf="order && order.consignments && order.consignments.length && order.consignments[0].trackingID">
          <b class="w-50 mr-2">{{order?.consignments[0].carrier}} #:</b>
          <div class="flex-grow-1">
            <div>{{order?.consignments[0].trackingID}}</div>
          </div>
        </div>
      </div>

      <div class="d-flex cart-listing pr-3 pl-3 pt-3 pb-3" *ngFor="let entry of order?.entries; let i=index"
        [class.border-bottom]="i!==(order?.entries.length - 1)">
        <div class="d-flex flex-column cart-list-img align-items-center justify-content-start mr-3">
          <div class="pet-name d-flex align-items-center pt-2 pb-3">
            <h5 class="pt-1">{{entry.pet}}</h5>
          </div>
          <img src="{{entry.product.images | appImg}}" class="app-cart-img" *ngIf="entry.product.images" alt="{{entry.product.name}}" />
          <div class="app-cart-img text-center" *ngIf="!entry.product.images">
            <i class="fas fa-image fa-4x"></i>
          </div>
          <div class="small mt-2 w500">
            SKU {{entry.product.code}}
          </div>
        </div>
        <div class="flex-grow-1 d-flex flex-column ml-2 w500 confirm-order">
          <div class="cart-product-head mt-1">
            <!-- <span>Hill's Prescription</span> -->
            <h4>{{entry.product.name}}</h4>
            <!-- <span>2.9 OZ, 24 Pack</span> -->
          </div>
          <div class="text-danger">
            <span class="font-weight-bold" *ngIf="entry.status == 'RETURN_INITIATED'">Return Initiated</span>
            <span class="font-weight-bold" *ngIf="entry.status == 'RETURN_DENIED'">Return Denied</span>
            <span class="font-weight-bold" *ngIf="entry.status == 'RETURN_COMPLETED'">Return Completed</span>
          </div>
          <!-- <div class="mt-2">
            <span class="font-weight-bold">Pet: </span>
            <span>{{entry.pet}}</span>
          </div> -->
          <div class="mt-2">
            <span class="font-weight-bold">Subscription: </span>
            <span>{{entry.subscriptionUnit?.deliveryFrequency | tolabel : LabelTypes.Frequency}}</span>
            <div *ngIf="entry.subscriptionUnit.endDate && entry.subscriptionUnit.deliveryFrequency != DeliveryFrequencies?.OneTime">Subscription ends on {{entry.subscriptionUnit.endDate | date: 'MM/dd/yyyy'}}</div>
          </div>
          <div class="mt-2">
            <span class="font-weight-bold">Qty: </span>
            <span>{{entry.quantity}}</span>
          </div>
          <div class="mt-2"
            *ngIf="entry.subscriptionUnit && entry.subscriptionUnit.deliveryFrequency !== DeliveryFrequencies.OneTime">
            <span class="font-weight-bold">Price: </span>
            <span class="text-line-through">{{entry.basePrice.formattedValue}}</span>
            <span class="product-price w600"> ${{(entry.totalPrice.value / entry.quantity | number: '.2-2')}}</span>
          </div>
          <div class="mt-2"
            *ngIf="!entry.subscriptionUnit || entry.subscriptionUnit.deliveryFrequency === DeliveryFrequencies.OneTime">
            <span class="font-weight-bold">Price: </span>
            <span>{{entry.basePrice.formattedValue}}</span>
          </div>
          <div class="mt-2">
            <span class="font-weight-bold">Subtotal: </span>
            <span class="sub-total w600">{{entry.totalPrice.formattedValue}}</span>
          </div>
        </div>
      </div>
      <div *ngIf="order && order.status === OrderStatuses.InProgress" class="p-3 white-card-bordered mt-4">
        <div class="w500">
          *Please note that "In Progress" orders cannot be cancelled online. If you need to cancel an "In Progress"
          order,
          please email hillstohome@hillspet.com or call us at 1-800-235-6877
        </div>
      </div>
      <!-- Need a note for partially returned order instructing Pet Parents to contact CSC to return the rest of the order - Rahul Solanki -->
      <div *ngIf="order && order.status === OrderStatuses.PartialReturn" class="p-3 white-card-bordered mt-4">
        <div class="w500">
          *If you would like to return another item from this order please contact our customer support team at 1-800-235-6877.
        </div>
      </div>
      <!-- END -->
      <!-- Promotions discount info added - Rahul Solanki -->
      <app-order-summary class="mt-4" [isMobile]="isMobile" [order]="order" [isOrderCompletionPage]="true" [isMyOrderPage]="true">
      </app-order-summary>
      <!-- END -->

      <div *ngIf="order && order.status === OrderStatuses.Pending" class="w500 text-center p-3">
        Canceling orders can only be done when order is in <b>Pending Status</b>
      </div>
      <div *ngIf="order && order.status === OrderStatuses.Pending" class="mt-4">
        <button class="btn btn-blue btn-lg w-100 py-3" (click)="confirm(order)">
          Cancel Order
        </button>
      </div>

    </div>
  </div>
</ng-template>

<ng-template #fullLayout>
  <div class="app-full-layout">
  </div>
</ng-template>

<ng-template #confirmModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-body">
    <h3 class="mt-4 mb-4 ml-2 mr-2">
      <!--Are you sure you want to cancel order {{orderDetail?.code}}?-->
      Are you sure you want to cancel this order?
    </h3>
  </div>
  <div class="modal-footer justify-content-center">
    <button type="button" class="btn btn-secondary pl-5 pr-5" (click)="dismissConfirm()">
      Don't Cancel
    </button>
    <button type="button" class="btn btn-primary pl-5 pr-5 ml-2" (click)="closeConfirm()">
      Cancel Order
    </button>
  </div>
</ng-template>
<ng-template #returnModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-body">
    <h3 class="m-4">
      Are you sure you want to return this order?
    </h3>
  </div>
  <div class="modal-footer justify-content-center">
    <button type="button"
            class="btn btn-secondary btn_confirm_return"
            (click)="dismissConfirm()">
      No, Cancel
    </button>
    <button type="button"
            class="btn btn-primary btn_confirm_return ml-2"
            (click)="closeConfirm()">
      Yes, Return Order
    </button>
  </div>
</ng-template>
