import { EditOrderDeliveryModule } from './../orders/containers/edit-order-delivery-date-page/edit-order-delivery.module';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgbCollapseModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { CoreRoutingModule } from './core-routing.module';
import { SharedModule } from '../shared/shared.module';
import { MainComponent } from './components/main/main.component';
import { MainPageComponent } from './containers/main-page/main-page.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { NotFoundPageComponent } from './containers/not-found-page/not-found-page.component';
import { ErrorComponent } from './components/error/error.component';
import { ErrorPageComponent } from './containers/error-page/error-page.component';

import { reducers } from './reducers';
import { ErrorEffects } from './effects/error.effects';
import { CoreEffects } from './effects/core.effects';

import { ErrorGuard } from './guards/error.guard';
import { NoErrorGuard } from './guards/no-error.guard';
import { TermsComponent } from './components/terms/terms.component';
import { TermsPageComponent } from './containers/terms/terms-page.component';
import { NotLinkedMessageComponent } from './components/not-linked-message/not-linked-message.component';
import { NotLinkedMessagePageComponent } from './containers/not-linked-message-page/not-linked-message-page.component';
import { EditOrderDeliveryDatePage } from '../orders/containers/edit-order-delivery-date-page/edit-order-delivery-date-page.component';
import { EditOrderDeliveryDate } from '../orders/components/edit-order-delivery-date/edit-order-delivery-date.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    CoreRoutingModule,
    NgbCollapseModule,
    NgbDropdownModule,
    StoreModule.forFeature('core', reducers),
    EffectsModule.forFeature([ErrorEffects]),
    EffectsModule.forFeature([CoreEffects]),
    EditOrderDeliveryModule.forRoot()
  ],
  declarations: [
    MainComponent,
    MainPageComponent,
    NotFoundComponent,
    NotFoundPageComponent,
    ErrorComponent,
    ErrorPageComponent,
    TermsComponent,
    TermsPageComponent,
    NotLinkedMessageComponent,
    NotLinkedMessagePageComponent
  ],
  exports: [
    MainPageComponent
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [ErrorGuard, NoErrorGuard],
    };
  }
}
