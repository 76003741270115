import { Component, HostBinding, OnDestroy, OnInit, Input, AfterViewInit, SimpleChanges, SimpleChange } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { Subscription } from 'rxjs';
import { skip, switchMap, take, tap, debounceTime, delay } from 'rxjs/operators';
import * as fromRoot from '../../../reducers';
import * as fromCore from '../../../core/reducers';
import * as fromPets from '../../../pets/reducers';
import * as fromOrders from '../../../orders/reducers';
import * as fromProducts from '../../reducers';
import * as ProductsActions from '../../actions/products.actions';
import * as PetsActions from '../../../pets/actions/pets.actions';
import { AppAnimate, flyOutIn } from '../../../shared/animations/common.animations';
import { ScrollChange } from '../../../core/models/scrollChange';
import { AddingCart, SearchedProducts, NutritionPlan } from '../../../api';
import { ProductFiltersSort } from '../../models/productFilters';
import { LayoutModes } from '../../../core/constants/layout';
import * as LayoutActions from '../../../core/actions/layout.actions';
import * as OrdersActions from '../../../orders/actions/orders.actions';
import { LocationUtil } from '../../../shared/utils/locationUtil';
import * as CoreActions from "../../../core/actions/core.actions";

@Component({
  selector: 'app-np-products-page',
  template: `
    <app-np-products
      [clinicId]="clinicId"
      [changeProduct]="changeProduct"
      [isMobile]="isMobile$ | async"
      [pending]="planSearchPending$ | async"
      [addingPending]="cartProductChangePending$ | async"
      [sort]="planSort$ | async"
      [searchedProducts]="searchedProducts$ | async"
      [nutritionPlan]="nutritionPlan$ | async"
      [nutritionPlans]="nutritionPlans$ | async"
      [facets]="facets$ | async"
      [filters]="filters$ | async"
      (submitted)="onSubmit($event)"
      (adding)="onAdd($event)"
      [subscription]="subscription$"
      [subscriptionChangePending]="subscriptionChangePending$ | async"
      (subscriptionProductChanged)="onChangeProduct($event)"
      (searchWellnessProducts)="searchWellnessProducts($event)"
      (filterProductsSubmitted)="filterProducts($event)"
      (seeMoreProducts)="seeMoreProducts($event)"
      [showWellnessProducts]="showWellnessproducts"
      [wellnessId]="wellnessId"
      [pet]="petDetail$ | async"
      (navigatedBack)="onNavigateBack()"
      (searchWellnessProductsByName)="searchWellnessProductsByName($event)"
      [wellnessPlan]="wellnessPlan$ | async"
      >
    </app-np-products>
  `,
  styles: [],
  animations: [flyOutIn]
})
export class NpProductsPageComponent implements OnDestroy, OnInit {

  @Input() defaultSelectedPlan: NutritionPlan | null;

  clinicId: string;

  subscriptionId: string;

  changeProduct: boolean;

  isPopState: boolean;

  @HostBinding('@flyOutIn')
  flyOutIn = '';

  // @HostBinding('class.app-animated-page')
  // animatedPage = true;

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  planSearchPending$ = this.store.pipe(select(fromProducts.getPlanSearchPending));

  cartProductChangePending$ = this.store.pipe(select(fromOrders.getCartProductChangePending));

  planCurrentPage$ = this.store.pipe(select(fromProducts.getPlanCurrentPage));

  planSort$ = this.store.pipe(select(fromProducts.getPlanSort));

  searchedProducts$ = this.store.pipe(select(fromProducts.getSearchedPlanProducts));

  nutritionPlan$ = this.store.pipe(select(fromPets.getNutritionPlanDetail));

  facets$ = this.store.pipe(select(fromProducts.getPlanFacets));

  filters$ = this.store.pipe(select(fromProducts.getPlanFilters));

  scrollChange$ = this.store.pipe(select(fromCore.getScrollChange));

  subscriptionChangePending$ = this.store.pipe(select(fromOrders.getSubscriptionChangePending));

  petDetail$ = this.store.pipe(select(fromPets.getPetDetail));

  subscription$ = this.store.pipe(select(fromOrders.getSubscription));

  wellnessPlan$ = this.store.pipe(select(fromPets.getWellnessPlan));

  nutritionPlans$ = this.store.pipe(select(fromPets.getNutritionPlans));

  subscriptions: Subscription[] = [];

  showWellnessproducts: boolean = false;

  wellnessId: string;

  constructor(private store: Store<fromRoot.State>,
    private route: ActivatedRoute,
    private locationStrategy: LocationStrategy,
    private router: Router) {
    AppAnimate('flyOutIn', this, this.subscriptions);
    this.isPopState = LocationUtil.isPopState();
    this.subscriptions.push(
      route.params
        .subscribe(params => {
          this.clinicId = params['clinicId'];
          this.subscriptionId = params['subscriptionId'];
          this.wellnessId = params['wellnessId']; // if wellness id is 0 , show healthy/science diet products
          if (this.clinicId && this.wellnessId !== '0') {
            // only if it is not coming form a back button
            if (!this.isPopState) {
              this.store.dispatch(new ProductsActions.GetPlanFacets(this.clinicId, this.subscriptionId));
              this.store.dispatch(new ProductsActions.SearchPlan(this.clinicId));
            }
          }

          if (this.clinicId && this.wellnessId === '0') {
            if (!this.isPopState) {
              this.store.dispatch(new PetsActions.GetWellnessPlanDetail());
              this.store.dispatch(new ProductsActions.SearchPlan(this.clinicId));
            }
          }
        })
    );

    this.subscriptions.push(
      route.data
        .subscribe(params => {
          this.changeProduct = params['changeProduct'];
          if(this.changeProduct == true) {
            this.nutritionPlan$ = this.store.pipe(select(fromOrders.getNutritionPlanDetailSubscription));
            this.petDetail$ = this.store.pipe(select(fromOrders.getPetDetailSubscription));
          }else {
            this.nutritionPlan$ = this.store.pipe(select(fromPets.getNutritionPlanDetail));
            this.petDetail$ = this.store.pipe(select(fromPets.getPetDetail));
          }
        })
    );

    // Check on route change
    this.subscriptions.push(
      this.route.url
        .pipe(
          switchMap(() =>
            this.isMobile$
              .pipe(
                take(1), // Only once needs to apply this change
                tap((isMobile: boolean) => {
                  if (!isMobile) {
                    setTimeout(() => {
                      this.store.dispatch(new LayoutActions.ChangeLayoutMode(LayoutModes.Alternate3));
                    });
                  }
                }
                ))
          )
        )
        .subscribe()
    );
  }

  ngOnInit() {

    this.locationStrategy.onPopState(() => {
      LocationUtil.isPopState(true);
    });


    // this.subscriptions.push(
    //   this.scrollChange$
    //     .pipe(
    //       skip(1),
    //       switchMap((scrollChange: ScrollChange) =>
    //         this.searchedProducts$
    //           .pipe(
    //             take(1),
    //             switchMap((searchedProducts: SearchedProducts) =>
    //               this.planCurrentPage$
    //                 .pipe(
    //                   take(1),
    //                   switchMap((currentPage: number) =>
    //                     this.isMobile$
    //                       .pipe(
    //                         take(1),
    //                         debounceTime(1000),
    //                         tap((isMobile: boolean) => {
    //                           currentPage++; // go to next page
    //                           // if is mobile, and there is a scroll change, and
    //                           // next page is less than total pages
    //                           if (isMobile && scrollChange &&
    //                             searchedProducts && currentPage < searchedProducts.pagination.totalPages) {
    //                             // if we are at the end of the page minus an offset of 100
    //                             if ((scrollChange.offsetHeight + scrollChange.yOffset) >= (scrollChange.scrollHeight - 100)) {
    //                               // search the next page, and stacked the results
    //                               this.store.dispatch(new ProductsActions.SearchPlan(this.clinicId, currentPage, true));
    //                               /*setTimeout(() => {
    //                                 this.store.dispatch(new ProductsActions.SearchPlan(this.clinicId, currentPage, true));
    //                               },500);*/
    //                             }
    //                           }
    //                         }
    //                         ))
    //                   ))
    //             )
    //           )
    //       ))
    //     .subscribe()
    // );

    this.subscriptions.push(
      this.isMobile$
        .pipe(
          skip(1), // Skip first time is being take care of
          tap((isMobile: boolean) => {
            if (isMobile) {
              this.store.dispatch(new LayoutActions.ChangeLayoutMode(LayoutModes.Normal));
            } else {
              this.store.dispatch(new LayoutActions.ChangeLayoutMode(LayoutModes.Alternate3));
            }
          }
          ))
        .subscribe()
    );

    this.subscriptions.push(this.planCurrentPage$.subscribe());
  }


  ngOnChanges(changes: SimpleChanges) {
    const defaultSelectedPlanChange: SimpleChange = changes['defaultSelectedPlan'];

    if (defaultSelectedPlanChange && !defaultSelectedPlanChange.firstChange) {
      const { id, clinic } = this.defaultSelectedPlan;
      this.clinicId = clinic.uid;
      // only if it is not coming form a back button
      if (this.clinicId) {
        this.store.dispatch(new PetsActions.GetNutritionPlanDetail(id));
        this.store.dispatch(new ProductsActions.SearchPlan(this.clinicId));
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
  }

  onSubmit(filtersSort: ProductFiltersSort) {
    this.store.dispatch(new ProductsActions.SetPlanSort(filtersSort.sort));
    if (filtersSort.filters) {
      this.store.dispatch(new ProductsActions.SetPlanFilters(filtersSort.filters));
    }
    this.store.dispatch(new ProductsActions.SearchPlan(this.clinicId, filtersSort.page));
  }

  filterProducts(filtersSort: ProductFiltersSort) {
    this.store.dispatch(new ProductsActions.SetPlanSort(filtersSort.sort));
    if (filtersSort.filters) {
      this.store.dispatch(new ProductsActions.SetPlanFilters(filtersSort.filters));
    }
    this.store.dispatch(new ProductsActions.FilterProduct(this.clinicId, filtersSort.page));
  }

  searchWellnessProducts(wellnessPlanId) {

    // this.store.dispatch(new PetsActions.GetWellnessPlanDetail());
    // this.store.dispatch(new ProductsActions.SearchPlan(this.clinicId));
    this.store.dispatch(new PetsActions.SetSelectedPlans([wellnessPlanId]));
    this.router.navigate(['/np-products', this.clinicId, 0]);
  }

  onAdd(addingCart: AddingCart) {
    this.store.dispatch(new OrdersActions.AddProductToCart(addingCart));
  }

  onChangeProduct(productCode: string) {
    this.store.dispatch(new OrdersActions.ChangeSubscriptionProduct(productCode));
  }

  searchWellnessProductsByName(name) {
    this.store.dispatch(new ProductsActions.SearchProductsByName(this.clinicId, name));
  }

  seeMoreProducts(name?: string) {
    this.planCurrentPage$.pipe(
      take(1))
      .subscribe(currentPage => {
        let page = currentPage + 1;
        this.store.dispatch(new ProductsActions.SearchPlan(this.clinicId, page, name));
      })
  }

  onNavigateBack() {
    this.store.dispatch(new CoreActions.NavigateBack(true));
  }

}

