<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout light-grey-bg custom-font px-2 pt-4">
    <div class="px-1">
      <!-- <h5 class="app-title text-center m-4">
      Order
    </h5> -->
      <a (click)="goBack()" class="fa fa-arrow-left btn-back-arrow"></a>
      <form (ngSubmit)="placeOrder()" [formGroup]="cartForm">
        <div class="cart-listing shipping-detail-card">
          <div class="d-flex align-items-center py-2">
            <h3 class="flex-grow-1 w800 m-0">Shipping Address</h3>
            <button [routerLink]="['/profile/add-cart-address']" class="btn btn-outline-dark btn-md px-2" type="button">
              Add Address
            </button>
          </div>
          <div *ngIf="profileAddresses" class="mt-3">
            <div *ngFor="let address of profileAddresses.addresses;let i=index"
              [class.is-primary]="address.defaultAddress" class="d-flex flex-column py-3 border-top">
              <div class="custom-control custom-radio flex-grow-1 delivery-address-radio">
                <div class="mb-2 primary-label" *ngIf="address.defaultAddress">
                  Primary
                </div>
                <input (change)="onAddressChanged()" [id]="'deliveryAddress_'+i" [value]="address.id"
                  class="custom-control-input" formControlName="deliveryAddressId" type="radio">
                <label [for]="'deliveryAddress_'+i" class="custom-control-label d-flex flex-column pl-1">
                  <div>
                    {{address.line1}}
                    {{address.line2}}
                  </div>
                  <div>
                    {{address.town}},
                    {{address.region.isocode | appRegion}}
                    {{address.postalCode}}
                  </div>
                </label>
              </div>
              <div class="d-flex pt-3">
                <!-- <div *ngIf="address.defaultAddress" class="text-center set-primary px-4">
                  Primary
                </div>
                <a (click)="makePrimaryProfileAddress(address)" *ngIf="!address.defaultAddress"
                  class="btn btn-outline-primary px-4" href="javascript:void(0)">
                  Primary
                </a> -->
                <a (click)="confirmDeleteAddress(address)" class="btn btn-outline-primary px-3 ml-3"
                  href="javascript:void(0)" aria-label="Delete item">
                  <i class="fa fa-trash-alt"></i>
                </a>
                <a [routerLink]="['/profile/edit-cart-address', address.id]" class="btn btn-outline-primary px-3 ml-3"
                  href="javascript:void(0)" aria-label="Edit item">
                  <i class="fa fa-pencil-alt"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="cart-listing shipping-detail-card">
          <div class="d-flex align-items-center py-2">
            <h3 class="flex-grow-1 w800 m-0">Payment</h3>
            <button [routerLink]="['/profile/add-cart-payment']" class="btn btn-outline-dark btn-md px-2" type="button">
              Add Payment
            </button>
          </div>
          <div *ngIf="profilePaymentChangeError" class="alert alert-warning alert-dismissible" role="alert">
            <button (click)="clearPaymentError()" aria-label="Close" class="close" type="button"><span
                aria-hidden="true">&times;</span></button>
            Unable to add card. Reason - {{profilePaymentChangeError}}
          </div>
          <div *ngIf="profilePayments" class="mt-3">
            <div *ngFor="let payment of profilePayments.payments;let i=index"
              [class.is-primary]="payment.defaultPaymentInfo" class="d-flex border-top py-3 flex-column">
              <div class="custom-control custom-radio flex-grow-1 payment-radio">
                <div class="mb-2 primary-label" *ngIf="payment.defaultPaymentInfo">
                  Primary
                </div>
                <input (change)="onPaymentChanged()" [id]="'payment_'+i" [value]="payment.id"
                  class="custom-control-input" formControlName="paymentId" type="radio">
                <label [for]="'payment_'+i" class="custom-control-label d-flex align-items-center pl-1">
                  <div>
                    <!-- <i [class]="payment.cardType.code | cardTypeToImage: 'app-icon app-credit-card fa-2x'"></i> -->
                    <img width="38" [src]="payment.cardType.code | cardTypeToImage" alt="{{payment.cardType.code}} card" />
                  </div>
                  <div class="pl-2 d-flex flex-column flex-grow-1">
                    <div>
                      <span class="text-capitalize">{{payment.cardType.code}}</span>
                      {{'Ending in - ' + payment.cardNumber}}
                    </div>
                  </div>
                </label>
              </div>
              <div class="d-flex pt-3">
                <!-- <div *ngIf="payment.defaultPaymentInfo" class="text-center set-primary px-4">
                  Primary
                </div>
                <a (click)="confirmMakePrimaryProfilePayment(payment)" *ngIf="!payment.defaultPaymentInfo"
                  class="btn btn-outline-primary px-4" href="javascript:void(0)">
                  Primary
                </a> -->
                <a (click)="confirmDeletePayment(payment)" class="btn btn-outline-primary px-3 ml-3"
                  href="javascript:void(0)" aria-label="Delete item">
                  <i class="fa fa-trash-alt"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="cart-listing shipping-detail-card pb-2">
          <h3 class="w800 mt-2">
            Estimated Shipping Time
            <!-- <span>(Select your shipping speed)</span> -->
          </h3>
          <div class="d-flex flex-column pt-1 pb-1">
            <div *ngFor="let deliveryMode of deliveryModes; let i=index" class="d-flex pt-2 pb-2">
              <div class="custom-control custom-radio flex-grow-1">
                <input (change)="onDeliveryModeChanged()" [id]="'deliveryMode_'+i" [value]="deliveryMode.code"
                  class="custom-control-input" formControlName="deliveryModeCode" type="radio">
                <label [for]="'deliveryMode_'+i" class="custom-control-label">
                  {{deliveryMode.description}}
                  <!-- <i *ngIf="deliveryMode.code === DeliveryModeCodes.SelectedDate"
                    class="app-icon fa fa-calendar-alt ml-1"></i> -->
                </label>
                <div *ngIf="deliveryMode.code === DeliveryModeCodes.SelectedDate &&
                    cartForm.value.deliveryModeCode === deliveryMode.code">
                  <div
                    [class.is-invalid]="cartForm.controls['deliveryDate'].dirty && cartForm.controls['deliveryDate'].invalid"
                    class="form-group mt-1 mb-0" style="max-width: 180px;">
                    <input [id]="'deliveryDate_'+i" class="form-control" formControlName="deliveryDate"
                      min="{{maxDate}}" onkeydown="return false" type="date">
                  </div>
                  <ul *ngIf="cartForm.controls['deliveryDate'].dirty &&
                        cartForm.controls['deliveryDate'].errors &&
                        (cartForm.controls['deliveryDate'].errors['minDate'] ||
                        cartForm.controls['deliveryDate'].errors['onlyWeekDays'])"
                    class="list-unstyled app-invalid-feedback small mt-2 mb-0">
                    <li
                      *ngIf="cartForm.controls['deliveryDate'].errors['minDate'] && cartForm.controls['deliveryDate'].errors['onlyWeekDays']">
                      Please select a date on or after {{maxDate}}
                    </li>
                    <li
                      *ngIf="cartForm.controls['deliveryDate'].errors['minDate'] && !(cartForm.controls['deliveryDate'].errors['onlyWeekDays'])">
                      Please select a date on or after {{maxDate}}
                    </li>
                    <li
                      *ngIf="cartForm.controls['deliveryDate'].errors['onlyWeekDays'] && !(cartForm.controls['deliveryDate'].errors['minDate'])">
                      Please select week days only
                    </li>
                    <i class="app-bottom-arrow"></i>
                  </ul>
                </div>
              </div>
              <div class="ml-3 w600 delivery-cost">
                {{deliveryMode.deliveryCost.formattedValue}}
              </div>
            </div>
          </div>
          <div class="estimated-date mt-2 mb-3">
            <span class="w500"><b class="w800">Estimated Delivery:</b> {{cart?.estimatedDeliveryDate}}</span>
          </div>
          <div class="estimated-date mt-2 mb-3">
            <span class="w500">
              Please note that orders received after 1pm local time may not be shipped until the following day
            </span>
          </div>
          <div class="mt-2 mb-3" *ngIf="showExpeditedShippingMessage">
            <span class="w500">
              <b>Due to high order volume you may experience some delays. Expedited shipping is not available at this time due to increased demand on our shipping partners.</b>
            </span>
          </div>
        </div>

        <!-- <div class="p-3">
        <div class="d-flex align-items-end">
          <h5 class="flex-grow-1">Shipping Address</h5>
          <a [routerLink]="['/profile/add-cart-address']" class="btn btn-primary btn-sm">
            + Add Address
          </a>
        </div>
        <div *ngIf="profileAddresses" class="mt-3">
          <a *ngFor="let address of profileAddresses.addresses;let i=index" [class.is-primary]="address.defaultAddress"
             [class.mt-2]="i!==0" [routerLink]="['/profile/edit-cart-address', address.id]"
             class="d-flex border p-3 align-items-center">
            <div (click)="$event.stopPropagation();"
                 class="custom-control custom-radio flex-grow-1 delivery-address-radio">
              <input (change)="onAddressChanged()" [id]="'deliveryAddress_'+i" [value]="address.id"
                     class="custom-control-input" formControlName="deliveryAddressId" type="radio">
              <label [for]="'deliveryAddress_'+i" class="custom-control-label d-flex flex-column pl-1">
                <span>
                  {{address.line1}}
                  {{address.line2}}
                </span>
                <span>
                  {{address.town}},
                  {{address.region.name}}
                  {{address.postalCode}}
                </span>
              </label>
            </div>
            <div>
              {{address.defaultAddress ? '(Primary)' : ''}}
            </div>
            <i class="fas fa-angle-right ml-3"></i>
          </a>
        </div>
      </div> -->

        <!-- <div class="p-3 mt-3">
        <div class="d-flex align-items-end">
          <h5 class="flex-grow-1">Payment</h5>
          <a [routerLink]="['/profile/add-cart-payment']" class="btn btn-primary btn-sm">
            + Add Payment
          </a>
        </div>
        <div *ngIf="profilePayments" class="mt-3">
          <a *ngFor="let payment of profilePayments.payments;let i=index" [class.is-primary]="payment.defaultPayment"
             [class.mt-2]="i!==0" [routerLink]="['/profile/edit-cart-payment', payment.id]"
             class="d-flex border p-3 align-items-center">
            <div (click)="$event.stopPropagation();" class="custom-control custom-radio flex-grow-1 payment-radio">
              <input (change)="onPaymentChanged()" [id]="'payment_'+i" [value]="payment.id" class="custom-control-input"
                     formControlName="paymentId" type="radio">
              <label [for]="'payment_'+i" class="custom-control-label d-flex align-items-center pl-1">
                <span>
                  <i [class]="payment.cardType.code | cardTypeToImage: 'app-icon app-credit-card'"></i>
                </span>
                <span class="pl-2 d-flex flex-column flex-grow-1">
                  <span>
                    <span class="text-capitalize">{{payment.cardType.code}}</span>
                    {{'Ending in - ' + payment.cardNumber}}
                  </span>
                </span>
              </label>
            </div>
            <div>
              {{payment.defaultPayment ? '(Primary)' : ''}}
            </div>
            <i class="fas fa-angle-right ml-3"></i>
          </a>
          <br/>
          <a (click)="creditHolds()" href="javascript:void(0)" class="ml-3">
            Learn more about credit card holds
          </a>
        </div>
      </div> -->

        <!-- <div *ngIf="deliveryModes && deliveryModes.length" class="p-3 mt-3">
        <h5>
          Estimated shipping times
        </h5>
        <div class="border p-3 mt-3">
          <h6 class="font-weight-light text-black-50">
            Select your shipping speed
          </h6>
          <div class="d-flex flex-column pt-1 pb-1">
            <div *ngFor="let deliveryMode of deliveryModes; let i=index" class="d-flex pt-1 pb-1">
              <div class="custom-control custom-radio flex-grow-1">
                <input (change)="onDeliveryModeChanged()" [id]="'deliveryMode_'+i" [value]="deliveryMode.code"
                       class="custom-control-input" formControlName="deliveryModeCode" type="radio">
                <label [for]="'deliveryMode_'+i" class="custom-control-label">
                  {{deliveryMode.description}}
                  <i *ngIf="deliveryMode.code === DeliveryModeCodes.SelectedDate"
                     class="app-icon fa fa-calendar-alt ml-1"></i>
                </label>
                <div *ngIf="deliveryMode.code === DeliveryModeCodes.SelectedDate &&
                          cartForm.value.deliveryModeCode === deliveryMode.code">
                  <div
                    [class.is-invalid]="cartForm.controls['deliveryDate'].dirty && cartForm.controls['deliveryDate'].invalid"
                    class="form-group mt-1 mb-0">
                    <input [id]="'deliveryDate_'+i" class="form-control" formControlName="deliveryDate"
                           min="{{maxDate}}"
                           onkeydown="return false" type="date">
                  </div>
                  <ul *ngIf="cartForm.controls['deliveryDate'].dirty &&
                             cartForm.controls['deliveryDate'].errors &&
                             (cartForm.controls['deliveryDate'].errors['minDate'] ||
                              cartForm.controls['deliveryDate'].errors['onlyWeekDays'])"
                      class="list-unstyled app-invalid-feedback small mt-2 mb-0">
                    <li
                      *ngIf="cartForm.controls['deliveryDate'].errors['minDate'] && cartForm.controls['deliveryDate'].errors['onlyWeekDays']">
                      Please select a date on or after {{maxDate}}
                    </li>
                    <li
                      *ngIf="cartForm.controls['deliveryDate'].errors['minDate'] && !(cartForm.controls['deliveryDate'].errors['onlyWeekDays'])">
                      Please select a date on or after {{maxDate}}
                    </li>
                    <li
                      *ngIf="cartForm.controls['deliveryDate'].errors['onlyWeekDays'] && !(cartForm.controls['deliveryDate'].errors['minDate'])">
                      Please select week days only
                    </li>
                    <i class="app-bottom-arrow"></i>
                  </ul>
                </div>
              </div>
              <div class="ml-3">
                {{deliveryMode.deliveryCost.formattedValue}}
              </div>
            </div>
          </div>

          <div class="estimated-date mt-2 mb-2">
            <div class="estimated-heading">
              Estimated Delivery:
            </div>
            <div>
              {{cart?.estimatedDeliveryDate}}
            </div>
          </div>

          <hr>
          <div class="estimated-date mt-2 mb-2">
            <span class="font-weight-bold">
              Please note that orders received after 1pm local time may not be shipped until the following day
            </span>
          </div>
        </div>
      </div> -->
      </form>
      <!-- <div class="p-3 mt-3">
      <h5>
        Items in Cart ({{cart?.totalItems}})
      </h5>
      <div class="app-cart-content border mt-3">
        <div *ngFor="let entry of cart?.entries; let i=index" [class.border-bottom]="i!==(cart?.entries.length - 1)"
             class="d-flex pt-5 pb-5 ml-3 mr-3">
          <div class="d-flex flex-column align-items-center">
            <img *ngIf="entry.product.images" class="app-cart-img" src="{{entry.product.images | appImg}}"/>
            <div *ngIf="!entry.product.images" class="app-cart-img text-center">
              <i class="fas fa-image fa-4x"></i>
            </div>
            <div class="text-black-50 small mt-3">
              SKU {{entry.product.code}}
            </div>
          </div>
          <div class="flex-grow-1 d-flex flex-column ml-2">
            <div>
              {{entry.product.name}}
            </div>
            <div class="mt-2">
              <span class="font-weight-bold">Pet: </span>
              <span>{{entry.pet}}</span>
            </div>
            <div class="mt-2">
              <span class="font-weight-bold">Subscription: </span>
              <span>{{entry.subscriptionUnit | appSubscription}}</span>
            </div>
            <div class="mt-2">
              <span class="font-weight-bold">Qty: </span>
              <span>{{entry.quantity}}</span>
            </div>
            <div
              *ngIf="entry.subscriptionUnit && entry.subscriptionUnit.deliveryFrequency !== DeliveryFrequencies.OneTime"
              class="mt-2">
              <span class="font-weight-bold">Price: </span>
              <span class="text-line-through">{{entry.basePrice.formattedValue}}</span>
              <span class="app-title"> ${{(entry.totalPrice.value / entry.quantity | number: '.2-2')}}</span>
            </div>
            <div
              *ngIf="!entry.subscriptionUnit || entry.subscriptionUnit.deliveryFrequency === DeliveryFrequencies.OneTime"
              class="mt-2">
              <span class="font-weight-bold">Price: </span>
              <span>{{entry.basePrice.formattedValue}}</span>
            </div>
            <div class="mt-2">
              <span class="font-weight-bold">Subtotal: </span>
              <span>{{entry.totalPrice.formattedValue}}</span>
            </div>
          </div>
        </div>
      </div>
    </div> -->

      <app-order-summary [isMobile]="isMobile" [order]="cart" [cartForm]="cartForm" class="mt-3">
      </app-order-summary>
      <!-- <div class="font-weight-light ml-3 mr-3">
        <div *ngIf="(cart?.totalSurcharge?.value | number : 0) > 0" class="p-2 mt-2">
          Free shipping excluding Alaska and Hawaii which will incur a surcharge of $1.00/item
        </div>
        <div *ngIf="(cart?.totalSurcharge?.value | number : 0) > 0" class="p-2 ">
          Please note that shipments to Alaska, Hawaii, and some remote areas in the continental US may take additional business days to arrive.
        </div> -->
      <!-- <div class="p-2">
          Shipping rates and discounts are subject to change.
        </div> -->
      <!-- </div> -->
      <!-- <hr/> -->
      <!-- <div *ngIf="cart?.subscriptionLongestDate" class="font-weight-light p-3 ml-3 mr-3 mt-3">
      By clicking <b>"Place Order"</b> below, you accept that <br>
      <span *ngIf="cart?.totalItems > 1 || cart?.subscriptionLongestDate ==='UNLIMITED'">this automatic renewal will
        continue for the term selected unless cancelled</span>
      <span *ngIf="cart?.totalItems == 1 && cart?.subscriptionLongestDate !=='UNLIMITED'">this subscription will
        continue until {{cart?.subscriptionLongestDate}} unless cancelled</span> on HillsToHome.com at least 2 days
      before the orders ships.
      Your card will be charged {{cart?.totalPrice?.formattedValue}} today and the current selling prices at the time of
      future shipments.
    </!-->
      <!-- <div class="p-3 mt-5 mb-5">
      <button (click)="placeOrder()" [disabled]="cartForm.invalid"
              class="btn btn-primary btn-lg font-weight-light w-100">
        Place Order
      </button>
    </div> -->
    </div>
  </div>
</ng-template>

<ng-template #fullLayout>
  <div class="app-full-layout">
    <!-- <div class="bg-light p-4">
        <h2 class="font-weight-bold text-center m-0">
          Place Order
        </h2>
      </div> -->
    <div class="d-flex">
      <div class="flex-grow-1">
        <form (ngSubmit)="placeOrder()" [formGroup]="cartForm">
          <div class="cart-listing shipping-detail-card">
            <div class="d-flex align-items-center py-2">
              <h3 class="flex-grow-1 w800 m-0">Shipping Address</h3>
              <button (click)="openProfileAddress()" class="btn btn-outline-dark btn-md px-3" type="button">
                ADD ADDRESS
              </button>
            </div>
            <div *ngIf="profileAddresses" class="mt-3">
              <div *ngFor="let address of profileAddresses.addresses;let i=index"
                [class.is-primary]="address.defaultAddress" class="d-flex py-3 align-items-center border-top">
                <div class="custom-control custom-radio flex-grow-1 delivery-address-radio">
                  <div class="mb-2 primary-label" *ngIf="address.defaultAddress">
                    Primary
                  </div>
                  <input (change)="onAddressChanged()" [id]="'deliveryAddress_'+i" [value]="address.id"
                    class="custom-control-input" formControlName="deliveryAddressId" type="radio">
                  <label [for]="'deliveryAddress_'+i" class="custom-control-label d-flex flex-column pl-1">
                    <div>
                      {{address.line1}}
                      {{address.line2}}
                    </div>
                    <div>
                      {{address.town}},
                      {{address.region.isocode | appRegion}}
                      {{address.postalCode}}
                    </div>
                  </label>
                </div>
                <!-- <div *ngIf="address.defaultAddress" class="text-center set-primary px-4">
                  PRIMARY
                </div>
                <a (click)="makePrimaryProfileAddress(address)" *ngIf="!address.defaultAddress"
                  class="btn btn-outline-primary px-4" href="javascript:void(0)">
                  PRIMARY
                </a> -->
                <a (click)="confirmDeleteAddress(address)" class="btn btn-outline-primary px-4 ml-3"
                  href="javascript:void(0)" aria-label="Delete item">
                  <i class="fa fa-trash-alt"></i>
                </a>
                <a (click)="openProfileAddress(address)" class="btn btn-outline-primary px-4 ml-3"
                  href="javascript:void(0)" aria-label="Edit item">
                  <i class="fa fa-pencil-alt"></i>
                </a>
              </div>
            </div>
          </div>

          <div class="cart-listing shipping-detail-card">
            <div class="d-flex align-items-center py-2">
              <h3 class="flex-grow-1 w800 m-0">Payment</h3>
              <button (click)="openProfilePayment()" class="btn btn-outline-dark btn-md px-3" type="button">
                ADD PAYMENT
              </button>
            </div>
            <div *ngIf="profilePaymentChangeError" class="alert alert-warning alert-dismissible" role="alert">
              <button (click)="clearPaymentError()" aria-label="Close" class="close" type="button"><span
                  aria-hidden="true">&times;</span></button>
              Unable to add card. Reason - {{profilePaymentChangeError}}
            </div>
            <div *ngIf="profilePayments" class="mt-3">
              <!-- Pet Parent cannot identify if a change in the Primary payment in Cart page is successful as the button color does not change -Rahul Solanki -->
              <div *ngFor="let payment of profilePayments.payments;let i=index"
                class="d-flex border-top py-3 align-items-center">
                <div class="custom-control custom-radio flex-grow-1 payment-radio">
                  <div class="mb-2 primary-label" *ngIf="payment.defaultPaymentInfo">
                    Primary
                  </div>
                  <input (change)="onPaymentChanged()" [id]="'payment_'+i" [value]="payment.id"
                    class="custom-control-input" formControlName="paymentId" type="radio">
                  <label [for]="'payment_'+i" class="custom-control-label d-flex align-items-center pl-1">
                    <div>
                      <!-- <i [class]="payment.cardType.code | cardTypeToImage: 'app-icon app-credit-card fa-2x'"></i> -->
                      <img width="38" [src]="payment.cardType.code | cardTypeToImage" alt="{{payment.cardType.code}} card" />
                    </div>
                    <div class="pl-2 d-flex flex-column flex-grow-1">
                      <div>
                        <span class="text-capitalize">{{payment.cardType.code}}</span>
                        {{'Ending in - ' + payment.cardNumber}}
                      </div>
                    </div>
                  </label>
                </div>
                <!-- <div *ngIf="payment.defaultPaymentInfo" class="text-center set-primary px-4">
                  PRIMARY
                </div>
                <a (click)="confirmMakePrimaryProfilePayment(payment)" *ngIf="!payment.defaultPaymentInfo"
                  class="btn btn-outline-primary px-4" href="javascript:void(0)">
                  PRIMARY
                </a> -->
                <a (click)="confirmDeletePayment(payment)" class="btn btn-outline-primary px-4 ml-3"
                  href="javascript:void(0)" aria-label="Delete item">
                  <i class="fa fa-trash-alt"></i>
                </a>
              </div>
              <!-- End -->
              <!-- <br/>
                <a (click)="creditHolds()" href="javascript:void(0)" class="w-15">
                  Learn more about credit card holds
                </a> -->
            </div>
          </div>

          <div class="cart-listing shipping-detail-card pb-2">
            <h3 class="w800 mt-3">
              Estimated Shipping Time
              <span>(Select your shipping speed)</span>
            </h3>
            <div class="d-flex flex-column pt-1 pb-1">
              <div *ngFor="let deliveryMode of deliveryModes; let i=index" class="d-flex pt-3 pb-2">
                <div class="custom-control custom-radio flex-grow-1">
                  <input (change)="onDeliveryModeChanged()" [id]="'deliveryMode_'+i" [value]="deliveryMode.code"
                    class="custom-control-input" formControlName="deliveryModeCode" type="radio">
                  <label [for]="'deliveryMode_'+i" class="custom-control-label">
                    {{deliveryMode.description}}
                    <!-- <i *ngIf="deliveryMode.code === DeliveryModeCodes.SelectedDate"
                      class="app-icon fa fa-calendar-alt ml-1"></i> -->
                  </label>
                  <div *ngIf="deliveryMode.code === DeliveryModeCodes.SelectedDate &&
                          cartForm.value.deliveryModeCode === deliveryMode.code">
                    <div
                      [class.is-invalid]="cartForm.controls['deliveryDate'].dirty && cartForm.controls['deliveryDate'].invalid"
                      class="form-group mt-1 mb-0" style="max-width: 180px;">
                      <input [id]="'deliveryDate_'+i" class="form-control" formControlName="deliveryDate"
                        min="{{maxDate}}" onkeydown="return false" type="date">
                    </div>
                    <ul *ngIf="cartForm.controls['deliveryDate'].dirty &&
                             cartForm.controls['deliveryDate'].errors &&
                             (cartForm.controls['deliveryDate'].errors['minDate'] ||
                              cartForm.controls['deliveryDate'].errors['onlyWeekDays'])"
                      class="list-unstyled app-invalid-feedback small mt-2 mb-0">
                      <li
                        *ngIf="cartForm.controls['deliveryDate'].errors['minDate'] && cartForm.controls['deliveryDate'].errors['onlyWeekDays']">
                        Please select a date on or after {{maxDate}}
                      </li>
                      <li
                        *ngIf="cartForm.controls['deliveryDate'].errors['minDate'] && !(cartForm.controls['deliveryDate'].errors['onlyWeekDays'])">
                        Please select a date on or after {{maxDate}}
                      </li>
                      <li
                        *ngIf="cartForm.controls['deliveryDate'].errors['onlyWeekDays'] && !(cartForm.controls['deliveryDate'].errors['minDate'])">
                        Please select week days only
                      </li>
                      <i class="app-bottom-arrow"></i>
                    </ul>
                  </div>
                </div>
                <div class="ml-3 w600 delivery-cost">
                  {{deliveryMode.deliveryCost.formattedValue}}
                </div>
              </div>
            </div>
            <div class="estimated-date mt-2 mb-3">
              <span class="w500"><b class="w800">Estimated Delivery:</b> {{cart?.estimatedDeliveryDate}}</span>
            </div>
            <!--Note added-->

            <div class="estimated-date mt-2 mb-4">
              <span class="w500">
                Please note that orders received after 1pm local time may not be shipped until the following day
              </span>
            </div>
            <div class="mt-2 mb-4" *ngIf="showExpeditedShippingMessage">
              <span class="w500">
                <b>Due to high order volume you may experience some delays. Expedited shipping is not available at this time due to increased demand on our shipping partners.</b>
              </span>
            </div>

          </div>
        </form>

        <!-- <div class="p-3 mt-3">
            <h5>
              Products in your cart
            </h5>
            <div class="border pl-3 pr-3 mt-3">
              <div *ngFor="let entry of cart?.entries; let i=index"
                   [class.border-bottom]="i!==(cart?.entries.length - 1)"
                   class="d-flex pl-3 pr-3 pt-5 pb-5">
                <div class="d-flex flex-column align-items-center">
                  <img *ngIf="entry.product.images" class="app-cart-img"
                       src="{{entry.product.images | appImg:'lrg'}}"/>
                  <div *ngIf="!entry.product.images" class="app-cart-img text-center">
                    <i class="fas fa-image fa-4x"></i>
                  </div>
                  <div class="text-black-50 small mt-3">
                    SKU {{entry.product.code}}
                  </div>
                </div>
                <div class="flex-grow-1 d-flex flex-column ml-3">
                  <div>
                    {{entry.product.name}}
                  </div>
                  <div class="d-flex justify-content-between mt-3">
                    <div>
                      <div class="font-weight-bold">Pet</div>
                      <div>{{entry.pet}}</div>
                    </div>
                    <div>
                      <div class="font-weight-bold">Qty</div>
                      <div>{{entry.quantity}}</div>
                    </div>
                    <div>
                      <div class="font-weight-bold">Price</div>
                      <div
                        *ngIf="entry.subscriptionUnit && entry.subscriptionUnit.deliveryFrequency !== DeliveryFrequencies.OneTime">
                        <span class="text-line-through">{{entry.basePrice.formattedValue}}</span>
                        <span class="app-title"> ${{(entry.totalPrice.value / entry.quantity | number: '.2-2')}}</span>
                      </div>
                      <div
                        *ngIf="!entry.subscriptionUnit || entry.subscriptionUnit.deliveryFrequency === DeliveryFrequencies.OneTime">
                        {{entry.basePrice.formattedValue}}
                      </div>
                    </div>
                    <div>
                      <div class="font-weight-bold">Subtotal</div>
                      <div>{{entry.totalPrice.formattedValue}}</div>
                    </div>
                  </div>
                  <div class="mt-4">
                    <div class="font-weight-bold">Subscription</div>
                    <div>{{entry.subscriptionUnit | appSubscription}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
      </div>
      <!-- <div class="w-35 pl-3 pt-3">
          <app-order-summary [isMobile]="isMobile" [order]="cart">
          </app-order-summary>
          <div class="app-after-summary pl-3 pt-2 text-center small">
              <div *ngIf="(cart?.totalSurcharge?.value | number : 0) > 0" class="pt-2 pb-2 pr-3 ">
                Free shipping excluding Alaska and Hawaii which will incur a surcharge of $1.00/item
              </div>
              <div *ngIf="(cart?.totalSurcharge?.value | number : 0) > 0" class="pb-2 pr-3 ">
                Please note that shipments to Alaska, Hawaii, and some remote areas in the continental US may take additional business days to arrive.
              </div>
              <div class="pr-3 pb-2">
                Shipping rates and discounts are subject to change.
              </div>
          </div>
          <div class="app-after-summary pt-2 pb-2">
            <div *ngIf="cart?.subscriptionLongestDate" class="text-center small pt-4 pb-4 pl-3 pr-3">
              By clicking <b>"Place Order"</b> below, you accept that
              <span *ngIf="cart?.totalItems > 1 || cart?.subscriptionLongestDate ==='UNLIMITED'">this automatic renewal
                will continue for the term selected unless cancelled</span>
              <span *ngIf="cart?.totalItems == 1 && cart?.subscriptionLongestDate !=='UNLIMITED'">this subscription will
                continue until {{cart?.subscriptionLongestDate}} unless cancelled</span>
              on HillsToHome.com at least 2 days before the orders
              ships. Your card will be charged {{cart?.totalPrice?.formattedValue}} today and the current selling prices
              at the time of future shipments.
            </div>
            <div class="p-3">
              <button (click)="placeOrder()" [disabled]="cartForm.invalid"
                      class="btn btn-primary font-weight-light w-100">
                Place Order
              </button>
            </div>
          </div>
        </div> -->
    </div>
  </div>

</ng-template>

<ng-template #editAddressModal let-closeTerms="close" let-dismissTerms="dismiss">
  <div class="modal-header">
    <div class="flex-grow-1 text-center">
      <h4 class="modal-title">{{profileAddress.id ? 'Edit':'Add'}} Address</h4>
    </div>
    <button (click)="dismissTerms()" aria-label="Close" class="close" type="button">
      <i class="fa fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <app-edit-address #editAddress (submitAddress)="onSubmitSuggestedAddress($event)"
      (submitted)="onSubmitProfileAddress($event)" [address]="profileAddress" [isCart]="false" [isMobile]="isMobile"
      [profileAddressChangeError]="profileAddressChangeError" [profileCorrectedAddress]="profileCorrectedAddress">
    </app-edit-address>
  </div>
  <div class="modal-footer">

    <button (click)="editAddress.save();"
      [disabled]="editAddress.profileForm.pristine || editAddress.profileForm.invalid || editAddress.profileForm.disabled"
      class="btn btn-primary pl-5 pr-5 btn-lg ml-2" type="button">
      Save
    </button>
    <!-- <button (click)="dismissTerms()" class="btn btn-secondary pl-5 pr-5 btn-lg" type="button">
      Cancel
    </button> -->
  </div>
</ng-template>


<ng-template #confirmDeleteAddressModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-body">

    <button (click)="dismissConfirm()" aria-label="Close" class="close" type="button">
      <i class="fa fa-times"></i>
    </button>

    <p class="m-4 h4 text-center">
      Are you sure you want to delete address {{profileAddress.line1}} {{profileAddress.line2}}?
    </p>
  </div>
  <div class="modal-footer">

    <button (click)="closeConfirm()" class="btn btn-primary pl-5 pr-5 btn-lg ml-2" type="button" ngbAutofocus>
      I'm Sure
    </button>
    <button (click)="dismissConfirm()" class="btn btn-secondary pl-5 pr-5 btn-lg" type="button">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #alertDeleteAddressModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-header">
    <div class="flex-grow-1">
      <h4 class="modal-title text-center">Delete Address</h4>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="dismissConfirm()">
      <i class="fa fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <p class="m-4 h5 text-center">
      Please add a new address before removing this address.
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary pl-5 pr-5 btn-lg" (click)="dismissConfirm()" ngbAutofocus>
      Ok
    </button>
  </div>
</ng-template>

<ng-template #editPaymentModal let-closeTerms="close" let-dismissTerms="dismiss">
  <div class="modal-header">
    <div class="flex-grow-1 text-center">
      <h4 class="modal-title">{{profilePayment?.id ? 'Edit' : 'Add'}} Payment</h4>
    </div>
    <button (click)="dismissTerms()" aria-label="Close" class="close" type="button">
      <i class="fa fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <app-edit-payment #editPayment (submitted)="onSubmitProfilePayment($event)"
      [changeError]="profilePaymentChangeError" [isCart]="false" [isMobile]="isMobile" [payment]="profilePayment">
    </app-edit-payment>
  </div>
  <div class="modal-footer">

    <button (click)="editPayment.save(!profilePayment?.id);" class="btn btn-primary pl-5 pr-5 btn-lg ml-2"
      type="button">
      Save
    </button>
    <!-- <button (click)="dismissTerms()" class="btn btn-secondary pl-5 pr-5 btn-lg" type="button">
      Cancel
    </button> -->
  </div>
</ng-template>


<ng-template #confirmDeletePaymentModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-body">

    <button (click)="dismissConfirm()" aria-label="Close" class="close" type="button">
      <i class="fa fa-times"></i>
    </button>

    <p class="m-4 h4 text-center">
      Are you sure you want to delete credit card ending in - {{profilePayment.cardNumber}}?
    </p>
  </div>
  <div class="modal-footer">

    <button (click)="closeConfirm()" class="btn btn-primary pl-5 pr-5 btn-lg ml-2" type="button" ngbAutofocus>
      I'm Sure
    </button>
    <button (click)="dismissConfirm()" class="btn btn-secondary pl-5 pr-5 btn-lg" type="button">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #alertDeletePaymentModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-header">
    <div class="flex-grow-1">
      <h4 class="modal-title text-center">Delete Payment Method</h4>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="dismissConfirm()">
      <i class="fa fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <p class="m-4 h5 text-center">
      Please add a new payment method before removing this one.
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary pl-5 pr-5 btn-lg" (click)="dismissConfirm()" ngbAutofocus>
      Ok
    </button>
  </div>
</ng-template>

<ng-template #creditCardHolds let-closeSuccess="close" let-dismissSuccess="dismiss">
  <div class="modal-header">
    <div class="flex-grow-1 text-center">
      <h4 class="modal-title">Credit Card Holds</h4>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="dismissSuccess()">
      <i class="fa fa-times"></i>
    </button>
  </div>
  <div class="modal-body text-center">
    <!--    <i class="mt-4 far fa-check-circle fa-6x text-white"></i>-->
    <p class="m-2 text-center">
      Please be aware that your bank may place an authorization hold on your account for an amount including estimated
      tax.
      This hold may last up to 7 days based on the practices of your bank.
      When your Hill's to Home order ships, you will see a charge on your account for the either the same amount as the
      hold or an adjusted amount due to the final tax calculation.
      In some cases, your bank may not immediately release the authorization hold even after the actual charge is
      processed, but the hold should be released within a 7 day window.
      If you have any questions about your bank's hold policy, please call the number on the back of your credit card.
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary pl-5 pr-5 btn-lg ml-2" (click)="closeSuccess()">
      OK
    </button>
  </div>
</ng-template>

<ng-template #paymentErrorModal let-closeConfirm="close" let-dismissSuccess="dismiss">
  <div class="modal-header">
    <div class="flex-grow-1 text-center">
      <h4 class="modal-title">Card Authorization Failed</h4>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="dismissSuccess()">
      <i class="fa fa-times"></i>
    </button>
  </div>
  <div class="modal-body text-center">
    <p class="m-2 text-center">
      {{ unhandledError }}
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary pl-5 pr-5 btn-lg ml-2" (click)="dismissSuccess()" ngbAutofocus>
      OK
    </button>
  </div>
</ng-template>

<ng-template #confirmMakePrimaryPaymentModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-body">
    <button (click)="dismissConfirm()" aria-label="Close" class="close" type="button">
      <i class="fa fa-times"></i>
    </button>
    <p class="m-4 h4 text-center">
      Are you sure you would like to make the {{profilePayment.cardType.code.toUpperCase()}} credit card ending in {{profilePayment.cardNumber}} the payment selection for all future orders on the account?
    </p>
  </div>
  <div class="modal-footer">
    <button (click)="closeConfirm()" class="btn btn-primary pl-5 pr-5 btn-lg ml-2" type="button" ngbAutofocus>
      I'm Sure
    </button>
    <button (click)="dismissConfirm()" class="btn btn-secondary pl-5 pr-5 btn-lg" type="button">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #successPrimaryProfilePaymentModal let-closeSuccess="close" let-dismissSuccess="dismiss">
  <div class="modal-body text-center">
    <i class="mt-4 far fa-check-circle fa-6x txt-success"></i>
    <p class="m-4 h4 text-center">
      Your {{profilePayment.cardType.code.toUpperCase()}} credit card ending in {{profilePayment.cardNumber}} is now updated to be the payment selection for all future orders on the account.
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary pl-5 pr-5 btn-lg ml-2" (click)="closeSuccess()">
      OK
    </button>
  </div>
</ng-template>