import { Component, OnDestroy, OnInit, HostBinding } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromRoot from '../../../reducers';
import { tap } from 'rxjs/operators';
import * as fromSupport from '../../../support/reducers';
import * as fromCore from '../../../core/reducers';
import * as SupportActions from '../../actions/support.actions';
import { ContactUsDetail } from '../../models/contact-us-detail';
import * as fromAuth from '../../../auth/reducers';
import { AppAnimate, fade } from '../../../shared/animations/common.animations';

@Component({
    selector: 'app-contact-us-page',
    template: `
      <app-contact-us
        [isContactUsEmailSent]="isContactUsEmailSent$ | async"
        [pending]="pending$ | async"
        [errorMessage]="error$ | async"
        [isMobile]="isMobile$ | async"
        [isLoggedIn] = "isLoggedIn$ | async"
        (contactUsSubmitted)="onContactUsDetailSubmit($event)">
      </app-contact-us>
    `,
    styles: []
  })

export class ContactUsPageComponent implements  OnInit, OnDestroy {

    isContactUsEmailSent$ = this.store.pipe(select(fromSupport.getContactUsEmail));

    pending$ = this.store.pipe(select(fromSupport.getContactUsEmailPending));

    error$ = this.store.pipe(select(fromSupport.getContactUsEmailError));

    isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

    isLoggedIn$ = this.store.pipe(select(fromAuth.getIsLoggedIn));

    subscriptions: Subscription[] = [];

    constructor(private store: Store<fromSupport.State>) {
    }

    ngOnInit() {
        this.subscriptions.push(
          this.isContactUsEmailSent$
            .pipe(
              tap((isContactUsEmailSent: any) => {
                if (isContactUsEmailSent) {
                  console.log('Contact Email Sent');
                }
              })
            )
            .subscribe()
        );
      }
      
      ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
      }

      onContactUsDetailSubmit(contactusdetail: ContactUsDetail) {
        this.store.dispatch(new SupportActions.ContactUsEmail(contactusdetail));
      }
}
