import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromCore from '../../../core/reducers';


@Component({
  selector: 'app-not-linked-message-page',
  template: `<app-not-linked-message [isMobile]="isMobile$ | async"> </app-not-linked-message>`
})
export class NotLinkedMessagePageComponent implements OnInit {

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  constructor(private store: Store<fromCore.State>) {
  }

  ngOnInit() {
  }

}

