import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as fromAuth from '../../reducers';
import * as fromCore from '../../../core/reducers';
import * as AuthActions from '../../actions/auth.actions';
import { PetParentDetail } from '../../models/pet-parent-detail';

@Component({
    selector: 'app-register-pet-parent-page',
    template: `
      <app-register-pet-parent
        [isPetParentRegistered]="isPetParentRegistered$ | async"
        [pending]="pending$ | async"
        [errorMessage]="error$ | async"
        [isMobile]="isMobile$ | async"
        (submitted)="onSubmit($event)">
      </app-register-pet-parent>
    `,
    styles: []
  })

export class RegisterPetParentPageComponent implements  OnInit, OnDestroy {

    isPetParentRegistered$ = this.store.pipe(select(fromAuth.getRegisterPetParent));

    pending$ = this.store.pipe(select(fromAuth.getRegisterPetParentPending));

    error$ = this.store.pipe(select(fromAuth.getRegisterPetParentError));

    isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

    subscriptions: Subscription[] = [];

    constructor(private store: Store<fromAuth.State>) {}

    ngOnInit() {
        this.store.dispatch(new AuthActions.GetCSRFToken());
        this.subscriptions.push(
          this.isPetParentRegistered$
            .pipe(
              tap((isPetParentRegistered: any) => {
                if (isPetParentRegistered) {
                  console.log('Registation Success');
                }
              })
            )
            .subscribe()
        );
      }
      ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
      }
      onSubmit(authenticate: PetParentDetail) {
        this.store.dispatch(new AuthActions.RegisterPetParent(authenticate));
      }
}
