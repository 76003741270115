export interface Faq {
  question: string;
  answer: string;
}

export interface subList {          // Support - sub list of each support topic
  name: string;
  code: string;
}
export interface supportList {      // Support - main list
  name: string;
  code?: string;                    //for routing
  subList: Array<subList>;
}

export const programPolicy: subList[] = [
  {
    name: "General Program Information",
    code: "general-program-information"
  },
  {
    name: "Sign Up for Hill\'s to Home",
    code: "sign-up"
  },
  {
    name: "General Shipping Information",
    code: "general-shipping-information"
  },
  {
    name: "Promo Code Inquiry",
    code: "promo-code-inquiry"
  }
];

export const registrationLogin: subList[] = [
  {
    name: "Registration Instructions",
    code: "registration-instructions"
  },
  {
    name: "Registration Email Not Received/Lost",
    code: "registration-email-issue"
  },
  {
    name: "Forgot/Reset Password",
    code: "forgot-reset-password"
  },
  {
    name: "Password Error/Locked Out",
    code: "error-lock-password"
  }
];

export const myAccount: subList[] = [
  {
    name: "Update Registered Email Address",
    code: "update-registered-email-address"
  },
  {
    name: "Update, Delete, or Add Shipping Address",
    code: "update-delete-add-shipping-address"
  },
  {
    name: "Set or Change Primary Shipping Address",
    code: "set-change-primary-shipping-address"
  },
  {
    name: "Add or Change Payment Method",
    code: "add-change-payment-method"
  }
];

export const petProfile: subList[] = [
  {
    name: "Add Pet Profile",
    code:"add-pet-profile"
  },
  {
    name: "Change/Update Pet\'s Name",
    code: "change-update-pet-name"
  },
  {
    name: "Shop Plan",
    code: "shop-plan"
  },
  {
    name: "Incorrect Prescription Diet Product Listed",
    code: "incorrect-prescription-diet-product-listed"
  },
  {
    name: "Missing Prescription Diet Nutrition Plan",
    code: "missing-prescription-diet-nutrition-plan"
  },
  {
    name: "Nutrition Plan: Ended or Expiring Soon",
    code: "nutrition-plan-ended-expiring-soon"
  }
];

export const order: subList[] = [
  {
    name: "New Order/One Time Shipment",
    code: "new-order-one-time-shipment",
  },
  {
    name: "Cancel Order",
    code: "cancel-order"
  },
  {
    name: "Skip Next Order",
    code: "skip-next-order"
  },
  {
    name: "Track Order",
    code: "track-order"
  },
  {
    name: "Order Delayed or Missing",
    code: "order-delayed-missing"
  },
  {
    name: "Change Next Scheduled Order Date",
    code: "change-next-scheduled-order-date"
  },
  {
    name: "Unexpected Order Inquiry",
    code: "unexpected-order-inquiry"
  },
  {
    name: "Order vs Subscription",
    code: "Order vs Subscription"
  }
];

export const subscriptions: subList[] = [
  {
    name: "New Subscription/Auto-Shipments",
    code: "new-subscription-auto-shipments"
  },
  {
    name: "Cancel Subscription",
    code: "cancel-subscription"
  },
  {
    name: "Change Subscription Frequency",
    code: "change-subscription-requency"
  },
  {
    name: "Add or Change Subscription Product & Quantities",
    code: "add-change-subscription-product-quantities"
  },
  {
    name: "Subscription End Dates",
    code: "subscription-end-dates"
  }
];

export const returnRefunds: subList[] = [
  {
    name: "Return Requests & Labels",
    code: "return-request"
  },
  {
    name: "100% Satisfaction Guarantee/Return Policy",
    code: "return-policy"
  },
  {
    name: "Return/Refund Status Inquiry",
    code: "refund-return-status"
  }
];

export const billing: subList[] = [
  {
    name: "Authorization Hold Inquiry",
    code: "authorization-hold-issue"

  },
  {
    name: "Multiple Authorization Holds and/or Charges",
    code: "multiple-authorization-issue"
  },
  {
    name: "State Retail Delivery Fee",
    code: "state-retail-delivery"
  },
  {
    name: "Estimated Tax & Final Charges",
    code: "tax-final-charge"
  },
  {
    name: "Tax Dispute",
    code: "tax-dispute"
  },
  {
    name: "Rebate Inquiry & Status Check",
    code: "rebate-inquiry"
  },
  {
    name: "Promotions & Discounts Inquiry or Dispute",
    code: "promotion-inquiry"
  },
  {
    name: "Pricing Inquiry or Dispute",
    code: "pricing-inquiry"
  },
  {
    name: "Invoice Request",
    code: "invoive-request"
  },
  {
    name: "Shipping Fee Inquiry & Dispute",
    code: "shipping-dispute"
  }
];

export const feedback: subList[] = [
  {
    name: "Share My Feedback",
    code: "share-my-feedback"
  }
];

export const technical: subList[] = [
  {
    name: "Technical Assistance",
    code: "technical-assistance"
  }
];

export const Default: subList[] = [
  {
    name: "Select Sub Topic",
    code: "select-sub-topic"
  }
];

export const supportList: supportList[] = [    
  //Main Support List
 {
    name: "Select Topic",
    code: "Default",
    subList: Default
},
 {
  name: "Program or Policy",
  code: "program-policy",
  subList: programPolicy
 },
 {
  name: "Registration & Log In",
  code: "registration-login",
  subList: registrationLogin
 },
 {
  name: "My Account",
  code: "my-account",
  subList: myAccount
 },
 {
  name: "Pet Profile & Nutrition Plan",
  code: "pet-profile",
  subList: petProfile
 },
 {
  name: "Order",
  code: "order",
  subList: order
 },
 {
  name: "Subscriptions",
  code: "subscriptions",
  subList: subscriptions
 },
 {
  name: "Returns & Refunds",
  code: "returns-refunds",
  subList: returnRefunds
 },
 {
  name: "Billing",
  code: "billing",
  subList: billing
 },
 {
  name: "Feedback",
  code: "feedback",
  subList: feedback
 },
 {
  name: "Technical",
  code: "technical",
  subList: technical
 }
];

export const whatsNewSupportInfo = {
    code: 'whats-new',
    name: `What's New`,
    subList: null
};
