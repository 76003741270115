import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';

import * as ProfileActions from '../../actions/profile.actions';
import * as CoreActions from '../../../core/actions/core.actions'
import * as fromCore from '../../../core/reducers';
import * as fromProfile from '../../reducers';
import { Address } from '../../../api';
import { flyOutIn } from '../../../shared/animations/common.animations';

@Component({
  selector: 'app-edit-address-page',
  template: `
    <app-edit-address
      [isCart]="isCart"
      [isMobile]="isMobile$ | async"
      [changePending]="changePending$ | async"
      [removalPending]="removalPending$ | async"
      [profileAddressChangeError]="profileAddressChangeError$ | async"
      [profileCorrectedAddress]="profileCorrectedAddress$ | async"
      [profileAddresses]="profileAddresses$ | async"
      [address]="address$ | async"
      (submitted)="onSubmit($event)"
      (submitAddress)="onSubmitSuggestedAddress($event)"
      (removed)="onRemove($event)"
      (navigatedBack)="onNavigateBack()">
    </app-edit-address>
  `,
  styles: [],
  animations: [flyOutIn]
})
export class EditAddressPageComponent implements OnInit, OnDestroy {

  @HostBinding('@flyOutIn')
  flyOutIn = 'in';

  // @HostBinding('class.app-animated-page')
  // animatedPage = true;

  subscriptions: Subscription[] = [];

  isCart = false;

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  changePending$ = this.store.pipe(select(fromProfile.getProfileAddressChangePending));

  removalPending$ = this.store.pipe(select(fromProfile.getProfileAddressRemovalPending));

  address$ = this.store.pipe(select(fromProfile.getProfileAddress));

  profileAddresses$ = this.store.pipe(select(fromProfile.getProfileAddresses));

  profileAddressChangeError$ = this.store.pipe(select(fromProfile.getProfileAddressChangeError));

  profileCorrectedAddress$ = this.store.pipe(select(fromProfile.getProfileCorrectedAddress));

  constructor(private store: Store<fromProfile.State>, private route: ActivatedRoute) {
    this.subscriptions.push(
      route.params
        .subscribe(params => {
          const id = params['id'];
          if (id) {
            this.store.dispatch(new ProfileActions.GetAddress(id));
          } else {
            this.store.dispatch(new ProfileActions.NewAddress());
          }
        })
    );

    this.subscriptions.push(
      route.data
        .subscribe(params => {
          this.isCart = params['fromCart'];
        })
    );
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
  }

  onSubmit(address: Address) {
    //mobile side save button
    this.store.dispatch(new ProfileActions.CheckAddress(address));
  }

  onRemove(address: Address) {
    if (address.id) {
      this.store.dispatch(new ProfileActions.DeleteAddress(address.id));
    }
  }

  onSubmitSuggestedAddress(address)
  {
    if (address.id) {
      this.store.dispatch(new ProfileActions.UpdateAddress(address.id, address));
    } else {
      this.store.dispatch(new ProfileActions.AddAddress(address));
    }
  }

  onNavigateBack() {
    this.store.dispatch(new CoreActions.NavigateBack(true));
  }

}
