import { Pipe, PipeTransform } from '@angular/core';
import { SubscriptionUnit } from '../../../api';
import { deliveryDurations, DeliveryFrequencies, deliveryFrequencies } from '../../constants/payment';

@Pipe({
  name: 'appSubscription'
})
export class SubscriptionPipe implements PipeTransform {

  transform(value: SubscriptionUnit, args?: any): any {
    let subscriptionStr = '';
    if (value) {
      const deliveryFrequency = deliveryFrequencies.find(df => df.code === value.deliveryFrequency);
      if (deliveryFrequency) {
        subscriptionStr = deliveryFrequency.name;
      }

      if (deliveryFrequency && deliveryFrequency.code !== DeliveryFrequencies.OneTime) {
        const deliveryDuration = deliveryDurations.find(df => df.code === value.duration);

        //Remove 'Every' from the value
        subscriptionStr = subscriptionStr.replace (/Every/g, "");
        subscriptionStr = subscriptionStr.trim();

        subscriptionStr += ' for ';
        if (deliveryDuration) {
          subscriptionStr += deliveryDuration.name;
        }
      }
    }

    return subscriptionStr;
  }
}
