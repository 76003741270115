import { Pipe, PipeTransform } from '@angular/core';;
import { deliveryDurations } from '../../constants/payment';

@Pipe({
  name: 'appSubscriptionLength'
})
export class SubscriptionLengthPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    let subscriptionLen = '';
    if (value) {
      const durationStatus = deliveryDurations.find(os => os.code.toLowerCase() === value.toLowerCase());
      if (durationStatus) {
        subscriptionLen = durationStatus.name;
      }
    }
    return subscriptionLen;
  }
}
