import { AbstractControl } from '@angular/forms';

export class EmailValidation {

  static matchEmail(control: AbstractControl,
                    newEmailName: string = 'newEmail',
                    confirmEmailName: string = 'confirmEmail') {

    const newEmail = control.get(newEmailName).value; // to get value in input tag
    const confirmEmail = control.get(confirmEmailName).value; // to get value in input tag

    if (newEmail !== confirmEmail) {
      control.get(confirmEmailName).setErrors({ matchEmail: true });
    }
  }
}
