import {Component, OnInit, Output, EventEmitter, ViewChild, Input, SimpleChanges, SimpleChange} from '@angular/core';
import { environment } from '../../../../environments/environment';
import {  Router, ActivatedRoute} from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import * as fromCore from "../../../core/reducers";
import { ContactUsDetail } from '../../models/contact-us-detail';
import { supportList, subList } from 'src/app/shared/constants/faq';
import { checkTopicValidator } from './contact-us-validators';
import { SupportService } from 'src/app/api/api/support.service';
import * as SupportActions from '../../actions/support.actions';
import * as fromAuth from '../../../auth/reducers';
import * as fromProfile from '../../../../app/profile/reducers'
import { Patterns } from 'src/app/shared/validation/patterns';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  supportList: Array<supportList> = supportList; 
  subTopicList: Array<subList>;
  contactUsForm: UntypedFormGroup;
  fullName: String;
  siteKey: string = environment.google_captcha_site_key;
  showErrorMessage: Boolean = false;
  
  @Input()
  pending: boolean | false;

  @Input()
  isContactUsEmailSent: boolean | null;

  @Input()
  errorMessage: string | null;

  @Input()
  isMobile: boolean | false;

  @Input()
  isLoggedIn: boolean | false;

  @Input() userProfile: any | null
  
  @Output() contactUsSubmitted = new EventEmitter<ContactUsDetail>();
  
  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  isLoggedIn$ = this.store.pipe(select(fromAuth.getIsLoggedIn));

  userProfile$ = this.store.pipe(select(fromProfile.getProfile));

  constructor(private route: ActivatedRoute,  private router: Router, private formBuilder: UntypedFormBuilder, private supportService: SupportService, private modalService: NgbModal, private store: Store<fromCore.State>) {
    this.createForm();
    this.isMobile$.subscribe(value => {
      this.isMobile=value;
    })
    this.isLoggedIn$.subscribe(value => {
      this.isLoggedIn=value;
    })
    this.userProfile$.subscribe(value => {
      this.userProfile=value;
    })
}

createForm() {
  this.contactUsForm = this.formBuilder.group({
    fullName: ['', Validators.required],
    emailAddress: ['', {validators: [Validators.required, Validators.pattern(Patterns.emailPattern)]}],
    topic: ['', [Validators.required,checkTopicValidator]],
    subTopic: ['', [Validators.required,checkTopicValidator]],
    question: ['', Validators.required],
     recaptchaReactive: [null, {
       validators: [Validators.required],
     }],
  });
}
  ngOnInit() {
    
    if(this.isLoggedIn && this.userProfile!=null){
      this.fullName = this.userProfile.firstName +" "+ this.userProfile.lastName;
      this.contactUsForm.controls['fullName'].setValue(this.fullName,{onlySelf: true});
      this.contactUsForm.controls['emailAddress'].setValue(this.userProfile.emailAddress,{onlySelf: true});
    }
    this.subTopicList = this.supportList[0].subList;
    this.contactUsForm.controls['subTopic'].setValue(this.supportList[0].subList[0].code, {onlySelf: false});
    this.contactUsForm.controls['topic'].setValue(this.supportList[0].code, {onlySelf: false});
  }

  get f() { return this.contactUsForm.controls; }

  submitContactUs() {
      if (this.contactUsForm.valid) {
        const model = this.contactUsForm.value;
        const contactusdetail: ContactUsDetail = {
          fullName : model.fullName,
          emailAddress: model.emailAddress,
          question: model.question,
          supportTopic:{
            code: model.topic,
            desc: this.getTopicName(model.topic)
          },
          subTopic : {
            code: model.subTopic,
            desc: this.getSubTopicName(model.subTopic)
          },
        }
        this.contactUsSubmitted.emit(contactusdetail);
      }else{
        this.showErrorMessage = true;
      }
  }

  getTopicName(topicCode: string){
    for (let entry of supportList) {
      if(entry.code == topicCode){
         return entry.name;
       }
    }
  }
  getSubTopicName(subTopicCode: string){
    for (let entry of this.subTopicList) {
      if(entry.code == subTopicCode){
        this.contactUsForm.controls['subTopic'].setValue(this.subTopicList[0].code, {onlySelf: true});
         return entry.name;
       }
    }
  }
  //Google Captcha Resolved
  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  // Google Captcha Expired
  handleCaptchaExpiry() {
    grecaptcha.reset();
  }

  onTopicChanged(topicCode: string) {
    console.log("On Change");
    console.log(topicCode);
    for (let entry of supportList) {
       if(entry.code == topicCode){
          this.subTopicList = entry.subList;
          this.contactUsForm.controls['subTopic'].setValue(this.subTopicList[0].code, {onlySelf: true});
        }
     }
  }

  gotoLogin() {
    this.router.navigate(['/login']);
  }

  openUrl() {
    window.open('https://www.hillspet.com/', '_blank');
  }
}
