<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>

<ng-template #mobileLayout>

  <div *ngIf="upcomingOrders?.length" class="msg-bar mb-2 mt-1 px-3">
    <div class="d-flex align-items-center justify-content-center">
      <p *ngIf="upcomingOrders?.length > 1">You have multiple orders arriving soon!</p>
      <p *ngIf="upcomingOrders?.length == 1">Your order will arrive soon!</p>

      <a *ngIf="upcomingOrders?.length > 1" (click)="openEditDeliveryDateModal()" class="msg-btn">Edit Delivery
        Dates</a>
      <a *ngIf="upcomingOrders?.length == 1" (click)="openEditDeliveryDateModal()" class="msg-btn">Edit Delivery
        Date</a>
    </div>
  </div>


</ng-template>

<ng-template #fullLayout>

  <div *ngIf="upcomingOrders?.length" class="msg-bar">
    <div class="container d-flex align-items-center justify-content-center">
      <i class="ficon-delivery"></i>
      <p *ngIf="upcomingOrders?.length > 1">You have multiple orders arriving soon!</p>
      <p *ngIf="upcomingOrders?.length == 1">Your order will arrive soon!</p>
      <a *ngIf="upcomingOrders?.length > 1" (click)="openEditDeliveryDateModal()" class="msg-btn">Edit Delivery
        Dates</a>
      <a *ngIf="upcomingOrders?.length == 1" (click)="openEditDeliveryDateModal()" class="msg-btn">Edit Delivery
        Date</a>
    </div>
  </div>

</ng-template>

<ng-template #EditDeliveryDateModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-body p-0">
    <form [formGroup]="nextDeliveryDateSelectionform" autocomplete="off">
      <div *ngIf="showFirstScreen">
        <div class="modal-header">
          <div class="flex-grow-1 text-center">
            <h4 class="modal-title">Edit delivery date</h4>
          </div>
          <button type="button" class="close mt-2" aria-label="Close" (click)="dismissConfirm()">
            <i class="fa fa-times"></i>
          </button>
        </div>
        <p class="px-3 pt-3 w600">Select Order</p>
        <div class="list-wrapper">
          <div *ngFor="let upOrder of upcomingOrders" class="edit-delivery-card mx-3">

            <input type="radio" formControlName="OrderSelected" [id]="upOrder.subscriptionId"
              [value]="upOrder.subscriptionId">
            <label [for]="upOrder.subscriptionId" (click)="toggleFormSreen(upOrder.subscriptionId)">
              <h5>Estimated Delivery On {{ upOrder.nextScheduledDeliveryDate | date: 'MMMM d, y' | uppercase}}</h5>
              <div class="pt-3 list-container" *ngFor="let orderItem of upOrder?.cart?.entries">
                <div class="row">
                  <div class="col-3 d-flex justify-content-center align-items-start">
                    <img src="{{orderItem.product.images | appImg}}" *ngIf="orderItem.product.images" />
                    <div class="" *ngIf="!orderItem.product.images">
                      <i class="fas fa-image fa-10x"></i>
                    </div>
                  </div>
                  <div class="col-9">
                    <h6>{{upOrder?.subscriptionId}}</h6>
                    <h6>{{orderItem?.pet}}</h6>
                    <h4 class="w700">{{orderItem?.product?.name}}</h4>
                  </div>
                </div>
              </div>
            </label>
          </div>
        </div>



      </div>

      <div *ngIf="showSecondScreen">
        <div class="modal-header">
          <div class="flex-grow-1 text-center">
            <h4 class="modal-title">Edit delivery date</h4>
          </div>
          <button type="button" class="close mt-2" aria-label="Close" (click)="closeModal();dismissConfirm()">
            <i class="fa fa-times"></i>
          </button>
        </div>
        <div class="estimated-grid m-3">
          <span class="mb-2 w700" *ngIf="selectedOrder">ESTIMATED DELIVERY ON
            {{selectedOrder?.nextScheduledDeliveryDate | date: 'MMMM d, y' | uppercase}}</span>

          <div *ngFor="let upOrder of selectedOrder.cart.entries">
            <h5>{{upOrder.product.name}}</h5>
          </div>
        </div>

        <div class="delivery-radio-list">
          <div class="custom-control custom-radio mt-3">
            <input type="radio" id="ship_now" class="custom-control-input" name="NextDeliveryDateSelectionRadio"
              formControlName="NextDeliveryDateSelectionRadio" value="Week_0" (change)="checkValue()">
            <label class="custom-control-label pl-3" for="ship_now">
              Process now
            </label>
          </div>
          <div class="custom-control custom-radio mt-3">
            <input type="radio" id="delay_1" name="NextDeliveryDateSelectionRadio" class="custom-control-input"
              formControlName="NextDeliveryDateSelectionRadio" value="WEEK_1" (change)="checkValue()">
            <label class="custom-control-label pl-3" for="delay_1">
              Delay one week
            </label>
          </div>
          <div class="custom-control custom-radio mt-3">
            <input type="radio" id="delay_2" name="NextDeliveryDateSelectionRadio" class="custom-control-input"
              formControlName="NextDeliveryDateSelectionRadio" value="WEEK_2" (change)="checkValue()">
            <label class="custom-control-label pl-3" for="delay_2">
              Delay two week
            </label>
          </div>
          <div class="custom-control custom-radio mt-3">
            <input type="radio" id="delay_3" name="NextDeliveryDateSelectionRadio" class="custom-control-input"
              formControlName="NextDeliveryDateSelectionRadio" value="WEEK_3" (change)="checkValue()">
            <label class="custom-control-label pl-3" for="delay_3">
              Delay three week
            </label>
          </div>

          <div class="custom-control custom-radio mt-3">
            <input type="radio" id="delay_custom" name="NextDeliveryDateSelectionRadio" class="custom-control-input"
              formControlName="NextDeliveryDateSelectionRadio" value="WEEK_n" (change)="checkValue()">
            <label class="custom-control-label pl-3" for="delay_custom">
              Choose custom date:
            </label>
          </div>

          <!--------->
          <div class="custom-control mt-2 row w-100">
            <div class="mt-1 mb-0 custom-box date-picker col-sm-6 col-xs-12"
              [class.is-invalid]="nextDeliveryDateSelectionform.controls['deliveryDate'].dirty && nextDeliveryDateSelectionform.controls['deliveryDate'].invalid">
              <input [id]="'deliveryDate_'+i" type="date" class="form-control" formControlName="deliveryDate"
                min="{{maxDate}}" onkeydown="return false">
            </div>
            <ul
              *ngIf="nextDeliveryDateSelectionform.controls['deliveryDate'].dirty &&
                                nextDeliveryDateSelectionform.controls['deliveryDate'].errors &&
                                           (nextDeliveryDateSelectionform.controls['deliveryDate'].errors['minDate'] ||
                                           nextDeliveryDateSelectionform.controls['deliveryDate'].errors['onlyWeekDays'])"
              class="list-unstyled app-invalid-feedback small mt-2 mb-0">
              <li
                *ngIf="nextDeliveryDateSelectionform.controls['deliveryDate'].errors['minDate'] && nextDeliveryDateSelectionform.controls['deliveryDate'].errors['onlyWeekDays']">
                Please select a date on or after {{maxDate}}
              </li>
              <li
                *ngIf="nextDeliveryDateSelectionform.controls['deliveryDate'].errors['minDate'] && !(nextDeliveryDateSelectionform.controls['deliveryDate'].errors['onlyWeekDays'])">
                Please select a date on or after {{maxDate}}
              </li>
              <li
                *ngIf="nextDeliveryDateSelectionform.controls['deliveryDate'].errors['onlyWeekDays'] && !(nextDeliveryDateSelectionform.controls['deliveryDate'].errors['minDate'])">
                Please select week days only
              </li>
              <i class="app-bottom-arrow"></i>
            </ul>

          </div>
          <!------->

          <div class="custom-control custom-radio mt-3">
            <input type="radio" id="SKIP" name="NextDeliveryDateSelectionRadio" class="custom-control-input"
              formControlName="NextDeliveryDateSelectionRadio" value="SKIP">
            <label class="custom-control-label pl-3" for="SKIP">
              Skip this order
            </label>
          </div>

        </div>


        <div
          *ngIf="(nextDeliveryDateSelectionform.controls['NextDeliveryDateSelectionRadio'].errors && nextDeliveryDateSelectionform.controls['NextDeliveryDateSelectionRadio'].dirty) || showRadioError"
          class="alert alert-danger mt-4 small text-center">
          Please select atleast one option.
        </div>

      </div>
    </form>
  </div>
  <div class="modal-footer d-flex flex-column">
    <!-- <button *ngIf="showFirstScreen" type="button" class="btn btn-primary w-100 mb-1"
      (click)="toggleFormSreen(nextDeliveryDateSelectionform.get('OrderSelected').value)"
      [disabled]="!nextDeliveryDateSelectionform.get('OrderSelected').value">Next</button> -->
    <button *ngIf="showSecondScreen" class="btn btn-primary w-100 mb-1" (click)="editDeliveryDate(selectedOrder)"
      [disabled]="nextDeliveryDateSelectionform.invalid">Save</button>
    <button type="button" class="btn btn-secondary btn-lg w-100 m-0 mt-2 w600" (click)="closeModal();dismissConfirm()">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #successModal let-closeSuccess="close" let-dismissSuccess="dismiss">
  <div class="modal-body text-center">
    <i class="mt-4 far fa-check-circle fa-6x txt-success"></i>
    <p class="m-4 h4 text-center">
      You have successfully updated your order details
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary pl-5 pr-5 btn-lg ml-2" (click)="fetchUpcomingOrders();closeSuccess();">
      OK
    </button>
  </div>
</ng-template>
