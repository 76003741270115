import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromAuth from '../../reducers';
import * as fromCore from '../../../core/reducers';
import * as AuthActions from '../../actions/auth.actions';

@Component({
  selector: 'app-forgot-password-page',
  template: `
    <app-forgot-password
      [isMobile]="isMobile$ | async"
      [error]="error$ | async"
      [pending]="pending$ | async"
      (submitted)="onSubmit($event)">
    </app-forgot-password>
  `,
  styles: [],
})
export class ForgotPasswordPageComponent implements OnInit {

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  error$ = this.store.pipe(select(fromAuth.getForgotError));

  pending$ = this.store.pipe(select(fromAuth.getForgotPending));

  constructor(private store: Store<fromAuth.State>) {
  }

  ngOnInit() {
  }

  onSubmit(email: string) {
    this.store.dispatch(new AuthActions.ForgotPassword(email));
  }
}
