export interface SortType {
  name: string;
  code: string;
}

export const productSortTypes: SortType[] = [
  {
    name: 'Name: Ascending',
    code: 'name-asc'
  },
  {
    name: 'Name: Descending',
    code: 'name-desc'
  },
  {
    name: 'Relevance',
    code: 'relevance'
  },
  {
    name: 'SKU: Ascending',
    code: 'sku-asc'
  },
  {
    name: 'SKU: Descending',
    code: 'sku-desc'
  },
  {
    name: 'Map: Ascending',
    code: 'map-asc'
  },
  {
    name: 'Map: Descending',
    code: 'map-desc'
  },
  {
    name: 'Price: Ascending',
    code: 'msrp-asc'
  },
  {
    name: 'Price: Descending',
    code: 'msrp-desc'
  },
  {
    name: 'Price: Ascending',
    code: 'price-asc'
  },
  {
    name: 'Price: Descending',
    code: 'price-desc'
  },
  {
    name: 'Top Rated',
    code: 'topRated'
  }
];

const getQuantitiesFn = (max: number) => {
  const result = [];
  for (let i = 1; i <= max; i++) {
    result.push(i);
  }
  return result;
};

//Updated the quantity as per v2c application max user can add 10 per item/product
export const quantities = getQuantitiesFn(10);
