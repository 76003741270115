import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as ErrorActions from '../../core/actions/error.actions';
import * as PetsActions from '../actions/pets.actions';
import * as fromAuth from '../../auth/reducers';
import * as fromPets from '../../reducers';
import { AllNutritionPlans, NutritionPlan, PetService } from '../../api';
import { Authenticate } from '../../auth/models/authenticate';

@Injectable({
  providedIn: 'root'
})
export class NutritionPlanGuard  {

  constructor(private store: Store<fromPets.State>,
              private petService: PetService) {
  }

  getAuthenticate(): Observable<Authenticate> {
    return this.store.pipe(
      select(fromAuth.getAuthenticate)
    );
  }

  getIsLoggedIn(): Observable<boolean> {
    return this.store.pipe(
      select(fromAuth.getIsLoggedIn)
    );
  }

  getNutritionPlan(authenticate: Authenticate, petId: string, nutritionPlanId: string): Observable<NutritionPlan> {
    return this.petService.getNutritionPlans(petId)
      .pipe(
        map((allNutritionPlans: AllNutritionPlans) =>
          allNutritionPlans.nutritionPlans && allNutritionPlans.nutritionPlans.length ?
            allNutritionPlans.nutritionPlans : []),
        map((nutritionPlans: NutritionPlan[]) => {
          let np = nutritionPlans.find(
            (nutritionPlan: NutritionPlan) => nutritionPlan.id === nutritionPlanId);
          np = np ? np : (nutritionPlans.length ? nutritionPlans[0] : null);
          return np;
        }),
        tap(np => {
          this.store.dispatch(new PetsActions.LoadNutritionPlan(np));
        })
      );
  }

  checkNutritionPlan(petId: string, nutritionPlanId: string): Observable<boolean> {
    return this.getIsLoggedIn()
      .pipe(
        switchMap((auth: boolean) => {
          if (!auth) {
            return of(auth);
          }
          return this.getAuthenticate()
            .pipe(
              switchMap(authenticate => {
                return this.getNutritionPlan(authenticate, petId, nutritionPlanId)
                  .pipe(
                    map(np => {
                      if (!np) {
                        this.store.dispatch(new PetsActions.NutritionPlanRedirect());
                        return false;
                      }
                      return true;
                    }),
                    catchError((error) => {
                      this.store.dispatch(new ErrorActions.ReportError(error));
                      return of(false);
                    })
                  );
              })
            );
        })
      );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const petId = next.params['petId'];
    const npId = next.params['npId'];
    return this.checkNutritionPlan(petId, npId);
  }
}
