import { OnDestroy, OnInit, Output, EventEmitter, SimpleChange, SimpleChanges } from '@angular/core';
import { Component, Input, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { DateUtil } from 'src/app/shared/utils/dateUtil';
import { DateValidation } from 'src/app/shared/validation/dateValidation';
import { AutoShipTemplate, EditingSubscription } from 'src/app/api';
import { OrderStatuses } from 'src/app/shared/constants/payment';


@Component({
  selector: 'app-edit-order-delivery-date',
  templateUrl: './edit-order-delivery-date.component.html'
})
export class EditOrderDeliveryDate implements OnInit {

  @ViewChild('EditDeliveryDateModal', { static: true }) EditDeliveryDateModal: any;

  @ViewChild('successModal', { static: true })
  successModal: any;

  @Input() isMobile: boolean | false;

  @Input() upcomingOrders: any;

  @Input() subscriptionChangePending: boolean | false;

  @Output() submitted = new EventEmitter<EditingSubscription>();

  @Output() fetchOrders = new EventEmitter<any>();

  EditDeliveryDateModalRef: NgbModalRef;

  showFirstScreen: boolean = false;
  showSecondScreen: boolean = false;

  nextDeliveryDateSelectionform: UntypedFormGroup;

  minDate: string;
  maxDate: string;
  dtr: string;
  showRadioError: boolean;
  selectedOrder: AutoShipTemplate;

  constructor(private modalService: NgbModal, private fb: UntypedFormBuilder) {
    this.createNextDeliveryDateSelectionForm();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    // Only after the subscription is changed and there are not errors we close the desktop dialog
    const subscriptionChangePendingChange: SimpleChange = changes['subscriptionChangePending'];
    if (subscriptionChangePendingChange && !subscriptionChangePendingChange.firstChange &&
      !subscriptionChangePendingChange.currentValue) {
      this.showFirstScreen = false;
      this.showSecondScreen = false;
      if (this.EditDeliveryDateModalRef) {
        this.EditDeliveryDateModalRef.close();
      }
      this.success();
      this.resetForm();
    }
  }

  success() {
    // Using timeout because of issue here:
    // https://github.com/ng-bootstrap/ng-bootstrap/issues/1775
    setTimeout(() => {
      this.modalService.open(this.successModal)
    });
  }

  createNextDeliveryDateSelectionForm() {
    /*For Calendar */
    const now = new Date();
    const minDate = DateUtil.addWeekDay(now, 6);
    this.minDate = DateUtil.getDateStr(minDate, 'M/d/y');

    const dtToday = new Date();
    const dt = DateUtil.addWeekDay(dtToday, 6);
    this.maxDate = this.dateConvertion(dt);
    /*End For Calendar */
    this.nextDeliveryDateSelectionform = this.fb.group({
      OrderSelected: [''],
      NextDeliveryDateSelectionRadio: ['', Validators.required],
      deliveryDate: ['', {
        validators: [Validators.required, DateValidation.onlyWeekDays(), DateValidation.minDate(this.maxDate)],
      }],
    });
  }

  // To set the Min Date for selection
  dateConvertion(date) {
    const dtToday = date;
    const month = dtToday.getMonth() + 1;
    const day = dtToday.getDate();
    const year = dtToday.getFullYear();
    let currentMon: string | number = month,
      today: string | number = day;
    if (month < 10) {
      currentMon = '0' + month.toString();
    }
    if (day < 10) {
      today = '0' + day.toString();
    }
    return year + '-' + currentMon + '-' + today;
  }


  openEditDeliveryDateModal(subscription: AutoShipTemplate) {

    this.showFirstScreen = true;
    setTimeout(() => {
      this.EditDeliveryDateModalRef = this.modalService.open(this.EditDeliveryDateModal, { size: 'lg', windowClass: "delivery-list" });
    }, 100);
  }

  checkValue() {
    this.nextDeliveryDateSelectionform.get('NextDeliveryDateSelectionRadio').valueChanges.subscribe(val => {
      this.showRadioError = false;
      if (val === 'WEEK_n') {
        this.nextDeliveryDateSelectionform.get('deliveryDate').reset({ value: this.dtr, disabled: false });
      } else {
        this.nextDeliveryDateSelectionform.get('deliveryDate').reset({ value: this.dtr, disabled: true });
      }
    });
  }

  editDeliveryDate(subscription: AutoShipTemplate) {
    const model = this.nextDeliveryDateSelectionform.value;
    let temp_date: string;
    if (this.nextDeliveryDateSelectionform.value.NextDeliveryDateSelectionRadio == 'WEEK_n') {
      temp_date = DateUtil.getDateStr(model.deliveryDate, 'MM/dd/y');
    } else {
      temp_date = '';
    }

    if (this.nextDeliveryDateSelectionform.valid) {
      const nextDeliveryMode = this.nextDeliveryDateSelectionform.value.NextDeliveryDateSelectionRadio;
      const editingSubscription: EditingSubscription = {
        ...this.getEditingSubscription(subscription),
        subscriptionOrderStatus: OrderStatuses.Active,
        nextDeliveryDate: '',
        nextCustomDeliveryMode: nextDeliveryMode,
        nextCustomDeliveryDate: temp_date
      };

      this.submitted.emit(editingSubscription);

    } else {
      this.showRadioError = true;
    }

  }

  getEditingSubscription(subscription: AutoShipTemplate): EditingSubscription {

    const entry = subscription && subscription.cart.entries.length ?
      subscription.cart.entries[0] : null;

    const editingSubscription: EditingSubscription = {
      autoShipCode: subscription.autoShipCode,
      subscriptionOrderStatus: subscription.recurringOrderStatus,
      product: {
        code: entry.product.code
      },
      nutritionPlanId: entry.subscriptionUnit.nutritionPlanId,
      nextDeliveryDate: DateUtil.getDateStr(subscription.nextScheduledDeliveryDate),
      quantity: '' + entry.quantity,
      deliveryFrequency: entry.subscriptionUnit.deliveryFrequency,
      duration: entry.subscriptionUnit.duration,
    };

    return editingSubscription;
  }

  toggleFormSreen(subscriptionId) {

    this.selectedOrder = this.upcomingOrders.find((order: AutoShipTemplate) => order.subscriptionId === subscriptionId)
    //form initialization
    const next = this.selectedOrder.nextScheduledDeliveryDate ?
      DateUtil.add(DateUtil.getDateStr(this.selectedOrder.nextScheduledDeliveryDate), 0) :
      DateUtil.addWeekDay(new Date(), 6);
    this.dtr = DateUtil.getDateStr(next);
    this.nextDeliveryDateSelectionform.reset();
    this.checkValue();
    this.nextDeliveryDateSelectionform.get('deliveryDate').reset({ value: this.dtr, disabled: true });

    this.showFirstScreen = false;
    this.showSecondScreen = true;

  }

  resetForm() {
    this.nextDeliveryDateSelectionform.reset();
    this.showRadioError = false;
  }

  closeModal() {
    this.showFirstScreen = true;
    this.showSecondScreen = false;
    if (this.EditDeliveryDateModalRef) {
      this.EditDeliveryDateModalRef.close;
    }
  }

  fetchUpcomingOrders() {
    this.fetchOrders.emit();
  }
}
