import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap, map, take, tap, catchError } from 'rxjs/operators';

import * as fromOrders from '../reducers';
import * as fromAuth from '../../auth/reducers';
import {
  GetCart,
  GetCartSuccess,
  GetCartDetail,
  GetCartDetailSuccess,
  GetDeliveryModes,
  GetDeliveryModesSuccess,
  OrdersActionTypes,
  PlaceOrder,
  PlaceOrderSuccess,
  SearchOrders,
  SearchOrdersSuccess,
  GetOrders,
  GetOrdersSuccess,
  GetOrderDetail,
  GetOrderDetailSuccess,
  UpdateCartAddress,
  UpdateCartAddressSuccess,
  UpdateCartPayment,
  UpdateCartPaymentSuccess,
  UpdateCartDeliveryMode,
  UpdateCartDeliveryModeSuccess,
  SearchSubscriptions,
  SearchSubscriptionsSuccess,
  GetSubscription,
  GetSubscriptionSuccess,
  GetDeliveryModesAndCartDetail,
  GetDeliveryModesAndCartDetailSuccess,
  AddProductToCart,
  AddProductToCartSuccess,
  UpdateCartProduct, UpdateCartProductSuccess,
  CancelOrder, CancelOrderSuccess,
  UpdateSubscription, UpdateSubscriptionSuccess,
  ChangeSubscriptionProduct,
  GetUpdateSubscription, GetUpdateSubscriptionSuccess, GetUpcomingOrders, GetUpcomingOrdersSuccess, UpdateOrderDeliveryDate, UpdateOrderDeliveryDateSuccess,
  ReturnOrder, ReturnOrderSuccess, GetReturnOrderDetail, GetReturnOrderDetailSuccess
} from '../actions/orders.actions';
import {
  OrderService,
  Cart,
  AllCarts,
  AllDeliveryModes,
  DeliveryMode,
  OrderDetail,
  Order,
  AllOrders, Subscriptions, NutritionPlan, AutoShipTemplate, EditingResultSubscription, EditingSubscription
} from '../../api';

import { GetEditSubscription } from "../../api/model/getEditSubscription";
import { Authenticate } from '../../auth/models/authenticate';
import { OrderFilters } from '../models/orderFilters';
import { DateUtil } from '../../shared/utils/dateUtil';
import { DeliveryModeCodes } from '../../shared/constants/payment';
import * as fromPets from '../../pets/reducers';
import { ReturnOrderDetail } from 'src/app/api/model/returnOrderDetail';
import { ReportError } from 'src/app/core/actions/error.actions';

@Injectable()
export class OrdersEffects {

  getAuthenticate = ((): Observable<Authenticate> =>
    this.store.pipe(
      select(fromAuth.getAuthenticate),
      take(1)
    ));

  getCart = ((): Observable<Cart> =>
    this.store.pipe(
      select(fromOrders.getCart),
      take(1)
    ));

  getCartDetail = ((): Observable<Cart> =>
    this.store.pipe(
      select(fromOrders.getCartDetail),
      take(1)
    ));

  getSubscriptions = ((): Observable<Subscriptions> =>
    this.store.pipe(
      select(fromOrders.getSearchedSubscriptions),
      take(1)
    ));

  getSubscription = ((): Observable<AutoShipTemplate> =>
    this.store.pipe(
      select(fromOrders.getSubscription),
      take(1)
    ));

  getNutritionPlanDetail = ((): Observable<NutritionPlan> =>
    this.store.pipe(
      select(fromPets.getNutritionPlanDetail),
      take(1)
    ));

  
  getCart$ = createEffect(() => this.actions$
    .pipe(
      ofType<GetCart>(OrdersActionTypes.GetCart),
      switchMap(() =>
        this.getAuthenticate()
          .pipe(
            switchMap((authenticate: Authenticate) =>
              this.orderService.getCarts(authenticate.username)
                .pipe(
                  // Only grab carts that have products
                  map((allCarts: AllCarts) =>
                    allCarts.carts && allCarts.carts.length &&
                      allCarts.carts[0].entries && allCarts.carts[0].entries.length ?
                      allCarts.carts[0] :
                      null),
                  map((cart: Cart) => new GetCartSuccess(cart))
                )
            )
          )
      )
    ));

  
  getCartDetail$ = createEffect(() => this.actions$
    .pipe(
      ofType<GetCartDetail>(OrdersActionTypes.GetCartDetail),
      switchMap(() =>
        this.getAuthenticate()
          .pipe(
            switchMap((authenticate: Authenticate) =>
              this.getCart()
                .pipe(
                  switchMap((cart: Cart) =>
                    this.orderService.getCart(authenticate.username, cart.code)
                      .pipe(
                        map((cartDetail: Cart) => new GetCartDetailSuccess(cartDetail))
                      )
                  )
                )
            )
          )
      )
    ));

  
  updateApplyPromo$ = createEffect(() => this.actions$
    .pipe(
      ofType<GetCartDetail>(OrdersActionTypes.UpdateApplyPromo),
      switchMap(() =>
        this.getAuthenticate()
          .pipe(
            switchMap((authenticate: Authenticate) =>
              this.getCart()
                .pipe(
                  switchMap((cart: Cart) =>
                    this.orderService.updateAppliedPromo(authenticate.username, cart.code, localStorage.getItem("promoCode"))
                      .pipe(
                        map((cartDetail: Cart) => new GetCartDetailSuccess(cartDetail))
                      )
                  )
                )
            )
          )
      )
    ));
  
  removeApplyPromo$ = createEffect(() => this.actions$
    .pipe(
      ofType<GetCartDetail>(OrdersActionTypes.removeApplyPromo),
      switchMap(() =>
        this.getAuthenticate()
          .pipe(
            switchMap((authenticate: Authenticate) =>
              this.getCart()
                .pipe(
                  switchMap((cart: Cart) =>
                    this.orderService.removeAppliedPromo(authenticate.username, cart.code, localStorage.getItem("promoCode"))
                      .pipe(
                        map((cartDetail: Cart) => new GetCartDetailSuccess(cartDetail))

                      )
                  )
                )
            )
          )
      )
    ));

  
  cartRedirect$ = createEffect(() => this.actions$
    .pipe(
      ofType(OrdersActionTypes.CartRedirect),
      tap(() => {
        // this.router.navigate(['/cart']);
        this.router.navigate(['/pets/shop']); // redirect to shop page instead of cart page
      })
    ), { dispatch: false });



  
  getDeliveryModes$ = createEffect(() => this.actions$
    .pipe(
      ofType<GetDeliveryModes>(OrdersActionTypes.GetDeliveryModes),
      switchMap(() =>
        this.getAuthenticate()
          .pipe(
            switchMap((authenticate: Authenticate) =>
              this.getCart()
                .pipe(
                  switchMap((cart: Cart) =>
                    this.orderService.getDeliveryModes(authenticate.username, cart.code)
                      .pipe(
                        map((allDeliveryModes: AllDeliveryModes) => this.sortDeliveryModes(allDeliveryModes)),
                        map((deliveryModes: DeliveryMode[]) => new GetDeliveryModesSuccess(deliveryModes))
                      )
                  )
                )
            )
          )
      )
    ));

  
  getDeliveryModesAndCartDetail$ = createEffect(() => this.actions$
    .pipe(
      ofType<GetDeliveryModesAndCartDetail>(OrdersActionTypes.GetDeliveryModesAndCartDetail),
      switchMap(() =>
        this.getAuthenticate()
          .pipe(
            switchMap((authenticate: Authenticate) =>
              this.getCart()
                .pipe(
                  switchMap((cart: Cart) =>
                    this.orderService.getDeliveryModes(authenticate.username, cart.code)
                      .pipe(
                        map((allDeliveryModes: AllDeliveryModes) => this.sortDeliveryModes(allDeliveryModes)),
                        switchMap((deliveryModes: DeliveryMode[]) =>
                          this.orderService.getCart(authenticate.username, cart.code)
                            .pipe(
                              map((cartDetail: Cart) => new GetDeliveryModesAndCartDetailSuccess(deliveryModes, cartDetail))
                            )
                        )
                      )
                  )
                )
            )
          )
      )
    ));

  
  placeOrder$ = createEffect(() => this.actions$
    .pipe(
      ofType<PlaceOrder>(OrdersActionTypes.PlaceOrder),
      switchMap(() =>
        this.getAuthenticate()
          .pipe(
            switchMap((authenticate: Authenticate) =>
              this.getCart()
                .pipe(
                  switchMap((cart: Cart) => {
                      return this.orderService.placeOrder(authenticate.username, cart.code)
                        .pipe(
                          map((placedOrder: OrderDetail) => {
                            return new PlaceOrderSuccess(placedOrder)
                          }),
                          catchError(err => {
                           err.noRedirect = true;
                           return of(new ReportError(err))
                          })
                        )
                    },

                  )
                )
            )
          )
      )
    ));



  
  placeOrderSuccess$ = createEffect(() => this.actions$
    .pipe(
      ofType<PlaceOrderSuccess>(OrdersActionTypes.PlaceOrderSuccess),
      tap((placedOrder) => {
        this.router.navigate(['/order-complete']);
      })
    ), { dispatch: false });

  
  getOrders$ = createEffect(() => this.actions$
    .pipe(
      ofType<GetOrders>(OrdersActionTypes.GetOrders),
      switchMap((action: GetOrders) =>
        this.getAuthenticate()
          .pipe(
            switchMap((authenticate: Authenticate) =>
              this.orderService.getOrders(authenticate.username)
                .pipe(
                  map((allOrders: AllOrders) => allOrders.orders && allOrders.orders.length ? allOrders.orders : []),
                  map((orders: Order[]) => new GetOrdersSuccess(orders))
                )
            )
          )
      )
    ));

    getUpcomingOrders$ = createEffect(() => this.actions$
    .pipe(
      ofType<GetUpcomingOrders>(OrdersActionTypes.GetUpcomingOrders),
      switchMap((action: GetUpcomingOrders) =>
        this.getAuthenticate()
          .pipe(
            switchMap((authenticate: Authenticate) =>
              this.orderService.getUpcomingOrders(authenticate.username)
                .pipe(
                  map((upcomingOrders: any) => upcomingOrders.autoShipTemplates && upcomingOrders.autoShipTemplates.length ? upcomingOrders.autoShipTemplates : []),
                  map((orders: AutoShipTemplate[]) => new GetUpcomingOrdersSuccess(orders))
                )
            )
          )
      )
    ));
  
  searchOrders$ = createEffect(() => this.actions$
    .pipe(
      ofType<SearchOrders>(OrdersActionTypes.SearchOrders),
      map((action: SearchOrders) => action.orderFilters),
      switchMap((filters: OrderFilters) =>
        this.getAuthenticate()
          .pipe(
            switchMap((authenticate: Authenticate) =>
              this.orderService.getOrders(authenticate.username)
                .pipe(
                  map((allOrders: AllOrders) => allOrders.orders && allOrders.orders.length ? allOrders.orders : []),
                  map((orders: Order[]) => {
                    return orders.filter(order => {
                      if (filters) {
                        return DateUtil.isBetween(order.placed, filters.startDate, filters.endDate);
                      }
                      return true;
                    });
                  }),
                  map((orders: Order[]) => new SearchOrdersSuccess(orders))
                )
            )
          )
      )
    ));

  
  getOrderDetail$ = createEffect(() => this.actions$
    .pipe(
      ofType<GetOrderDetail>(OrdersActionTypes.GetOrderDetail),
      map((action: GetOrderDetail) => action.id),
      switchMap((id: string) =>
        this.getAuthenticate()
          .pipe(
            switchMap((authenticate: Authenticate) =>
              this.orderService.getOrder(authenticate.username, id)
                .pipe(
                  map((orderDetail: OrderDetail) => new GetOrderDetailSuccess(orderDetail))
                )
            )
          )
      )
    ));

  
  cancelOrder$ = createEffect(() => this.actions$
    .pipe(
      ofType<CancelOrder>(OrdersActionTypes.CancelOrder),
      switchMap((action: CancelOrder) =>
        this.getAuthenticate()
          .pipe(
            switchMap((authenticate: Authenticate) =>
              this.orderService.cancelOrder(authenticate.username, action.order.code, action.order.vetUser.pk)
                .pipe(
                  map((orderDetail: OrderDetail) => new CancelOrderSuccess(orderDetail))
                )
            )
          )
      )
    ));

  
  getReturnOrderDetail$ = createEffect(() => this.actions$
    .pipe(
      ofType<GetReturnOrderDetail>(OrdersActionTypes.GetReturnOrderDetail),
      map((action: GetReturnOrderDetail) => action.id),
      switchMap((id: string) =>
        this.getAuthenticate()
          .pipe(
            switchMap((authenticate: Authenticate) => 
              this.orderService.getReturnOrderDetail(authenticate.username, id)
                .pipe(
                  map((returnOrderDetail: ReturnOrderDetail) => new GetReturnOrderDetailSuccess(returnOrderDetail))
                )
            )
          )
      )
    ));
  
  
  returnOrder$ = createEffect(() => this.actions$
  .pipe(
    ofType<ReturnOrder>(OrdersActionTypes.ReturnOrder),
    switchMap((action: ReturnOrder) => 
      this.getAuthenticate()
        .pipe(
          switchMap((authenticate: Authenticate) => 
            this.orderService.returnOrder(authenticate.username, action.order)
            .pipe(
              map((returnOrderDetail:ReturnOrderDetail) => new ReturnOrderSuccess(returnOrderDetail))
              // map((orderDetail:OrderDetail) => new ReturnOrderSuccess(orderDetail))
            )
          )
        )
    )
  ));

  
  updateCartAddress$ = createEffect(() => this.actions$
    .pipe(
      ofType<UpdateCartAddress>(OrdersActionTypes.UpdateCartAddress),
      switchMap((action: UpdateCartAddress) =>
        this.getAuthenticate()
          .pipe(
            switchMap((authenticate: Authenticate) =>
              this.getCart()
                .pipe(
                  switchMap((cart: Cart) =>
                    this.orderService.updateCartAddress(authenticate.username, cart.code, action.addressId)
                      .pipe(
                        map(() => new UpdateCartAddressSuccess())
                      )
                  )
                )
            )
          )
      )
    ));

  
  updateCartPayment$ = createEffect(() => this.actions$
    .pipe(
      ofType<UpdateCartPayment>(OrdersActionTypes.UpdateCartPayment),
      switchMap((action: UpdateCartPayment) =>
        this.getAuthenticate()
          .pipe(
            switchMap((authenticate: Authenticate) =>
              this.getCart()
                .pipe(
                  switchMap((cart: Cart) =>
                    this.orderService.updateCartPayment(authenticate.username, cart.code, action.paymentId)
                      .pipe(
                        map(() => new UpdateCartPaymentSuccess())
                      )
                  )
                )
            )
          )
      )
    ));

  
  updateCartDeliveryMode$ = createEffect(() => this.actions$
    .pipe(
      ofType<UpdateCartDeliveryMode>(OrdersActionTypes.UpdateCartDeliveryMode),
      switchMap((action: UpdateCartDeliveryMode) =>
        this.getAuthenticate()
          .pipe(
            switchMap((authenticate: Authenticate) =>
              this.getCart()
                .pipe(
                  switchMap((cart: Cart) =>
                    this.orderService.updateCartDeliveryMode(
                      authenticate.username,
                      cart.code,
                      action.deliveryModeChange.code,
                      action.deliveryModeChange.code === DeliveryModeCodes.SelectedDate &&
                        action.deliveryModeChange.deliveryDate ?
                        DateUtil.getDateStr(action.deliveryModeChange.deliveryDate, 'MM/dd/y') : undefined
                    )
                      .pipe(
                        map(() => new UpdateCartDeliveryModeSuccess())
                      )
                  )
                )
            )
          )
      )
    ));

  
  searchSubscriptions$ = createEffect(() => this.actions$
    .pipe(
      ofType<SearchSubscriptions>(OrdersActionTypes.SearchSubscriptions),
      switchMap((action: SearchSubscriptions) =>
        this.getAuthenticate()
          .pipe(
            switchMap((authenticate: Authenticate) =>
              this.orderService.getSubscriptions(authenticate.username,
                {
                  ...action.subscriptionFilters,
                  startDate: !action.subscriptionFilters ? '' :
                    DateUtil.getDateStr(action.subscriptionFilters.startDate, 'MM/dd/y'), // using services format
                  endDate: !action.subscriptionFilters ? '' :
                    DateUtil.getDateStr(action.subscriptionFilters.endDate, 'MM/dd/y')
                })
                .pipe(
                  map((subscriptions: Subscriptions) => new SearchSubscriptionsSuccess(subscriptions))
                )
            )
          )
      )
    ));

  
  getSubscription$ = createEffect(() => this.actions$
    .pipe(
      ofType<GetSubscription>(OrdersActionTypes.GetSubscription),
      map((action: GetSubscription) => action.id),
      switchMap((id: string) =>
        this.getSubscriptions()
          .pipe(
            map((subscriptions: Subscriptions) => {
              const subscription = subscriptions.autoShipTemplates.find(s => s.subscriptionId === id);
              return new GetSubscriptionSuccess(subscription);
            })
          )
      )
    ));

  
  addProductToCart$ = createEffect(() => this.actions$
    .pipe(
      ofType<AddProductToCart>(OrdersActionTypes.AddProductToCart),
      switchMap((action: AddProductToCart) =>
        this.getAuthenticate()
          .pipe(
            switchMap((authenticate: Authenticate) =>
              this.getNutritionPlanDetail()
                .pipe(
                  switchMap((np: NutritionPlan) =>
                    this.getSelectedPlans().pipe(
                      switchMap(plans =>

                        this.orderService.addProductToCart(
                          authenticate.username,
                          np.vetUser.pk,
                          {
                            ...action.addingCart,
                            pet: np.pet.petCode,
                            selectedPlans: plans
                          }
                        )
                          .pipe(
                            map(() => new AddProductToCartSuccess())
                          )
                      ))





                  )
                )
            )
          )
      )
    ));

  
  addProductToCartSuccess$ = createEffect(() => this.actions$
    .pipe(
      ofType<AddProductToCartSuccess>(OrdersActionTypes.AddProductToCartSuccess),
      map(() => new GetCart())
    ));

  
  updateCartProduct$ = createEffect(() => this.actions$
    .pipe(
      ofType<UpdateCartProduct>(OrdersActionTypes.UpdateCartProduct),
      switchMap((action: UpdateCartProduct) =>
        this.getAuthenticate()
          .pipe(
            switchMap((authenticate: Authenticate) =>
              this.getCartDetail()
                .pipe(
                  switchMap((cart: Cart) =>
                    this.orderService.updateCartProduct(
                      authenticate.username,
                      cart.vetUser.pk,
                      '' + action.cartChange.entryNumber,
                      {
                        ...action.cartChange.editingCart
                      }
                    )
                      .pipe(
                        map(() => new UpdateCartProductSuccess())
                      )
                  )
                )
            )
          )
      )
    ));

  
  updateProductToCartSuccess$ = createEffect(() => this.actions$
    .pipe(
      ofType<UpdateCartProductSuccess>(OrdersActionTypes.UpdateCartProductSuccess),
      map(() => new GetCart())
    ));


  
  updateSubscription$ = createEffect(() => this.actions$
    .pipe(
      ofType<UpdateSubscription>(OrdersActionTypes.UpdateSubscription),
      switchMap((action: UpdateSubscription) =>
        this.getAuthenticate()
          .pipe(
            switchMap((authenticate: Authenticate) =>
              this.orderService.updateSubscription(
                authenticate.username,
                action.editingSubscription
              ).pipe(
                map((result: EditingResultSubscription) => new UpdateSubscriptionSuccess(action.editingSubscription, result))
              )
            )
          )
      )
    ));

  
  updateOrderDeliveryDate$ = createEffect(() => this.actions$
    .pipe(
      ofType<UpdateOrderDeliveryDate>(OrdersActionTypes.UpdateOrderDeliveryDate),
      switchMap((action: UpdateOrderDeliveryDate) =>
        this.getAuthenticate()
          .pipe(
            switchMap((authenticate: Authenticate) =>
              this.orderService.updateSubscription(
                authenticate.username,
                action.editingSubscription
              ).pipe(
                map((result: EditingResultSubscription) => new UpdateOrderDeliveryDateSuccess(action.editingSubscription, result))
              )
            )
          )
      )
    ));

  
  getUpdateSubscription$ = createEffect(() => this.actions$
    .pipe(
      ofType<GetUpdateSubscription>(OrdersActionTypes.GetUpdateSubscription),
      switchMap((action: GetUpdateSubscription) =>
        this.getAuthenticate()
          .pipe(
            switchMap((authenticate: Authenticate) =>
              this.orderService.getUpdateSubscription(
                authenticate.username,
                action.getEditSubscription
              )
                .pipe(
                  map((result: EditingResultSubscription) => new GetUpdateSubscriptionSuccess(action.getEditSubscription, result))
                )
            )
          )
      )
    ));

  
  changeSubscriptionProduct$ = createEffect(() => this.actions$
    .pipe(
      ofType<ChangeSubscriptionProduct>(OrdersActionTypes.ChangeSubscriptionProduct),
      switchMap((action: ChangeSubscriptionProduct) =>
        this.getSubscription()
          .pipe(
            map((subscription: AutoShipTemplate) => {
              const editingSubscription: EditingSubscription = {
                ...this.getEditingSubscription(subscription),
                product: {
                  code: action.productCode
                }
              };
              return editingSubscription;
            }),
            map((editingSubscription: EditingSubscription) => new UpdateSubscription(editingSubscription))
          )
      )
    ));

  constructor(private store: Store<fromOrders.State>,
    private actions$: Actions,
    private router: Router,
    private orderService: OrderService) {
  }

  sortDeliveryModes(allDeliveryModes: AllDeliveryModes): Array<DeliveryMode> {
    if (allDeliveryModes.deliveryModes) { // Sort the delivery modes
      const deliveryModeOrder = {};
      deliveryModeOrder[DeliveryModeCodes.Standard] = 1;
      deliveryModeOrder[DeliveryModeCodes.NextDay] = 2;
      deliveryModeOrder[DeliveryModeCodes.SelectedDate] = 3;

      return allDeliveryModes.deliveryModes.sort((a: DeliveryMode, b: DeliveryMode) => {
        const indexA = deliveryModeOrder[a.code] || 4;
        const indexB = deliveryModeOrder[b.code] || 4;
        return indexA - indexB; // ascending
      });
    }
  }

  getEditingSubscription(subscription: AutoShipTemplate): EditingSubscription {

    const entry = subscription && subscription.cart.entries.length ?
      subscription.cart.entries[0] : null;

    const editingSubscription: EditingSubscription = {
      autoShipCode: subscription.autoShipCode,
      subscriptionOrderStatus: subscription.recurringOrderStatus,
      product: {
        code: entry.product.code
      },
      nutritionPlanId: entry.subscriptionUnit.nutritionPlanId,
      nextDeliveryDate: DateUtil.getDateStr(subscription.nextScheduledDeliveryDate),
      quantity: '' + entry.quantity,
      deliveryFrequency: entry.subscriptionUnit.deliveryFrequency,
      duration: entry.subscriptionUnit.duration,
    };

    return editingSubscription;
  }

  getSelectedPlans = (): Observable<string[]> =>
    this.store.pipe(
      select(fromPets.getSelectedPlans),
      take(1)
    )

}


