import {
  createSelector,
  createFeatureSelector,
  ActionReducerMap,
} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromAuth from './auth.reducer';

export interface AuthState {
  status: fromAuth.State;
}

export interface State extends fromRoot.State {
  auth: AuthState;
}

export const reducers: ActionReducerMap<AuthState> = {
  status: fromAuth.reducer
};

export const getAuthState = createFeatureSelector<AuthState>('auth');

// Auth Status

export const getAuthStatusState = createSelector(
  getAuthState,
  (state: AuthState) => state.status
);

export const getIsLoggedIn = createSelector(
  getAuthStatusState,
  fromAuth.getIsLoggedIn
);

export const getSession = createSelector(
  getAuthStatusState,
  fromAuth.getSession);

export const getAuthenticate = createSelector(
  getAuthStatusState,
  fromAuth.getAuthenticate);

export const getRegistration = createSelector(
  getAuthStatusState,
  fromAuth.getRegistration);

export const getLoginError = createSelector(
  getAuthStatusState,
  fromAuth.getLoginError
);

export const getLoginPending = createSelector(
  getAuthStatusState,
  fromAuth.getLoginPending
);

export const getRegisterError = createSelector(
  getAuthStatusState,
  fromAuth.getRegisterError
);

export const getRegisterPending = createSelector(
  getAuthStatusState,
  fromAuth.getRegisterPending
);

export const getForgotError = createSelector(
  getAuthStatusState,
  fromAuth.getForgotError
);

export const getForgotPending = createSelector(
  getAuthStatusState,
  fromAuth.getForgotPending
);

export const getIsTokenExpired = createSelector(
  getAuthStatusState,
  fromAuth.getIsTokenExpired
);

export const getResetError = createSelector(
  getAuthStatusState,
  fromAuth.getResetError
);

export const getRegisterPetParent = createSelector(
  getAuthStatusState,
  fromAuth.getRegisterPetParent);

export const getRegisterPetParentError = createSelector(
  getAuthStatusState,
  fromAuth.getRegisterPetParentError
);

export const getRegisterPetParentPending = createSelector(
  getAuthStatusState,
  fromAuth.getRegisterPetParentPending
);

export const getIsMaintenanceMode = createSelector(
  getAuthStatusState,
  fromAuth.getIsMaintenanceMode
);