<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout custom-font terms-not-logged-in" *ngIf="isLoggedIn">
    <div class="header-banner">
      <div class="d-flex align-items-center flex-shrink-0 p-4">
        <a href="javascript:void(0)" (click)="goBack()" *ngIf="isTermsAccepted">
          <i class="fas fa-angle-left fa-2x"></i>
        </a>
        <h5 class="flex-grow-1 ml-4 mb-0">
          Hill's to Home Terms of Service
        </h5>
      </div>
      <div class="flex-grow-1 p-4 app-scrollable">
        <div>
          <b>Last Updated: {{termsAndConditions?.revisionDate | date: 'longDate'}}</b>
          <div class="tnc-content" [innerHTML]="termsAndConditions?.termsAndConditions"></div>
        </div>
      </div>
      <div class="d-flex align-items-center flex-shrink-0 p-2" *ngIf="!isTermsAccepted">
        <button type="button" class="btn btn-secondary pl-5 pr-5 mt-4 ml-auto mr-2  btn-lg" (click)="cancel()">
              Cancel
        </button>
        <button type="button" class="btn btn-primary pl-5 pr-5 mt-4 ml-2 mr-auto btn-lg" (click)="accept()">
          Accept
        </button>        
      </div>
    </div>
  </div>
  <div class="app-mobile-layout custom-font terms-not-logged-in" *ngIf="!isLoggedIn">
    <div class="header-banner">
      <div class="container d-flex align-items-start pt-4">
        <div class="home-logo">
          <img src="assets/images/060118_HillsToHomeLogo.png" routerLink="/login">
        </div>
        <button class="visit-btn mt-3" (click)="openUrl()">
          <span class="w700">VISIT HILLSPET.COM</span>
          <i class="fa fa-external-link-square-alt pl-2"></i>
        </button>
      </div>
      <div class="d-flex align-items-center flex-shrink-0 p-4">
        <a href="javascript:void(0)" (click)="goBack()">
          <i class="fas fa-angle-left fa-2x"></i>
        </a>
        <h5 class="flex-grow-1 ml-4 mb-0">
          Hill's to Home Terms of Service
        </h5>
      </div>
      <div class="flex-grow-1 p-4 app-scrollable">
        <b>Last Updated {{termsAndConditions?.revisionDate | date: 'longDate'}}</b>
        <div class="tnc-content" [innerHTML]="termsAndConditions?.termsAndConditions"></div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #fullLayout>
  <div class="app-full-layout custom-font">
    <div class="header-banner">
    <div class="container d-flex align-items-start pt-4" *ngIf="!isLoggedIn">
      <div class="home-logo">
        <img src="assets/images/060118_HillsToHomeLogo.png" routerLink="/login">
      </div>
      <button class="visit-btn mt-3 mr-2" routerLink="/login">
        <span class="w700">Sign In</span>
        <i class="fas fa-user pl-2"></i>
      </button>
      <button class="visit-btn mt-3" (click)="openUrl()">
        <span class="w700">VISIT HILLSPET.COM</span>
        <i class="fa fa-external-link-square-alt pl-2"></i>
      </button>
    </div>
    <div class="container pt-5 pb-5">
      <h2 >Hill's to Home Terms of Service</h2>
      <div class="d-flex flex-column">
        <b>Last Updated {{termsAndConditions?.revisionDate | date: 'longDate'}}</b>

        <div class="scroll-content" [innerHTML]="termsAndConditions?.termsAndConditions"></div>
      </div>
      <div class="d-inline-flex align-content-end" *ngIf="!isTermsAccepted">        
        <button type="button" class="btn btn-secondary pl-5 pr-5 btn-lg mr-2 mt-4 mb-4" (click)="cancel()">
          Cancel
        </button>
        <button type="button" class="btn btn-primary pl-5 pr-5 btn-lg mr-4 mt-4 mb-4" (click)="accept()">
            Accept
          </button>
      </div>
    </div>
  </div>
  </div>
</ng-template>

<ng-template #terms>

</ng-template>
