import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'promotionDescription'
})
export class OrderPromotionDescriptionPipe implements PipeTransform {

    // Pipe to bold out the % value in potential order  promotion description

    transform(value: string, args?: any): string {
        if (value && args === 'percentage') {
            return value.split(' ')[0] + ' ';
        }
        if (value && args === 'desc') {
            return value.slice(3);
        }
    }
}