import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
// import { skip, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import * as fromCore from '../../../core/reducers';
import * as fromOrders from '../../reducers';
import * as OrdersActions from '../../actions/orders.actions';
import { flyOutIn } from '../../../shared/animations/common.animations';
// import { OrderDetail,Order } from '../../../api';
import * as CoreActions from '../../../core/actions/core.actions';
import { ReturnOrderDetail } from '../../../api/model/returnOrderDetail';

@Component({
  selector: 'app-return-page',
  template: `
    <app-return
      [isHome]="isHome | async"
      [isMobile]="isMobile$ | async"
      [returnOrderDetail]="returnOrderDetail$ | async"
      [orderReturnPending]="orderReturnPending$ | async"
      (submitted)="onSubmit($event)"
      (succeed)="onSuccess()">
    </app-return>
  `,
  styles: [],
  animations: [flyOutIn]
})
export class ReturnPageComponent implements OnInit, OnDestroy {

  @HostBinding('@flyOutIn')
  flyOutIn = 'in';

  // @HostBinding('class.app-animated-page')
  // animatedPage = true;

  subscriptions: Subscription[] = [];

  isHome = false;

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  returnOrderDetail$ = this.store.pipe(select(fromOrders.getReturnOrderDetail))

  orderReturnPending$ = this.store.pipe(select(fromOrders.getOrderReturnPending));

  constructor(private store: Store<fromOrders.State>, private route: ActivatedRoute, private router: Router) {
    this.subscriptions.push(
      route.params
        .subscribe(params => {
          const id = params['id'];
          if (id) {
            this.store.dispatch(new OrdersActions.GetReturnOrderDetail(id));
          }
        })
    );

    this.subscriptions.push(
      route.data
        .subscribe(params => {
          this.isHome = params['fromHome'];
        })
    );
  }

  ngOnInit() {
  }

  onSuccess() {
    this.store.dispatch(new CoreActions.NavigateBack());
  }

  onSubmit(returnOrderDetail: ReturnOrderDetail) {
    // console.log(returnOrderDetail);
    this.store.dispatch(new OrdersActions.ReturnOrder(returnOrderDetail));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
  }

}
