import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromCore from '../../reducers';
import * as fromAuth from '../../../auth/reducers';
import * as fromProfile from '../../../profile/reducers';
import * as AuthActions from '../../../auth/actions/auth.actions';
import * as CoreActions from '../../../core/actions/core.actions';
import * as ProfileActions from '../../../profile/actions/profile.actions';
import { map, take } from 'rxjs/operators';
import { Profile } from 'src/app/api';
import { TermsStatusChanged } from '../../models/termsStatusChanged';
import { Router } from '@angular/router';


@Component({
  selector: 'app-terms-page',
  template: `
    <app-terms
      [isMobile]="isMobile$ | async"
      [isLoggedIn]="isLoggedIn$ | async"
      [isTermsAccepted]="isTermsAccepted$ | async"
      [termsAndConditions]="termsAndConditions$ | async"
      (loggedOut)="onLoggedOut()"
      (accepted)="onTermsAccepted($event)">
    </app-terms>
  `,
  styles: []
})
export class TermsPageComponent implements OnInit {
  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  isLoggedIn$ = this.store.pipe(select(fromAuth.getIsLoggedIn));

  isTermsAccepted$ = this.store.pipe(
    select(fromProfile.getProfile),
    map((profile: Profile) => (profile ? profile.termsAndConditions : true))
  );

  termsAndConditions$ = this.store.pipe(select(fromCore.getTermsAndConditions));
  constructor(private store: Store<fromCore.State>, private router: Router) {}

  ngOnInit() {
    this.store.dispatch(new CoreActions.GetTermsAndConditions());
  }

  onLoggedOut() {
    this.store.dispatch(new AuthActions.Logout());
  }

  onTermsAccepted(event: TermsStatusChanged) {
    this.store.dispatch(
      new ProfileActions.UpdateTermsAcceptedStatus(
        event.accepted
      )
    );
  
    // Dispatch an action to load the profile if it's not already loaded.
    // This step is necessary if the profile might not be in the store yet.
    // this.store.dispatch(new ProfileActions.LoadProfile());
    setTimeout(() => {
      this.store.pipe(
        select(fromProfile.getProfile),
        take(1) // Take only one value from the observable and then unsubscribe
      ).subscribe((profile: Profile) => {
        const isOttoCustomer = sessionStorage.getItem('ottoCustomer') === 'true';
        const ottoStatus = sessionStorage.getItem('ottoStatus') === 'pending';
        const nutritionPlanId = profile && profile.pets && profile.pets[0] ? profile.pets[0].petCode : null;
  
        if (isOttoCustomer && ottoStatus && nutritionPlanId) {
          this.router.navigate(['/pets/shop/', nutritionPlanId]);
        } else {
          this.router.navigateByUrl('/pets/shop');
        }
      });
    }, 500);
  }

}
