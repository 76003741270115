import { Pipe, PipeTransform } from '@angular/core';
import {
  DeliveryDuration,
  DeliveryDurations,
  deliveryDurations,
  DeliveryFrequency,
  DeliveryFrequencies,
  LabelTypes
} from '../../../../constants/payment';
import { ToDeliveryFrequencyPipe } from './to-delivery-frequency.pipe';




@Pipe({
  name: 'tolabel'
})
export class TolabelPipe implements PipeTransform {

  transform(code: string, type: string): string {
    if (code !== null && code !== '') {
      switch (type) {
        case LabelTypes.Frequency:
          return this.createFrequencyLabelFrom(code);
        case LabelTypes.Duration:
          return this.createDurationLabelFrom(code);
        default:
          return code;
      }
    }
    return '';
  }

  createFrequencyLabelFrom(code: string) {
    if (code.startsWith('WEEK')) {
      const weeknum = Number(code.split(/_/g)[1]);
      const plural = weeknum > 1 ? 's' : '';
      return `Every ${weeknum} week${plural}`;
    } else if (code === DeliveryFrequencies.OneTime) {
      return 'One time shipment';
    }
    return code;
  }

  createDurationLabelFrom(code: string) {
    const retVal = deliveryDurations.find(
      aDuration => aDuration.code === code
    );
    if (retVal) {
      return retVal.name;
    }
    return code;
  }

}
