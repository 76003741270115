import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  SimpleChanges,
  SimpleChange,
  OnChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbAccordion, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AutoShipTemplate, EditingSubscription, Subscriptions } from '../../../api';
import { DeliveryFrequencies, OrderStatuses, NextDeliveryDateSelections, LabelTypes } from '../../../shared/constants/payment';
import { DateUtil } from '../../../shared/utils/dateUtil';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DateValidation } from '../../../shared/validation/dateValidation';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent implements OnInit, OnChanges {

  DeliveryFrequencies = DeliveryFrequencies;

  OrderStatuses = OrderStatuses;

  selectedPanelId: string;

  selectedSubscription: any | null;    

  editSubscriptionModalRef: NgbModalRef;

  nextDeliveryDateSelectionform: UntypedFormGroup;

  showRadioError = false;

  showNextDeliveryDate = false;

  nextDeliveryDateSelectionModalRef: NgbModalRef;

  cancelSubscriptionModalRef: NgbModalRef;

  skipSubscriptionModalRef: NgbModalRef;

  shipNowModalRef: NgbModalRef;

  LabelTypes = LabelTypes;

  dtr: string;

  minDate: string;

  maxDate: string;

  showInactiveSub: boolean = true;

  @Input() isMobile: boolean | false;

  @Input() subscriptionChangePending: boolean | false;

  @Input() subscriptions: Subscriptions | null;

  @Input() subscriptionDetail: AutoShipTemplate | null;

  @Output() subscriptionSelected = new EventEmitter<AutoShipTemplate>();

  @Output() subscriptionSubmitted = new EventEmitter<EditingSubscription>();

  @Output() succeed = new EventEmitter<any>();

  @ViewChild('subscriptionsAccordion')
  subscriptionsAccordion: NgbAccordion;

  @ViewChild('editSubscriptionModal', { static: true })
  editSubscriptionModal: any;

  @ViewChild('confirmCancelModal', { static: true })
  confirmCancelModal: any;

  @ViewChild('confirmHoldModal', { static: true })
  confirmHoldModal: any;

  @ViewChild('confirmEditSubscriptionModal', { static: true })
  confirmEditSubscriptionModal: any;

  @ViewChild('successModal', { static: true })
  successModal: any;
  subscriptionCancelForm: UntypedFormGroup;
  reason = '';

  @ViewChild('confirmReactivatelModal', { static: true })
  confirmReactivatelModal: any;

  @ViewChild('skipSubscriptionModal', { static: true })
  skipSubscriptionModal: any;

  @ViewChild('confirmSkipSubscriptionModal')
  confirmSkipSubscriptionModal: any;

  @ViewChild('shipNowModal', { static: true })
  shipNowModal: any;

  constructor(private router: Router,
              private modalService: NgbModal, private fb: UntypedFormBuilder) {
                this.createNextDeliveryDateSelectionForm();
                this.subscriptionCancelFormInit();
              }

  subscriptionCancelFormInit() {
    this.subscriptionCancelForm = this.fb.group({
      subscriptionCancelReasonRadio: ['', Validators.required],
       cancelReason: [' ']
    });
  }

  resetForm() {
      this.subscriptionCancelForm.reset();
      this.showRadioError = false;
      this.showNextDeliveryDate = false;
  }

  ngOnInit() {
    console.log('loading subscription component');
    this.showInactiveSub = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    // On mobile close any open desktop dialogs
    const isMobileChange: SimpleChange = changes['isMobile'];
    if (isMobileChange && !isMobileChange.firstChange &&
      isMobileChange.currentValue) {
      if (this.editSubscriptionModalRef) {
        this.editSubscriptionModalRef.close();
      }
    }
    // Only after the subscription is changed and there are not errors we close the desktop dialog
    const subscriptionChangePendingChange: SimpleChange = changes['subscriptionChangePending'];
    if (subscriptionChangePendingChange && !subscriptionChangePendingChange.firstChange &&
      !subscriptionChangePendingChange.currentValue) {
      if (this.editSubscriptionModalRef) {
        this.editSubscriptionModalRef.close();
      }
      if (this.nextDeliveryDateSelectionModalRef) {
        this.nextDeliveryDateSelectionModalRef.close();
      }
      this.success();
      this.resetForm();
    }

    const subChange: SimpleChange = changes['subscriptions'];        //To track changes after editing a subscription
    if (subChange && !subChange.firstChange &&
      subChange.currentValue) {
      this.refreshSubscription();                                   // Refresh the subscription with updated values
    }
  }

  select(subscription: AutoShipTemplate) {
    if (subscription) {
      if (this.isMobile) {
        this.router.navigate(['/subscriptions', subscription.subscriptionId]);
      } else {
        this.subscriptionSelected.emit(subscription);
      }
    }
    this.selectedSubscription = null;
  }

  createNextDeliveryDateSelectionForm() {
    /*For Calendar */
    const now = new Date();
    const minDate = DateUtil.addWeekDay(now, 6);
    this.minDate = DateUtil.getDateStr(minDate, 'M/d/y');

    const dtToday = new Date();
    const dt = DateUtil.addWeekDay(dtToday, 6);
    this.maxDate = this.dateConvertion(dt);
    /*End For Calendar */
    this.nextDeliveryDateSelectionform = this.fb.group({
      NextDeliveryDateSelectionRadio: ['', Validators.required],
      deliveryDate: ['', {
        validators: [Validators.required, DateValidation.onlyWeekDays(), DateValidation.minDate(this.maxDate)],
      }],
    });
  }

  // To set the Min Date for selection
  dateConvertion(date) {
    const dtToday = date;
    const month = dtToday.getMonth() + 1;
    const day = dtToday.getDate();
    const year = dtToday.getFullYear();
    let currentMon: string | number = month,
      today: string | number = day;
    if (month < 10) {
      currentMon = '0' + month.toString();
    }
    if (day < 10) {
      today = '0' + day.toString();
    }
    return year + '-' + currentMon + '-' + today;
  }

  openEditSubscription() {
    // Using timeout because of issue here:
    // https://github.com/ng-bootstrap/ng-bootstrap/issues/1775
    setTimeout(() => {
      this.editSubscriptionModalRef = this.modalService.open(this.editSubscriptionModal, {size: 'lg'});
    }, 100);
  }

  onSubmitSubscription(editingSubscription: EditingSubscription) {
    this.showNextDeliveryDate = true;
    this.subscriptionSubmitted.emit(editingSubscription);
    this.selectedSubscription = editingSubscription;
  }

  refreshSubscription() {
    if(this.selectedSubscription != null) {
        this.subscriptionSelected.emit(this.selectedSubscription);     // To Emit Get Subscription Detail Action, to refresh subscription with new values
    }
  }

  cancelSubscription(subscription: AutoShipTemplate) {
    const model = this.subscriptionCancelForm.value;
    this.reason = model.subscriptionCancelReasonRadio.valueOf();
    if (this.reason === 'OTHER') {
      if (model.cancelReason != null) {
        this.reason = model.cancelReason.valueOf();
      } else {
        this.reason = 'OTHER';
      }
    }
      const editingSubscription: EditingSubscription = {
        ...this.getEditingSubscription(subscription),
        subscriptionOrderStatus: OrderStatuses.Cancelled,
        subscriptionCancelReason: this.reason
      };
      this.subscriptionSubmitted.emit(editingSubscription);
      this.closeCancelSubscription();
      this.subscriptionsAccordion.activeIds = [];
  }

  checkCancelSubscription(subscription: AutoShipTemplate) {
    if (this.subscriptionCancelForm.valid) {
      this.showRadioError = false;
      this.cancelSubscription(subscription);
    } else {
      this.showRadioError = true;
    }
  }

  changeRadioStatus() {
    if (this.subscriptionCancelForm.valueChanges) {
      this.showRadioError = false;
    }
  }

  openCancelSubscription() {
    this.resetForm();
    setTimeout(() => {
      this.cancelSubscriptionModalRef = this.modalService.open(this.confirmCancelModal, {size: 'lg'});
    }, 100);
  }

  closeCancelSubscription() {
    this.cancelSubscriptionModalRef.close();
    this.resetForm();
  }

  closeSkipSubscription(){
    this.skipSubscriptionModalRef.close();
  }

  pauseSubscription(subscription: AutoShipTemplate) {
    const editingSubscription: EditingSubscription = {
      ...this.getEditingSubscription(subscription),
      subscriptionOrderStatus: OrderStatuses.Hold
    };
    this.subscriptionSubmitted.emit(editingSubscription);
  }

  confirmPauseSubscription(subscription: AutoShipTemplate) {
    this.modalService.open(this.confirmHoldModal).result.then(
      () => {
        this.pauseSubscription(subscription);
      },
      () => {
      }
    );
  }

  openSkipSubscription() {
    setTimeout(() => {
      this.skipSubscriptionModalRef = this.modalService.open(this.skipSubscriptionModal);
    }, 100);
  }
  
  confirmSkipSubscription(subscription: AutoShipTemplate){
    const nextSkipDeliveryDate = DateUtil.getDateStr(subscription.nextSkipDeliveryDate, 'MM/dd/yyyy');

    const editingSubscription: EditingSubscription = {
      ...this.getEditingSubscription(subscription),
      subscriptionOrderStatus: OrderStatuses.SkipNextDelivery,
      nextDeliveryDate: nextSkipDeliveryDate

    };

    this.subscriptionSubmitted.emit(editingSubscription);

    this.skipSubscriptionModalRef ? this.skipSubscriptionModalRef.close() : this.showRadioError = true;
    // close active accordion panel
    this.subscriptionsAccordion.activeIds = [];
  }

  showNextSubscriptionDateModal(subscription: AutoShipTemplate){
      const next = subscription.nextScheduledDeliveryDate ?
      DateUtil.add(DateUtil.getDateStr(subscription.nextScheduledDeliveryDate), 0) :
      DateUtil.addWeekDay(new Date(), 6);
      this.dtr = DateUtil.getDateStr(next);
      this.nextDeliveryDateSelectionform.reset();
      this.checkValue();
      this.nextDeliveryDateSelectionform.get('deliveryDate').reset({value: this.dtr, disabled: true});
      setTimeout(() => {
        this.nextDeliveryDateSelectionModalRef = this.modalService.open(this.confirmReactivatelModal);
        this.nextDeliveryDateSelectionModalRef.result.then((result) => {
        }, (reason) => {
        });
      }, 100);
  }
  checkValue() {
    this.nextDeliveryDateSelectionform.get('NextDeliveryDateSelectionRadio').valueChanges.subscribe(val => {
      this.showRadioError = false;
      if (val === 'WEEK_n') {
        this.nextDeliveryDateSelectionform.get('deliveryDate').reset({value: this.dtr, disabled: false});
      } else {
        this.nextDeliveryDateSelectionform.get('deliveryDate').reset({value: this.dtr, disabled: true});
      }
    });
  }

  reactivateSubscription(subscription: AutoShipTemplate) {
    const model = this.nextDeliveryDateSelectionform.value;
    let temp_date: string;
    if (this.nextDeliveryDateSelectionform.value.NextDeliveryDateSelectionRadio == 'WEEK_n') {
      temp_date = DateUtil.getDateStr(model.deliveryDate, 'MM/dd/y');
    } else {
      temp_date = '';
    }

    if (this.nextDeliveryDateSelectionform.valid) {
        const editingSubscription: EditingSubscription = {
          ...this.getEditingSubscription(subscription),
          subscriptionOrderStatus: OrderStatuses.Active,
          nextDeliveryDate: '',
          nextCustomDeliveryMode: this.nextDeliveryDateSelectionform.value.NextDeliveryDateSelectionRadio,
          nextCustomDeliveryDate: temp_date

        };
        if (this.nextDeliveryDateSelectionModalRef) {
          this.nextDeliveryDateSelectionModalRef.close;
        }
        this.subscriptionSubmitted.emit(editingSubscription);
      } else {
        this.showRadioError = true;
      }
  }

  getEditingSubscription(subscription: AutoShipTemplate): EditingSubscription {

    const entry = subscription && subscription.cart.entries.length ?
      subscription.cart.entries[0] : null;

    const editingSubscription: EditingSubscription = {
      autoShipCode: subscription.autoShipCode,
      subscriptionOrderStatus: subscription.recurringOrderStatus,
      product: {
        code: entry.product.code
      },
      nutritionPlanId: entry.subscriptionUnit.nutritionPlanId,
      nextDeliveryDate: DateUtil.getDateStr(subscription.nextScheduledDeliveryDate, 'MM/dd/yyyy'),
      quantity: '' + entry.quantity,
      deliveryFrequency: entry.subscriptionUnit.deliveryFrequency,
      duration: entry.subscriptionUnit.duration,
    };

    return editingSubscription;
  }

  // To get the Orders
  getOrders() {
    this.router.navigate(['/orders']);
  }

  success() {
    console.log(this.showNextDeliveryDate);
    // Using timeout because of issue here:
    // https://github.com/ng-bootstrap/ng-bootstrap/issues/1775
    if(this.showNextDeliveryDate)
    {
      setTimeout(() => {
        this.modalService.open(this.confirmEditSubscriptionModal).result.then(
          () => {
            this.succeed.emit(this.showInactiveSub);
          },
          () => {
            this.succeed.emit(this.showInactiveSub);
          }
        );
      });
    }else
    {
      setTimeout(() => {
        this.modalService.open(this.successModal).result.then(
          () => {
            this.succeed.emit(this.showInactiveSub);
          },
          () => {
            this.succeed.emit(this.showInactiveSub);
          }
        );
      });
    }
  }

  openShipNowPopup() {
    setTimeout(() => {
      this.shipNowModalRef = this.modalService.open(this.shipNowModal, {size: 'lg'});
    }, 100);
  }

  confirmShipNowSubscription(subscription: AutoShipTemplate) {
    const editingSubscription: EditingSubscription = {
      ...this.getEditingSubscription(subscription),
      subscriptionOrderStatus: OrderStatuses.Active,
      shipNowFlag: true
    };

    this.subscriptionSubmitted.emit(editingSubscription);
    this.closeShipNowPopup();
    // close active accordion panel
    this.subscriptionsAccordion.activeIds = [];
  }
  
  closeShipNowPopup() {
    if(this.shipNowModalRef) {
      this.shipNowModalRef.close();
    }
  }

  showInactiveSubscription() {
    this.showInactiveSub = !this.showInactiveSub;
    this.succeed.emit(this.showInactiveSub);
  }

}
