import {
  Component, EventEmitter, Input, OnChanges, OnInit,
  Output, SimpleChange, SimpleChanges, ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Profile } from '../../../api';
import { PasswordValidation } from '../../../shared/validation/passwordValidation';
import { Patterns } from '../../../shared/validation/patterns';
import { PasswordChange } from '../../models/passwordChange';

@Component({
  selector: 'app-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.scss']
})
export class EditPasswordComponent implements OnInit, OnChanges {

  profileForm: UntypedFormGroup;

  @Input() isMobile: boolean | false;

  @Input() error: string | null;

  @Input() pending: boolean | false;

  @Input() profile: Profile | null;

  @Input()
  isCurrentPasswordVisible: boolean | false;
  isNewPasswordVisible: boolean | false;
  isConfirmNewPasswordVisible: boolean | false;


  @Output() submitted = new EventEmitter<PasswordChange>();

  @ViewChild('successModal', { static: true })
  successModal: any;

  constructor(private fb: UntypedFormBuilder,
              private modalService: NgbModal,
              private router: Router) {
    this.createForm();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    const profileChange: SimpleChange = changes['profile'];
    if (profileChange) {
      this.resetForm();
    }
    const pendingChange: SimpleChange = changes['pending'];
    if (pendingChange && !pendingChange.firstChange) {
      if (pendingChange.currentValue) {
        this.profileForm.disable();
      } else {
        this.profileForm.enable();
        if (!this.error) {
          this.success();
        }
      }
    }
    // On desktop redirect back to master page
    const isMobileChange: SimpleChange = changes['isMobile'];
    if (isMobileChange && !isMobileChange.firstChange &&
      !isMobileChange.currentValue) {
      this.router.navigate(['/profile']);
    }
  }

  createForm() {
    this.profileForm = this.fb.group({
        password: ['', [Validators.required]],
        newPassword: ['', {
          validators: [Validators.required, Validators.pattern(Patterns.pwdpattern)],
        }],
        confirmPassword: ['', {
          validators: [Validators.required],
        }]
      },
      {
        validator: PasswordValidation.matchPassword
      });
  }

  resetForm() {
    this.profileForm.reset({
      password: '',
      newPassword: '',
      confirmPassword: ''
    });
    this.error = null;
  }

  save() {
    if (this.profileForm.valid) {
      const model = this.profileForm.value;
      const passwordChange: PasswordChange = {
        password: model.password,
        newPassword: model.newPassword
      };
      this.submitted.emit(passwordChange);
    }
  }

  success() {

    const path = this.router.url;
    const editPassword = /edit-password/gi;

    if(path.match(editPassword)) {
      // Using timeout because of issue here:
      // https://github.com/ng-bootstrap/ng-bootstrap/issues/1775
      setTimeout(() => {
        this.modalService.open(this.successModal).result.then(
          () => {
            this.modalService.dismissAll();
            this.router.navigate(['/profile']);
          },
          () => {
            this.modalService.dismissAll();
            this.router.navigate(['/profile']);
          }
        );
      });
    }
  }

  //Toggle Password Visibility
  toggleVisibility(passwordType){
    switch (passwordType) {
      case 'currentPassword':
        this.isCurrentPasswordVisible = !this.isCurrentPasswordVisible;
        break;
      case 'newPassword':
        this.isNewPasswordVisible = !this.isNewPasswordVisible;
        break;
      case 'confirmNewPassword':
        this.isConfirmNewPasswordVisible = !this.isConfirmNewPasswordVisible;
        break;
    }
  }

  //Remove the error message when user clicks on Current Password
  currentPwd(){
    this.error = null;
  }

}
