<div *ngIf="isMobile; then mobileLayout; else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout light-grey-bg pt-4 custom-font pet-profile">
    <div class="container px-3">
      <div *ngIf="showPetDetail">
        <div class="d-flex align-items-center pet-profile-title">
          <h5 class="w800 m-0">
            <a class="pet-back mr-1" href="javascript:void(0)" (click)="goBack()" aria-label="Back">
              <i class="fa fa-arrow-left"></i>
            </a>
            {{ pet?.petName }}
          </h5>
          <span class="flex-grow-1 d-flex justify-content-end">
            <a [routerLink]="['/edit-pet', pet.petCode]" *ngIf="pet"
              class="bg-white btn btn-outline-default btn-sm px-2 py-1">
              Edit Name
            </a>
          </span>
        </div>
        <div class="profile-img py-4">
          <figure>
            <img src="{{ pet | appPetImg }}" class="app-pet-img" alt="{{ pet?.petName }}" />
          </figure>
        </div>
        <h5 class="w800 m-0">
          Nutrition Plan(s)
        </h5>
        <div class="row px-1">
          <!-- Start Of Active Nutriton Plan Listing -->
          <div *ngFor="let np of nutritionPlans; let i = index" class="col-12 col-md-6 col-lg-4 px-2 mt-3">
            <div *ngIf="!np.clinic.enableWellnessBrand && !(_nutritionPlans?.length == 1)" class="active-inactive-card">
              <!-- <input type="checkbox" [id]="np.id" /> -->
              <h5>{{ np.planName }}</h5>
              <span class="w500" *ngFor="let pl of np?.productLines; let i = index">
                {{ pl.name }}
                <!-- <span *ngIf="i < nutritionPlans?.productLines.length - 1">, </span> -->
              </span>
              <div class="d-flex align-items-start card-foot justify-content-between pt-2">
                <p>
                  <label>Status</label>
                  <b class="d-flex align-items-center"><em class="mr-2"></em>{{np.status | appPlanStatusFormat}}</b>
                </p>
                <p>
                  <label>End Date</label>
                  <b>{{ np.endDate | date: 'MM/dd/yyyy'}}</b>
                </p>
              </div>
            </div>
            <p *ngIf="np.clinic.enableWellnessBrand && (_nutritionPlans?.length == 1)" class="pl-1">Need Prescription Diet? Contact your vet to get a nutrition plan created.</p>
          </div>
        </div> <!-- End Of Active Nutriton Plan Listing -->

        <div class="pt-4 pb-4 d-flex justify-content-center" style="margin-bottom: -1.2rem;"
          *ngIf="inActiveNutritionPlans?.length && !showInactiveNutritionPlans">
          <a *ngIf="inActiveNutritionPlans?.length && !showInactiveNutritionPlans"
            (click)="toggleInactiveNutritionPlans()" class="txt-blue w600 link-more-plan">
            View All InActive Plans</a>
        </div>

        <div class="d-flex align-items-center pt-4">
          <h5 [hidden]="!showInactiveNutritionPlans" class="w800 m-0">
            InActive Nutrition Plan(s)
          </h5>
        </div>

        <div [hidden]="!showInactiveNutritionPlans" class="row px-1">
          <!-- Start Of InActive Nutriton Plan Listing -->
          <div *ngFor="let np of inActiveNutritionPlans; let i = index" class="col-12 col-md-6 col-lg-4 px-2 mt-3">
            <div class="active-inactive-card">
              <h5>{{ np.planName }}</h5>
              <span class="w500" *ngFor="let pl of np?.productLines; let i = index">
                {{ pl.name }}
                <!-- <span *ngIf="i < nutritionPlans?.productLines.length - 1">, </span> -->
              </span>
              <div class="d-flex align-items-start card-foot justify-content-between pt-2">
                <p>
                  <label>Status</label>
                  <b class="d-flex align-items-center"><i class="mr-2"></i>{{np.status | appPlanStatusFormat}}</b>
                </p>
                <p>
                  <label>End Date</label>
                  <b>{{ np.endDate | date: 'MM/dd/yyyy'}}</b>
                </p>
              </div>
            </div>
          </div>
        </div> <!-- End Of InActive Nutriton Plan Listing -->
        <div class="pt-4 pb-4 d-flex justify-content-center"
          *ngIf="inActiveNutritionPlans?.length && showInactiveNutritionPlans">
          <a *ngIf="inActiveNutritionPlans?.length && showInactiveNutritionPlans"
            (click)="toggleInactiveNutritionPlans()" class="txt-blue w600 link-more-plan">
            Hide All InActive Plans</a>
        </div>
      </div> <!-- End of pet detail section-->

      <div *ngIf="!showPetDetail" class="nutrition-plan-details pt-1">
        <h4 class="w800 main-title-mob mb-0 pt-0 mt-n2">
          <a class="pet-back" href="javascript:void(0)" (click)="goBack()" aria-label="Back">
            <i class="fa fa-arrow-left"></i>
          </a>
          {{ pet?.petName }}<br />Nutrition Plan(s)
        </h4>
        <h4 *ngIf="ottoRegisteredFlag">Registration complete!</h4>
        <div *ngIf="_nutritionPlans?.length == 1" class="mt-3 mb-1 custom-font">
          <p class="w500 m-0 title-tagline">Need Prescription Diet?<br>
            Contact your vet to get a nutrition plan
            created.</p>
        </div>

        <p class="title-tagline mb-0 py-1" *ngIf="!(_nutritionPlans?.length == 1)">
          Available products based on {{ pet?.petName }}'s nutrition plan(s)
        </p>
        <div class="row px-1">
          <div *ngFor="let np of _nutritionPlans; let i = index" class="col-12 col-md-6 col-lg-4 px-2 mt-2">

            <!-- If nutrition plan lenght is one , means that we only have a welness plan -->
            <div class="plan-tab" *ngIf="!np.clinic.enableWellnessBrand && !(_nutritionPlans?.length == 1)">
              <input type="checkbox" [id]="np.id" [checked]="i == 0" (change)="search(np, $event)" [disabled]="
                      np.isChecked && selectedNutritionPlans.length == 1
                    " />
              <label [for]="np.id">
                <b>{{ np.planName }}</b>
                <span *ngFor="let pl of np?.productLines; let i = index">
                  {{ pl.name }}
                  <!-- <span *ngIf="i < nutritionPlans?.productLines.length - 1">, </span> -->
                </span>
              </label>
            </div>

           <!-- <div class="plan-tab" *ngIf="np.clinic.enableWellnessBrand && (_nutritionPlans?.length == 1)">
              <input type="checkbox" [id]="np.id" [checked]="i == 0" (change)="search(np, $event)" [disabled]="
                                  np.isChecked && selectedNutritionPlans.length == 1
                                " />
              <label [for]="np.id">
                <b>{{ np.planName }}</b>
                <span *ngFor="let pl of np?.productLines; let i = index">
                  {{ pl.name }}       
                </span>
              </label>
            </div>-->
          </div>
        </div>
      </div>

    </div>
  </div>
</ng-template>



<ng-template #fullLayout>
  <div class="app-full-layout light-grey-bg pt-4 custom-font pet-profile pb-4">
    <div class="container">
      <div *ngIf="showPetDetail">
        <div class="d-flex align-items-center pet-profile-title">
          <h2 class="main-title text-center pt-2 flex-grow-1" style="text-indent: 105px;">

            {{ pet?.petName }}</h2>
          <span class="d-flex justify-content-end">
            <a [routerLink]="['/edit-pet', pet.petCode]" *ngIf="pet"
              class="btn btn-outline-default btn-sm px-3 py-2 bg-white">Edit
              Name</a>
          </span>
        </div>
        <div class="profile-img py-4">
          <figure>
            <img src="{{ pet | appPetImg }}" class="app-pet-img" alt="{{ pet?.petName }}" />
          </figure>
        </div>
        <h5 class="w800 m-0">
          Nutrition Plan(s)
        </h5>
        <div class="row px-1">
          <!-- Start Of Active Nutriton Plan Listing -->
          <div *ngFor="let np of nutritionPlans; let i = index" class="col-12 col-md-6 col-lg-4 px-2 mt-3">

            <div *ngIf="!np.clinic.enableWellnessBrand && !(_nutritionPlans?.length == 1)" class="active-inactive-card">
              <h5>{{ np.planName }}</h5>
              <span class="w600" *ngFor="let pl of np?.productLines; let i = index">
                {{ pl.name }}
                <!-- <span *ngIf="i < nutritionPlans?.productLines.length - 1">, </span> -->
              </span>
              <div class="d-flex align-items-start card-foot justify-content-between pt-2">
                <p>
                  <label>Status</label>
                  <b class="d-flex align-items-center"><em class="mr-2"></em>{{np.status | appPlanStatusFormat}}</b>
                </p>
                <p>
                  <label>End Date</label>
                  <b>{{ np.endDate | date: 'MM/dd/yyyy'}}</b>
                </p>
              </div>
            </div>
            <p *ngIf="np.clinic.enableWellnessBrand && (_nutritionPlans?.length == 1)" class="pl-1">Need Prescription Diet? Contact your vet to get a nutrition plan created.</p>
          </div>
        </div> <!-- End Of Active Nutriton Plan Listing -->

        <div class="pt-4 d-flex justify-content-center">
          <a *ngIf="inActiveNutritionPlans?.length && !showInactiveNutritionPlans"
            (click)="toggleInactiveNutritionPlans()" class="txt-blue w600 link-more-plan">
            View All InActive Plans</a>
        </div>

        <div class="d-flex align-items-center pet-profile-title pt-3">
          <h5 [hidden]="!showInactiveNutritionPlans" class="w800 m-0">
            InActive Nutrition Plan(s)
          </h5>
        </div>
        <div [hidden]="!showInactiveNutritionPlans" class="row px-1">
          <!-- Start Of InActive Nutriton Plan Listing -->
          <div *ngFor="let np of inActiveNutritionPlans; let i = index" class="col-12 col-md-6 col-lg-4 px-2 mt-3">
            <div class="active-inactive-card">
              <h5>{{ np.planName }}</h5>
              <span class="w600" *ngFor="let pl of np?.productLines; let i = index">
                {{ pl.name }}
                <!-- <span *ngIf="i < nutritionPlans?.productLines.length - 1">, </span> -->
              </span>
              <div class="d-flex align-items-start card-foot justify-content-between pt-2">
                <p>
                  <label>Status</label>
                  <b class="d-flex align-items-center"><i class="mr-2"></i>{{np.status | appPlanStatusFormat}}</b>
                </p>
                <p>
                  <label>End Date</label>
                  <b>{{ np.endDate | date: 'MM/dd/yyyy'}}</b>
                </p>
              </div>
            </div>
          </div>
        </div> <!-- End Of InActive Nutriton Plan Listing -->

        <div class="pt-4 d-flex justify-content-center">
          <a *ngIf="inActiveNutritionPlans?.length && showInactiveNutritionPlans"
            (click)="toggleInactiveNutritionPlans()" class="txt-blue w600 link-more-plan">
            Hide All InActive Plans</a>
        </div>
      </div> <!-- End of pet detail section-->

      <div class="container pt-4 pb-4" *ngIf="!showPetDetail">
        <div class="d-flex">
          <div class="flex-grow-1">
            <div *ngIf="pet?.petCode && !showPetDetail" class="nutrition-plan-details pt-2">
              <h2 class="main-title text-center font-weight-normal" *ngIf="ottoRegisteredFlag">Registration complete!</h2>
              <h2 class="main-title text-center font-weight-normal">
                Nutrition Plan(s)
              </h2>

              <!-- If nutrition plan lenght is one , means that we only have a welness plan -->
              <p *ngIf="_nutritionPlans?.length == 1" class="text-center title-tagline">Need Prescription Diet?<br>
                Contact your vet to get a nutrition plan
                created.</p>

              <p *ngIf="!(_nutritionPlans?.length == 1)" class="text-center title-tagline">
                Available products based on {{ pet?.petName }}'s nutrition plan(s)
              </p>

              <h5 *ngIf="!(_nutritionPlans?.length == 1)" class="w800 pt-4">{{ pet?.petName }}'s Nutrition Plan(s)</h5>
              <div class="row plan-box-wrapper">
                <div class="col-12 col-md-6 col-lg-4 px-0 my-2 plan-box"
                  *ngFor="let np of _nutritionPlans; let i = index">

                  <!-- If nutrition plan lenght is one , means that we only have a welness plan -->
                  <div *ngIf="!np.clinic.enableWellnessBrand && !(_nutritionPlans?.length == 1)">
                    <div class="plan-tab">
                      <input type="checkbox" [id]="np.id" [checked]="i == 0" (change)="search(np, $event)" [disabled]="
                                                              np.isChecked && selectedNutritionPlans.length == 1
                                                            " />
                      <label [for]="np.id">
                        <b>{{ np.planName }}</b>
                        <span *ngFor="let pl of np?.productLines; let i = index">
                          {{ pl.name }}
                          <!-- <span *ngIf="i < nutritionPlans?.productLines.length - 1">, </span> -->
                        </span>
                      </label>
                    </div>
                  </div>

                <!-- <div *ngIf="np.clinic.enableWellnessBrand && (_nutritionPlans?.length == 1)">
                    <div class="plan-tab">
                      <input type="checkbox" [id]="np.id" [checked]="i == 0" (change)="search(np, $event)" [disabled]="
                                                                              np.isChecked && selectedNutritionPlans.length == 1
                                                                            " />
                      <label [for]="np.id">
                        <b>{{ np.planName }}</b>
                        <span *ngFor="let pl of np?.productLines; let i = index">
                          {{ pl.name }}                  
                        </span>
                      </label>
                    </div>
                  </div>--> 

                </div>
              </div>



            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</ng-template>

<ng-template #successModal let-closeSuccess="close" let-dismissSuccess="dismiss">
  <div class="modal-body text-center">
    <i class="mt-4 far fa-check-circle fa-6x txt-success"></i>
    <p class="m-4 h4 text-center">
      You have successfully {{ pet?.petCode ? "changed" : "added" }} your pet
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary pl-5 pr-5 btn-lg ml-2" (click)="closeSuccess()">
      OK
    </button>
  </div>
</ng-template>