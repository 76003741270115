import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import * as OrdersActions from '../actions/orders.actions';
import * as ErrorActions from '../../core/actions/error.actions';
import * as fromAuth from '../../auth/reducers';
import * as fromOrders from '../reducers';
import {
  Cart,
  AllCarts,
  OrderService
} from '../../api';
import { Authenticate } from '../../auth/models/authenticate';

@Injectable({
  providedIn: 'root'
})
export class CartGuard  {

  constructor(private store: Store<fromOrders.State>,
    private orderService: OrderService) {
  }

  getIsLoggedIn(): Observable<boolean> {
    return this.store.pipe(
      select(fromAuth.getIsLoggedIn)
    );
  }

  getAuthenticate(): Observable<Authenticate> {
    return this.store.pipe(
      select(fromAuth.getAuthenticate)
    );
  }

  getCart(authenticate: Authenticate): Observable<Cart> {
    return this.orderService.getCarts(authenticate.username)
      .pipe(
        map((allCarts: AllCarts) =>
          allCarts.carts && allCarts.carts.length &&
            allCarts.carts[0].entries && allCarts.carts[0].entries.length ?
            allCarts.carts[0] :
            null),
        tap(cart => {
          this.store.dispatch(new OrdersActions.LoadCart(cart));
        })
      );
  }

  checkCart(): Observable<boolean> {
    return this.getIsLoggedIn()
      .pipe(
        switchMap((auth: boolean) => {
          if (!auth) {
            return of(auth);
          }
          return this.getAuthenticate()
            .pipe(
              switchMap(authenticate => {
                return this.getCart(authenticate)
                  .pipe(
                    map(cart => {
                      // If we are not in the cart, check if there is a cart available,
                      // if there is one, then redirect to the cart page.
                      if (cart) {
                        this.store.dispatch(new OrdersActions.CartRedirect());
                        return false;
                      }
                      return true;
                    }),
                    catchError((error) => {
                      this.store.dispatch(new ErrorActions.ReportError(error));
                      return of(false);
                    })
                  );
              })
            );
        })
      );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkCart();
  }
}
