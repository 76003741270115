import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT, LocationStrategy } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription, of, fromEvent } from 'rxjs';
import {
  debounceTime, distinctUntilChanged,
  switchMap, take, tap,
  skip, throttleTime, filter, map
} from 'rxjs/operators';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Store, select } from '@ngrx/store';
import * as fromCore from '../../../core/reducers';
import * as fromAuth from '../../../auth/reducers';
import * as fromProfile from '../../../profile/reducers';
import * as fromOrders from '../../../orders/reducers';
import * as LayoutActions from '../../../core/actions/layout.actions';
import * as CoreActions from '../../../core/actions/core.actions';
import * as AuthActions from '../../../auth/actions/auth.actions';
import { LayoutModes } from '../../constants/layout';
import { LocationUtil } from '../../../shared/utils/locationUtil';

@Component({
  selector: 'app-main-page',
  template: `
    <app-main
      [layoutMode]="layoutMode"
      [back]="back"
      [isMobile]="isMobile$ | async"
      [loading]="loading$ | async"
      [isLoggedIn]="isLoggedIn$ | async"
      [profile]="profile$ | async"
      [session]="session$ | async"
      [authenticate]="authenticate$ | async"
      [cart]="cart$ | async"
      (loggedOut)="onLoggedOut()"      
      (navigatedBack)="onNavigateBack()">
    </app-main>
  `,
  styles: [],
})
export class MainPageComponent implements OnInit, OnDestroy {

  back = '';

  layoutMode = LayoutModes.Normal;

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  layoutMode$ = this.store.pipe(select(fromCore.getLayoutMode));

  loading$ = this.store.pipe(
    select(fromCore.getLoading),
    debounceTime(200), // giving some time for loading indicator to aggregate
    distinctUntilChanged() // only when changed
  );

  isLoggedIn$ = this.store.pipe(select(fromAuth.getIsLoggedIn));

  session$ = this.store.pipe(select(fromAuth.getSession));

  authenticate$ = this.store.pipe(select(fromAuth.getAuthenticate));

  profile$ = this.store.pipe(select(fromProfile.getProfile));

  cart$ = this.store.pipe(select(fromOrders.getCart));

  subscriptions: Subscription[] = [];

  constructor(private store: Store<fromCore.State>,
              private media: MediaObserver,
              private route: ActivatedRoute,
              private router: Router,
              private locationStrategy: LocationStrategy,
              @Inject(DOCUMENT) private document: Document) {

    this.subscriptions.push(
      media.asObservable()
      .pipe(
        filter((changes: MediaChange[]) => changes.length > 0),
        map((changes: MediaChange[]) => changes[0])
        )
        .subscribe((change: MediaChange) => {
          this.store.dispatch(new LayoutActions.ChangeMedia(change));
        })
    );

    this.subscriptions.push(
      // Need to get the data when a navigation ends, to verify if this is home page
      this.router.events.pipe(
        switchMap(event => {
          if (event instanceof NavigationEnd && this.route.firstChild) {
            return this.route.firstChild.data.pipe(
              take(1)
            );
          }
          return of(null);
        })
      ).subscribe(data => {
        if (data) {
          const layoutMode = data['layoutMode'] ? data['layoutMode'] : LayoutModes.Normal;
          this.store.dispatch(new LayoutActions.ChangeLayoutMode(layoutMode));

          const back = data['back'] ? data['back'] : '';
          this.back = back;
        }
      })
    );
  }

  ngOnInit() {

    this.locationStrategy.onPopState(() => {
      LocationUtil.isPopState(true);
    });

    this.subscriptions.push(
      // Need to get the data when a navigation ends, to verify correct the scrolling issues
      this.router.events.pipe(
        tap(event => {

          if (event instanceof NavigationEnd) {

            // reset the back navigated action
            this.store.dispatch(new CoreActions.NavigatedBack(false));

            // If this is coming from pop state, then is a navigated back action
            if (LocationUtil.isPopState()) {
              this.store.dispatch(new CoreActions.NavigatedBack(true));
            }

            // Ensures that isPopState is reset
            LocationUtil.isPopState(false);

            // Reset navigate back state
            this.store.dispatch(new CoreActions.NavigateBack(false));
          }
        })
      ).subscribe()
    );

    // we apply throttle and debounce to make sure we are grabbing the first and latest
    // set of events for the scrolling change

    const changeEvent = fromEvent(this.document.defaultView, 'scroll');

    this.subscriptions.push(
      changeEvent
        .pipe(
          throttleTime(100),
          distinctUntilChanged(),
          tap((x: Event) => {
            this.store.dispatch(new LayoutActions.ChangeScroll({
              xOffset: this.document.defaultView.pageXOffset,
              yOffset: this.document.defaultView.pageYOffset,
              offsetWidth: this.document.documentElement.offsetWidth,
              offsetHeight: this.document.documentElement.offsetHeight,
              scrollWidth: this.document.documentElement.scrollWidth,
              scrollHeight: this.document.documentElement.scrollHeight,
            }));
          })
        )
        .subscribe()
    );

    this.subscriptions.push(
      changeEvent
        .pipe(
          debounceTime(100),
          distinctUntilChanged(),
          tap((x: Event) => {
            this.store.dispatch(new LayoutActions.ChangeScroll({
              xOffset: this.document.defaultView.pageXOffset,
              yOffset: this.document.defaultView.pageYOffset,
              offsetWidth: this.document.documentElement.offsetWidth,
              offsetHeight: this.document.documentElement.offsetHeight,
              scrollWidth: this.document.documentElement.scrollWidth,
              scrollHeight: this.document.documentElement.scrollHeight,
            }));
          })
        )
        .subscribe()
    );

    // allowing dynamic layout mode change
    this.subscriptions.push(
      this.layoutMode$
        .pipe(
          skip(1),
          tap((layoutMode: LayoutModes) => {
              if (layoutMode) {
                this.layoutMode = layoutMode;
              }
            }
          )).subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
  }

  onLoggedOut() {
    this.store.dispatch(new AuthActions.Logout());
  }

  onNavigateBack() {
    this.store.dispatch(new CoreActions.NavigateBack(true));
  }
}
