import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Image } from '../../../api';
import { ImageFormats } from '../../constants/image';

@Pipe({
  name: 'appImg'
})
export class ImgPipe implements PipeTransform {

  transform(value: Array<Image>, args?: any): any {
    let path = '';
    if (value && value.length) {
      // default format medium
      let format = ImageFormats.Medium;
      // look if we need large
      if (args === 'lrg') {
        format = ImageFormats.Large;
      }

      // find the image from the format, if not there find default, if not there use first
      let img = value.find(image => image.format === format);
      if (!img) {
        img = value.find(image => image.format === ImageFormats.Medium);
      }
      if (!img) {
        img = value[0];
      }
      // pick the url
      path = img.url;
      path = environment.img_base_path + path;
      const index = path.indexOf('/medias/sys_master');
      const stiboindex = path.indexOf('pxmshare.colgatepalmolive.com');
      if(stiboindex >= 0){
        path =  img.url.replace("/hillsv2ccommerceservices/v2","");
       } else {
        path = '/hillsv2cstorefront' + path.substring(index);
        path = environment.img_base_path + path;  
       }    
    }
    return path;
  }
}
