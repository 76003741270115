import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'appSubscriptionFrequency'
})
export class SubscriptionFrequencyPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    let subscriptionFrequency = '';
   if (value) {
      if(value.toLowerCase() === 'onetime'){
        subscriptionFrequency = 'One time shipment';
      } else {
        let num = value.substring(5);
        if(num !== '1'){
          subscriptionFrequency = 'Every '+ num + ' weeks';
        } else {
          subscriptionFrequency = 'Every '+ num + ' week';
        }
      }

    }
    return subscriptionFrequency;
  }
}
