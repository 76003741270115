import { ProductsActionsUnion, ProductsActionTypes } from '../actions/products.actions';
import {
  Facet,
  Product, SearchedProducts, SearchedProduct,
} from '../../api';
import { ProductFilters } from '../models/productFilters';

export interface State {
  // products
  planSearchPending: boolean;
  searchedPlanProducts: SearchedProducts;
  planFacets: Facet[];
  productDetail: Product;
  planSort: string;
  planCurrentPage: number;
  planFilters: ProductFilters;
}

export const initialState: State = {
  planSearchPending: false,
  searchedPlanProducts: null,
  planFacets: null,
  planFilters: null,
  planSort: null,
  planCurrentPage: null,
  productDetail: null
};

export function reducer(state = initialState, action: ProductsActionsUnion): State {
  switch (action.type) {

    case ProductsActionTypes.SearchPlan: {
      return {
        ...state,
        planSearchPending: true,
        planCurrentPage: action.pageNumber,
      };
    }

    case ProductsActionTypes.SearchPlanSuccess: {
      return {
        ...state,
        searchedPlanProducts: {
          ...action.searchedPlanProducts,
          products: state.searchedPlanProducts ? state.searchedPlanProducts.products.concat(action.searchedPlanProducts.products) : action.searchedPlanProducts.products,
        },
        planSearchPending: false,
      };
    }

    case ProductsActionTypes.FilterProductSuccess: {
      return {
        ...state,
        searchedPlanProducts: {
          ...action.searchedPlanProducts,
          //  products: action.searchedPlanProducts.products,
        },
        planSearchPending: false,
      };
    }

    case ProductsActionTypes.SearchProductsByName: {
      return {
        ...state,
        planSearchPending: true,
        planCurrentPage: action.pageNumber,
      };
    }

    case ProductsActionTypes.SearchProductsByNameSuccess: {
      return {
        ...state,
        searchedPlanProducts: {
          ...action.searchedPlanProducts,
          //  products: action.searchedPlanProducts.products,
        },
        planSearchPending: false,
      };
    }

    case ProductsActionTypes.GetPlanFacetsSuccess: {

      return {
        ...state,
        planFacets: action.planFacets,
      };

      // return {
      //   ...state,
      //   planFacets: state.planFacets ? [...state.planFacets,...action.planFacets] : action.planFacets,
      // };  // merging values of facets , TODO need to verify if this needed
    }

    case ProductsActionTypes.SetPlanFilters: {

      return {
        ...state,
        planFilters: action.planFilters,
      };
    }

    case ProductsActionTypes.SetPlanSort: {
      return {
        ...state,
        planSort: action.planSort,
      };
    }

    case ProductsActionTypes.ResetSearchPlan: {
      return {
        ...state,
        searchedPlanProducts: null,
        planFacets: null,
        planFilters: null,
        planSort: '',
        planCurrentPage: 0,
      };
    }

    case ProductsActionTypes.GetProductDetailSuccess: {
      return {
        ...state,
        productDetail: action.productDetail,
      };
    }

    default:
      return state;
  }
}

export const getPlanSearchPending = (state: State) => state.planSearchPending;
export const getSearchedPlanProducts = (state: State) => state.searchedPlanProducts;
export const getPlanFacets = (state: State) => state.planFacets;
export const getPlanFilters = (state: State) => state.planFilters;
export const getPlanSort = (state: State) => state.planSort;
export const getPlanCurrentPage = (state: State) => state.planCurrentPage;
export const getProductDetail = (state: State) => state.productDetail;

