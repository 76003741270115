import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Exception } from '../../../shared/exceptions/custom';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  private _error = '';
  private _errorName = null;

  production = environment.production;

  @Input()
  set error(error: any) {
    if (error instanceof Exception) {
      this._errorName = error.name;
      this._error = error.toString();
    } else if (error.stack) {
      this._error = error.stack;
    } else {
      if (error.name) { this._errorName = error.name; }
      // spacing level = 2
      this._error = JSON.stringify(error, null, 2);
    }
  }

  get error() {
    return this._error;
  }

  get errorName() {
    return this._errorName;
  }

  @Input()
  isMobile: boolean | false;

  constructor() { }

  ngOnInit() { }
}
