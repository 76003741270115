import {
  createSelector,
  createFeatureSelector,
  ActionReducerMap,
} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromProducts from './products.reducer';

export interface ProductsState {
  status: fromProducts.State;
}

export interface State extends fromRoot.State {
  products: ProductsState;
}

export const reducers: ActionReducerMap<ProductsState> = {
  status: fromProducts.reducer,
};

export const getProductsState = createFeatureSelector<ProductsState>('products');

// Products Status

export const getProductsStatusState = createSelector(
  getProductsState,
  (state: ProductsState) => state.status
);

export const getPlanSearchPending = createSelector(
  getProductsStatusState,
  fromProducts.getPlanSearchPending
);

export const getSearchedPlanProducts = createSelector(
  getProductsStatusState,
  fromProducts.getSearchedPlanProducts
);

export const getPlanFacets = createSelector(
  getProductsStatusState,
  fromProducts.getPlanFacets
);

export const getPlanFilters = createSelector(
  getProductsStatusState,
  fromProducts.getPlanFilters
);

export const getPlanSort = createSelector(
  getProductsStatusState,
  fromProducts.getPlanSort
);

export const getPlanCurrentPage = createSelector(
  getProductsStatusState,
  fromProducts.getPlanCurrentPage
);

export const getProductDetail = createSelector(
  getProductsStatusState,
  fromProducts.getProductDetail
);

