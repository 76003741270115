<div [class.app-mobile-layout]="isMobile" [class.app-full-layout]="!isMobile" class="h-100 d-flex flex-column"
  [class.alternate-1]="layoutMode === LayoutModes.Alternate1"
  [class.alternate-3]="layoutMode === LayoutModes.Alternate3">

  <!-- to do to confirm alternate2 layout -->
  <!-- [class.alternate-2]="layoutMode === LayoutModes.Alternate2" -->

  <!-- Header Mobile -->
  <header *ngIf="isMobile && isLoggedIn" class="fixed-top pl-2 pr-2">

    <div class="d-flex align-items-center py-2">
      <a class="p-3 no-outline" *ngIf="back" [@flyOutIn]="back? 'out' : ''"
        [class.alternate]="layoutMode === LayoutModes.Alternate2 || layoutMode === LayoutModes.Alternate3"
        href="javascript:void(0)" (click)="goBack()" aria-label="Back">
        <i class="fa fa-arrow-left"></i>
      </a>
      <a (click)="logoRoute()" class="d-flex no-outline">
        <i class="app-logo-icon"></i>
      </a>
      <!-- <a class="p-3 no-outline" *ngIf="back" [@flyOutIn]="back? 'out' : ''"
        [class.alternate]="layoutMode === LayoutModes.Alternate2 || layoutMode === LayoutModes.Alternate3"
        href="javascript:void(0)" (click)="goBack()">
        <i class="fa fa-arrow-left"></i>
      </a> -->
      <!-- <div class="d-flex align-items-center flex-grow-1 text-center">
        {{title}}
      </div> -->
      <!-- <div class="d-flex align-items-center">
        <ng-content select='div[app-header-content]'></ng-content>
      </div> -->
      <div *ngIf="isLoggedIn && profile?.stripeVetAccountId && profile?.termsAndConditions"
        class="d-flex align-items-center flex-grow-1 justify-content-end">
        <!-- <a href="javascript:void(0)" class="p-3 app-cart-link no-outline"
          [class.alternate]="layoutMode === LayoutModes.Alternate2 || layoutMode === LayoutModes.Alternate3">
          <i class="fas fa-bell"></i>
          <span class="badge">2</span>
        </a> -->
        <a class="p-3 app-cart-link no-outline"
          [class.alternate]="layoutMode === LayoutModes.Alternate2 || layoutMode === LayoutModes.Alternate3"
          [routerLink]="isCartEmpty ? '':'/cart'">
          <i (click)="openToolTip();" class="fas fa-shopping-cart"></i>
          <span class="badge" *ngIf="cart?.deliveryItemsQuantity">{{cart?.deliveryItemsQuantity}}</span>
          <p class="cart-tooltip custom-font px-3 py-2" [ngClass]="isCartEmpty ? 'visible':'hidden'">You have no items
            in your Shopping Cart</p>
        </a>
        <a *ngIf="isLoggedIn && profile?.stripeVetAccountId && profile?.termsAndConditions"
          class="hamburger ml-3 mr-1 no-outline"
          [class.alternate]="layoutMode === LayoutModes.Alternate2 || layoutMode === LayoutModes.Alternate3"
          href="javascript:void(0)" (click)="openMenu()" aria-label="Menu">
          <i class="fa fa-bars"></i>
        </a>
      </div>
    </div>

  </header>

  <!-- Header Desktop -->
  <header *ngIf="!isMobile" class="header-lg">

    <div *ngIf="router.url === '/pets/shop'">
      <app-edit-order-delivery-page></app-edit-order-delivery-page>
    </div>

    <div *ngIf="isLoggedIn" class="container d-flex pt-2 pb-2 align-items-center custom-font"
      [ngClass]="{'header-ipad' : isLoggedIn}">
      <!-- px-md-2-->
      <div class="d-flex align-items-center justify-content-start w-20 main-logo">
        <a (click)="logoRoute()" class="d-flex">
          <i class="app-logo"></i>
        </a>
      </div>
      <div
        class="primary-menu flex-grow-1 d-flex align-items-center justify-content-lg-end justify-content-sm-center flex-wrap pr-5 main-menu-items">
        <a *ngIf="isLoggedIn && profile?.stripeVetAccountId && profile?.termsAndConditions"
          [routerLinkActive]="['active-menu']" class=" ml-4 alternate text-nowrap" routerLink="/pets/shop">
          <!-- <i class="fas fa-paw app-icon"></i> -->
          Home/Shop 
          <!-- Jake -->
        </a>
        <a *ngIf="isLoggedIn && profile?.stripeVetAccountId && profile?.termsAndConditions"
          [routerLinkActive]="['active-menu']" class="ml-4 alternate text-nowrap" routerLink="/pets/profile">
          <!-- <i class="fas fa-paw app-icon"></i> -->
          Pets
        </a>
        <a *ngIf="isLoggedIn && profile?.stripeVetAccountId && profile?.termsAndConditions"
          [routerLinkActive]="['active-menu']" class="ml-4 alternate text-nowrap" routerLink="/orders">
          <!-- <i class="fas fa-tag app-icon"></i> -->
          Orders
        </a>
        <a *ngIf="isLoggedIn && profile?.stripeVetAccountId && profile?.termsAndConditions"
          class="ml-4 alternate text-nowrap" routerLink="/subscriptions" [routerLinkActive]="['active-menu']">
          Subscriptions
        </a>
        <a *ngIf="isLoggedIn && profile?.stripeVetAccountId && profile?.termsAndConditions"
          [routerLinkActive]="['active-menu']" class="ml-4 alternate text-nowrap" routerLink="/profile">
          <!-- <i class="fas fa-tag app-icon"></i> -->
          My Account
        </a>

        <a *ngIf="isLoggedIn && profile?.stripeVetAccountId && profile?.termsAndConditions"
          [routerLinkActive]="['active-menu']" class="ml-4 alternate text-nowrap" routerLink="/support">
          <!-- <i class="fas fa-address-card app-icon"></i> -->
          Support
        </a>
        <!-- <a *ngIf="isLoggedIn?(!profile?.stripeVetAccountId || !profile?.termsAndConditions):true"
          class="ml-4 h2 alternate text-nowrap" routerLink="/home">Hill's to Home</a> -->
      </div>
      <div class="d-flex flex-shrink-0 align-items-center justify-content-end">
        <!-- <a href="javascript:void(0)" *ngIf="isLoggedIn && profile?.stripeVetAccountId && profile?.termsAndConditions"
          class="font-weight-light alternate mr-4 app-cart-link">
          <i class="fas fa-bell"></i>
          <span class="badge">5</span>
        </a> -->
        <a *ngIf="isLoggedIn && profile?.stripeVetAccountId && profile?.termsAndConditions"
          [routerLink]="isCartEmpty ? '':'/cart'" class="font-weight-light alternate mr-4 ml-1 app-cart-link">
          <!-- <span class="mr-1">Cart</span> -->
          <i (click)="openToolTip()" class="fas fa-shopping-cart"></i>
          <span class="badge" *ngIf="cart?.deliveryItemsQuantity">{{cart?.deliveryItemsQuantity}}</span>
          <p class="cart-tooltip custom-font px-3 py-2" [ngClass]="isCartEmpty ? 'visible':'hidden'">You have no items
            in your Shopping Cart</p>
        </a>
        <a *ngIf="isLoggedIn && profile?.stripeVetAccountId && profile?.termsAndConditions" (click)="logout()"
          class="ml-1 btn btn-blue btn-logout">
          <span>Log Out</span>
        </a>
        <!-- <a *ngIf="!isLoggedIn" routerLink="/login" class="user-profile w500 alternate">
          Sign In <i class="fas fa-user ml-1"></i>
        </a> -->
        <a *ngIf="isLoggedIn && profile?.stripeVetAccountId && profile?.termsAndConditions"
          class="hamburger hamburger-tablet-view ml-3 no-outline"
          [class.alternate]="layoutMode === LayoutModes.Alternate2 || layoutMode === LayoutModes.Alternate3"
          href="javascript:void(0)" (click)="openMenu()" aria-label="Menu">
          <i class="fa fa-bars"></i>
        </a>
        <!-- <div *ngIf="isLoggedIn && profile?.stripeVetAccountId && profile?.termsAndConditions" ngbDropdown>
          <a href="javascript:void(0)" ngbDropdownToggle class="user-profile d-flex flex-shrink-0 align-items-center w500 alternate">
            {{profile?.firstName}} {{profile?.lastName}}
            <i class="fas fa-user ml-1"></i>
          </a>
          <div ngbDropdownMenu>
            <a routerLink="/profile" class="dropdown-item p-2">
              <i class="fas fa-user app-icon"></i>
              <span class="ml-2">My Account</span>
            </a>
            <a class="dropdown-item p-2" href="javascript:void(0)" (click)="logout()">
              <i class="fas fa-arrow-left app-icon"></i>
              <span class="ml-2">Sign Out</span>
            </a>
          </div>
        </div> -->
      </div>
    </div>
  </header>

  <!-- Main -->

  <main class="flex-grow-1" [ngClass]="{'mobile-login' : isMobile && isLoggedIn}">
    <router-outlet></router-outlet>
  </main>

  <!-- Footer Desktop -->

  <footer class="footer-lg custom-font" *ngIf="!isMobile || isstripeNotLinkedMobile"
    [ngClass]="{'footerIsStripe' : isstripeNotLinkedMobile}">
    <div class="container pt-4 pb-4">
      <!-- <div class="row my-3 mb-4" *ngIf="!isLoggedIn">
        <div class="col-12 footer-signin d-flex align-items-center w600">
          <a *ngIf="!isLoggedIn" href="javascript:void(0)">REGISTER</a><span *ngIf="!isLoggedIn" class="px-2">|</span>
          <a *ngIf="!isLoggedIn" href="javascript:void(0)">SIGN IN</a>
        </div>
      </div> -->
      <div class="row pt-2">
        <div class="col-lg-4 col-md-5 mb-md-2 mb-sm-2 mt-3">
          <div class="footer-hth d-flex">
            <div class="mr-3">
              <a class="d-flex" *ngIf="isLoggedIn" (click)="logoRoute()" ng-reflect-router-link="/pets/shop" aria-label="Navigate to Shop Page" alt="Navigate to Shop Page">
                <i class="app-logo"></i>
              </a>
              <a class="d-flex" *ngIf="!isLoggedIn" routerLink="/login" ng-reflect-router-link="/login" aria-label="Navigate to Login Page" alt="Navigate to Login Page">
                <i class="app-logo"></i>
              </a>
            </div>
            <div class="">
              <h6>HILL'S TO HOME</h6>
              <p>#1 Vet Recommended brand right to your door</p>
              <div>
                <h6>Contact Us</h6>
                <p class="my-1"><i class="fa fa-phone mr-2"></i>(800) 235-6877</p>
                <p class="my-1"><i class="fa fa-clock mr-2"></i>7 a.m. - 7 p.m. CST Mon-Fri</p>
                <p>Email <a class="text-underline" routerLink="/contact-us">Customer Support</a></p>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-lg-8 col-md-12">
          <div class="row"> -->
        <div class="col-lg-2 col-md-2 mt-md-3 mt-sm-4">
          <h6>ABOUT HILL'S&reg;</h6>
          <ul>
            <li><a [href]="'https://www.hillspet.com/about-us'" target="_blank">ABOUT</a></li>
          </ul>
        </div>
        <div class="col-lg-2 col-md-2 mt-md-3 mt-sm-4">
          <h6>OUR BRANDS</h6>
          <ul>
            <li><a [href]="'https://www.hillspet.com/prescription-diet'" target="_blank">PRESCRIPTION DIET</a></li>
            <li><a [href]="'https://www.hillspet.com/science-diet'" target="_blank">SCIENCE DIET</a></li>
          </ul>
        </div>
        <div class="col-lg-2 col-md-3 mt-md-3 mt-sm-4">
          <h6>HELP</h6>
          <ul>
            <!-- <li><a [href]="'https://www.hillspet.com/contact-us'" target="_blank">CONTACT US</a></li> -->
            <li><a href="javascript:void(0)" routerLink="/support">SUPPORT</a></li>
            <li><a [href]="'https://www.hillspet.com/legal-statement-and-privacy-policy'" target="_blank">LEGAL
                &amp; PRIVACY
                POLICY</a></li>
            <li><a href="javascript:void(0)" routerLink="/terms">TERMS OF SERVICES</a></li>
            <li><a [href]="'https://www.hillspet.com/do-not-sell-my-personal-information'" target="_blank">DO NOT SELL
                MY PERSONAL INFORMATION</a></li>
          </ul>
        </div>
        <div class="col-lg-2 col-md-12 mt-md-3 mt-sm-4">
          <!-- <h6>SUBSCRIBE</h6>
              <form class="d-flex align-items-center form-subscribe pt-2 pb-4">
                <input placeholder="Email" type="email" />
                <button type="button">SUBSCRIBE</button>
              </form> -->
          <div class="social-block pl-md-5 ml-md-4 pl-lg-0 ml-lg-0">
            <h6 class="pl-md-1 pl-lg-0">CONNECT WITH US</h6>
            <div class="d-flex social-brands pl-md-1 pl-lg-0">
              <a href="https://www.facebook.com/HillsPet/" target="_blank" class="fa fa-facebook-f" aria-label="Navigate to HillsPet Facebook Profile"></a>
              <a href="https://twitter.com/hillspet" target="_blank" class="fa fa-twitter" aria-label="Navigate to HillsPet Twitter Profile"></a>
              <a href="https://www.instagram.com/hillspet/" target="_blank" class="fa fa-instagram" aria-label="Navigate to HillsPet Instagram Profile"></a>
              <a href="https://www.youtube.com/user/OfficialHillsPet" target="_blank" class="fa fa-youtube" aria-label="Navigate to HillsPet Youtube Profile"></a>
            </div>
          </div>
        </div>
        <!-- </div>
        </div> -->
      </div>
      <!-- <div class="d-flex">
        <div class="d-flex align-items-start flex-grow-1">
          <a routerLink="/home" class="d-flex">
            <img class="app-logo" alt="Hills Logo"
              src='assets/images/Hills_TransformingLives_Logo_CMYK_WhtsubHead.png' />
          </a>
          <div style="color:#FFF;margin-left: 10px"> Contact Us<br>1 800-235-6877 <br>hillstohome@hillspet.com<br>7 a.m
            - 7 p.m CST Mon-Fri</div>
        </div>
        <div class="d-flex align-items-center">
          <a *ngIf="isLoggedIn && profile?.stripeVetAccountId && profile?.termsAndConditions"
            class="text-light font-weight-light" routerLink="/home">Home</a>
          <a *ngIf="isLoggedIn" class="ml-4 text-light font-weight-light" routerLink="/pets">My Pets</a>

          <a *ngIf="isLoggedIn && profile?.stripeVetAccountId && profile?.termsAndConditions"
            class="ml-4 text-light font-weight-light" routerLink="/orders">Orders</a>
          <a *ngIf="isLoggedIn && profile?.stripeVetAccountId && profile?.termsAndConditions"
            class="ml-4 text-light font-weight-light" routerLink="/subscriptions">Subscriptions</a>
          <a *ngIf="!isLoggedIn" class="ml-4 text-light font-weight-light" routerLink="/support">Support</a>
          <a *ngIf="!isLoggedIn" class="ml-4 text-light font-weight-light" routerLink="/login">Login</a>
          <a class="ml-2 pl-2 text-white-50 font-weight-light"
            [href]="'https://www.hillspet.com/legal-statement-and-privacy-policy'" appExternalUrl>Legal & Privacy
            Policy</a>
          <a class="ml-2 pl-2 text-white-50 font-weight-light" routerLink="/terms">Terms of service</a>
        </div>
      </div> -->
    </div>
    <div class="container-fluid copyright">
      <div class="row">
        <div class="container d-flex align-items-center justify-content-center py-4">
          <p class="m-0">&copy; Hill's to Home, 2019-2024 - All rights reserved. <a href="javascript:void(0)" onclick="truste.eu &amp;&amp; truste.eu.clickListener(2)">Cookie Consent Tool</a></p>
        </div>
      </div>
    </div>
  </footer>

  <!-- Footer Mobile -->

  <footer class="footer-lg custom-font mobile-footer" *ngIf="isMobile">
    <div class="container pt-4 pb-4">
      <!-- <div class="row my-3 mb-4" *ngIf="!isLoggedIn">
        <div class="col-12 footer-signin d-flex align-items-center w600">
          <a *ngIf="!isLoggedIn" href="javascript:void(0)">REGISTER</a><span *ngIf="!isLoggedIn" class="px-2">|</span>
          <a *ngIf="!isLoggedIn" href="javascript:void(0)">SIGN IN</a>
        </div>
      </div> -->
      <div class="row pt-2">
        <div class="col-md-4">
          <div class="footer-hth d-flex flex-column justify-content-center text-center align-items-center">
            <div class="pb-4">
              <a class="d-flex" *ngIf="isLoggedIn" (click)="logoRoute()" ng-reflect-router-link="/pets/shop" aria-label="Navigate to Shop Page" alt="Navigate to Shop Page">
                <i class="app-logo"></i>
              </a>
              <a class="d-flex" *ngIf="!isLoggedIn" routerLink="/login" ng-reflect-router-link="/login" aria-label="Navigate to Login Page" alt="Navigate to Login Page">
                <i class="app-logo"></i>
              </a>
            </div>
            <div class="about-hth">
              <h6>HILL'S TO HOME</h6>
              <p>#1 Vet Recommended brand right to your door</p>
              <div>
                <h6>Contact Us</h6>
                <p class="my-1"><i class="fa fa-phone mr-2"></i>(800) 235-6877</p>
                <p class="my-1"><i class="fa fa-clock mr-2"></i>7 a.m. - 7 p.m. CST Mon-Fri</p>
                <p>Email <a class="text-underline" routerLink="/contact-us">Customer Support</a></p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="row">
            <div
              class="has-dd col-md-3 py-4 mt-4 has-border-top d-flex flex-column align-items-center justify-content-center"
              [ngClass]="{ 'visible': openFooterAboutAccordian }" (click)="toggleFooterAboutAccordian()">
              <h6 class="m-0">ABOUT HILL'S&reg;</h6>
              <ul class="pt-3">
                <li><a [href]="'https://www.hillspet.com/prescription-diet'" target="_blank">OUR BRANDS</a></li>
                <!--<li><a href="javascript:void(0)">PROMOTIONS</a></li>-->
                <li><a [href]="'https://www.hillspet.com/about-us'" target="_blank">ABOUT</a></li>
              </ul>
            </div>
            <div
              class="has-dd col-md-4 py-4 has-border-top d-flex flex-column align-items-center justify-content-center"
              [ngClass]="{ 'visible': openFooterHelpAccordian }" (click)="toggleFooterHelpAccordian()">
              <h6 class="m-0">HELP</h6>
              <ul class="pt-3">
                <!-- <li><a [href]="'https://www.hillspet.com/contact-us'" target="_blank">CONTACT US</a></li> -->
                <li><a href="javascript:void(0)" routerLink="/support">SUPPORT</a></li>
                <li><a [href]="'https://www.hillspet.com/legal-statement-and-privacy-policy'" target="_blank">LEGAL
                    &amp; PRIVACY
                    POLICY</a></li>
                <li><a href="javascript:void(0)" routerLink="/terms">TERMS OF SERVICES</a></li>
                <li><a [href]="'https://www.hillspet.com/do-not-sell-my-personal-information'" target="_blank">DO NOT
                    SELL MY PERSONAL INFORMATION</a></li>
              </ul>
            </div>
            <div class="col-md-5 pt-4 px-4 has-border-top d-flex flex-column align-items-center justify-content-center">
              <!-- <h6 class="pb-1">SUBSCRIBE</h6>
              <form class="d-flex w-100 flex-column justify-content-center align-items-center form-subscribe pt-2 pb-4">
                <input class="w-100 py-3 mb-1" placeholder="Email" type="email" />
                <button class="w-100 mt-2 py-3" type="button">SUBSCRIBE</button>
              </form> -->
              <h6 class="pt-2">CONNECT WITH US</h6>
              <div class="d-flex social-brands">
                <a href="https://www.facebook.com/HillsPet/" target="_blank" class="fa fa-facebook-f" aria-label="Navigate to HillsPet Facebook Profile"></a>
                <a href="https://twitter.com/hillspet" target="_blank" class="fa fa-twitter" aria-label="Navigate to HillsPet Twitter Profile"></a>
                <a href="https://www.instagram.com/hillspet/" target="_blank" class="fa fa-instagram" aria-label="Navigate to Instagram Facebook Profile"></a>
                <a href="https://www.youtube.com/user/OfficialHillsPet" target="_blank" class="fa fa-youtube" aria-label="Navigate to Youtube Facebook Profile"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="d-flex">
        <div class="d-flex align-items-start flex-grow-1">
          <a routerLink="/home" class="d-flex">
            <img class="app-logo" alt="Hills Logo"
              src='assets/images/Hills_TransformingLives_Logo_CMYK_WhtsubHead.png' />
          </a>
          <div style="color:#FFF;margin-left: 10px"> Contact Us<br>1 800-235-6877 <br>hillstohome@hillspet.com<br>7 a.m
            - 7 p.m CST Mon-Fri</div>
        </div>
        <div class="d-flex align-items-center">
          <a *ngIf="isLoggedIn && profile?.stripeVetAccountId && profile?.termsAndConditions"
            class="text-light font-weight-light" routerLink="/home">Home</a>
          <a *ngIf="isLoggedIn" class="ml-4 text-light font-weight-light" routerLink="/pets">My Pets</a>

          <a *ngIf="isLoggedIn && profile?.stripeVetAccountId && profile?.termsAndConditions"
            class="ml-4 text-light font-weight-light" routerLink="/orders">Orders</a>
          <a *ngIf="isLoggedIn && profile?.stripeVetAccountId && profile?.termsAndConditions"
            class="ml-4 text-light font-weight-light" routerLink="/subscriptions">Subscriptions</a>
          <a *ngIf="!isLoggedIn" class="ml-4 text-light font-weight-light" routerLink="/support">Support</a>
          <a *ngIf="!isLoggedIn" class="ml-4 text-light font-weight-light" routerLink="/login">Login</a>
          <a class="ml-2 pl-2 text-white-50 font-weight-light"
            [href]="'https://www.hillspet.com/legal-statement-and-privacy-policy'" appExternalUrl>Legal & Privacy
            Policy</a>
          <a class="ml-2 pl-2 text-white-50 font-weight-light" routerLink="/terms">Terms of service</a>
        </div>
      </div> -->
    </div>
    <div class="container-fluid copyright">
      <div class="row">
        <div class="container d-flex align-items-center justify-content-center py-4">
          <p class="m-0">&copy; Hill's to Home, 2019-2024 - All rights reserved. <a href="javascript:void(0)" onclick="truste.eu &amp;&amp; truste.eu.clickListener(2)">Cookie Consent Tool</a></p>
        </div>
      </div>
    </div>
  </footer>


</div>

<app-loader [isMobile]="isMobile" *ngIf="loaderVisible" [@fade]="loaderVisible? 'in' : ''">
</app-loader>

<ng-template #menuModal let-closeMenu="close">
  <button (click)="closeMenu()" class="close-tab" type="button"><i class="fa fa-times"></i></button>
  <div class="app-mobile-layout mobile-menu-sidebar" (click)="closeMenu()" [@flyInOut]="'out'">
    <div class="app-menu d-flex flex-column h-100 custom-font">
      <div class="d-flex justify-content-end">
        <i class="app-logo"></i>
      </div>
      <div class="mobile-menu-tabs d-flex flex-column">
        <a routerLink="/pets/shop">
          <i class="fas fa-home app-icon ml-2"></i>
          HOME
        </a>

        <a routerLink="/pets/shop">
          <i class="fas fa-paw app-icon ml-2"></i>
          SHOP
        </a>
        <a routerLink="/pets/profile">

          <i class="fas fa-paw app-icon ml-2"></i>
          PETS
        </a>
        <a routerLink="/orders">
          <i class="fas fa-tag app-icon ml-2"></i>
          ORDERS
        </a>
        <a routerLink="/subscriptions">
          <i class="fas fa-paw app-icon ml-2"></i>
          SUBSCRIPTIONS
        </a>
        <a routerLink="/profile">
          <i class="fas fa-user app-icon ml-2"></i>
          MY ACCOUNT
        </a>
        <a routerLink="/support">
          <i class="fas fa-address-card app-icon ml-2"></i>
          SUPPORT
        </a>
        <!-- <a class="p-4" [href]="'https://www.hillspet.com/legal-statement-and-privacy-policy'" appExternalUrl>
          <i class="fas fa-file app-icon ml-2"></i>
          <span class="h5 font-weight-normal ml-4">Legal & Privacy Policy</span>
        </a>
        <a class="p-4" routerLink="/terms">
          <i class="fas fa-file app-icon ml-2"></i>
          <span class="h5 font-weight-normal ml-4">Terms of Service</span>
        </a> -->
        <a href="javascript:void(0)" (click)="logout()" aria-label="Logout">
          <i class="fas fa-arrow-left app-icon ml-2"></i>
          LOGOUT
        </a>
      </div>
      <div class="d-flex align-items-center justify-content-end pt-3 pb-4 menu-social-media">
        <a href="https://www.facebook.com/HillsPet/" target="_blank" class="fa fa-facebook-f p-1 mx-2"></a>
        <a href="https://twitter.com/hillspet" target="_blank" class="fa fa-twitter p-1 mx-2"></a>
        <a href="https://www.youtube.com/user/OfficialHillsPet" target="_blank" class="fa fa-youtube p-1 mx-2"></a>
      </div>
      <div class="flex-grow-1">
      </div>

    </div>
  </div>
</ng-template>

<ng-template #confirmUpdateModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-body">
    <p class="m-4 h4">
      There is a new version of the application, would you like to update now?
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary pl-5 pr-5 btn-lg" (click)="dismissConfirm()">
      No
    </button>
    <button type="button" class="btn btn-primary pl-5 pr-5 btn-lg ml-2" (click)="closeConfirm()">
      Yes
    </button>
  </div>
</ng-template>