import { Component, OnInit, Input, EventEmitter, Output, SimpleChange, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbAccordion, NgbModal, NgbAccordionConfig, NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { OrderFilters } from '../../models/orderFilters';
import { debounceTime, distinctUntilChanged, skip } from 'rxjs/operators';
import { DateUtil } from '../../../shared/utils/dateUtil';
import { Order, OrderDetail } from '../../../api';
import { DeliveryFrequencies, OrderStatuses } from '../../../shared/constants/payment';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DateValidation } from '../../../shared/validation/dateValidation';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  providers: [DatePipe, NgbAccordionModule]
})
export class OrdersComponent implements OnInit, OnChanges {

  DeliveryFrequencies = DeliveryFrequencies;

  OrderStatuses = OrderStatuses;

  selectedPanelId: string;

  searchForm: UntypedFormGroup;

  footerStr: string = "";

  @Input() isMobile: boolean | false;

  @Input() orders: Order[] | null;

  @Input() pending: boolean | false;

  @Output() submitted = new EventEmitter<OrderFilters>();

  @Input() orderDetail: OrderDetail | null;

  @Output() orderSelected = new EventEmitter<Order>();

  @Output() cancelling = new EventEmitter<Order>();

  @Output() returning = new EventEmitter<Order>();

  todayDate: string;

  @ViewChild('ordersAccordion') ordersAccordion: NgbAccordion;
  @ViewChild('confirmModal', { static: true }) confirmModal: any;
  @ViewChild('returnModal', { static: true }) returnModal: any;

  constructor(private fb: UntypedFormBuilder, private router: Router, private modalService: NgbModal, private datePipe: DatePipe, private deviceService: DeviceDetectorService, private titlecasePipe: TitleCasePipe, config: NgbAccordionConfig) {
    this.createForm();
    config.closeOthers = true;
  }

  createForm() {
    const now = new Date();
    const prev = DateUtil.add(now, -90, 'd');
    const dtr = DateUtil.getDateStr(prev);
    const dtr2 = DateUtil.getDateStr(now);
    this.todayDate = DateUtil.getDateStr(now);

    this.searchForm = this.fb.group({
      startDate: [dtr, [Validators.required, DateValidation.maxDate(this.todayDate)]],
      endDate: [dtr2, [Validators.required, DateValidation.maxDate(this.todayDate)]]
    });

    this.searchForm.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged((x, y) => {
          const same =
            x.startDate === y.startDate &&
            x.endDate === y.endDate;
          return same;
        }),
        skip(1) // skips the first search with the default values
      )
      .subscribe(() => {
        this.search();
      });
  }

  ngOnInit() {
    // setting time out to move search to next cycle
    setTimeout(() => {
      this.search();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const pendingChange: SimpleChange = changes['pending'];
    if (pendingChange && !pendingChange.firstChange) {
      if (pendingChange.currentValue) {
        this.searchForm.disable();
      } else {
        this.searchForm.enable();
      }
    }
  }

  search() {
    if (this.searchForm.valid) {
      const model = this.searchForm.value;
      const filters: OrderFilters = {
        startDate: model.startDate,
        endDate: model.endDate
      };
      this.submitted.emit(filters);
      this.searchForm.disable();

      if (this.selectedPanelId) {
        this.ordersAccordion.toggle(this.selectedPanelId);
      }
    }
  }

  select(order: Order) {
    this.orderDetail = null;

    if (order) {
      if (this.isMobile) {
        this.router.navigate(['/orders', order.code]);
      } else {
        this.orderSelected.emit(order);
      }
    }
  }

  cancelOrder(order: Order) {
    this.cancelling.emit(order);
  }

  confirm(order: Order) {
    this.modalService.open(this.confirmModal).result.then(
      () => {
        this.cancelOrder(order);
      },
      () => { }
    );
  }

  returnOrder(order: Order) {
    this.modalService.open(this.returnModal).result.then(
      () => {
        this.confirmReturnOrder(order);
      },
      () => { }
    );
  }

  getBase64ImageFromURL(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }

  taxTotalText(order: OrderDetail): string {
    let finalStr = "";
    if (order.status === OrderStatuses.PartialShipped) {
      finalStr = "*";
      this.footerStr = "\n*Note: Final order total will be reflected on your credit card.";
    } else {
      this.footerStr = "";
    }

    if (order.sapTotalTaxValue == undefined) finalStr += 'Estimated Tax and Surcharges';
    else finalStr += 'Total Taxes and Surcharges';

    return finalStr;
  }

  async downloadInvoice(order: OrderDetail) {
    try {
      const pdfMake = (await import('pdfmake/build/pdfmake')).default;
      const pdfFonts = (await import('pdfmake/build/vfs_fonts')).default;

      pdfMake.vfs = pdfFonts.pdfMake.vfs;

      var table_body = [
        // Table Header
        [
          { text: 'PET', style: ['itemsHeader', 'center'] },
          { text: 'QUANTITY', style: ['itemsHeader', 'center'] },
          { text: 'ITEM #', style: ['itemsHeader', 'center'] },
          { text: 'DESCRIPTION', style: ['itemsHeader', 'center'] },
          { text: 'UNIT PRICE', style: ['itemsHeader', 'center'] },
          { text: 'DISCOUNT', style: ['itemsHeader', 'center'] },
          { text: 'LINE TOTAL', style: ['itemsHeader', 'center'] }
        ]
      ];

      for (var key in order.entries) {
        if (order.entries.hasOwnProperty(key)) {
          var data = order.entries[key];
          var row = new Array();

          row.push({ text: data.pet.toString(), style: 'item_values' });
          row.push({ text: data.quantity.toString(), style: 'item_values' });
          row.push({ text: data.product.code.toString(), style: 'item_values' });
          row.push({ text: data.product.name.toString(), style: 'item_values_left' });
          row.push({ text: data.basePrice.formattedValue.toString(), style: 'item_values' });
          row.push({ text: '$' + ((data.basePrice.value * data.quantity) - data.totalPrice.value).toFixed(2).toString(), style: 'item_values' });
          row.push({ text: data.totalPrice.formattedValue.toString(), style: 'item_values' });
          table_body.push(row);
        }
      }

      var docDefinition = {
        pageMargins: [30, 20, 40, 127],
        content: [
          {
            image: await this.getBase64ImageFromURL(
              '../../../../assets/images/060118_HillsToHomeLogo.png'
            ),
            width: 100,
            alignment: "center"
          },
          {
            text: 'INVOICE',
            style: 'header'
          },
          {
            columns: [
              {
                text: 'DATE',
                style: ['invoice_title', 'left_align'],
              },
              {
                text: 'INVOICE NO',
                style: ['invoice_title', 'right_align'],
              },
            ]
          },
          {
            columns: [
              {
                text: this.datePipe.transform(order.created, "MM/dd/yy"),
                style: ['invoice_value', 'left_align'],
              },
              {
                text: order.code,
                style: ['invoice_value', 'right_align'],
              },
            ]
          },
          {
            text: 'PAYMENT METHOD : ' + this.titlecasePipe.transform(order.paymentInfo.cardType.code) + ' ending in - ' + order.paymentInfo.cardNumber,
            style: 'payment_info'
          },
          {
            text: 'INVOICE TO :',
            style: 'sub_header'
          },
          {
            text: 'Pet Name:        ' + order.entries[0].pet.toString(),
            style: 'invoice_to'
          },
          {
            text: 'Pet Parent:       ' + order.deliveryAddress.firstName + ' ' + order.deliveryAddress.lastName,
            style: 'invoice_to'
          },
          {
            text: 'Address:           ' + order.deliveryAddress.formattedAddress,
            style: 'invoice_to',
            alignment: 'left',
          },
          '\n\n',
          {
            table: {
              headerRows: 1,
              widths: [45, 45, 45, '*', 45, 45, 45],
              body: table_body
            },
          },
          '\n',
          {
            table: {
              headerRows: 0,
              widths: ['*', 80],
              body: [
                [
                  { text: 'Product Total', style: 'itemsFooterSubTitle', border: [false, false, false, false] },
                  { text: '$' + (order.subTotal.value + order.totalDiscounts.value).toFixed(2), style: 'itemsFooterSubValue', border: [false, false, false, false] }
                ],
                [
                  { text: 'Subscription Discount', style: 'itemsFooterSubTitle', border: [false, false, false, false] },
                  { text: (order.productDiscounts.value > 0 ? '-' : '') + order.productDiscounts.formattedValue, style: 'itemsFooterSubValue', border: [false, false, false, false] }
                ],
                [
                  { text: 'Promotions Discount', style: 'itemsFooterSubTitle', border: [false, false, false, false] },
                  { text: (order.orderDiscounts.value > 0 ? '-' : '') + order.orderDiscounts.formattedValue, style: 'itemsFooterSubValue', border: [false, false, false, false] }
                ],
                [
                  { text: 'Shipping', style: 'itemsFooterSubTitle', border: [false, false, false, false] },
                  { text: order.deliveryCost.formattedValue, style: 'itemsFooterSubValue', border: [false, false, false, false] }
                ],
                createConditionalRow('State Delivery fee**', order.retailDeliveryFee?.value, order.retailDeliveryFee?.formattedValue),
                [
                  { text: await this.taxTotalText(order), style: 'itemsFooterSubTitle', border: [false, false, false, false] },
                  { text: "$" + (order.sapTotalTaxValue == undefined ? (Number(order.totalTax.value) + order.totalSurcharge.value) : (Number(order.sapTotalTaxValue) + order.totalSurcharge.value)).toFixed(2), style: 'itemsFooterSubValue', border: [false, false, false, true] }
                ],
                [
                  { text: this.orderDetail.status === this.OrderStatuses.Shipped ? 'Order Total' : '*Order Total', style: 'itemsFooterTotalTitle', border: [false, false, false, false] },
                  { text: order.sapOrderTotalValue == undefined ? order.totalPrice.formattedValue : "$" + order.sapOrderTotalValue, style: 'itemsFooterTotalValue', border: [false, false, false, true] }
                ],
              ].filter(row => row !== null) // Filter out null rows
            }
          },
          {
            columns: [
              {
                text: 'Free shipping excluding Alaska and Hawaii which will incur a surcharge of $1.00/item. Please note that shipments to Alaska, Hawaii, and some remote areas in the continental US may take additional business days to arrive. Shipping rates and discounts are subject to change.',
                style: ['footNote'],
              }
            ]
          },
          order?.retailDeliveryFee?.value ? {
            columns: [
              {
                text: '**If your state has a retail delivery fee, this fee is non-refundable.',
                style: ['footNote'],
              }
            ]
          } : null
        ],
        footer: [
          {
            text: ['By placing this order, you agree to the Hill\'s to Home Terms of Service and our Legal and Privacy Policy; both are available on ',
            { text: "HillsToHome.com", link: "https://www.hillstohome.com", decoration: "underline" },
            this.footerStr],
            style: 'footer_text_contents'
          },
          {
            table: {
              widths: ['*', '*'],
              body: [
                [
                  { text: 'RETURN/REFUND POLICY', style: ['footer_header', 'footer_left'] },
                  { text: 'CONTACT US ', style: ['footer_header', 'footer_right'] }
                ],
                [
                  { text: 'If you need to return product for any reason, please contact Hill\'s to Home rather than returning the product to your veterinarian.', style: ['footer_contents', 'footer_left'] },
                  { text: ['Login to your account on ',
                  { text: "www.hillstohome.com", link: "https://www.hillstohome.com", decoration: "underline" }, ' to make changes to your account, return product, cancel or modify a subscription, or to chat online with a live representative. '], style: ['footer_contents', 'footer_right'] }
                ],
                [
                  { text: 'Hill\'s to Home service provided by Hill\'s Pet Nutrition, Inc, P. O. Box 148 Topeka KS, 66601-0148 © 2018  Hill\'s Pet Nutrition, Inc. ®/™ Trademarks owned by Hill\'s® Pet Nutrition, Inc. As used herein, ® denotes registered trademark status in the U.S. only; registration status in other geographies may be different.', style: ['footer_contents', 'footer_left'] },
                  { text: 'You can also email Hill\'s to Home Customer Service with any questions or feedback at HillsToHome@hillspet.com; or call us at +1-800-235-6877. Customer Service Department is available 7am-7pm CST, Monday through Friday. We\'d love to hear from you!', style: ['footer_contents', 'footer_right'] }
                ],
                [
                  { text: 'As a service to veterinarian, Hill\'s will report tax on this sale to the proper jurisdiction(s) under Hill\'s permit number.', style: ['footer_contents', 'footer_left'] },
                  { text: ['Follow Hill\'s on Facebook at ',
                  { text: "www.facebook.com/HillsPet", link: "https://www.facebook.com/HillsPet", decoration: "underline" }], style: ['footer_contents', 'footer_right'] }
                ],
              ],
            },
            layout: 'noBorders'
          }
        ],
        styles: {
          header: {
            fontSize: 28,
            margin: [0, 15, 0, 0]
          },
          invoice_title: {
            fontSize: 15,
            margin: [0, 15, 0, 0]
          },
          left_align: {
            alignment: 'left'
          },
          right_align: {
            alignment: 'right'
          },
          invoice_value: {
            fontSize: 13,
            margin: [0, 0, 0, 0]
          },
          sub_header: {
            fontSize: 15,
            margin: [0, 20, 0, 0]
          },
          invoice_to: {
            fontSize: 11,
            margin: [0, 2, 0, 0],
            width: 300
          },
          payment_info: {
            fontSize: 15,
            margin: [0, 20, 0, 0]
          },
          itemsHeader: {
            margin: [-1, 5, -1, 5],
            fontSize: 9.5,
            bold: true
          },
          center: {
            alignment: 'center',
          },
          item_values: {
            alignment: 'center',
            fontSize: 10.5
          },
          item_values_left: {
            alignment: 'left',
            fontSize: 10.5
          },
          itemsFooterSubTitle: {
            margin: [0, 5, 0, 5],
            alignment: 'right',
          },
          itemsFooterSubValue: {
            margin: [0, 5, 0, 5],
            alignment: 'right',
          },
          itemsFooterTotalTitle: {
            margin: [0, 5, 0, 5],
            bold: true,
            alignment: 'right',
          },
          itemsFooterTotalValue: {
            margin: [0, 5, 0, 5],
            bold: true,
            alignment: 'right',
          },
          footNote: {
            margin: [10, 5, 0, 5],
            fontSize: 7,
            alignment: 'justify'
          },
          footer_header: {
            bold: true,
            fontSize: 7.5,
          },
          footer_contents: {
            fontSize: 7,
            alignment: 'justify'
          },
          footer_text_contents: {
            fontSize: 7,
            alignment: 'justify',
            margin: [40, 5, 40, 5]
          },
          footer_left: {
            margin: [40, 0, 0, 0],
          },
          footer_right: {
            margin: [10, 0, 40, 0]
          }
        }
      };

      const filename = `${order.code}.pdf`;

        if (this.deviceService.isDesktop()) {
          pdfMake.createPdf(docDefinition).download(filename);
        } else if (this.deviceService.isTablet() || this.deviceService.isMobile()) {
          pdfMake.createPdf(docDefinition).getBlob((blob) => {
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
          }, (error) => {
            // Handle any errors that occur during PDF generation
            console.error('Error generating PDF:', error);
          });
        }
    } catch (error) {
      console.error('Error loading pdfMake or pdfFonts:', error);
    }}
  confirmReturnOrder(order: Order) {
    this.returning.emit(order);
  }
}
function createConditionalRow(label: string, value: number | undefined, formattedValue: string | undefined): any[] | null {
  if (value === undefined || value === null || value === 0) {
    // Don't create a row if the value is not present
    return null;
  }
  return [
    { text: label, style: 'itemsFooterSubTitle', border: [false, false, false, false] },
    { text: formattedValue, style: 'itemsFooterSubValue', border: [false, false, false, false] },
  ];
}

