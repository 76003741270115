import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import {
  map,
  take,
  switchMap,
  distinctUntilChanged,
  debounceTime,
  catchError,
  tap
} from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';

import * as fromProfile from '../../profile/reducers';
import * as fromAuth from '../../auth/reducers';
import { ProfileService, Profile } from '../../api';
import * as ErrorActions from '../../core/actions/error.actions';
import { Authenticate } from 'src/app/auth/models/authenticate';

@Injectable({
  providedIn: 'root'
})
export class TermsCheckGuard  {
  constructor(
    private store: Store<fromProfile.State>,
    private router: Router,
    private profileService: ProfileService
  ) {}

  getIsLoggedIn(): Observable<boolean> {
    return this.store.pipe(select(fromAuth.getIsLoggedIn));
  }

  isTermsSigned(): Observable<boolean> {
    return this.store.pipe(
      select(fromAuth.getAuthenticate),
      switchMap((authenticate: Authenticate) =>
        this.profileService
          .getProfile(authenticate.username)
          .toPromise()  // Fix for observable chain not getting consumed
          .then((profile: Profile) => {
            const signed = profile.termsAndConditions;
            const isCardExpiringSoon = profile.cardExpiringSoon;
            if (!signed && profile.stripeVetAccountId) {
              this.router.navigateByUrl('/terms');
            }
            if (isCardExpiringSoon) {
              this.router.navigateByUrl('/profile');
            }
            return signed;
          })
      )
    );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.getIsLoggedIn().pipe(
      switchMap((auth: boolean) => {
        if (!auth) {
          return of(true);
        }
        return this.isTermsSigned();
      }),
      catchError(error => {
        this.store.dispatch(new ErrorActions.ReportError(error));
        return of(false);
      })
    );
  }
}
