import {
  createSelector,
  createFeatureSelector,
  ActionReducerMap,
} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromProfile from './profile.reducer';

export interface ProfileState {
  status: fromProfile.State;
}

export interface State extends fromRoot.State {
  profile: ProfileState;
}

export const reducers: ActionReducerMap<ProfileState> = {
  status: fromProfile.reducer,
};

export const getProfileState = createFeatureSelector<ProfileState>('profile');

// Profile Status

export const getProfileStatusState = createSelector(
  getProfileState,
  (state: ProfileState) => state.status
);

export const getProfile = createSelector(
  getProfileStatusState,
  fromProfile.getProfile
);

export const getProfileAddress = createSelector(
  getProfileStatusState,
  fromProfile.getProfileAddress
);

export const getProfileAddresses = createSelector(
  getProfileStatusState,
  fromProfile.getProfileAddresses
);

export const getProfilePayment = createSelector(
  getProfileStatusState,
  fromProfile.getProfilePayment
);

export const getProfilePayments = createSelector(
  getProfileStatusState,
  fromProfile.getProfilePayments
);

export const getProfileChangePending = createSelector(
  getProfileStatusState,
  fromProfile.getProfileChangePending
);

export const getProfileNameChangePending = createSelector(
  getProfileStatusState,
  fromProfile.getProfileNameChangePending
);

export const getProfileEmailChangeError = createSelector(
  getProfileStatusState,
  fromProfile.getProfileEmailChangeError
);

export const getProfileEmailChangePending = createSelector(
  getProfileStatusState,
  fromProfile.getProfileEmailChangePending
);

export const getProfilePasswordChangeError = createSelector(
  getProfileStatusState,
  fromProfile.getProfilePasswordChangeError
);

export const getProfilePasswordChangePending = createSelector(
  getProfileStatusState,
  fromProfile.getProfilePasswordChangePending
);

export const getPasswordSuccessfullyUpdated = createSelector(
  getProfileStatusState,
  fromProfile.getPasswordSuccessfullyUpdated
);

export const getProfilePhoneChangePending = createSelector(
  getProfileStatusState,
  fromProfile.getProfilePhoneChangePending
);

export const getProfileOptedInChangePending = createSelector(
  getProfileStatusState,
  fromProfile.getProfileOptedInChangePending
);

export const getProfileAddressChangePending = createSelector(
  getProfileStatusState,
  fromProfile.getProfileAddressChangePending
);

export const getProfileAddressRemovalPending = createSelector(
  getProfileStatusState,
  fromProfile.getProfileAddressRemovalPending
);

export const getProfileAddressChangeError = createSelector(
  getProfileStatusState,
  fromProfile.getProfileAddressChangeError
);

export const getProfileCorrectedAddress = createSelector(
  getProfileStatusState,
  fromProfile.getProfileCorrectedAddress
);

export const getProfileAddressAdded = createSelector(
  getProfileStatusState,
  fromProfile.getProfileAddressAdded
);

export const getProfilePaymentAdded = createSelector(
  getProfileStatusState,
  fromProfile.getProfilePaymentAdded
);

export const getProfilePaymentChangeError = createSelector(
  getProfileStatusState,
  fromProfile.getProfilePaymentChangeError
);

export const getProfilePaymentChangePending = createSelector(
  getProfileStatusState,
  fromProfile.getProfilePaymentChangePending
);

export const getProfilePaymentRemovalPending = createSelector(
  getProfileStatusState,
  fromProfile.getProfilePaymentRemovalPending
);

