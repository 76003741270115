import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import * as ErrorActions from '../actions/error.actions';
import * as fromError from '../reducers';

@Injectable()
export class ErrorGuard  {

  constructor(private store: Store<fromError.State>) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.store.pipe(
      select(fromError.getUnhandledError),
      map(error => {
        // If there is an error present, force to error, force to refresh
        if (error) {
          if(error.error && error.error.errors && error.error.errors.length > 0) {
            if(error.error.errors[0].type == "InvalidTokenError") {
              return true;      //Skip redirect to error page, if InvalidTokenError found
            }
          }
          this.store.dispatch(new ErrorActions.ErrorRedirect());
          return false;
        }

        return true;
      })
    );

  }
}
