import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginPageComponent } from './containers/login-page/login-page.component';
import { RegisterPageComponent} from './containers/register-page/register-page.component';
import { ForgotPasswordPageComponent } from './containers/forgot-password-page/forgot-password-page.component';
import { ResetPasswordPageComponent } from './containers/reset-password-page/reset-password-page.component';
import { CheckRegisteredGuard } from './guards/check-registered.guard';
import { CheckTokenGuard } from './guards/check-token.guard';
import { LayoutModes } from '../core/constants/layout';
import { ErrorGuard } from '../core/guards/error.guard';
import { RegisterPetParentPageComponent } from './containers/register-pet-parent-page/register-pet-parent-page.component';
import { PetParentRegistrationSuccessComponent } from './components/register-pet-parent/pet-parent-registration-success/pet-parent-registration-success.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginPageComponent,
    data: {layoutMode: LayoutModes.Alternate1},
    canActivate: [ErrorGuard]
  },
  {
    path: 'forgot',
    component: ForgotPasswordPageComponent,
    data: {layoutMode: LayoutModes.Alternate1},
    canActivate: [ErrorGuard]
  },
  {
    path: 'register',
    component: RegisterPageComponent,
    data: {layoutMode: LayoutModes.Alternate1},
    canActivate: [ErrorGuard, CheckRegisteredGuard, CheckTokenGuard]
  },
  {
    path: 'resetpw',
    component: ResetPasswordPageComponent,
    data: {layoutMode: LayoutModes.Alternate1},
    canActivate: [ErrorGuard, CheckTokenGuard]
  },
  {
    path: 'register-pet',
    component: RegisterPetParentPageComponent,
    data: {layoutMode: LayoutModes.Alternate1},
    canActivate: [ErrorGuard]
  },
  {
    path: 'register-pet-success',
    component: PetParentRegistrationSuccessComponent,
    data: {layoutMode: LayoutModes.Alternate1},
    canActivate: [ErrorGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
