import { Pipe, PipeTransform } from '@angular/core';
import { Order, OrderDetail } from '../../../api';

@Pipe({
  name: 'appTracking'
})
export class TrackingPipe implements PipeTransform {

  transform(value: OrderDetail | Order, args?: any): any {
    let path = '';
    if (value && value.consignments && value.consignments.length) {
      path = 'https://wwwapps.ups.com/tracking/tracking.cgi?tracknum=' + value.consignments[0].trackingID;
    }

    return path;
  }
}
