import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs/index';
import * as fromRoot from '../../../reducers';
import * as fromCore from '../../../core/reducers';
import * as fromProducts from '../../reducers';
import * as fromPets from '../../../pets/reducers';
import { AppAnimate, flyOutIn } from '../../../shared/animations/common.animations';
import * as ProductsActions from '../../actions/products.actions';
import { NavigateBack } from '../../../core/actions/core.actions';
import { ProductFilters } from '../../models/productFilters';
@Component({
  selector: 'app-np-filters-page',
  template: `
    <app-np-filters
      [isMobile]="isMobile$ | async"
      [pending]="pending$ | async"
      [facets]="facets$ | async"
      [filters]="filters$ | async"
      (submitted)="onSubmit($event)"
      [nutritionPlan]="nutritionPlanDetail$ | async">
    </app-np-filters>
  `,
  styles: [],
  animations: [flyOutIn]
})
export class NpFiltersPageComponent implements OnInit, OnDestroy {

  clinicId: string;

  @HostBinding('@flyOutIn')
  flyOutIn = '';

  // @HostBinding('class.app-animated-page')
  // animatedPage = true;

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  pending$ = this.store.pipe(select(fromProducts.getPlanSearchPending));

  facets$ = this.store.pipe(select(fromProducts.getPlanFacets));

  filters$ = this.store.pipe(select(fromProducts.getPlanFilters));

  nutritionPlanDetail$ = this.store.pipe(select(fromPets.getNutritionPlanDetail));

  subscriptions: Subscription[] = [];

  constructor(private store: Store<fromRoot.State>, private route: ActivatedRoute) {
    AppAnimate('flyOutIn', this, this.subscriptions);

    this.subscriptions.push(
      route.params
        .subscribe(params => {
          this.clinicId = params['clinicId'];
        })
    );
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
  }

  onSubmit(filters: ProductFilters) {
    this.store.dispatch(new ProductsActions.SetPlanFilters(filters));
    this.store.dispatch(new ProductsActions.SearchPlan(this.clinicId));
    this.store.dispatch(new NavigateBack());
  }
}

