<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout">

    <h5 class="app-title text-center m-4">
      My Account
    </h5>

    <div class="d-flex align-items-center m-4">
      <h6 class="mb-0">Edit Password</h6>
    </div>

    <form [formGroup]="profileForm" autocomplete="off" class="m-4" (ngSubmit)="save()">

      <p class="pt-3 pb-3">
        To change your password, please enter your current password
      </p>

      <div class="form-group"
           [class.is-invalid]="profileForm.controls['password'].dirty && profileForm.controls['password'].invalid">
        <label for="password" class="small">Current Password</label>
        <input id="password"
               name="password"
               [type]="isCurrentPasswordVisible ? 'text' : 'password'"
               class="form-control"
               placeholder="Password"
               formControlName="password"
               (click)="currentPwd()">
        <span (click)="toggleVisibility('currentPassword')" [ngClass]="isCurrentPasswordVisible ? 'fa fas fa-eye-slash showSpan' : 'fa fas fa-eye showSpan'"></span>
      </div>

      <div class="form-group"
           [class.is-invalid]="profileForm.controls['newPassword'].dirty && profileForm.controls['newPassword'].invalid">
        <label for="newPassword" class="small">New Password</label>
        <input id="newPassword"
               name="newPassword"
               [type]="isNewPasswordVisible ? 'text' : 'password'"
               class="form-control"
               placeholder="Password"
               formControlName="newPassword"
               autocomplete="new-password">
        <span (click)="toggleVisibility('newPassword')" [ngClass]="isNewPasswordVisible ? 'fa fas fa-eye-slash showSpan' : 'fa fas fa-eye showSpan'"></span>
      </div>
      <ul *ngIf="profileForm.controls['newPassword'].dirty &&
                 profileForm.controls['newPassword'].errors && profileForm.controls['newPassword'].errors['pattern']"
          class="list-unstyled app-invalid-feedback small">
        <li *ngIf="profileForm.controls['newPassword'].errors['pattern']">
          Must be between 8-16 characters
        </li>
        <li *ngIf="profileForm.controls['newPassword'].errors['pattern']">
          Must contain at least one upper case alphabetical character
        </li>
        <li *ngIf="profileForm.controls['newPassword'].errors['pattern']">
          Must contain at least one lower case alphabetical character
        </li>
        <li *ngIf="profileForm.controls['newPassword'].errors['pattern']">
          Must contain at least one numeric character
        </li>
        <li *ngIf="profileForm.controls['newPassword'].errors['pattern']">
          Must contain at least one of the following characters: @, #, $, %, ^, &
        </li>
        <i class="app-bottom-arrow"></i>
      </ul>
      <div class="form-group"
           [class.is-invalid]="profileForm.controls['confirmPassword'].dirty && profileForm.controls['confirmPassword'].invalid">
        <label for="confirmPassword" class="small">Confirm New Password</label>
        <input id="confirmPassword"
               name="confirmPassword"
               [type]="isConfirmNewPasswordVisible ? 'text' : 'password'"
               class="form-control"
               placeholder="Password"
               formControlName="confirmPassword"
               autocomplete="new-password">
        <span (click)="toggleVisibility('confirmNewPassword')" [ngClass]="isConfirmNewPasswordVisible ? 'fa fas fa-eye-slash showSpan' : 'fa fas fa-eye showSpan'"></span>
      </div>
      <ul *ngIf="profileForm.controls['confirmPassword'].dirty &&
                 profileForm.controls['confirmPassword'].errors &&
                 profileForm.controls['confirmPassword'].errors['matchPassword']"
          class="list-unstyled app-invalid-feedback small">
        <li *ngIf="profileForm.controls['confirmPassword'].errors['matchPassword']">
          Password does not match
        </li>
        <i class="app-bottom-arrow"></i>
      </ul>
      <div *ngIf="error"
           class="alert alert-danger mt-4 small text-center">
        {{error}}
      </div>
      <div class="mt-5">
        <button type="submit"
                class="btn btn-primary w-100"
                [disabled]="profileForm.pristine || profileForm.invalid || profileForm.disabled">
          Save
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #fullLayout>
  <form [formGroup]="profileForm" autocomplete="off" class="app-full-layout m-4"
        (ngSubmit)="save()" (keyup.enter)="save()">

    <p class="pt-3 pb-3">
      To change your password, please enter your current password
    </p>

    <div class="form-group"
         [class.is-invalid]="profileForm.controls['password'].dirty && profileForm.controls['password'].invalid">
      <label for="passwordFullLayout" class="small">Current Password</label>
      <input id="passwordFullLayout"
             name="password"
             [type]="isCurrentPasswordVisible ? 'text' : 'password'"
             class="form-control"
             placeholder="Password"
             formControlName="password"
             (click)="currentPwd()">
      <span (click)="toggleVisibility('currentPassword')" [ngClass]="isCurrentPasswordVisible ? 'fa fas fa-eye-slash showSpan' : 'fa fas fa-eye showSpan'"></span>
    </div>

    <div class="form-group"
         [class.is-invalid]="profileForm.controls['newPassword'].dirty && profileForm.controls['newPassword'].invalid">
      <label for="newPassword" class="small">New Password</label>
      <input id="newPassword"
             name="newPassword"
             [type]="isNewPasswordVisible ? 'text' : 'password'"
             class="form-control"
             placeholder="Password"
             formControlName="newPassword"
             autocomplete="new-password">
      <span (click)="toggleVisibility('newPassword')" [ngClass]="isNewPasswordVisible ? 'fa fas fa-eye-slash showSpan' : 'fa fas fa-eye showSpan'"></span>

    </div>
    <ul *ngIf="profileForm.controls['newPassword'].dirty &&
               profileForm.controls['newPassword'].errors && profileForm.controls['newPassword'].errors['pattern']"
        class="list-unstyled app-invalid-feedback alternate small">
      <li *ngIf="profileForm.controls['newPassword'].errors['pattern']">
        Must be between 8-16 characters
      </li>
      <li *ngIf="profileForm.controls['newPassword'].errors['pattern']">
        Must contain at least one upper case alphabetical character
      </li>
      <li *ngIf="profileForm.controls['newPassword'].errors['pattern']">
        Must contain at least one lower case alphabetical character
      </li>
      <li *ngIf="profileForm.controls['newPassword'].errors['pattern']">
        Must contain at least one numeric character
      </li>
      <li *ngIf="profileForm.controls['newPassword'].errors['pattern']">
        Must contain at least one of the following characters: @, #, $, %, ^, &
      </li>
      <i class="app-bottom-arrow"></i>
    </ul>
    <div class="form-group"
         [class.is-invalid]="profileForm.controls['confirmPassword'].dirty && profileForm.controls['confirmPassword'].invalid">
      <label for="confirmPassword" class="small">Confirm New Password</label>
      <input id="confirmPassword"
             name="confirmPassword"
             [type]="isConfirmNewPasswordVisible ? 'text' : 'password'"
             class="form-control"
             placeholder="Password"
             formControlName="confirmPassword"
             autocomplete="new-password">
      <span (click)="toggleVisibility('confirmNewPassword')" [ngClass]="isConfirmNewPasswordVisible ? 'fa fas fa-eye-slash showSpan' : 'fa fas fa-eye showSpan'"></span>
    </div>
    <ul *ngIf="profileForm.controls['confirmPassword'].dirty &&
               profileForm.controls['confirmPassword'].errors &&
               profileForm.controls['confirmPassword'].errors['matchPassword']"
        class="list-unstyled app-invalid-feedback alternate small">
      <li *ngIf="profileForm.controls['confirmPassword'].errors['matchPassword']">
        Password does not match
      </li>
      <i class="app-bottom-arrow"></i>
    </ul>
    <div *ngIf="error"
         class="alert alert-danger mt-4 small text-center">
      {{error}}
    </div>
  </form>
</ng-template>

<ng-template #successModal let-closeSuccess="close" let-dismissSuccess="dismiss">
  <div class="modal-body text-center">
    <i class="mt-4 far fa-check-circle fa-6x txt-success"></i>
    <p class="m-4 h4 text-center">
      You have successfully updated your password
    </p>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="btn btn-primary pl-5 pr-5 btn-lg ml-2"
            (click)="closeSuccess()">
      OK
    </button>
  </div>
</ng-template>
