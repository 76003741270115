import { waitForAsync } from '@angular/core/testing';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { skip, switchMap, take, tap } from 'rxjs/operators';
import * as fromCore from '../../../core/reducers';
import * as fromOrders from '../../reducers';
import * as OrdersActions from '../../actions/orders.actions';
import * as fromProfile from '../../../profile/reducers';
import * as ProfileActions from '../../../profile/actions/profile.actions';
import * as CoreActions from '../../../core/actions/core.actions'
import { DeliveryModeChange } from '../../models/deliveryModelChange';
import { Address, Cart, Payment, ProfileAddresses } from '../../../api';
import { AppAnimate, fade } from '../../../shared/animations/common.animations';
import { PaymentBag } from 'src/app/profile/models/paymentBag';
import { ProfilePayments } from 'src/app/profile/models/ProfilePayments';

@Component({
  selector: 'app-place-order-page',
  template: `
    <app-place-order
      [isMobile]="isMobile$ | async"
      [cart]="cart$ | async"
      [deliveryModes]="deliveryModes$ | async"
      [profileAddresses]="profileAddresses$ | async"
      [profilePayments]="profilePayments$ | async"
      [addressChangePending]="addressChangePending$ | async"
      [paymentChangePending]="paymentChangePending$ | async"
      [deliveryModeChangePending]="deliveryModeChangePending$ | async"
      (submitted)="onSubmit()"
      (addressSubmitted)="onAddressSubmitted($event)"
      (paymentSubmitted)="onPaymentSubmitted($event)"
      (deliveryModeSubmitted)="onDeliveryModeSubmitted($event)"
      [profileAddressChangePending]="profileAddressChangePending$ | async"
      [profileAddressRemovalPending]="profileAddressRemovalPending$ | async"
      [profileAddress]="profileAddress$ | async"
      [profileAddressChangeError]="profileAddressChangeError$ | async"
      [profileCorrectedAddress]="profileCorrectedAddress$ | async"
      (profileAddressOpened)="onProfileAddressOpen($event)"
      (profileAddressSubmitted)="onProfileAddressSubmit($event)"
      (profileAddressRemoved)="onProfileAddressRemove($event)"
      (suggestedAddressSubmitted)="onSuggestedAddressSubmit($event)"
      (profileAddressSetPrimary)="onProfileAddressSetPrimary($event)"
      [profilePaymentChangeError]="profilePaymentChangeError$ | async"
      [profilePaymentChangePending]="profilePaymentChangePending$ | async"
      [profilePaymentRemovalPending]="profilePaymentRemovalPending$ | async"
      [profilePayment]="profilePayment$ | async"
      (profilePaymentOpened)="onProfilePaymentOpen($event)"
      (profilePaymentSubmitted)="onProfilePaymentSubmit($event)"
      (profilePaymentRemoved)="onProfilePaymentRemove($event)"
      (profilePaymentSetPrimary)="onProfilePaymentSetPrimary($event)"
      (navigatedBack)="onNavigateBack()">
    </app-place-order>
  `,
  styles: [],
  animations: [fade]
})
export class PlaceOrderPageComponent implements OnInit, OnDestroy {

  @HostBinding('@fade')
  fade = '';

  // @HostBinding('class.app-animated-page')
  // animatedPage = true;

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  cart$ = this.store.pipe(select(fromOrders.getCartDetail));

  deliveryModes$ = this.store.pipe(select(fromOrders.getDeliveryModes));

  profileAddresses$ = this.store.pipe(select(fromProfile.getProfileAddresses));

  profileAddressAdded$ = this.store.pipe(select(fromProfile.getProfileAddressAdded));

  profilePayments$ = this.store.pipe(select(fromProfile.getProfilePayments));

  profilePaymentAdded$ = this.store.pipe(select(fromProfile.getProfilePaymentAdded));

  addressChangePending$ = this.store.pipe(select(fromOrders.getCartAddressChangePending));

  paymentChangePending$ = this.store.pipe(select(fromOrders.getCartPaymentChangePending));

  deliveryModeChangePending$ = this.store.pipe(select(fromOrders.getCartDeliveryModeChangePending));

  // Address

  profileAddressChangePending$ = this.store.pipe(select(fromProfile.getProfileAddressChangePending));

  profileAddressRemovalPending$ = this.store.pipe(select(fromProfile.getProfileAddressRemovalPending));

  profileAddress$ = this.store.pipe(select(fromProfile.getProfileAddress));

  profileAddressChangeError$ = this.store.pipe(select(fromProfile.getProfileAddressChangeError));

  profileCorrectedAddress$ = this.store.pipe(select(fromProfile.getProfileCorrectedAddress));

  // Payment

  profilePaymentChangeError$ = this.store.pipe(select(fromProfile.getProfilePaymentChangeError));

  profilePaymentChangePending$ = this.store.pipe(select(fromProfile.getProfilePaymentChangePending));

  profilePaymentRemovalPending$ = this.store.pipe(select(fromProfile.getProfilePaymentRemovalPending));

  profilePayment$ = this.store.pipe(select(fromProfile.getProfilePayment));

  subscriptions: Subscription[] = [];

  constructor(private store: Store<fromOrders.State>) {
    AppAnimate('fade', this, this.subscriptions);
  }

  ngOnInit() {
    // All cart services require to be called sequentially,
    // otherwise services will return errors due to processing changes in cart items.
    // Profile services can be called async.

    // Getting profile data async
    this.store.dispatch(new ProfileActions.GetAddresses());
    this.store.dispatch(new ProfileActions.GetPayments());

    // Getting cart data and delivery modes sequentially
    this.store.dispatch(new OrdersActions.GetDeliveryModesAndCartDetail());

    // Changing the address refreshes the cart & delivery modes
    this.subscriptions.push(
      this.addressChangePending$
        .pipe(
          skip(1),
          tap((changePending: boolean) => {
            if (!changePending) {
              this.store.dispatch(new OrdersActions.GetDeliveryModesAndCartDetail());
            }
          })
        ).subscribe()
    );

    // Changing the delivery mode refreshes the cart for totals
    this.subscriptions.push(
      this.deliveryModeChangePending$
        .pipe(
          skip(1),
          tap((changePending: boolean) => {
            if (!changePending) {
              this.store.dispatch(new OrdersActions.GetCartDetail());
            }
          })
        ).subscribe()
    );

    // Adding a profile address refreshes the cart selected address
    this.subscriptions.push(
      this.profileAddressAdded$
        .pipe(
          skip(1),
          tap((address: Address) => {
            if (address) {
              this.store.dispatch(new OrdersActions.UpdateCartAddress(address.id));
              this.store.dispatch(new ProfileActions.AddAddressReset());
            }
          })
        ).subscribe()
    );

    // Adding a profile payment refreshes the cart selected payment method
    this.subscriptions.push(
      this.profilePaymentAdded$
        .pipe(
          skip(1),
          tap((paymentId: string) => {
            if (paymentId) {
              this.store.dispatch(new OrdersActions.UpdateCartPayment(paymentId));
              this.store.dispatch(new ProfileActions.AddPaymentReset());
            }
          })
        ).subscribe()
    );

    // After getting the cart detail need to verify there is a default address selected
    // updates the address to its primary state when no selected address
    // or if we are coming from an added address we selected
    this.subscriptions.push(
      this.cart$
        .pipe(
          skip(1),
          switchMap((cart: Cart) =>
            this.profileAddresses$
              .pipe(
                take(1),
                switchMap((profileAddresses: ProfileAddresses) =>
                  this.profileAddressAdded$
                    .pipe(
                      take(1),
                      tap((address: Address) => {
                        // if we have a recent added address saved in the state,
                        // and we have that address listed in our list of addresses
                        // we use it as the current address, then we clear
                        if (address &&
                          profileAddresses && profileAddresses.addresses &&
                          profileAddresses.addresses.length &&
                          profileAddresses.addresses.some(a => a.id === address.id)) {
                          this.store.dispatch(new OrdersActions.UpdateCartAddress(address.id));
                          this.store.dispatch(new ProfileActions.AddAddressReset());
                        }
                      }),
                      tap((address: Address) => {
                        // if we do not have a recent added new address and
                        // we do not have an address selected and
                        // we have a primary address, we auto select that address
                        if (!address &&
                          cart && !cart.deliveryAddress &&
                          profileAddresses && profileAddresses.addresses &&
                          profileAddresses.addresses.length &&
                          profileAddresses.addresses.some(a => a.defaultAddress)) {
                          const primaryAddress = profileAddresses.addresses.find(a => a.defaultAddress);
                          if (primaryAddress) {
                            this.store.dispatch(new OrdersActions.UpdateCartAddress(primaryAddress.id));
                          }
                        }
                      })
                    )
                )
              )
          )
        ).subscribe()
    );

    // // For Payment
    this.subscriptions.push(
      this.cart$
        .pipe(
          skip(1),
          switchMap((cart: Cart) =>
            this.profilePaymentAdded$
              .pipe(
                take(1),
                tap((paymentId: string) => {
                  // if we have a recent added payment saved in the state,
                  // use it as the current payment methid, then we clear
                  if (paymentId) {
                    this.store.dispatch(new OrdersActions.UpdateCartPayment(paymentId));
                    this.store.dispatch(new ProfileActions.AddPaymentReset());
                    setTimeout(()=>{ // Timeout added to prevent race around condition
                      this.store.dispatch(new ProfileActions.GetPayments());
                      this.store.dispatch(new OrdersActions.GetCartDetail());
                    },1000);
                  }
                })
              )
          )
        ).subscribe()
    );

    // Address

    this.subscriptions.push(
      this.profileAddressChangePending$
        .pipe(
          skip(1),
          tap((changePending => {
            if (!changePending) {
              this.store.dispatch(new ProfileActions.GetAddresses());
            }
          }
          ))).subscribe()
    );

    this.subscriptions.push(
      this.profileAddressRemovalPending$
        .pipe(
          skip(1),
          tap((changePending => {
            if (!changePending) {
              this.store.dispatch(new ProfileActions.GetAddresses());
            }
          }
          ))).subscribe()
    );

    // Payment

    this.subscriptions.push(
      this.profilePaymentChangePending$
        .pipe(
          skip(1),
          tap((changePending => {
            if (!changePending) {
              this.store.dispatch(new ProfileActions.GetPayments());
              this.store.dispatch(new OrdersActions.GetCartDetail());
            }
          }
          ))).subscribe()
    );

    this.subscriptions.push(
      this.profilePaymentRemovalPending$
        .pipe(
          skip(1),
          tap((changePending => {
            if (!changePending) {
              this.store.dispatch(new ProfileActions.GetPayments());
            }
          }
          ))).subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
  }

  onSubmit() {
    this.store.dispatch(new OrdersActions.PlaceOrder());
  }

  onAddressSubmitted(addressId: string) {
    this.store.dispatch(new OrdersActions.UpdateCartAddress(addressId));
  }

  onPaymentSubmitted(paymentId: string) {
    this.store.dispatch(new OrdersActions.UpdateCartPayment(paymentId));
  }

  onDeliveryModeSubmitted(deliveryModeChange: DeliveryModeChange) {
    this.store.dispatch(new OrdersActions.UpdateCartDeliveryMode(deliveryModeChange));
  }

  // Address

  onProfileAddressOpen(address: Address) {
    if (address) {
      this.store.dispatch(new ProfileActions.GetAddress(address.id));
    } else {
      this.store.dispatch(new ProfileActions.NewAddress());
    }
  }

  onProfileAddressSubmit(address: Address) {
    this.store.dispatch(new ProfileActions.CheckAddress(address));
  }

  onProfileAddressRemove(address: Address) {
    if (address.id) {
      this.store.dispatch(new ProfileActions.DeleteAddress(address.id));
    }
  }

  onProfileAddressSetPrimary(address: Address) {
    if (address.id) {
      this.store.dispatch(new ProfileActions.SetAddressPrimary(address.id));
    }
  }

  onSuggestedAddressSubmit(address: Address) {
    if (address.id) {
      this.store.dispatch(new ProfileActions.UpdateAddress(address.id, address));
    } else {
      this.store.dispatch(new ProfileActions.AddAddress(address));
    }
  }

  // Payment

  onProfilePaymentOpen(payment: Payment) {
    if (payment) {
      this.store.dispatch(new ProfileActions.GetPayment(payment.id));
    } else {
      this.store.dispatch(new ProfileActions.NewPayment());
    }
  }

  onProfilePaymentSubmit(bag: PaymentBag) {
    if (bag.payment && bag.payment.id) {
      this.store.dispatch(new ProfileActions.UpdatePayment(bag.payment.id, bag.payment));
    } else {
      this.store.dispatch(new ProfileActions.AddPayment(bag.tokenId));
    }
  }

  onProfilePaymentRemove(payment: Payment) {
    if (payment.id) {
      this.store.dispatch(new ProfileActions.DeletePayment(payment.id));
    }
  }

  onProfilePaymentSetPrimary(payment: Payment) {
    if (payment.id) {
      this.store.dispatch(new ProfileActions.SetPaymentPrimary(payment.id));
    }
  }

  onNavigateBack() {
    this.store.dispatch(new CoreActions.NavigateBack(true));
  }
}
