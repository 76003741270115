import { Component, OnInit, Input, Output  } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromCore from '../../../../core/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import * as fromAuth from '../../../../auth/reducers';

@Component({
  selector: 'app-contact-us-response',
  templateUrl: './contact-us-response.component.html',
  styleUrls: ['./contact-us-response.component.scss']
})
export class ContactUsResponseComponent implements OnInit {

  success: boolean | true;

  @Input()
  isMobile: boolean | false;

  @Input()
  isLoggedIn: boolean | false;

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));
  isLoggedIn$ = this.store.pipe(select(fromAuth.getIsLoggedIn));

  constructor(private route: ActivatedRoute, private store: Store<fromCore.State>, private router: Router,) {
    this.isMobile$.subscribe(value => {
      this.isMobile = value;
    })
    this.isLoggedIn$.subscribe(value => {
      this.isLoggedIn=value;
    })
   }

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      var myValue = params.get('success');
      this.success = (myValue === 'true');
      try{
        
      }catch(err){
        //this.vetId = encodedString;
      }
    });
  }

  gotoLogin() {
    this.router.navigate(['/login']);
  }

  openUrl() {
    window.open('https://www.hillspet.com/', '_blank');
  }

}
