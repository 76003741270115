import { DeliveryFrequencies } from '../../../shared/constants/payment';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AddingCart, AutoShipTemplate, ProductDetail} from '../../../api';
import { quantities } from '../../../shared/constants/shop';

@Component({
  selector: 'app-autoship',
  templateUrl: './autoship.component.html',
  styleUrls: ['./autoship.component.scss']
})

export class AutoshipComponent implements OnInit{

  shopForm: UntypedFormGroup;

  DeliveryFrequencies = DeliveryFrequencies;

  @Input() isMobile: boolean | false;

  @Input() product: ProductDetail | null;

  @Output() navigatedBack = new EventEmitter();

  totalPrice: number;
  basePrice: number;
  discountedPrice: number;
  totalDiscountedPrice: number;

  constructor(private fb: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.createForm();
  }

  
  createForm() {
    this.shopForm = this.fb.group({
      quantity: [1, Validators.required],
      size: ['', Validators.required],
      frequency: [''],
      duration: ['']
    });
  }



}