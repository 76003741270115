import { Component, Input, OnInit, OnChanges, SimpleChange, SimpleChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PasswordValidation } from '../../../shared/validation/passwordValidation';
import { Patterns } from '../../../shared/validation/patterns';
import { ResetPasswordChange } from '../../models/resetPasswordChange';
import { environment } from '../../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import * as fromProfile from "../../../profile/reducers/index";
import { Router } from '@angular/router';
import { UpdateIsPasswordSuccessfullyUpdated } from 'src/app/profile/actions/profile.actions';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnChanges {

  authForm: UntypedFormGroup;

  @Input() errorMessage: string | null;

  @Input() resetErrorMessage: string | null;

  @Input() isMobile: boolean | false;

  @Input() pending: boolean | false;

  @Input() forgot: boolean | false;

  @Input() isNewPasswordVisible: boolean | false;
           isConfirmNewPasswordVisible: boolean | false;

  @Output() submitted = new EventEmitter<ResetPasswordChange>();

  @ViewChild('modal', { static: true })
  modal: any;

  siteKey: string = environment.google_captcha_site_key;

  isPasswordSuccessfullyReset$ = this.store.pipe(select(fromProfile.getPasswordSuccessfullyUpdated));

  constructor(private fb: UntypedFormBuilder, private modalService: NgbModal, private store: Store<fromProfile.State>, private router: Router) {
    this.createForm();
  }

  ngOnInit() {
    this.isPasswordSuccessfullyReset$.subscribe((res:boolean) => {
      if(res) {
        this.onPasswordSuccessfullyUpdated();
        // Turn this to false once modal is up.
        this.store.dispatch(new UpdateIsPasswordSuccessfullyUpdated(false));
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    const pendingChange: SimpleChange = changes['pending'];
    if (pendingChange) {
      if (pendingChange.currentValue) {
        this.authForm.disable();
      } else {
        this.authForm.enable();
      }
    }
  }

  createForm() {
    this.authForm = this.fb.group({
      newPassword: ['', {
        validators: [Validators.required, Validators.pattern(Patterns.pwdpattern)],
      }],
      confirmPassword: ['', {
        validators: [Validators.required],
      }],
      // recaptchaReactive: [null, {
      //   validators: [Validators.required],
      // }]
    },
      {
        validator: PasswordValidation.matchPassword
      });
  }

  reset() {
    if (this.authForm.valid) {
      const model = this.authForm.value;
      const resetPasswordChange: ResetPasswordChange = {
        newPassword: model.newPassword
      };

      this.submitted.emit(resetPasswordChange);
    }
  }

  onPasswordSuccessfullyUpdated() {

    const currentRoute: string = this.router.url;

    const profileRoute: RegExp = /profile/gi;

    this.modalService.open(this.modal).result.then(
      () => {
        this.modalService.dismissAll();

        if(!currentRoute.match(profileRoute)) {
          this.router.navigate(["/login"])
        }
      },
      () => {
        this.modalService.dismissAll();

        if(!currentRoute.match(profileRoute)) {
          this.router.navigate(["/login"]);
        }
      }
    );
  }

  toggleVisibility(passwordType){
    switch (passwordType) {
      case 'newPassword':
        this.isNewPasswordVisible = !this.isNewPasswordVisible;
        break;
      case 'confirmNewPassword':
        this.isConfirmNewPasswordVisible = !this.isConfirmNewPasswordVisible;
        break;
      default:
        break
    }
  }

  //Google Captcha Resolved
  // resolved(response: string) {
  // }

  //Google Captcha Expired
  // handleCaptchaExpiry() {
  //   grecaptcha.reset();
  // }

  openUrl() {
    window.open('https://www.hillspet.com/', '_blank');
  }

}
