import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromRoot from '../../../reducers';
import * as fromCore from '../../../core/reducers';
import * as fromOrders from '../../../orders/reducers';
import * as fromPets from '../../../pets/reducers';
import * as fromProducts from '../../reducers';
import * as ProductsActions from '../../actions/products.actions';
import * as OrdersActions from '../../../orders/actions/orders.actions';
import * as CoreActions from '../../../core/actions/core.actions';
import { AppAnimate, flyOutIn } from '../../../shared/animations/common.animations';
import { AddingCart } from '../../../api';

@Component({
  selector: 'app-product-page',
  template: `
    <app-product
      [clinicId]="clinicId"
      [changeProduct]="changeProduct"
      [isMobile]="isMobile$ | async"
      [product]="productDetail$ | async"
      [nutritionPlan]="nutritionPlan$ | async"
      [pending]="cartProductChangePending$ | async"
      (submitted)="onSubmit($event)"
      [subscription]="subscription$ | async"
      [subscriptionChangePending]="subscriptionChangePending$ | async"
      (subscriptionProductChanged)="onChangeProduct($event)"
      (navigatedBack)="onNavigateBack()"
      (getProductDetail)="getProductDetail($event)"
      [pet]="petDetail$ | async"
      >
    </app-product>
  `,
  styles: [],
  animations: [flyOutIn]
})
export class ProductPageComponent implements OnInit, OnDestroy {

  clinicId: string;

  changeProduct: boolean;

  @HostBinding('@flyOutIn')
  flyOutIn = '';

  // @HostBinding('class.app-animated-page')
  // animatedPage = true;

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  productDetail$ = this.store.pipe(select(fromProducts.getProductDetail));

  nutritionPlan$ = this.store.pipe(select(fromPets.getNutritionPlanDetail));

  cartProductChangePending$ = this.store.pipe(select(fromOrders.getCartProductChangePending));

  subscriptionChangePending$ = this.store.pipe(select(fromOrders.getSubscriptionChangePending));

  subscription$ = this.store.pipe(select(fromOrders.getSubscription));

  subscriptions: Subscription[] = [];

  petDetail$ = this.store.pipe(select(fromPets.getPetDetail));

  constructor(private store: Store<fromRoot.State>, private route: ActivatedRoute) {
    AppAnimate('flyOutIn', this, this.subscriptions);

    this.subscriptions.push(
      route.params
        .subscribe(params => {
          this.clinicId = params['clinicId'];
          const id = params['id']; // product code
          const petCode = params['petCode'];
          if (this.clinicId && id) {
            this.store.dispatch(new ProductsActions.GetProductDetail(this.clinicId, id, petCode));
          }
        })
    );

    this.subscriptions.push(
      route.data
        .subscribe(params => {
          this.changeProduct = params['changeProduct'];
          if(this.changeProduct == true) {
            this.petDetail$ = this.store.pipe(select(fromOrders.getPetDetailSubscription));       //getting pet details from subscription
          }else {
            this.petDetail$ = this.store.pipe(select(fromPets.getPetDetail));
          }
        })
    );
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
  }

  onSubmit(addingCart: AddingCart) {
    this.store.dispatch(new OrdersActions.AddProductToCart(addingCart));
  }

  onChangeProduct(productCode: string) {
    this.store.dispatch(new OrdersActions.ChangeSubscriptionProduct(productCode));
  }

  getProductDetail({ clinicId, productCode, petCode }) {
    this.store.dispatch(new ProductsActions.GetProductDetail(clinicId, productCode, petCode));
  }

  onNavigateBack() {
    this.store.dispatch(new CoreActions.NavigateBack(true));
  }
}

