let isPopStateRef = false;

export class LocationUtil {

  static getCurrentDomain(relativePath: string = ''): string {
    return window.location.origin + '/' + relativePath ;
  }

  static isPopState = (isPopState?: boolean): boolean => {
    if (isPopState !== undefined) {
      isPopStateRef = isPopState;
    }

    return isPopStateRef;
  }
}
