import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BASE_PATH } from '../../api/variables';


@Injectable()
export class PromotionsService {
  constructor(private httpService: HttpClient, @Optional()@Inject(BASE_PATH) protected basePath: string) {}
}
