import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as ProfileActions from '../actions/profile.actions';
import * as ErrorActions from '../../core/actions/error.actions';
import * as fromAuth from '../../auth/reducers';
import * as fromProfile from '../reducers';
import { ProfileService, Profile } from '../../api';
import { Authenticate } from '../../auth/models/authenticate';

@Injectable()
export class ProfileGuard  {

  constructor(private store: Store<fromProfile.State>,
              private profileService: ProfileService) {
  }

  getIsLoggedIn(): Observable<boolean> {
    return this.store.pipe(
      select(fromAuth.getIsLoggedIn)
    );
  }

  getAuthenticate(): Observable<Authenticate> {
    return this.store.pipe(
      select(fromAuth.getAuthenticate)
    );
  }

  getProfile(authenticate: Authenticate): Promise<Profile> {
    return this.profileService.getProfile(authenticate.username)
      .toPromise()
      .then((profile: Profile) => {
        sessionStorage.setItem( 'ottoCustomer', profile.ottoCustomer);
        sessionStorage.setItem('ottoStatus', profile.ottoStatus );
        sessionStorage.setItem('pk', profile.pk );
        this.store.dispatch(new ProfileActions.Load(profile));
        return profile;
      });
  }

  loadProfile(): Observable<boolean> {
    return this.getIsLoggedIn().pipe(
      switchMap((auth: boolean) => {
        if (!auth) {
          return of(auth);
        }
        return this.getAuthenticate()
          .pipe(
            switchMap(authenticate => {
              return this.getProfile(authenticate);
            }),
            map((profile: Profile) => !!profile),
            catchError((error) => {
              this.store.dispatch(new ErrorActions.ReportError(error));
              return of(false);
            })
          );
      })
    );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.loadProfile();
  }
}
