import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { skip, switchMap, take, tap } from 'rxjs/operators';
import * as fromRoot from '../../../reducers';
import * as fromCore from '../../../core/reducers';
import * as fromAuth from '../../../auth/reducers';
import * as LayoutActions from '../../../core/actions/layout.actions';
import { LayoutModes } from '../../../core/constants/layout';
import { ActivatedRoute } from '@angular/router';
import { AppAnimate, fade } from '../../../shared/animations/common.animations';

@Component({
  selector: 'app-support-page',
  template: `
    <app-support
      [isMobile]="isMobile$ | async"
      [isLoggedIn]="isLoggedIn$ | async">
    </app-support>
  `,
  styles: [],
  animations: [fade]
})
export class SupportPageComponent implements OnInit, OnDestroy {

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  isLoggedIn$ = this.store.pipe(select(fromAuth.getIsLoggedIn));

  subscriptions: Subscription[] = [];

  constructor(private store: Store<fromRoot.State>, private route: ActivatedRoute) {

    // Check on route change
    this.subscriptions.push(
      this.route.url
        .pipe(
          switchMap(() =>
            this.isMobile$
              .pipe(
                take(1), // Only once needs to apply this change
                tap((isMobile: boolean) => {
                    if (!isMobile) {
                      setTimeout(() => {
                        this.store.dispatch(new LayoutActions.ChangeLayoutMode(LayoutModes.Alternate3));
                      });
                    }
                  }
                ))
          )
        )
        .subscribe()
    );
  }

  ngOnInit() {

    this.subscriptions.push(
      this.isMobile$
        .pipe(
          skip(1), // Skip first time is being take care of
          tap((isMobile: boolean) => {
              if (isMobile) {
                this.store.dispatch(new LayoutActions.ChangeLayoutMode(LayoutModes.Normal));
              } else {
                this.store.dispatch(new LayoutActions.ChangeLayoutMode(LayoutModes.Alternate3));
              }
            }
          ))
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
  }
}

