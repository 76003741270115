/**
 * Pet Companion Server
 * This is a sample server Pet Companion server. You can find out more about Swagger at [http://swagger.io](http://swagger.io) or on [irc.freenode.net, #swagger](http://swagger.io/irc/). For this sample, you can use the api key `special-key` to test the authorization filters.
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { ProductDetail } from '../model/productDetail';
import { SearchedProducts } from '../model/searchedProducts';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { SearchedProduct } from '..';


@Injectable()
export class ProductService {

    protected basePath = 'http://localhost:10010/rest';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get product details
     * 
     * @param id The clinic id
     * @param productCode The product code
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductDetail(id: string, productCode: string, plans?: string[], petCode?: string, t?: string, observe?: 'body', reportProgress?: boolean): Observable<ProductDetail>;
    public getProductDetail(id: string, productCode: string, plans?: string[], petCode?: string, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductDetail>>;
    public getProductDetail(id: string, productCode: string, plans?: string[], petCode?: string, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductDetail>>;
    public getProductDetail(id: string, productCode: string, plans?: string[], petCode?: string, t?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getProductDetail.');
        }
        if (productCode === null || productCode === undefined) {
            throw new Error('Required parameter productCode was null or undefined when calling getProductDetail.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (t !== undefined) {
            queryParameters = queryParameters.set('t', <any>t);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];

        // return this.httpClient.get<ProductDetail>(`${this.basePath}/rest/v2/V2CSite/products/
        // ${encodeURIComponent(String(id))}/${encodeURIComponent(String(productCode))}/appV2`,
        //     {
        //         params: queryParameters,
        //         withCredentials: this.configuration.withCredentials,
        //         headers: headers,
        //         observe: observe,
        //         reportProgress: reportProgress
        //     }
        // );

        return this.httpClient.get<ProductDetail>(`${this.basePath}/rest/v2/V2CSite/products/${encodeURIComponent(String(id))}/${encodeURIComponent(String(petCode))}/${encodeURIComponent(String(productCode))}/${encodeURIComponent(String(plans))}/appV2`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search the nutrition plan products
     * 
     * @param id The clinic id
     * @param query The query for filtering
     * @param pageSize The page size
     * @param currentPage The current page
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchPlanProducts(id: string, query?: string, pageSize?: number, currentPage?: number, t?: string, observe?: 'body', reportProgress?: boolean): Observable<SearchedProducts>;
    public searchPlanProducts(id: string, query?: string, pageSize?: number, currentPage?: number, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SearchedProducts>>;
    public searchPlanProducts(id: string, query?: string, pageSize?: number, currentPage?: number, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SearchedProducts>>;
    public searchPlanProducts(id: string, query?: string, pageSize?: number, currentPage?: number, t?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        // if (id === null || id === undefined) {
        //     throw new Error('Required parameter id was null or undefined when calling searchPlanProducts.');
        // }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (query !== undefined) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (pageSize !== undefined) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }
        if (currentPage !== undefined) {
            queryParameters = queryParameters.set('currentPage', <any>currentPage);
        }
        if (t !== undefined) {
            queryParameters = queryParameters.set('t', <any>t);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];

        return this.httpClient.get<SearchedProducts>(`${this.basePath}/rest/v2/V2CSite/products/${encodeURIComponent(String(id))}/search`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
