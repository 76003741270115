import { 
  Component, 
  OnInit, 
  HostListener, 
  Input, 
  ViewChild, 
  ElementRef, 
  Renderer2, 
  AfterViewChecked 
} from '@angular/core';

@Component({
  selector: 'app-zoomable',
  templateUrl: './zoomable.component.html',
  styleUrls: ['./zoomable.component.css']
})
export class ZoomableComponent  implements OnInit, AfterViewChecked {

  @Input()
  src: string;

  @Input()
  zoom: number = 2;

  @ViewChild('zoomContainer', { static: true })
  zoomContainer: ElementRef;
  
  @ViewChild('zoomImg', { static: true })
  zoomImg: ElementRef;

  listen: boolean = false;

  constructor(private renderer: Renderer2) {
  }

  ngOnInit() {
  }

  ngAfterViewChecked() {
    this.initZoom(this.zoom);
  }
 
  /**
   * Initializes the background color, background src url, and background size
   * for your zoom container holding the zoomed image.
   * @param zoom zoom level
   */
  initZoom(zoom: number) {
    const productImg = this.zoomImg.nativeElement;

    this.renderer.setStyle(this.zoomContainer.nativeElement,
          "background-image", `url('${productImg.src}')`);
    this.renderer.setStyle(this.zoomContainer.nativeElement,
          "background-size", `${(productImg.width * zoom)}px ${productImg.height * zoom}px`);
  }

  @HostListener("touchstart")
  @HostListener("mousedown")
  onMouseDown() {
    this.listen = true;
  }

  @HostListener("touchup")
  @HostListener("mouseup")
  onMouseUp() {
    this.listen = false;
  }

  @HostListener("mouseout")
  onMouseOut() {
    this.listen = false;
  }

  @HostListener("touchmove", ['$event'])
  @HostListener("mousemove", ['$event'])
  onMouseMove(event: any) {
    if (this.listen) {
      let width: number;
      let height: number;
      const img = this.zoomImg.nativeElement;
      const glass = this.zoomContainer.nativeElement;
      width = glass.offsetWidth / 2;
      height = glass.offsetHeight / 2;
    
    	// prevent any other actions that may occur when moving over the image
    	event.preventDefault();
    	let { x, y } = this.getCursorPos(event);

    	// prevent the magnifier glass from being positioned outside the image
      if (x > img.width - (width / this.zoom))
        x = img.width - (width / this.zoom);

      if (x < width / this.zoom)
        x = width / this.zoom;

      if (y > img.height - (height / this.zoom))
        y = img.height - (height / this.zoom);

      if (y < height / this.zoom)
        y = height / this.zoom;
    	// display what the magnifier glass "sees"
    	glass.style.backgroundPosition = "-" + ((x * this.zoom) - width) + "px -" + ((y * this.zoom) - height) + "px";
    }
  }

  /**
   * Obtains the client cursor position by using properties
   * of the event object.
   * @param event mouse or touch event
   */
  getCursorPos(event: any) {
    const el = this.zoomImg.nativeElement;
    let x = 0
    let y = 0;
    event = event || window.event;
    // gets difference between mouse/touch and zoomImg bounds
    x = (event.pageX || event.changedTouches[0].pageX) - el.getBoundingClientRect().left;
    y = (event.pageY || event.changedTouches[0].pageY) - el.getBoundingClientRect().top;
    // if page is scrolled down
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;
    return { x: x, y: y };
  }
      
}
