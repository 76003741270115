import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromCore from '../../../core/reducers';
import * as fromOrders from '../../reducers';
import * as OrdersActions from '../../actions/orders.actions';
import { OrderFilters } from '../../models/orderFilters';
import { Order, OrderDetail } from '../../../api';
import { AppAnimate, fade } from '../../../shared/animations/common.animations';
import { Router } from '@angular/router';

@Component({
  selector: 'app-orders-page',
  template: `
    <app-orders
      [isMobile]="isMobile$ | async"
      [orders]="orders$ | async"
      [pending]="ordersSearchPending$ | async"
      (submitted)="onSubmit($event)"
      [orderDetail]="orderDetail$ | async"
      (orderSelected)="onOrderSelect($event)"
      (cancelling)="onCancel($event)"
      (returning)="onReturn($event)">
    </app-orders>
  `,
  styles: [],
  animations: [fade]
})
export class OrdersPageComponent implements OnInit, OnDestroy {

  @HostBinding('@fade')
  fade = '';

  // @HostBinding('class.app-animated-page')
  // animatedPage = true;

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  orders$ = this.store.pipe(select(fromOrders.getSearchedOrders));

  ordersSearchPending$ = this.store.pipe(select(fromOrders.getOrdersSearchPending));

  orderDetail$ = this.store.pipe(select(fromOrders.getOrderDetail));

  subscriptions: Subscription[] = [];

  constructor(private store: Store<fromOrders.State>, private router : Router) {
    AppAnimate('fade', this, this.subscriptions);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
  }

  onSubmit(orderFilters: OrderFilters) {
    this.store.dispatch(new OrdersActions.SearchOrders(orderFilters));
  }

  onOrderSelect(order: Order) {
    this.store.dispatch(new OrdersActions.GetOrderDetail(order.code));
  }

  onCancel(order: OrderDetail) {
    this.store.dispatch(new OrdersActions.CancelOrder(order));
  }

  onReturn(order: Order) {
    this.router.navigate(['/return', order.code]);
  }
}

