import {
  Component, EventEmitter, Input, OnChanges, OnInit,
  Output, SimpleChange, SimpleChanges, ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Profile } from '../../../api';
import { PhoneChange } from '../../models/phoneChange';
import { Patterns } from '../../../shared/validation/patterns';

@Component({
  selector: 'app-edit-phone',
  templateUrl: './edit-phone.component.html',
  styleUrls: ['./edit-phone.component.scss']
})
export class EditPhoneComponent implements OnInit, OnChanges {

  profileForm: UntypedFormGroup;

  @Input() isMobile: boolean | false;

  @Input() pending: boolean | false;

  @Input() profile: Profile | null;

  @Output() submitted = new EventEmitter<PhoneChange>();

  @ViewChild('successModal', { static: true })
  successModal: any;

  constructor(private fb: UntypedFormBuilder,
              private modalService: NgbModal,
              private router: Router) {
    this.createForm();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    const profileChange: SimpleChange = changes['profile'];
    if (profileChange) {
      this.resetForm();
    }
    const pendingChange: SimpleChange = changes['pending'];
    if (pendingChange && !pendingChange.firstChange) {
      if (pendingChange.currentValue) {
        this.profileForm.disable();
      } else {
        this.profileForm.enable();
        this.success();
      }
    }
    // On desktop redirect back to master page
    const isMobileChange: SimpleChange = changes['isMobile'];
    if (isMobileChange && !isMobileChange.firstChange &&
      !isMobileChange.currentValue) {
      this.router.navigate(['/profile']);
    }
  }

  createForm() {
    this.profileForm = this.fb.group({
      phoneNumber: [{ value: '', disabled: true }, Validators.required],
      phoneType: ['', Validators.required],
      newPhoneNumber: ['', {
        validators: [Validators.required, Validators.pattern(Patterns.phoneNumberPattern)],
      }]
    });
  }

  resetForm() {
    this.profileForm.reset({
      phoneNumber: this.profile.phoneNumber,
      phoneType: this.profile.phoneType
    });
  }

  save() {
    if (this.profileForm.valid) {
      const model = this.profileForm.value;
      const phoneChange: PhoneChange = {
        phoneNumber: model.newPhoneNumber,
        phoneType: model.phoneType
      };
      this.submitted.emit(phoneChange);
    }
  }

  /**
   * Automatically insert '-' in phone number as per format
   * @param event Trigger from input key 
   */
  onKey(event: any) {
    let chIbn = event.target.value.split('-').join('');
    if (chIbn.length > 0 && event.target.value.length < 10) {
      console.log(chIbn);
      chIbn = chIbn.match(new RegExp('.{1,3}', 'g')).join('-');
      this.profileForm.controls['newPhoneNumber'].setValue(chIbn,{onlySelf: true});
    }
  }

  success() {
    // Using timeout because of issue here:
    // https://github.com/ng-bootstrap/ng-bootstrap/issues/1775
    setTimeout(() => {
      this.modalService.open(this.successModal).result.then(
        () => {
          this.router.navigate(['/profile']);
        },
        () => {
          this.router.navigate(['/profile']);
        }
      );
    });
  }

}
