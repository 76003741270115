import { ProductLine } from './../../api/model/productLine';
import { PetTypes } from '../constants/pet';

export class ShopUtil {

  static mapPetTypeFilter(petType: string): string {
    switch (petType.toUpperCase()) {
      case PetTypes.Dog:
        return 'Canine';
      case PetTypes.Cat:
        return 'Feline';
    }

    return petType;
  }

  static mapPetType(petType: string): string {
    switch (petType.toUpperCase()) {
      case "CANINE":
        return "Dog";
      case "FELINE":
        return "Cat";
    }
    return petType;
  }

  static mapFoodTypeFilter(foodType: string): string {
    switch (foodType) {
      case 'Wet Food':
        return 'Wet';
      case 'Dry Food':
        return 'Dry';
      default:
        return foodType;
    }
  }

  static mapBrandTypeFilter(brandType: string): string {
    let brand = brandType.replace(/ /g, '');
    return brand;
  }

  static mapProductLineFilter(productLinetype: string, productLines: ProductLine[]): string {
    const plCode = productLines.filter(pl => pl.name === productLinetype)[0].code;
    return plCode;
  }
}
