import { Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as fromCore from '../../../core/reducers';
import * as fromProfile from '../../reducers';
import * as ProfileActions from '../../actions/profile.actions';
import { PhoneChange } from '../../models/phoneChange';
import { flyOutIn } from '../../../shared/animations/common.animations';

@Component({
  selector: 'app-edit-phone-page',
  template: `
    <app-edit-phone
      [isMobile]="isMobile$ | async"
      [pending]="pending$ | async"
      [profile]="profile$ | async"
      (submitted)="onSubmit($event)">
    </app-edit-phone>
  `,
  styles: [],
  animations: [flyOutIn]
})
export class EditPhonePageComponent implements OnInit {

  @HostBinding('@flyOutIn')
  flyOutIn = 'in';

  // @HostBinding('class.app-animated-page')
  // animatedPage = true;

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  pending$ = this.store.pipe(select(fromProfile.getProfilePhoneChangePending));

  profile$ = this.store.pipe(select(fromProfile.getProfile));

  constructor(private store: Store<fromProfile.State>, private router: Router) {
  }

  ngOnInit() {
  }

  onSubmit(phoneChange: PhoneChange) {
    this.store.dispatch(new ProfileActions.UpdatePhone(phoneChange));
  }

}
