
/**
 * Pet Companion Server
 * This is a sample server Pet Companion server. You can find out more about Swagger at [http://swagger.io](http://swagger.io) or on [irc.freenode.net, #swagger](http://swagger.io/irc/). For this sample, you can use the api key `special-key` to test the authorization filters.
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BaseOptions } from './baseOptions';
import { Category } from './category';
import { Cost } from './cost';
import { Image } from './image';
import { LifeStage } from './lifeStage';
import { PriceRange } from './priceRange';
import { Stock } from './stock';
import { SubscriptionUnit } from '..';


export interface ProductDetail {
    activeIngredients?: string;
    availableForPickup?: string;
    baseOptions?: BaseOptions;
    baseProduct?: string;
    bodyWeight?: string;
    catDog?: string;
    categories?: Array<Category>;
    code?: string;
    description?: string;
    helpProcess?: string
    workProcess?: string;
    ingredients?: string;
    feedingGuide?: string;
    healthySick?: string;
    htmlStrippedDescription?: string;
    images?: Array<Image>;
    lifeStage?: LifeStage;
    mainUSPClaims?: string;
    name?: string;
    numberOfReviews?: string;
    price?: Cost;
    discountedPrice: number;
    discountedInfo: string;
    priceRange?: PriceRange;
    purchasable?: boolean;
    salesUnit?: string;
    stock?: Stock;
    summary?: string;
    url?: string;
    usageInstructions?: string;
    wetDry?: string;
    productReferences?: any[];
    subscriptionUnit?: SubscriptionUnit;
}
