import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges, ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, skip } from 'rxjs/operators';
import { DeliveryFrequencies, DeliveryModeCodes } from '../../../shared/constants/payment';
import { DateUtil } from '../../../shared/utils/dateUtil';
import {
  ProfileAddresses,
  Cart,
  DeliveryMode,
  Payment, Address
} from '../../../api';
import { DeliveryModeChange } from '../../models/deliveryModelChange';
import { DateValidation } from '../../../shared/validation/dateValidation';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ProfilePayments } from '../../../profile/models/ProfilePayments';
import { CorrectedAddress } from 'src/app/profile/models/correctedAddress';
import { select, Store } from '@ngrx/store';
import * as fromError from '../../../core/reducers/index';
import { Subscription } from 'rxjs';
import { ClearError } from 'src/app/core/actions/error.actions';

@Component({
  selector: 'app-place-order',
  templateUrl: './place-order.component.html',
  styleUrls: ['./place-order.component.scss']
})
export class PlaceOrderComponent implements OnInit, OnChanges, OnDestroy {

  DeliveryFrequencies = DeliveryFrequencies;

  DeliveryModeCodes = DeliveryModeCodes;

  dtr: string;

  minDate: string;

  maxDate: string;

  cartForm: UntypedFormGroup;

  editEmailModalRef: NgbModalRef;

  editPasswordModalRef: NgbModalRef;

  editAddressModalRef: NgbModalRef;

  editPaymentModalRef: NgbModalRef;

  paymentErrorModalRef: NgbModalRef;

  unhandledErrors$ = this.store.pipe(select(fromError.getUnhandledError));

  unhandledError: any = null;

  subscriptions: Subscription[] = [];

  showExpeditedShippingMessage: boolean = false;

  @Input() isMobile: boolean | false;

  @Input() cart: Cart | null;

  @Input() profileAddresses: ProfileAddresses | null;

  @Input() profilePayments: ProfilePayments | null;

  @Input() deliveryModes: DeliveryMode[] | null;

  @Input() addressChangePending: boolean | false;

  @Input() paymentChangePending: boolean | false;

  @Input() deliveryModeChangePending: boolean | false;

  @Output() submitted = new EventEmitter();

  @Output() addressSubmitted = new EventEmitter<string>();

  @Output() paymentSubmitted = new EventEmitter<string>();

  @Output() deliveryModeSubmitted = new EventEmitter<DeliveryModeChange>();

  // Address

  @Input() profileAddressChangePending: boolean | false;

  @Input() profileAddressRemovalPending: boolean | false;

  @Input() profileAddress: Address | null;

  @Input() profileAddressChangeError: boolean | false;

  @Input() profileCorrectedAddress: CorrectedAddress | null;

  @Output() profileAddressOpened = new EventEmitter<Address>();

  @Output() profileAddressSubmitted = new EventEmitter<Address>();

  @Output() profileAddressRemoved = new EventEmitter<Address>();

  @Output() profileAddressSetPrimary = new EventEmitter<Address>();

  @Output() submitAddress = new EventEmitter<Address>();

  @Output() suggestedAddressSubmitted = new EventEmitter<Address>();

  // Payment 

  @Input() profilePaymentChangeError: string | null;

  @Input() profilePaymentChangePending: boolean | false;

  @Input() profilePaymentRemovalPending: boolean | false;

  @Input() profilePayment: Payment | null;

  @Output() profilePaymentOpened = new EventEmitter<Payment>();

  @Output() profilePaymentSubmitted = new EventEmitter<Payment>();

  @Output() profilePaymentRemoved = new EventEmitter<Payment>();

  @Output() profilePaymentSetPrimary = new EventEmitter<Payment>();

  @Output() navigatedBack = new EventEmitter();

  @Input() deliveryModeIdFlag: boolean | false;

  @ViewChild('editAddressModal', { static: true })
  editAddressModal: any;

  @ViewChild('confirmDeleteAddressModal', { static: true })
  confirmDeleteAddressModal: any;

  @ViewChild('alertDeleteAddressModal', { static: true })
  alertDeleteAddressModal: any;

  @ViewChild('editPaymentModal', { static: true })
  editPaymentModal: any;

  @ViewChild('confirmDeletePaymentModal', { static: true })
  confirmDeletePaymentModal: any;

  @ViewChild('creditCardHolds', { static: true })
  creditCardHoldsModal: any;

  @ViewChild('alertDeletePaymentModal', { static: true })
  alertDeletePaymentModal: any;

  @ViewChild('paymentErrorModal', {static: true})
  paymentErrorModal: any;

  @ViewChild('confirmMakePrimaryPaymentModal', {static: true})
  confirmMakePrimaryPaymentModal: any;

  @ViewChild('successPrimaryProfilePaymentModal', { static: true })
  successPrimaryProfilePaymentModal: any;
  windowRef: any;

  constructor(private fb: UntypedFormBuilder,
    private modalService: NgbModal, 
    private store: Store<fromError.State>) {
    this.createForm();
    this.windowRef = window;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.unhandledErrors$.subscribe((res: any) => {
        if(res && res.error.errors[0].type === "PaymentAuthorizationError") {
          this.unhandledError = res.error.errors[0].message;
          this.paymentErrorModalRef = this.modalService.open(this.paymentErrorModal);
        }
        this.store.dispatch(new ClearError());
      })
    )
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges) {
    const cartChange: SimpleChange = changes['cart'];
    if (cartChange && !this.deliveryModeIdFlag) {
      this.resetForm();
    }
    const addressChangePendingChange: SimpleChange = changes['addressChangePending'];
    if (addressChangePendingChange && !addressChangePendingChange.firstChange &&
      !addressChangePendingChange.currentValue) {
      this.cartForm.enable();
      this.allowDeliveryDate();
    }
    const paymentChangePendingChange: SimpleChange = changes['paymentChangePending'];
    if (paymentChangePendingChange && !paymentChangePendingChange.firstChange &&
      !paymentChangePendingChange.currentValue) {
      this.cartForm.enable();
      this.allowDeliveryDate();
    }
    const deliveryModeChangePendingChange: SimpleChange = changes['deliveryModeChangePending'];
    if (deliveryModeChangePendingChange && !deliveryModeChangePendingChange.firstChange &&
      !deliveryModeChangePendingChange.currentValue) {
      this.cartForm.enable();
      this.allowDeliveryDate();
    }
    // On mobile close any open desktop dialogs
    const isMobileChange: SimpleChange = changes['isMobile'];
    if (isMobileChange && !isMobileChange.firstChange &&
      isMobileChange.currentValue) {
      if (this.editEmailModalRef) {
        this.editEmailModalRef.close();
      }
      if (this.editPasswordModalRef) {
        this.editPasswordModalRef.close();
      }
      if (this.editAddressModalRef) {
        this.editAddressModalRef.close();
      }
      if (this.editPaymentModalRef) {
        this.editPaymentModalRef.close();
      }
    }
    // Only after getting the profile address we open a modal
    const profileAddressChange: SimpleChange = changes['profileAddress'];
    if (profileAddressChange && !profileAddressChange.firstChange &&
      profileAddressChange.currentValue) {
      // Using timeout because of issue here:
      // https://github.com/ng-bootstrap/ng-bootstrap/issues/1775
      setTimeout(() => {
        this.editAddressModalRef = this.modalService.open(this.editAddressModal);
      }, 100);
    }
    // Only after the address is changed and there are not errors we close the desktop dialog
    const profileAddressChangePendingChange: SimpleChange = changes['profileAddressChangePending'];
    if (profileAddressChangePendingChange && !profileAddressChangePendingChange.firstChange &&
      !profileAddressChangePendingChange.currentValue) {
      if (this.editAddressModalRef) {
        this.editAddressModalRef.close();
      }
    }
    // Only after getting the profile payment we open a modal
    const profilePaymentChange: SimpleChange = changes['profilePayment'];
    if (profilePaymentChange && !profilePaymentChange.firstChange &&
      profilePaymentChange.currentValue) {
      // Using timeout because of issue here:
      // https://github.com/ng-bootstrap/ng-bootstrap/issues/1775
      setTimeout(() => {
        this.editPaymentModalRef = this.modalService.open(this.editPaymentModal);
      }, 100);
    }
    // Only after the payment is changed and there are not errors we close the desktop dialog
    const profilePaymentChangePendingChange: SimpleChange = changes['profilePaymentChangePending'];
    if (profilePaymentChangePendingChange && !profilePaymentChangePendingChange.firstChange &&
      !profilePaymentChangePendingChange.currentValue && !this.profilePaymentChangeError) {
      if (this.editPaymentModalRef) {
        this.editPaymentModalRef.close();
      }
    }
  }

  createForm() {
    const now = new Date();
    const minDate = DateUtil.addWeekDay(now, 6);
    this.minDate = DateUtil.getDateStr(minDate, 'M/d/y');

    let dtToday = new Date();
    let dt = DateUtil.addWeekDay(dtToday, 6);
    this.maxDate = this.dateConvertion(dt);

    this.cartForm = this.fb.group({
      deliveryModeCode: ['', Validators.required],
      deliveryDate: ['', {
        validators: [Validators.required, DateValidation.minDate(this.maxDate), DateValidation.onlyWeekDays()],
      }],
      deliveryAddressId: ['', Validators.required],
      paymentId: ['', Validators.required]
    });

    this.cartForm.controls['deliveryDate'].valueChanges
      .pipe(
        skip(1), // skip first since it is the setup
        debounceTime(1000), // debounce 1 sec
        distinctUntilChanged(), // only when changed
        filter(x => {
          return x !== this.dtr; // verifies is different for original date
        })
      )
      .subscribe(() => {
        if (this.cartForm.controls['deliveryDate'].valid) {
          this.onDeliveryModeChanged();
        }
      });
  }

  resetForm() {
    if (this.cart) {
      const next = this.cart.selectedDeliveryDate ?
        DateUtil.add(DateUtil.getDateStr(this.cart.selectedDeliveryDate), 0) :
        DateUtil.addWeekDay(new Date(), 6);

      this.dtr = DateUtil.getDateStr(next);
      // Logic to show message when expedited delivery is not available
      if(this.deliveryModes){
        let obj = this.deliveryModes.find(o => o.code === 'nextday-gross');
        if(obj == undefined)
          this.showExpeditedShippingMessage = true;
      }
      this.cartForm.reset({
        deliveryModeCode: this.cart.deliveryMode ? this.cart.deliveryMode.code : '',
        deliveryDate: this.dtr,
        deliveryAddressId: this.cart.deliveryAddress ? this.cart.deliveryAddress.id : '',
        paymentId: this.cart.paymentInfo ? this.cart.paymentInfo.id : '',
      });
      this.allowDeliveryDate();
    }
  }

  onAddressChanged() {
    const model = this.cartForm.value;
    if (model.deliveryAddressId) {
      this.addressSubmitted.emit(model.deliveryAddressId);
      this.cartForm.disable();
    }
  }

  onPaymentChanged() {
    const model = this.cartForm.value;
    if (model.paymentId) {
      this.paymentSubmitted.emit(model.paymentId);
      this.cartForm.disable();
    }
  }

  onDeliveryModeChanged() {
    this.allowDeliveryDate();
    const model = this.cartForm.value;
    if (model.deliveryModeCode) {
      if (model.deliveryModeCode === DeliveryModeCodes.SelectedDate) {
        this.deliveryModeIdFlag = true;
      } else {
        this.deliveryModeIdFlag = false;
      }
      this.deliveryModeSubmitted.emit(
        {
          code: model.deliveryModeCode,
          //deliveryDate: model.deliveryDate
          deliveryDate: model.deliveryModeCode === DeliveryModeCodes.SelectedDate ?
            model.deliveryDate : model.deliveryDate
        }
      );
      this.cartForm.disable();
    }
  }

  placeOrder() {

    setTimeout(() => {
      this.submitted.emit();
    });
  }

  allowDeliveryDate() {
    // disabling the delivery date when the delivery mode is not a date selection
    const model = this.cartForm.value;
    this.cartForm.get('deliveryDate').enable();
    /*if (model.deliveryModeCode !== DeliveryModeCodes.SelectedDate) {
      this.cartForm.get('deliveryDate').disable();
    } else {
      this.cartForm.get('deliveryDate').enable();
    }*/
  }

  hasSubscription() {
    return this.cart && this.cart.entries ?
      this.cart.entries.some(e => e.subscriptionUnit && e.subscriptionUnit.deliveryFrequency !== DeliveryFrequencies.OneTime) :
      false;
  }

  // Address

  openProfileAddress(address: Address = null) {
    if (address === null || this.cart === null) {
      this.profileAddressOpened.emit(address);
      return;
    }

    this.profileAddressOpened.emit(address);
    let dataLayer = this.windowRef['dataLayer'] || [];
    let products = this.cart.entries?.map((entry, index) => ({
      item_id: entry.product.code,
      item_name: entry.product.name, 
      index: index,
      discount: (entry.basePrice.value - entry.totalPrice.value / entry.quantity ).toFixed(2),
      quantity: entry.quantity,
      price: entry.basePrice.value.toFixed(2)
    }));
  
    if (products !== undefined) {
      dataLayer.push({
        event: "add_shipping_info",
        ecommerce: {
          currency: this.cart.deliveryCost.currencyIso,
          value: (this.cart.subTotal.value + this.cart.productDiscounts.value + this.cart.orderDiscounts.value).toFixed(2),
          shipping_tier: this.cart.deliveryMode.name,
          items: products
        }
      });
    }
  }

  onSubmitProfileAddress(address: Address) {
    this.profileAddressSubmitted.emit(address);
  }

  confirmDeleteAddress(address: Address) {
    if(this.profileAddresses.addresses.length > 1){
      this.profileAddress = address;

      this.modalService.open(this.confirmDeleteAddressModal).result.then(
        () => {
          this.deleteProfileAddress(address);
        },
        () => {
        }
      );
    }else{
      this.modalService.open(this.alertDeleteAddressModal).result.then(
        () => {
        },
        () => {
        }
      );
    }
  }

  onSubmitSuggestedAddress(address: Address) {
    this.suggestedAddressSubmitted.emit(address);
  }

  deleteProfileAddress(address: Address) {
    this.profileAddressRemoved.emit(address);
  }

  // makePrimaryProfileAddress(address: Address) {
  //   this.profileAddressSetPrimary.emit(address);
  // }

  // Payment

  openProfilePayment(payment: Payment = null) {
    if (payment === null || this.cart === null) {
      this.profilePaymentOpened.emit(payment);
      return;
    }

    this.profilePaymentOpened.emit(payment);

    let dataLayer = this.windowRef['dataLayer'] || [];
    let products = this.cart.entries?.map((entry, index) => ({
      item_id: entry.product.code,
      item_name: entry.product.name, 
      index: index,
      discount: (entry.basePrice.value - entry.totalPrice.value / entry.quantity ).toFixed(2),
      quantity: entry.quantity,
      price: entry.basePrice.value.toFixed(2)
    }));

    dataLayer.push({
      event: "add_payment_info",
      ecommerce: {
        currency: this.cart.deliveryCost.currencyIso,
        value: (this.cart.subTotal.value + this.cart.productDiscounts.value + this.cart.orderDiscounts.value).toFixed(2),
        payment_type: this.cart.paymentInfo.cardType.code,
        items: products
      }
    });
  }

  onSubmitProfilePayment(payment: Payment) {
    this.profilePaymentSubmitted.emit(payment);
  }

  confirmDeletePayment(payment: Payment) {
    if(this.profilePayments.payments.length > 1){
      this.profilePayment = payment;
      this.modalService.open(this.confirmDeletePaymentModal).result.then(
        () => {
          this.deleteProfilePayment(payment);
        },
        () => {
        }
      );
    }else{
      this.modalService.open(this.alertDeletePaymentModal).result.then(
        () => {
        },
        () => {
        }
      );
    }
  }

  deleteProfilePayment(payment: Payment) {
    this.profilePaymentRemoved.emit(payment);
  }

  makePrimaryProfilePayment(payment: Payment) {
    return new Promise(resolve => {
        resolve(this.profilePaymentSetPrimary.emit(payment));
    });
  }

  // confirmMakePrimaryProfilePayment(payment: Payment) {
  //     this.profilePayment = payment;
  //     this.modalService.open(this.confirmMakePrimaryPaymentModal).result.then(
  //       async () => {
  //         await this.makePrimaryProfilePayment(payment);
  //         this.modalService.open(this.successPrimaryProfilePaymentModal)
  //       },
  //       () => {
  //       }
  //     );
  // }
  //To set the Min Date for selection
  dateConvertion(date) {
    let dtToday = date;
    let month = dtToday.getMonth() + 1;
    let day = dtToday.getDate();
    let year = dtToday.getFullYear();
    let currentMon: string | number = month,
      today: string | number = day;
    if (month < 10) {
      currentMon = '0' + month.toString();
    }
    if (day < 10) {
      today = '0' + day.toString();
    }
    return year + '-' + currentMon + '-' + today;
  }

  clearPaymentError() {
    this.profilePaymentChangeError = null;
  }

  creditHolds() {
    // Using timeout because of issue here:
    // https://github.com/ng-bootstrap/ng-bootstrap/issues/1775
    setTimeout(() => {
      this.modalService.open(this.creditCardHoldsModal).result.then(
        () => {
        },
        () => {
        }
      );
    });
  }

  goBack() {
    this.navigatedBack.emit();
    // this.router.navigate([this.back]);
  }
}

