import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import * as ErrorActions from '../../core/actions/error.actions';
import * as AuthActions from '../actions/auth.actions';
import * as fromOrders from '../reducers';
import {
  ProfileRegistered,
  ProfileService
} from '../../api';

@Injectable()
export class CheckRegisteredGuard  {

  constructor(private store: Store<fromOrders.State>,
              private profileService: ProfileService) {
  }

  checkRegistered(username: string): Observable<boolean> {
    return this.profileService.getProfileRegistered(username)
      .pipe(
        map((registered: ProfileRegistered) => {
          // if user already registered, redirect to login page
          if (registered && registered.passwordSet) {
           this.store.dispatch(new AuthActions.LoginRedirect());
            return false;
          }
          return true;
        }),
        catchError((error) => {
          this.store.dispatch(new ErrorActions.ReportError(error));
          return of(false);
        })
      );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const username: string = next.queryParams['uid'];
    return this.checkRegistered(username);
  }
}
