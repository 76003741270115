<div class="modal-header">
  <h4 class="modal-title text-white">{{product?.name}}</h4>
</div>
<div class="modal-body">
  <app-zoomable
    class="app-cart-img"
    src="{{product?.images | appImg: 'lrg'}}"
    *ngIf="product?.images">
  </app-zoomable>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-light"
    (click)="activeModal.dismiss('Close click')"
    >Close</button>
</div>