import {
  createSelector,
  createFeatureSelector,
  ActionReducerMap,
} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromCore from './core.reducer';
import * as fromLayout from './layout.reducer';
import * as fromError from './error.reducer';

export interface CoreState {
  status: fromCore.State;
  layout: fromLayout.State;
  error: fromError.State;
}

export interface State extends fromRoot.State {
  core: CoreState;
}

export const reducers: ActionReducerMap<CoreState> = {
  status: fromCore.reducer,
  layout: fromLayout.reducer,
  error: fromError.reducer,
};

export const getCoreState = createFeatureSelector<CoreState>('core');

// Core Status

export const getCoreStatusState = createSelector(
  getCoreState,
  (state: CoreState) => state.status
);

export const getLoading = createSelector(
  getCoreStatusState,
  fromCore.getLoading
);

export const getIsNavigatingBack = createSelector(
  getCoreStatusState,
  fromCore.getIsNavigatingBack
);

export const getIsNavigatedBack = createSelector(
  getCoreStatusState,
  fromCore.getIsNavigatedBack
);

export const getTermsAndConditions = createSelector(
  getCoreStatusState,
  fromCore.getTermsAndConditions
);

export const getPromotionContent = createSelector(
  getCoreStatusState,
  fromCore.getPromotionText
);

// Layout

export const getLayoutState = createSelector(
  getCoreState,
  (state: CoreState) => state.layout
);

export const getIsMobile = createSelector(
  getLayoutState,
  fromLayout.getIsMobile
);

export const getLayoutMode = createSelector(
  getLayoutState,
  fromLayout.getLayoutMode
);

export const getScrollChange = createSelector(
  getLayoutState,
  fromLayout.getScrollChange
);

// Error

export const getErrorState = createSelector(
  getCoreState,
  (state: CoreState) => state.error
);

export const getUnhandledError = createSelector(
  getErrorState,
  fromError.getUnhandledError
);
