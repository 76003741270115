<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout custom-font">
    <div class="header-banner">
      <app-promotions [promoOn]="'loginPage'" [isMobile]="isMobile"></app-promotions>
      <div class="container d-flex align-items-start pt-4">
        <div class="home-logo">
          <img src="assets/images/HillsToHomeLogo.svg" alt="Hills to home logo">
        </div>
        <button class="visit-btn mt-3" (click)="openUrl()">
          <span class="w700">VISIT HILLSPET.COM</span>
          <i class="fa fa-external-link-square-alt pl-2"></i>
        </button>
      </div>

      <div class="container pt-4 mt-2" *ngIf="!isMaintenanceMode">
        <h1 class="intro text-white">PROVIDING YOUR BEST <br/>FRIEND THEIR BEST LIFE.<br/> SCIENCE DID THAT.</h1>
        <div class="intro-content text-white w500">Nutrition brought right to your door.</div>
        <div class="d-flex">
          <!-- Invisible V3 captcha for login - Rahul Solanki -->
          <form [formGroup]="authForm" class="loginform" (ngSubmit)="login()" (keyup.enter)="login()">
            <div *ngIf="errorMessage" class="alert alert-danger small text-center">
              {{errorMessage}}</div>
            <div class="email-error" *ngIf="(authForm.controls['password'].dirty || passwordFocus) &&
            authForm.controls['username'].errors && authForm.controls['username'].errors['pattern']" >
              <ul class="list-unstyled app-invalid-feedback small">
                <li *ngIf="authForm.controls['username'].errors['pattern']">
                  Enter valid email
                </li>
              </ul>
            </div>
            <label for="email" class="w500">Email</label>
            <div class="form-group"
              [class.is-invalid]="authForm.controls['username'].dirty && authForm.controls['username'].invalid">
              <input id="email" name="email" type="email" class="form-control" placeholder="Email"
                formControlName="username">
            </div>
            <label for="password" class="w500">Password</label>
            <div class="form-group"
              [class.is-invalid]="authForm.controls['password'].dirty && authForm.controls['password'].invalid">
              <input id="password" name="password" [type]="isVisible ? 'text' : 'password'" class="form-control" placeholder="Password"
                formControlName="password" (focus)="passwordFocus=true" (blur)="passwordFocus=false">
              <span (click)="toggleVisibility()" [ngClass]="isVisible ? 'fa fas fa-eye-slash showSpan' : 'fa fas fa-eye showSpan'"></span>
            </div>
          <re-captcha
                #captchaRef="reCaptcha"
                (resolved)="resolved($event)"
                (errored)="errored($event)"
                errorMode="handled"
                size="invisible">
            </re-captcha>  
            <!-- <re-captcha (resolved)="resolved($event)" (errored)="errored($event)" errorMode="handled"></re-captcha> -->
          </form>
          <!-- End -->
          <div class="flex-grow-1 d-flex flex-column align-self-center">
          </div>
        </div>

        <div class="form-footer d-flex justify-content-start flex-column">
          <!-- Invisible V3 captcha for login - Rahul Solanki -->
          <button type="button" class="btn btn-primary pl-5 pr-5 btn-lg w-100 mt-2 mb-3 w600" (click)="login()"
            [disabled]="authForm.invalid">
            SIGN IN
          </button>
          <!-- End -->
          <a class="text-nowrap w500 text-white" routerLink="/forgot">
            Forgot Password?
          </a>
        </div>
        <div class="text-white h2h-account-text">
          <p class="w500 pt-3">If you don’t have a <b>Hill’s to Home</b> account
            and would like to give it a try, please contact
            your veterinarian.</p>
        </div>
        <!-- <div class="text-left text-white font-weight-normal pl-2 pr-2 ">If you'd like to try Hill's to Home, please
              contact your veterinarian to get started.</div> -->
        <!-- <div class="d-inline-block mt-6 pb-2 pl-2 pr-2">
              <div class="text-left text-white font-weight-normal pl-2 pr-2 ">If you are a veterinarian interested in
                offering Hill's to Home to your clients,<br> please visit vet.hillstohome.com to register or contact your
                Hill's Representative for more information.
              </div>
            </div> -->
      </div>
      <div class="container pt-4 mt-2" *ngIf="isMaintenanceMode" style="margin-bottom: 14.2rem;">
        <h1 class="intro text-white">
          We're down for scheduled maintenance at this time.
          We'll be back up shortly.
        </h1>
         <re-captcha
            #captchaRef="reCaptcha"
            (resolved)="resolved($event)"
            (errored)="errored($event)"
            errorMode="handled"
            size="invisible">
        </re-captcha> 
        <!--<re-captcha (resolved)="resolved($event)" (errored)="errored($event)" errorMode="handled"></re-captcha>-->
        <p class="h2h-account-text text-white w500 mt-5">
          We apologize for the inconvenience and appreciate your patience!
          <br/>If you have any questions feel free to contact us 7 a.m. - 7 p.m. CST Mon-Fri at (800) 235-6877.
          <br/><br/>Thank you for using Hill's to Home!
        </p>
      </div>
    </div>
    <div class="home-body container mt-4 custom-font w500">
      <!-- <div class="row mb-4">
        <div class="col-12">
          <div class="col-sm-4 d-flex align-items-start healthlabtout">
            <div class="home-logo col-sm-1">
              <img src="assets/images/HillsToHomeLogo.svg" alt="Hills to home logo">
            </div>
            <div class="col-sm-3">
              <span>Hill's Health Lab</span>
              <button class="visit-btn" (click)="openHealthlabUrl()">
                <span>Learn more</span>
              </button>
            </div>
          </div>
        </div>
      </div> -->
      <div class="row">
        <div class="col-12">
          <h3 class="mb-3">THE PET FOOD DELIVERY SERVICE OF THE #1 VET RECOMMENDED BRAND</h3>
          <p class="mb-3 pb-1 pt-1">If you are a pet parent interested in ordering from Hill's to Home, please contact your veterinarian for more information.</p>
          <p class="mb-3 pb-1 pt-1">If you are a veterinarian interested in offering Hill’s to Home to your clients,
            please
            visit
            <a href="https://vet.hillstohome.com/" target="_blank">vet.hillstohome.com</a> to register or contact your
            Hill’s representative for more information.</p>
          <!-- Update copy on landing page - Rahul Solanki -->
          <p class="pt-1">With Hill’s to Home, you can have Prescription Diet or Science Diet conveniently delivered to your house. Plus, you’ll save money on the nutrition your vet recommends.</p>
          <!-- End -->
        </div>
      </div>
      <div class="row pt-4 home-callout px-2">
        <div class="col-sm-4 px-2 text-center">
          <img src="assets/images/dog.jpg" alt="dog image">
          <p class="pt-2 pb-3 mt-2">Save up to 35% on all Hill's products.</p>
        </div>
        <div class="col-sm-4 px-2 text-center">
          <img src="assets/images/dog2.jpg" alt="dog image">
          <p class="pt-2 pb-3 mt-2">Free shipping with no minimum order on the right schedule for you and your pet*</p>
        </div>
        <div class="col-sm-4 px-2 text-center">
          <img src="assets/images/cat.jpg" alt="cat image">
          <p class="pt-2 pb-3 mt-2">Manage your account 24/7 through our secure and mobile-friendly website</p>
        </div>
        <div class="px-2 home-note mb-4 col-md-8 text-center">
          <p>*Free shipping excluding Alaska and Hawaii which have a surcharge of $1.00/item. Shipping rates and
            discounts are subject to change.</p>
        </div>
      </div>
    </div><!-- home-body container -->
    <div class="vet-section py-4">
      <div class="container home-body custom-font w500">
        <div class="col-sm-7 pt-3 pb-2">
          <h3>TALK TO YOUR VET TODAY TO GET STARTED</h3>
          <p class="pt-2">Hill’s to Home is available through trusted veterinary partners. Talk to your vet to find out
            if Hill’s to Home is an option for you.</p>
          <p class="pt-2">If you are a veterinarian interested in offering Hill’s to Home to your clients, please visit
            <b><a href="https://vet.hillstohome.com/" target="_blank">vet.hillstohome.com</a></b> to register or contact
            your Hill’s representative for more information.</p>
        </div>
      </div>
    </div>
    <div class="container home-body custom-font w500 py-5">
      <h3 class="pb-2 text-center">HOW HILL’S TO HOME WORKS</h3>
      <div class="row how-works mt-4 px-2">
        <div class="col-sm-4 px-1">
          <span class="step-icon is-red mb-1">
            <img src="assets/images/icon-lab.png" alt="lab icon">
          </span>
          <p class="pt-2 mb-1 w600">Step 1</p>
          <h4 class="px-3 pb-4">Vet opens your account with the right nutrition for your pet</h4>
        </div>
        <div class="col-sm-4 px-1">
          <span class="step-icon is-blue mb-1">
            <img src="assets/images/icon-order.png" alt="order icon">
          </span>
          <p class="pt-2 mb-1 w600">Step 2</p>
          <h4 class="px-3 pb-4">You choose which products you want to order</h4>
        </div>
        <div class="col-sm-4 px-1">
          <span class="step-icon is-red mb-1">
            <img src="assets/images/icon-deliver.png" alt="deliver icon">
          </span>
          <p class="pt-2 mb-1 w600">Step 3</p>
          <h4 class="px-3">We deliver to your home at a frequency that works for you</h4>
        </div>
      </div>
    </div>
    <div class="container home-body custom-font w500 px-0">
      <div class="row mx-0 pet-section py-4 justify-content-end px-2">
        <div class="col-12 py-4">
          <h3>A STEP AHEAD FOR THEIR BEST LIFE</h3>
          <p class="pt-2">At Hill’s, we know every pet has their own journey, and we’re committed to making food that
            supports them
            every step of the way. Whether you’re feeding for everyday health or specialized nutritional needs, every
            bowl has the potential to bring out your best friend’s best self.</p>
          <a href="https://www.hillspet.com/" target="_blank"
            class="link-btn btn-blue px-5 py-3 mt-2 w500 w-100">hillspet.com</a>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="app-mobile-layout fixed-top fixed-bottom d-flex flex-column">
    <div class="d-flex flex-column flex-grow-1 flex-shrink-0 justify-content-around align-items-center">
      <div class="d-flex align-items-center">
        <i class="app-logo"></i>
      </div>
      <div class="text-center">
        <h1 class="ml-5 mr-5 text-white text-center">Welcome to<br>Hill's to Home</h1>
        <p class="ml-5 mr-5 text-white text-center">#1 Vet Recommended brand<br>brought right to your door for free</p>
      </div>
    </div>
    <form [formGroup]="authForm" autocomplete="off" class="m-4" (ngSubmit)="login()">
      <div class="form-group"
        [class.is-invalid]="authForm.controls['username'].dirty && authForm.controls['username'].invalid">
        <label for="email" class="small">Email</label>
        <input id="email" name="email" type="email" class="form-control" placeholder="Email" formControlName="username">
      </div>
      <div class="form-group"
        [class.is-invalid]="authForm.controls['password'].dirty && authForm.controls['password'].invalid">
        <label for="password" class="small">Password</label>
        <input id="password" name="password" type="password" class="form-control" placeholder="Password"
          formControlName="password">
      </div>
      <div class="mt-4 text-center">
        <a class="text-black font-weight-bold" routerLink="/forgot">
          Forgot password?
        </a>
      </div>
      <div *ngIf="errorMessage" class="alert alert-danger mt-4 small text-center">
        {{errorMessage}}
      </div>
      <div class="mr-2 ml-2 mt-4">
        <button type="submit" class="btn btn-primary w-100" [disabled]="authForm.invalid">
          Sign In
        </button>
      </div>
      <div class="mt-4 text-center text-white font-weight-normal pl-2 pr-2 ">If you'd like to try Hill's to Home, please
        contact your veterinarian to get started.</div>
      <div class="mt-4 text-center">
        <p class="text-center text-white font-weight-normal">
          If you are a veterinarian interested in offering Hill's to Home to your clients, please visit
          vet.hillstohome.com to register or contact your Hill's Representative for more information.
        </p>
      </div>
      <div class="mt-1 text-center">
        <a class="alternate text-white-50" [href]="'https://www.hillspet.com/legal-statement-and-privacy-policy'"
          appExternalUrl>
          Legal & Privacy Policy
        </a>
      </div>
      <div class="mt-2 mb-auto text-center">
        <a class="alternate text-white-50" routerLink="/terms">
          Terms of service
        </a>
      </div>
      <div class="mt-2 mb-auto text-center text-white">
        Contact Us<br>1 800-235-6877 <br>hillstohome@hillspet.com<br>7 a.m - 7 p.m CST Mon-Fri
      </div>
    </form>
  </div> -->
</ng-template>

<ng-template #fullLayout>
  <div class="app-full-layout custom-font">
    <div class="header-banner">
      <app-promotions [promoOn]="'loginPage'" [isMobile]="isMobile"></app-promotions>
      <div class="container d-flex align-items-start pt-4">
        <div class="home-logo">
          <img src="assets/images/HillsToHomeLogo.svg" alt="Hills to home logo">
        </div>
        <button class="visit-btn mt-3" (click)="openUrl()">
          <span class="w700">VISIT HILLSPET.COM</span>
          <i class="fa fa-external-link-square-alt pl-2"></i>
        </button>
      </div>

      <div class="container pt-4 mt-2" *ngIf="!isMaintenanceMode">
        <h1 class="intro text-white">PROVIDING YOUR BEST <br/>FRIEND THEIR BEST LIFE.<br/> SCIENCE DID THAT.</h1>
        <div class="intro-content text-white w500">Nutrition brought right to your door.</div>
        <div class="d-flex">
          <!-- Invisible V3 captcha for login - Rahul Solanki -->
          <form [formGroup]="authForm" class="w-25" (ngSubmit)="login()" (keyup.enter)="login()">
            <div *ngIf="errorMessage" class="alert alert-danger small text-center">
              {{errorMessage}}</div>
            <div class="email-error" *ngIf="(authForm.controls['password'].dirty || passwordFocus) &&
            authForm.controls['username'].errors && authForm.controls['username'].errors['pattern']">
              <ul class="list-unstyled app-invalid-feedback small">
                <li *ngIf="authForm.controls['username'].errors['pattern']">
                  Enter valid email
                </li>
              </ul>
            </div>
            <label for="email" class="w500 text-white">Email</label>
            <div class="form-group"
              [class.is-invalid]="authForm.controls['username'].dirty && authForm.controls['username'].invalid">
              <input id="email" name="email" type="email" class="form-control" placeholder="Email"
                formControlName="username">
            </div>
            <label for="password" class="w500 text-white">Password</label>
            <div class="form-group"
              [class.is-invalid]="authForm.controls['password'].dirty && authForm.controls['password'].invalid">
              <input id="password" name="password" [type]="isVisible ? 'text' : 'password'" class="form-control" placeholder="Password"
                formControlName="password" (focus)="passwordFocus=true" (blur)="passwordFocus=false">
                <span (click)="toggleVisibility()" [ngClass]="isVisible ? 'fa fas fa-eye-slash showSpan' : 'fa fas fa-eye showSpan'"></span>
            </div>
             <re-captcha
                #captchaRef="reCaptcha"
                (resolved)="resolved($event)"
                (errored)="errored($event)"
                errorMode="handled"
                size="invisible">
            </re-captcha>  
           <!-- <re-captcha (resolved)="resolved($event)" (errored)="errored($event)" errorMode="handled" size="invisible"></re-captcha>-->
          </form>
          <!-- End -->
          <div class="flex-grow-1 d-flex flex-column align-self-center">
          </div>
        </div>
        <div class="form-footer d-flex justify-content-start flex-column">
          <!-- Invisible V3 captcha for login - Rahul Solanki -->
          <button type="button" class="btn btn-primary pl-5 pr-5 btn-lg w-100 mt-2 mb-3 w600" (click)="login()"
            [disabled]="authForm.invalid">
            SIGN IN
          </button>
          <!-- End -->
          <a class="text-nowrap w500 text-white" routerLink="/forgot">
            Forgot Password?
          </a>
        </div>
        <div class="text-white h2h-account-text">
          <p class="w500 pt-3 pb-md-4">If you don’t have a <b>Hill’s to Home</b> account
            and would like to give it a try, please contact
            your veterinarian.</p>
        </div>
        <!-- <div class="text-left text-white font-weight-normal pl-2 pr-2 ">If you'd like to try Hill's to Home, please
          contact your veterinarian to get started.</div> -->
        <!-- <div class="d-inline-block mt-6 pb-2 pl-2 pr-2">
          <div class="text-left text-white font-weight-normal pl-2 pr-2 ">If you are a veterinarian interested in
            offering Hill's to Home to your clients,<br> please visit vet.hillstohome.com to register or contact your
            Hill's Representative for more information.
          </div>
        </div> -->
      </div>
      <div class="container pt-4 mt-2" *ngIf="isMaintenanceMode" style="margin-bottom: 13.5rem;">
        <h1 class="intro text-white">
          We're down for scheduled<br/>maintenance at this time.
          <br/>We'll be back up shortly.
        </h1>
        <re-captcha
            #captchaRef="reCaptcha"
            (resolved)="resolved($event)"
            (errored)="errored($event)"
            errorMode="handled"
            size="invisible">
        </re-captcha> 
         <!--<re-captcha (resolved)="resolved($event)" (errored)="errored($event)" errorMode="handled"></re-captcha>-->
        <div class="intro-content text-white w500 mt-5" >
          We apologize for the inconvenience and appreciate your patience!
          <br/>If you have any questions feel free to contact us 7 a.m. - 7 p.m. CST
          <br/>Mon-Fri at (800) 235-6877.
          <br/><br/>Thank you for using Hill's to Home!
        </div>
      </div>
    </div>
    <div class="home-body pet-food container mt-n6 custom-font w500">
      <div class="row vet-info">
        <div class="col-md-12">
          <h2 class="mb-4 pb-1">THE PET FOOD DELIVERY SERVICE OF THE #1 VET RECOMMENDED BRAND</h2>
        </div>
        <!-- <div class="col-md-2 col-sm-3 col-xs-3 d-flex align-items-start healthlabtout">
          <div class="home-logo col-md-4 col-sm-4">
            <img src="assets/images/HillsToHomeLogo.svg" alt="Hills to home logo">
          </div>
          <div class="col-md-8 col-sm-8">
            <span>Hill's Health Lab</span>
            <button class="visit-btn" (click)="openHealthlabUrl()">
              <span>Learn more</span>
            </button>
          </div>
        </div> -->
        <div class = "col-md-5">
          <p class = "mb-4 pb-1 text-justify">
            If you are a pet parent interested in ordering from Hill's to Home, please contact your veterinarian for more information.
          </p>
        </div>  
        <div class="col-md-5">
          <p class="mb-4 pb-1 text-justify content-divider">If you are a veterinarian interested in offering Hill’s to Home to your
            clients,
            please visit
            <a href="https://vet.hillstohome.com/" target="_blank">vet.hillstohome.com</a> to register or contact your
            Hill’s representative for more information.</p>
          </div>
          <div class="col-md-8">
            <!-- Update copy on landing page - Rahul Solanki -->
            <p class="pt-1 text-justify">With Hill’s to Home, you can have Prescription Diet or Science Diet conveniently delivered to your house. Plus, you’ll save money on the nutrition your vet recommends.</p>
            <!-- End -->
          </div>
      </div>
      <div class="row pt-4 home-callout px-2">
        <div class="col-sm-4 px-2">
          <img src="assets/images/dog.jpg" alt="dog image">
          <p class="pt-4 mt-2">Save up to 35% on all Hill's products.</p>
        </div>
        <div class="col-sm-4 px-2">
          <img src="assets/images/dog2.jpg" alt="dog image">
          <p class="pt-4 mt-2">Free shipping with no minimum order on the right schedule for you and your pet*</p>
        </div>
        <div class="col-sm-4 px-2">
          <img src="assets/images/cat.jpg" alt="cat image">
          <p class="pt-4 mt-2">Manage your account 24/7 through our secure and mobile-friendly website</p>
        </div>
        <div class="pl-2 pt-4 home-note mb-5 col-md-8">
          <p class="text-justify">*Free shipping excluding Alaska and Hawaii which have a surcharge of $1.00/item.
            Shipping rates and
            discounts are subject to change.</p>
        </div>
      </div>
    </div><!-- home-body container -->
    <div class="vet-section py-6">
      <div class="container home-body custom-font w500">
        <div class="col-sm-7">
          <h2>TALK TO YOUR VET <br>TODAY TO GET STARTED</h2>
          <p class="pt-2">Hill’s to Home is available through trusted veterinary partners. Talk to your vet to find out
            if Hill’s to Home is an option for you.</p>
          <p class="pt-2">If you are a veterinarian interested in offering Hill’s to Home to your clients, please visit
            <b><a href="https://vet.hillstohome.com/" target="_blank">vet.hillstohome.com</a></b> to register or contact
            your Hill’s representative for more information.</p>
        </div>
      </div>
    </div>
    <div class="container home-body custom-font w500 py-5 my-4">
      <h2 class="pb-2 text-sm-center text-xl-left">HOW HILL’S TO HOME WORKS</h2>
      <div class="row how-works mt-5 px-2">
        <div class="col-sm-4 px-1">
          <span class="step-icon is-red mb-1">
            <img src="assets/images/icon-lab.png" alt="lab icon">
          </span>
          <p class="pt-4 w600">Step 1</p>
          <h4 class="px-3">Vet opens your account with the right nutrition for your pet</h4>
        </div>
        <div class="col-sm-4 px-1">
          <span class="step-icon is-blue mb-1">
            <img src="assets/images/icon-order.png" alt="order icon">
          </span>
          <p class="pt-4 w600">Step 2</p>
          <h4 class="px-3">You choose which products you want to order</h4>
        </div>
        <div class="col-sm-4 px-1">
          <span class="step-icon is-red mb-1">
            <img src="assets/images/icon-deliver.png" alt="deliver icon">
          </span>
          <p class="pt-4 w600">Step 3</p>
          <h4 class="px-3">We deliver to your home at a frequency that works for you</h4>
        </div>
      </div>
    </div>
    <div class="container home-body custom-font w500 pb-5 mb-4 p-0 pb-lg-5 mb-lg-4 pb-md-0 mb-md-0">
      <div class="row mx-0 pet-section py-5 justify-content-end mb-2 mb-lg-2 mb-md-0">
        <div class="col-md-6 py-4 pr-4">
          <h2>A STEP AHEAD <br>FOR THEIR BEST LIFE</h2>
          <p class="pt-2">At Hill’s, we know every pet has their own journey, and we’re committed to making food that
            supports them
            every step of the way. Whether you’re feeding for everyday health or specialized nutritional needs, every
            bowl has the potential to bring out your best friend’s best self.</p>
          <a href="https://www.hillspet.com/" target="_blank"
            class="link-btn btn-blue px-5 py-3 mt-2 w500">hillspet.com</a>
        </div>
      </div>
    </div>
  </div><!-- end Full Layout -->
</ng-template>

<ng-template #resetPwdFailedModal let-closeconfirm="close">
  <div class="modal-header text-center">
    <p class="m-t4 h5 text-center">Link expired</p>
    <button type="button" class="close" aria-label="Close" (click)="closeconfirm()"><span
        aria-hidden="true">&times;</span></button>
  </div>
  <div class="modal-body text-center">
    <p class="m-t4">
      We're sorry, the password reset link
      has expired or has already been used.
    </p>
    <p class="m-t4">
      Please click "Forgot Password?" below
      the login to receive a new link.
    </p>
  </div>
  <div class='modal-footer' *ngIf="!isMobile">
    <button type="button" class="btn btn-primary pl-4 pr-4 btn-md" (click)="closeconfirm()">
      Return to Login
    </button>
  </div>
</ng-template>