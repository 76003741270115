import { Component, HostBinding, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromAuth from '../../../auth/reducers';
import * as fromCore from '../../../core/reducers';
import * as fromProfile from '../../reducers';
import * as ProfileActions from '../../actions/profile.actions';
import { EmailChange } from '../../models/emailChange';
import { flyOutIn } from '../../../shared/animations/common.animations';

@Component({
  selector: 'app-edit-email-page',
  template: `
    <app-edit-email
      [isLoggedIn]="isLoggedIn$ | async"
      [isMobile]="isMobile$ | async"
      [error]="error$ | async"
      [pending]="pending$ | async"
      [profile]="profile$ | async"
      (submitted)="onSubmit($event)">
    </app-edit-email>
  `,
  styles: [],
  animations: [flyOutIn]
})
export class EditEmailPageComponent implements OnInit {

  @HostBinding('@flyOutIn')
  flyOutIn = 'in';

  /* This is commented to prevent Form overlap with Footer*/
  // @HostBinding('class.app-animated-page')
  // animatedPage = true;

  isLoggedIn$ = this.store.pipe(select(fromAuth.getIsLoggedIn));

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  error$ = this.store.pipe(select(fromProfile.getProfileEmailChangeError));

  pending$ = this.store.pipe(select(fromProfile.getProfileEmailChangePending));

  profile$ = this.store.pipe(select(fromProfile.getProfile));

  constructor(private store: Store<fromProfile.State>) {
  }

  ngOnInit() {
    this.store.dispatch(new ProfileActions.UpdateEmailReset());
  }

  onSubmit(emailChange: EmailChange) {
    this.store.dispatch(new ProfileActions.UpdateEmail(emailChange));
  }

}
