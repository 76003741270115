import {
  Component, OnInit, Input, EventEmitter,
  Output, SimpleChanges, SimpleChange, OnChanges, ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Patterns } from '../../../shared/validation/patterns';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnChanges {

  authForm: UntypedFormGroup;

  @Input() isMobile: boolean | false;

  @Input() error: string | null;

  @Input() pending: boolean | false;

  @Output() submitted = new EventEmitter<string>();

  @ViewChild('successModal', { static: true })
  successModal: any;

  constructor(private fb: UntypedFormBuilder,
              private modalService: NgbModal,
              private router: Router) {
    this.createForm();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    const pendingChange: SimpleChange = changes['pending'];
    if (pendingChange && !pendingChange.firstChange &&
      !pendingChange.currentValue /*&& !this.error)*/) {
      this.success();
    }
  }

  createForm() {
    this.authForm = this.fb.group({
      email: ['', {
        validators: [Validators.required, Validators.pattern(Patterns.emailPattern)],
      }]
    });
  }

  resetPassword() {
     if (this.authForm.valid) {
      const model = this.authForm.value;
      this.submitted.emit(model.email);
     }
  }

  success() {
    // Using timeout because of issue here:
    // https://github.com/ng-bootstrap/ng-bootstrap/issues/1775
    setTimeout(() => {
      this.modalService.open(this.successModal).result.then(
        () => {
          this.router.navigate(['/login']);
        },
        () => {
          this.router.navigate(['/login']);
        }
      );
    });
  }
}
