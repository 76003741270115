
import { Action } from '@ngrx/store';
import {
  Cart,
  DeliveryMode,
  Order,
  OrderDetail,
  Subscriptions,
  SubscriptionFilters,
  AutoShipTemplate,
  AddingCart,
  EditingCart,
  EditingSubscription,
  EditingResultSubscription
} from '../../api';
import { OrderFilters } from '../models/orderFilters';
import { DeliveryModeChange } from '../models/deliveryModelChange';
import { CartChange } from '../models/cartChange';
import { GetEditSubscription } from "../../api/model/getEditSubscription";
import { ReturnOrderDetail } from '../../api/model/returnOrderDetail';

export enum OrdersActionTypes {
  LoadCart = '[Orders] Load Cart',
  CartRedirect = '[Orders] Cart Redirect',
  UpdateApplyPromo = '[Orders] Update Apply Promo',
  removeApplyPromo = '[Orders] Remove Apply Promo',
  GetCart = '[Orders] Get Cart',
  GetCartSuccess = '[Orders] Get Cart Success',
  GetCartDetail = '[Orders] Get Cart Detail',
  GetCartDetailSuccess = '[Orders] Get Cart Detail Success',
  GetDeliveryModes = '[Orders] Get Delivery Modes',
  GetDeliveryModesSuccess = '[Orders] Get Delivery Modes Success',
  GetDeliveryModesAndCartDetail = '[Orders] Get Delivery Modes And Cart Detail ',
  GetDeliveryModesAndCartDetailSuccess = '[Orders] Get Delivery Modes And Cart Detail Success',
  PlaceOrder = '[Orders] Place Order',
  PlaceOrderSuccess = '[Orders] Place Order Success',
  GetOrders = '[Orders] Get Orders',
  GetOrdersSuccess = '[Orders] Get Orders Success',
  SearchOrders = '[Orders] Search Orders',
  SearchOrdersSuccess = '[Orders] Search Orders Success',
  GetOrderDetail = '[Orders] Get Order',
  GetOrderDetailSuccess = '[Orders] Get Order Success',
  CancelOrder = '[Orders] Cancel Order',
  CancelOrderSuccess = '[Orders] Cancel Order Success',
  UpdateCartAddress = '[Orders] Update Cart Address',
  UpdateCartAddressSuccess = '[Orders] Update Cart Address Success',
  UpdateCartPayment = '[Orders] Update Cart Payment',
  UpdateCartPaymentSuccess = '[Orders] Update Cart Payment Success',
  UpdateCartDeliveryMode = '[Orders] Update Cart Delivery Mode',
  UpdateCartDeliveryModeSuccess = '[Orders] Update Cart Delivery Mode Success',
  SearchSubscriptions = '[Orders] Search Subscriptions',
  SearchSubscriptionsSuccess = '[Orders] Search Subscriptions Success',
  GetSubscription = '[Orders] Get Subscription',
  GetSubscriptionSuccess = '[Orders] Get Subscription Success',
  AddProductToCart = '[Orders] Add Product To Cart',
  AddProductToCartSuccess = '[Orders] Add Product To Cart Success',
  UpdateCartProduct = '[Orders] Update Cart Product',
  UpdateCartProductSuccess = '[Orders] Update Cart Product Success',
  UpdateSubscription = '[Orders] Update Subscription',
  UpdateSubscriptionSuccess = '[Orders] Update Subscription Success',
  GetUpdateSubscription = '[Orders] Get Update Subscription',
  GetUpdateSubscriptionSuccess = '[Orders] Get Update Subscription Success',
  ChangeSubscriptionProduct = '[Orders] Change Subscription Product',
  GetUpcomingOrders = '[Orders] Get Upcoming Orders',
  GetUpcomingOrdersSuccess = '[Orders] Get Upcoming Orders Success',
  UpdateOrderDeliveryDate = '[Orders] Update Order Delivery Date',
  UpdateOrderDeliveryDateSuccess = '[Orders] Update Order Delivery Date Success',
  ReturnOrder = '[Orders] Return Order',
  ReturnOrderSuccess = '[Orders] Return Order Success',
  GetReturnOrderDetail = '[Orders] Get Return Order',
  GetReturnOrderDetailSuccess = '[Orders] Get Return Order Success'
}

export class LoadCart implements Action {
  readonly type = OrdersActionTypes.LoadCart;

  constructor(public cart: Cart) {
  }
}

export class CartRedirect implements Action {
  readonly type = OrdersActionTypes.CartRedirect;

  constructor() {
  }
}

export class GetCart implements Action {
  readonly type = OrdersActionTypes.GetCart;

  constructor() {
  }
}

export class GetCartSuccess implements Action {
  readonly type = OrdersActionTypes.GetCartSuccess;

  constructor(public cart: Cart) {
  }
}

export class GetCartDetail implements Action {
  readonly type = OrdersActionTypes.GetCartDetail;

  constructor() {
  }
}

export class updateAppliedPromo implements Action {
  readonly type = OrdersActionTypes.UpdateApplyPromo;

  constructor() {

  }
}

export class removeAppliedPromo implements Action {
  readonly type = OrdersActionTypes.removeApplyPromo;

  constructor() {

  }
}

export class GetCartDetailSuccess implements Action {
  readonly type = OrdersActionTypes.GetCartDetailSuccess;

  constructor(public cartDetail: Cart) {
    if (cartDetail.appliedVouchers !== undefined)
      if (cartDetail.appliedVouchers[0].voucherCode !== "")
        localStorage.setItem("promoCode", cartDetail.appliedVouchers[0].voucherCode);
  }
}

export class GetDeliveryModes implements Action {
  readonly type = OrdersActionTypes.GetDeliveryModes;

  constructor() {
  }
}

export class GetDeliveryModesSuccess implements Action {
  readonly type = OrdersActionTypes.GetDeliveryModesSuccess;

  constructor(public deliveryModes: DeliveryMode[]) {
  }
}

export class GetDeliveryModesAndCartDetail implements Action {
  readonly type = OrdersActionTypes.GetDeliveryModesAndCartDetail;

  constructor() {
  }
}

export class GetDeliveryModesAndCartDetailSuccess implements Action {
  readonly type = OrdersActionTypes.GetDeliveryModesAndCartDetailSuccess;

  constructor(public deliveryModes: DeliveryMode[], public cartDetail: Cart) {
  }
}

export class PlaceOrder implements Action {
  readonly type = OrdersActionTypes.PlaceOrder;

  constructor() {
  }
}

export class PlaceOrderSuccess implements Action {
  readonly type = OrdersActionTypes.PlaceOrderSuccess;

  constructor(public placedOrderDetail: OrderDetail) {
  }
}

export class GetOrders implements Action {
  readonly type = OrdersActionTypes.GetOrders;

  constructor() {
  }
}

export class GetOrdersSuccess implements Action {
  readonly type = OrdersActionTypes.GetOrdersSuccess;

  constructor(public orders: Order[]) {
  }
}

export class SearchOrders implements Action {
  readonly type = OrdersActionTypes.SearchOrders;

  constructor(public orderFilters: OrderFilters) {
  }
}

export class SearchOrdersSuccess implements Action {
  readonly type = OrdersActionTypes.SearchOrdersSuccess;

  constructor(public searchedOrders: Order[]) {
  }
}

export class GetOrderDetail implements Action {
  readonly type = OrdersActionTypes.GetOrderDetail;

  constructor(public id: string) {
  }
}

export class GetOrderDetailSuccess implements Action {
  readonly type = OrdersActionTypes.GetOrderDetailSuccess;

  constructor(public orderDetail: OrderDetail) {
  }
}

export class CancelOrder implements Action {
  readonly type = OrdersActionTypes.CancelOrder;

  constructor(public order: OrderDetail) {
  }
}

export class CancelOrderSuccess implements Action {
  readonly type = OrdersActionTypes.CancelOrderSuccess;

  constructor(public orderDetail: OrderDetail) {
  }
}

export class UpdateCartAddress implements Action {
  readonly type = OrdersActionTypes.UpdateCartAddress;

  constructor(public addressId: string) {
  }
}

export class UpdateCartAddressSuccess implements Action {
  readonly type = OrdersActionTypes.UpdateCartAddressSuccess;

  constructor() {
  }
}

export class UpdateCartPayment implements Action {
  readonly type = OrdersActionTypes.UpdateCartPayment;

  constructor(public paymentId: string) {
  }
}

export class UpdateCartPaymentSuccess implements Action {
  readonly type = OrdersActionTypes.UpdateCartPaymentSuccess;

  constructor() {
  }
}

export class UpdateCartDeliveryMode implements Action {
  readonly type = OrdersActionTypes.UpdateCartDeliveryMode;

  constructor(public deliveryModeChange: DeliveryModeChange) {
  }
}

export class UpdateCartDeliveryModeSuccess implements Action {
  readonly type = OrdersActionTypes.UpdateCartDeliveryModeSuccess;

  constructor() {
  }
}

export class SearchSubscriptions implements Action {
  readonly type = OrdersActionTypes.SearchSubscriptions;

  constructor(public subscriptionFilters: SubscriptionFilters = null) {
  }
}

export class SearchSubscriptionsSuccess implements Action {
  readonly type = OrdersActionTypes.SearchSubscriptionsSuccess;

  constructor(public searchedSubscriptions: Subscriptions) {
  }
}

export class GetSubscription implements Action {
  readonly type = OrdersActionTypes.GetSubscription;

  constructor(public id: string) {
  }
}

export class GetSubscriptionSuccess implements Action {
  readonly type = OrdersActionTypes.GetSubscriptionSuccess;

  constructor(public subscription: AutoShipTemplate) {
  }
}

export class AddProductToCart implements Action {
  readonly type = OrdersActionTypes.AddProductToCart;

  constructor(public addingCart: AddingCart) {
  }
}

export class AddProductToCartSuccess implements Action {
  readonly type = OrdersActionTypes.AddProductToCartSuccess;

  constructor() {
  }
}

export class UpdateCartProduct implements Action {
  readonly type = OrdersActionTypes.UpdateCartProduct;

  constructor(public cartChange: CartChange) {
  }
}

export class UpdateCartProductSuccess implements Action {
  readonly type = OrdersActionTypes.UpdateCartProductSuccess;

  constructor() {
  }
}

export class UpdateSubscription implements Action {
  readonly type = OrdersActionTypes.UpdateSubscription;

  constructor(public editingSubscription: EditingSubscription) {
  }
}

export class UpdateSubscriptionSuccess implements Action {
  readonly type = OrdersActionTypes.UpdateSubscriptionSuccess;

  constructor(public editingSubscription: EditingSubscription, public editingResultSubscription: EditingResultSubscription) {
  }
}

export class GetUpdateSubscription implements Action {
  readonly type = OrdersActionTypes.GetUpdateSubscription
  constructor(public getEditSubscription: GetEditSubscription) {
  }
}

export class GetUpdateSubscriptionSuccess implements Action {
  readonly type = OrdersActionTypes.GetUpdateSubscriptionSuccess;

  constructor(public getEditSubscription: GetEditSubscription, public editingResultSubscription: EditingResultSubscription) {
  }
}

export class ChangeSubscriptionProduct implements Action {
  readonly type = OrdersActionTypes.ChangeSubscriptionProduct;

  constructor(public productCode: string) {
  }
}

export class GetUpcomingOrders implements Action {
  readonly type = OrdersActionTypes.GetUpcomingOrders;

  constructor() { }
}

export class GetUpcomingOrdersSuccess implements Action {
  readonly type = OrdersActionTypes.GetUpcomingOrdersSuccess;

  constructor(public upcomingOrders: AutoShipTemplate[]) { }
}

export class UpdateOrderDeliveryDate implements Action {
  readonly type = OrdersActionTypes.UpdateOrderDeliveryDate;
  constructor(public editingSubscription: EditingSubscription) { };
}

export class UpdateOrderDeliveryDateSuccess implements Action {
  readonly type = OrdersActionTypes.UpdateOrderDeliveryDateSuccess;
  constructor(public editingSubscription: EditingSubscription, public editingResultSubscription: EditingResultSubscription) { };
}

export class ReturnOrder implements Action {
  readonly type = OrdersActionTypes.ReturnOrder;

  constructor(public order: ReturnOrderDetail) {
  }
}

export class ReturnOrderSuccess implements Action {
  readonly type = OrdersActionTypes.ReturnOrderSuccess;

  constructor(public returnOrderDetail: ReturnOrderDetail) {
  }
}

export class GetReturnOrderDetail implements Action {
  readonly type = OrdersActionTypes.GetReturnOrderDetail

  constructor(public id: string) {
  }
}

export class GetReturnOrderDetailSuccess implements Action {
  readonly type = OrdersActionTypes.GetReturnOrderDetailSuccess;

  constructor(public returnOrderDetail:ReturnOrderDetail) {
  }
}

export type OrdersActionsUnion =
  | LoadCart
  | CartRedirect
  | updateAppliedPromo
  | removeAppliedPromo
  | GetCart
  | GetCartSuccess
  | GetCartDetail
  | GetCartDetailSuccess
  | GetDeliveryModes
  | GetDeliveryModesSuccess
  | GetDeliveryModesAndCartDetail
  | GetDeliveryModesAndCartDetailSuccess
  | PlaceOrder
  | PlaceOrderSuccess
  | GetOrders
  | GetOrdersSuccess
  | SearchOrders
  | SearchOrdersSuccess
  | GetOrderDetail
  | GetOrderDetailSuccess
  | CancelOrder
  | CancelOrderSuccess
  | UpdateCartAddress
  | UpdateCartAddressSuccess
  | UpdateCartPayment
  | UpdateCartPaymentSuccess
  | UpdateCartDeliveryMode
  | UpdateCartDeliveryModeSuccess
  | SearchSubscriptions
  | SearchSubscriptionsSuccess
  | GetSubscription
  | GetSubscriptionSuccess
  | AddProductToCart
  | AddProductToCartSuccess
  | UpdateCartProduct
  | UpdateCartProductSuccess
  | UpdateSubscription
  | UpdateSubscriptionSuccess
  | GetUpdateSubscription
  | GetUpdateSubscriptionSuccess
  | ChangeSubscriptionProduct
  | GetUpcomingOrders
  | GetUpcomingOrdersSuccess
  | UpdateOrderDeliveryDateSuccess
  | UpdateOrderDeliveryDate
  | ReturnOrder
  | ReturnOrderSuccess
  | GetReturnOrderDetail
  | GetReturnOrderDetailSuccess
  ;
