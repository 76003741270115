import {
  ErrorActionTypes,
  ErrorActionsUnion,
} from '../actions/error.actions';

export interface State {
  unhandledError: any;
}

const initialState: State = {
  unhandledError: null,
};

export function reducer(state: State = initialState, action: ErrorActionsUnion): State {
  switch (action.type) {

    case ErrorActionTypes.ReportError:
      return {
        ...state,
        unhandledError: action.unhandledError,
      };
    case ErrorActionTypes.ClearError:
      return {
        ...state,
        unhandledError: null
      }
    default:
      return state;
  }
}

export const getUnhandledError = (state: State) => state.unhandledError;
