import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

// Error handler
import { AppErrorHandler } from './app.errorhandler';

// Bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Modules
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { ApiModule } from './api/api.module';
import { CoreModule } from './core/core.module';
import { AuthModule } from './auth/auth.module';
import { HomeModule } from './home/home.module';
import { ProfileModule } from './profile/profile.module';
import { OrdersModule } from './orders/orders.module';
import { PetsModule } from './pets/pets.module';
import { SupportModule } from './support/support.module';
import { ProductsModule } from './products/products.module';

// Environment
import { environment } from '../environments/environment';

// Api
import { Configuration } from './api/configuration';
import { BASE_PATH } from './api/';

// PWA
import { ServiceWorkerModule } from '@angular/service-worker';

// Redux
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './app.effects';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { CustomRouterStateSerializer } from './shared/utils/routerStateUrl';

// Http Interceptor
import { AppHttpInterceptor } from './app.http-interceptor';

// Angular Stripes Module for payments
import { NgxStripeModule } from 'ngx-stripe';

// To Google Captcha
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//To Logout when user is idle
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { PromotionsService } from "./shared/services/promotions.service";
import { EditOrderDeliveryModule } from './orders/containers/edit-order-delivery-date-page/edit-order-delivery.module';
/**
 * Configuration for API, example:
 * https://github.com/swagger-api/swagger-codegen/tree/master/samples/client/petstore/typescript-angular-v5/npm
 */
export const apiConfig = new Configuration({
  apiKeys: {}
});

/**
 * Returning static api config
 */
export function getApiConfig(): Configuration {
  return apiConfig;
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    /**
     * If the Web Animations API is not supported natively by the browser,
     * then Angular will use CSS keyframes as a fallback instead (automatically).
     * This means that the polyfill is no longer required unless any code uses AnimationBuilder.
     * If your code does use AnimationBuilder, then uncomment the web-animations-js polyfill
     * from the polyfills.ts file generated by Angular CLI.
     */
    BrowserAnimationsModule,
    /**
     * Adding the core for ng-bootstrap
     */
    NgbModule,
    /**
     * Adding the http client module for api calls
     */
    HttpClientModule,
    /**
     * Sending out the static api configuration to the api module
     */
    ApiModule.forRoot(getApiConfig),

    // Initialize the Stripes provider
    NgxStripeModule.forRoot(environment.stripe_publishable_key),
    /**
     * The forRoot convention implies that a given module must be registered with the root NgModule of an application
     * while invoking the forRoot() method. Auth requires to be loaded at the root for its providers.
     */
    SharedModule.forRoot(),
    CoreModule.forRoot(),
    AuthModule.forRoot(),
    OrdersModule.forRoot(),
    ProfileModule.forRoot(),
    PetsModule.forRoot(),
    ProductsModule.forRoot(),
    SupportModule.forRoot(),
    HomeModule,
    // EditOrderDeliveryModule.forRoot(),
    AppRoutingModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    FormsModule,
    ReactiveFormsModule,
    NgIdleKeepaliveModule.forRoot(),

    // todo: need further testing
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: false && environment.production }),
    /**
     * StoreModule.forRoot is imported once in the root module, accepting a reducer
     * function or object map of reducer functions. If passed an object of
     * reducers, combineReducers will be run creating your application
     * meta-reducer. This returns all providers for an @ngrx/store
     * based application.
     */
    StoreModule.forRoot(reducers, { metaReducers }),
    /**
     * @ngrx/router-store keeps router state up-to-date in the store.
     */
    StoreRouterConnectingModule.forRoot({
      /*
        They stateKey defines the name of the state used by the router-store reducer.
        This matches the key defined in the map of reducers
      */
      stateKey: 'router',
    }),
    /**
     * Store devtools instrument the store retaining past versions of state
     * and recalculating new states. This enables powerful time-travel
     * debugging.
     *
     * To use the debugger, install the Redux Devtools extension for either
     * Chrome or Firefox
     *
     * See: https://github.com/zalmoxisus/redux-devtools-extension
     */
    !environment.production ? StoreDevtoolsModule.instrument({
      name: 'NgRx Pet-Companion Store DevTools',
    }) : [],
    /**
     * EffectsModule.forRoot() is imported once in the root module and
     * sets up the effects class to be initialized immediately when the
     * application starts.
     *
     * See: https://github.com/ngrx/platform/blob/master/docs/effects/api.md#forroot
     */
    EffectsModule.forRoot([AppEffects])
  ],
  providers: [
    PromotionsService,
    /**
     * Error handler for any unhandled error management
     */
    { provide: ErrorHandler, useClass: AppErrorHandler },
    /**
     * Http interceptor to handle different scenarios like loading indicators
     */
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
    /**
     * For Api, if different than the generated base path, during app bootstrap, provide the base path to your service.
     */
    { provide: BASE_PATH, useValue: environment.api_base_path },
    /**
     * The `RouterStateSnapshot` provided by the `Router` is a large complex structure.
     * A custom RouterStateSerializer is used to parse the `RouterStateSnapshot` provided
     * by `@ngrx/router-store` to include only the desired pieces of the snapshot.
     */
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
