import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromCore from '../../../core/reducers';
import * as fromProfile from '../../reducers';
import * as ProfileActions from '../../actions/profile.actions';
import * as CoreActions from '../../../core/actions/core.actions'
import { Payment, Profile } from '../../../api';
import { flyOutIn } from '../../../shared/animations/common.animations';
import { PaymentBag } from '../../models/paymentBag';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-edit-payment-page',
  template: `
    <app-edit-payment
      [isCart]="isCart"
      [isMobile]="isMobile$ | async"
      [changeError]="changeError$ | async"
      [changePending]="changePending$ | async"
      [removalPending]="removalPending$ | async"
      [payment]="payment$ | async"
      [profilePayments]="profilePayments$ | async"
      (submitted)="onSubmit($event)"
      (removed)="onRemove($event)"
      (navigatedBack)="onNavigateBack()">
    </app-edit-payment>
  `,
  styles: [],
  animations: [flyOutIn]
})
export class EditPaymentPageComponent implements OnInit, OnDestroy {
  @HostBinding('@flyOutIn')
  flyOutIn = 'in';

  // @HostBinding('class.app-animated-page')
  // animatedPage = true;

  subscriptions: Subscription[] = [];

  isCart = false;

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  changeError$ = this.store.pipe(
    select(fromProfile.getProfilePaymentChangeError)
  );

  changePending$ = this.store.pipe(
    select(fromProfile.getProfilePaymentChangePending)
  );

  removalPending$ = this.store.pipe(
    select(fromProfile.getProfilePaymentRemovalPending)
  );

  accountHolder$ = this.store
    .pipe(select(fromProfile.getProfile))
    .pipe(
      map((profile: Profile) => `${profile.firstName} ${profile.lastName}`)
    );

  payment$ = this.store.pipe(select(fromProfile.getProfilePayment));
  profilePayments$ = this.store.pipe(select(fromProfile.getProfilePayments));

  constructor(
    private store: Store<fromProfile.State>,
    private route: ActivatedRoute
  ) {
    this.subscriptions.push(
      route.params.subscribe(params => {
        const id = params['id'];
        if (id) {
          this.store.dispatch(new ProfileActions.GetPayment(id));
        } else {
          this.store.dispatch(new ProfileActions.NewPayment());
        }
      })
    );

    this.subscriptions.push(
      route.data.subscribe(params => {
        this.isCart = params['fromCart'];
      })
    );
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onSubmit(bag: PaymentBag) {
    if (bag.tokenId) {
      // Only happens when there is a add new card operation
      this.store.dispatch(new ProfileActions.AddPayment(bag.tokenId));
      if (bag.payment && bag.payment.id) {
        this.store.dispatch(
          new ProfileActions.SetPaymentPrimary(bag.payment.id)
        );
      }
    } else if (bag.payment && bag.payment.id) {
      this.store.dispatch(new ProfileActions.SetPaymentPrimary(bag.payment.id));
    }
  }

  onRemove(payment: Payment) {
    if (payment.id) {
      this.store.dispatch(new ProfileActions.DeletePayment(payment.id));
    }
  }

  onNavigateBack() {
    this.store.dispatch(new CoreActions.NavigateBack(true));
  }
}
