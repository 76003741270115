import {
    createSelector,
    createFeatureSelector,
    ActionReducerMap,
  } from '@ngrx/store';
  import * as fromRoot from '../../reducers';
  import * as fromSupport from './support.reducer';

  export interface SupportState {
    status: fromSupport.State;
  }
  
  export interface State extends fromRoot.State {
    support: SupportState;
  }
  
  export const reducers: ActionReducerMap<SupportState> = {
    status: fromSupport.reducer
  };
  
  export const getSupportState = createFeatureSelector<SupportState>('support');
  
  export const getSupportStatusState = createSelector(
    getSupportState,
    (state: SupportState) => state.status
  );

  export const getContactUsEmail = createSelector(
    getSupportStatusState,
    fromSupport.getContactUsEmail);
  
  export const getContactUsEmailError = createSelector(
    getSupportStatusState,
    fromSupport.getContactUsEmailError
  );
  
  export const getContactUsEmailPending = createSelector(
    getSupportStatusState,
    fromSupport.getContactUsEmailPending
  );