import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfilePageComponent } from './containers/profile-page/profile-page.component';
import { EditNamePageComponent } from './containers/edit-name-page/edit-name-page.component';
import { EditEmailPageComponent } from './containers/edit-email-page/edit-email-page.component';
import { EditPhonePageComponent } from './containers/edit-phone-page/edit-phone-page.component';
import { EditPasswordPageComponent } from './containers/edit-password-page/edit-password-page.component';
import { EditPaymentPageComponent } from './containers/edit-payment-page/edit-payment-page.component';
import { EditAddressPageComponent } from './containers/edit-address-page/edit-address-page.component';
import { ProfileGuard } from './guards/profile.guard';
import { AuthGuard } from '../auth/guards/auth.guard';
import { ErrorGuard } from '../core/guards/error.guard';

const routes: Routes = [
  {
    path: 'profile',
    component: ProfilePageComponent,
    canActivate: [ErrorGuard, AuthGuard, ProfileGuard]
  },
  {
    path: 'profile/edit-name',
    component: EditNamePageComponent,
    canActivate: [ErrorGuard, AuthGuard],
    data: {back: true}
  },
  {
    path: 'profile/edit-email',
    component: EditEmailPageComponent,
    canActivate: [ErrorGuard, AuthGuard],
    data: {back: true}
  },
  {
    path: 'profile/edit-phone',
    component: EditPhonePageComponent,
    canActivate: [ErrorGuard, AuthGuard],
    data: {back: true}
  },
  {
    path: 'profile/edit-password',
    component: EditPasswordPageComponent,
    canActivate: [ErrorGuard, AuthGuard],
    data: {back: true}
  },
  {
    path: 'profile/add-address',
    component: EditAddressPageComponent,
    canActivate: [ErrorGuard, AuthGuard],
    data: {back: true}
  },
  {
    path: 'profile/edit-address/:id',
    component: EditAddressPageComponent,
    canActivate: [ErrorGuard, AuthGuard],
    data: {back: true}
  },
  {
    path: 'profile/add-payment',
    component: EditPaymentPageComponent,
    canActivate: [ErrorGuard, AuthGuard],
    data: {back: true}
  },
  {
    path: 'profile/edit-payment/:id',
    component: EditPaymentPageComponent,
    canActivate: [ErrorGuard, AuthGuard],
    data: {back: true}
  },
  {
    path: 'profile/add-cart-address',
    component: EditAddressPageComponent,
    canActivate: [ErrorGuard, AuthGuard],
    data: {fromCart: true, back: true}
  },
  {
    path: 'profile/edit-cart-address/:id',
    component: EditAddressPageComponent,
    canActivate: [ErrorGuard, AuthGuard],
    data: {fromCart: true, back: true}
  },
  {
    path: 'profile/add-cart-payment',
    component: EditPaymentPageComponent,
    canActivate: [ErrorGuard, AuthGuard],
    data: {fromCart: true, back: true}
  },
  {
    path: 'profile/edit-cart-payment/:id',
    component: EditPaymentPageComponent,
    canActivate: [ErrorGuard, AuthGuard],
    data: {fromCart: true, back: true}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileRoutingModule { }
