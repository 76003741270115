import { Pipe, PipeTransform } from '@angular/core';
import { Facet } from '../../../api/model/facet';

@Pipe({
  name: 'facetFilter',
  pure: false
})
export class FacetFilterPipe implements PipeTransform {
  transform(facets: Facet[], filter?: any): Facet[] {
    if (!facets || !filter) {
      return facets;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    if (filter.showVisibleOnly) {
      return facets.filter(facet => facet.visible === true);
    }
    return facets;
  }
}
