import { SharedModule } from './../../../shared/shared.module';
import { EditOrderDeliveryDatePage } from './edit-order-delivery-date-page.component';
import { EditOrderDeliveryDate } from './../../components/edit-order-delivery-date/edit-order-delivery-date.component';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

import { reducers } from '../../../orders/reducers';
import { OrdersEffects } from '../../effects/orders.effects';


@NgModule({
    imports: [
        ReactiveFormsModule,
        NgbAccordionModule,
        SharedModule.forRoot(),
        CommonModule,
        StoreModule.forFeature('orders', reducers),
        EffectsModule.forFeature([OrdersEffects])
    ],
    declarations: [
        EditOrderDeliveryDate,
        EditOrderDeliveryDatePage

    ],
    exports: [
        EditOrderDeliveryDatePage
    ]
})
export class EditOrderDeliveryModule {
    static forRoot(): ModuleWithProviders<EditOrderDeliveryModule> {
        return {
            ngModule: EditOrderDeliveryModule,

        };
    }
}

