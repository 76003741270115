import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

import { select, Store } from '@ngrx/store';
import {
  map,
  take,
  switchMap,
  distinctUntilChanged,
  debounceTime,
  catchError,
  tap
} from 'rxjs/operators';

import * as fromProfile from '../../profile/reducers';
import * as fromAuth from '../../auth/reducers';
import { ProfileService, Profile } from '../../api';
import * as ErrorActions from '../../core/actions/error.actions';
import { Authenticate } from 'src/app/auth/models/authenticate';

@Injectable({
  providedIn: 'root'
})
export class StripeRegisteredCheckGuard  {

  constructor(
    private store: Store<fromProfile.State>,
    private router: Router,
    private profileService: ProfileService
  ) { }

  getIsLoggedIn(): Observable<boolean> {
    return this.store.pipe(select(fromAuth.getIsLoggedIn));
  }

  isStripeRegistered(): Observable<boolean> {
    return this.store.pipe(
      select(fromAuth.getAuthenticate),
      switchMap((authenticate: Authenticate) =>
        this.profileService
          .getProfile(authenticate.username)
          .toPromise()  // Fix for observable chain not getting consumed
          .then((profile: Profile) => {
            const signed = profile.stripeVetAccountId;
            if (!signed) {
              this.router.navigateByUrl('/stripeNotLinked');
            }
            return signed;
          })
      )
    );
  }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
     return this.getIsLoggedIn().pipe(
      switchMap((auth: boolean) => {
        if (!auth) {
          return of(true);
        }
        return this.isStripeRegistered();
      }),
      catchError(error => {
        this.store.dispatch(new ErrorActions.ReportError(error));
        return of(false);
      })
    );
  }
}
