import { AuthActionsUnion, AuthActionTypes } from '../actions/auth.actions';
import { Session } from '../../api';
import { Authenticate } from '../models/authenticate';
import { Registration } from '../models/registration';
import { PetParentDetail } from '../models/pet-parent-detail';
import { login_display_error_messages, login_server_error_messages } from 'src/app/shared/constants/messages';

export interface State {
  isLoggedIn: boolean;
  session: Session | null;
  authenticate: Authenticate | null;
  registration: Registration | null;
  loginError: string | null;
  loginPending: boolean;
  isLoggedOut: boolean;
  logoutError: string | null;
  logoutPending: boolean;
  registerError: string | null;
  registerPending: boolean;
  forgotError: string | null;
  forgotPending: boolean;
  isTokenExpired: boolean;
  resetError: string | null;
  registerPetParent: PetParentDetail | null;
  registerPetParentError: string | null;
  registerPetParentPending: boolean;
  isMaintenanceMode: boolean;
  pk: string | null;
  error: any | null;
}

export const initialState: State = {
  isLoggedIn: false,
  session: null,
  authenticate: null,
  registration: null,
  loginError: null,
  loginPending: false,
  isLoggedOut: false,
  logoutError: null,
  logoutPending: false,
  registerError: null,
  registerPending: false,
  forgotError: null,
  forgotPending: false,
  isTokenExpired: false,
  resetError: null,
  registerPetParent: null,
  registerPetParentError: null,
  registerPetParentPending: false,
  isMaintenanceMode: false,
  pk: null,
  error: null,
};

export function reducer(state = initialState, action: AuthActionsUnion): State {
  switch (action.type) {

    case AuthActionTypes.Login: {
      return {
        ...state,
        isLoggedIn: false,
        session: null,
        authenticate: action.authenticate,
        loginError: null,
        loginPending: true,
        forgotError: null
      };
    }

    case AuthActionTypes.LoginSuccess: {
      return {
        ...state,
        isLoggedIn: true,
        session: action.session,
        authenticate: { ...state.authenticate, password: '' },
        loginError: null,
        loginPending: false,
      };
    }

    case AuthActionTypes.LoginFailure: {
      return {
        ...state,
        authenticate: { ...state.authenticate, password: '' },
        loginError: action.payload.error.error_description.includes(login_server_error_messages.DEACTIVATED_ACCOUNT) ? login_display_error_messages.DEACTIVATED_USER : login_display_error_messages.INVALID_USER ,
        loginPending: false,
      };
    }

    case AuthActionTypes.FetchPkSuccess:
      return {
      ...state,
      pk: action.payload.pk,
      error: null
    };

    case AuthActionTypes.FetchPkFailure:
      return {
      ...state,
      error: action.payload.error
    };


    case AuthActionTypes.Logout:{
      return {
      ...state,
      isLoggedIn: true,
      isLoggedOut: false,
      logoutError: null,
      logoutPending: true
    };}

    case AuthActionTypes.LogoutSuccess: {
      return {
        ...state,
        isLoggedIn: false,
        isLoggedOut: true,
        logoutError: null,
        logoutPending: false,
      };
    }

    case AuthActionTypes.LogoutFailure: {
      return {
        ...state,
        isLoggedIn: false,
        isLoggedOut: true,
        logoutError: 'Something went wrong! Close the Tab & Relogin',
        logoutPending: false,
      };
    }
    
    case AuthActionTypes.LoginRedirect: {
      return {
        ...state,
        isLoggedIn: false,
        session: null,
        authenticate: null,
        loginError: null,
        loginPending: false
      };
    }

    case AuthActionTypes.Register: {
      return {
        ...state,
        registration: action.registration,
        registerError: null,
        registerPending: true
      };
    }

    case AuthActionTypes.RegisterSuccess: {
      return {
        ...state,
        authenticate: {
          ...state.authenticate,
          username: state.registration.username,
          password: state.registration.newPassword
        },
        registration: null,
        registerError: null,
        registerPending: false,
      };
    }

    case AuthActionTypes.RegisterFailure: {
      return {
        ...state,
        registration: null,
        registerError: action.registerError,
        registerPending: false,
      };
    }

    case AuthActionTypes.ForgotPassword: {
      return {
        ...state,
        forgotError: null,
        forgotPending: true,
        loginError: null,
      };
    }

    case AuthActionTypes.ForgotPasswordSuccess: {
      return {
        ...state,
        forgotError: null,
        forgotPending: false,
      };
    }

    case AuthActionTypes.ForgotPasswordFailure: {
      return {
        ...state,
        forgotError: null, // action.forgotPasswordError,
        forgotPending: false,
      };
    }


    case AuthActionTypes.CheckTokenExpiry: {
      return {
        ...state,
        isTokenExpired: action.isTokenExpired
      };
    }

    case AuthActionTypes.SetLogin: {
      return {
        ...state,
        authenticate: {
          ...state.authenticate,
          username: action.emailChange.newEmail,
          password: action.emailChange.password,
        }
      };
    }

    case AuthActionTypes.ResetPasswordFailure: {
      return {
        ...state,
        resetError: action.resetPasswordError,
      };
    }

    case AuthActionTypes.RegisterPetParent: {
      return {
        ...state,
        registerPetParent: action.petParentRegistration,
        registerPetParentError: null,
        registerPetParentPending: true
      };
    }

    case AuthActionTypes.RegisterPetParentSuccess: {
      return {
        ...state,
        authenticate: {
          ...state.authenticate,
          username: state.registerPetParent.emailAddress,
          //password: state.registerPetParent.password,
        },
        registerPetParent: null,
        registerPetParentError: null,
        registerPetParentPending: false,
      };
    }

    case AuthActionTypes.RegisterPetParentFailure: {
      return {
        ...state,
        registerPetParent: null,
        registerPetParentError: action.registerPetParentError,
        registerPetParentPending: false,
      };
    }

    case AuthActionTypes.GetCSRFToken: {
      return {
        ...state
      };
    }

    case AuthActionTypes.GetCSRFTokenSuccess: {
      return {
        ...state,
        session: {
          ...state.session,
          csrfToken : action.session.csrfToken
        },
      };
    }

    case AuthActionTypes.GetCSRFTokenFailure: {
      return {
        ...state,
        isMaintenanceMode: action.payload.status == "0" ? true : false  
      };
    }

    default: {
      return state;
    }
  }
}

export const getIsLoggedIn = (state: State) => state.isLoggedIn;
export const getSession = (state: State) => state.session;
export const getAuthenticate = (state: State) => state.authenticate;
export const getRegistration = (state: State) => state.registration;

export const getLoginError = (state: State) => state.loginError;
export const getLoginPending = (state: State) => state.loginPending;

export const getIsLoggedOut = (state: State) => state.isLoggedOut;
export const getLogoutError = (state: State) => state.logoutError;
export const getLogoutPending = (state: State) => state.logoutPending;

export const getRegisterError = (state: State) => state.registerError;
export const getRegisterPending = (state: State) => state.registerPending;
export const getForgotError = (state: State) => state.forgotError;
export const getForgotPending = (state: State) => state.forgotPending;
export const getIsTokenExpired = (state: State) => state.isTokenExpired;

export const getResetError = (state: State) => state.resetError;

export const getRegisterPetParent = (state: State) => state.registerPetParent;
export const getRegisterPetParentError = (state: State) => state.registerPetParentError;
export const getRegisterPetParentPending = (state: State) => state.registerPetParentPending;
export const getIsMaintenanceMode = (state: State) => state.isMaintenanceMode;