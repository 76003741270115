<div class="app-mobile-layout">

  <h5 class="app-title text-center m-4">
    My Account
  </h5>

  <div class="d-flex align-items-center m-4">
    <h6 class="mb-0">Edit Phone Number</h6>
  </div>

  <form [formGroup]="profileForm" autocomplete="off" class="m-4" (ngSubmit)="save()">
    <div class="form-group"
         [class.is-invalid]="profileForm.controls['phoneNumber'].dirty && profileForm.controls['phoneNumber'].invalid">
      <label for="phone" class="small">Current Phone Number</label>
      <input id="phone"
             name="phone"
             type="tel"
             class="form-control"
             placeholder="Phone Number"
             formControlName="phoneNumber">
    </div>
    <div class="form-group"
         [class.is-invalid]="profileForm.controls['newPhoneNumber'].dirty && profileForm.controls['newPhoneNumber'].invalid">
      <label for="newPhone" class="small">Change Phone Number</label>
      <div class="d-flex">
        <input id="newPhone"
               name="newPhone"
               type="tel"
               class="form-control ml-2"
               placeholder="Phone Number"
               formControlName="newPhoneNumber" (keyup)="onKey($event)">
      </div>
    </div>
    <ul *ngIf="profileForm.controls['newPhoneNumber'].dirty &&
               profileForm.controls['newPhoneNumber'].errors &&
               profileForm.controls['newPhoneNumber'].errors['pattern']"
        class="list-unstyled app-invalid-feedback small">
      <li *ngIf="profileForm.controls['newPhoneNumber'].errors['pattern']">
        Phone Number should be 123-123-1234 format
      </li>
      <i class="app-bottom-arrow"></i>
    </ul>
    <div class="mt-5">
      <button type="submit"
              class="btn btn-primary w-100"
              [disabled]="profileForm.pristine || profileForm.invalid || profileForm.disabled">
        Save
      </button>
    </div>
  </form>

</div>

<ng-template #successModal let-closeSuccess="close" let-dismissSuccess="dismiss">
  <div class="modal-body text-center">
    <i class="mt-4 far fa-check-circle fa-6x txt-success"></i>
    <p class="m-4 h4 text-center">
      You have successfully changed your phone number
    </p>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="btn btn-primary pl-5 pr-5 btn-lg ml-2"
            (click)="closeSuccess()">
      Ok
    </button>
  </div>
</ng-template>
