/**
 * Pet Companion Server
 * This is a sample server Pet Companion server. You can find out more about Swagger at [http://swagger.io](http://swagger.io) or on [irc.freenode.net, #swagger](http://swagger.io/irc/). For this sample, you can use the api key `special-key` to test the authorization filters.
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AllNutritionPlans } from '../model/allNutritionPlans';
import { AllPets } from '../model/allPets';
import { Pet } from '../model/pet';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { AllWhatsNewContent } from '../model/allWhatsNewContent';


@Injectable()
export class PetService {

    protected basePath = 'http://localhost:10010/rest';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Add new pet
     * 
     * @param username The username
     * @param body The pet
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addPet(username: string, body: Pet, t?: string, observe?: 'body', reportProgress?: boolean): Observable<Pet>;
    public addPet(username: string, body: Pet, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Pet>>;
    public addPet(username: string, body: Pet, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Pet>>;
    public addPet(username: string, body: Pet, t?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling addPet.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addPet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (t !== undefined) {
            queryParameters = queryParameters.set('t', <any>t);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        if (this.configuration.apiKeys["csrf_token"]) {
            headers = headers.set('CSRFToken', this.configuration.apiKeys['csrf_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }
        const pk = sessionStorage.getItem('pk');  
        return this.httpClient.post<Pet>(`${this.basePath}/rest/v2/V2CSite/users/${encodeURIComponent(String(pk))}/addnewpet`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentialsAndCookies,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the nutrition plan for a particular pet
     * 
     * @param id The pet code
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNutritionPlans(id: string, t?: string, observe?: 'body', reportProgress?: boolean): Observable<AllNutritionPlans>;
    public getNutritionPlans(id: string, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AllNutritionPlans>>;
    public getNutritionPlans(id: string, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AllNutritionPlans>>;
    public getNutritionPlans(id: string, t?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getNutritionPlans.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (t !== undefined) {
            queryParameters = queryParameters.set('t', <any>t);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];

        return this.httpClient.get<AllNutritionPlans>(`${this.basePath}/rest/v2/V2CSite/nutritionplan/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all pets
     * 
     * @param username The username
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPets(username: string, t?: string, observe?: 'body', reportProgress?: boolean): Observable<AllPets>;
    public getPets(username: string, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AllPets>>;
    public getPets(username: string, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AllPets>>;
    public getPets(username: string, t?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling getPets.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (t !== undefined) {
            queryParameters = queryParameters.set('t', <any>t);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.get<AllPets>(`${this.basePath}/rest/v2/V2CSite/users/${encodeURIComponent(String(pk))}/pets`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an existing pet
     * 
     * @param username The username
     * @param body The pet
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePet(username: string, body: Pet, t?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updatePet(username: string, body: Pet, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updatePet(username: string, body: Pet, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updatePet(username: string, body: Pet, t?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling updatePet.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updatePet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (t !== undefined) {
            queryParameters = queryParameters.set('t', <any>t);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        if (this.configuration.apiKeys["csrf_token"]) {
            headers = headers.set('CSRFToken', this.configuration.apiKeys['csrf_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }
        const pk = sessionStorage.getItem('pk');  
        return this.httpClient.put<any>(`${this.basePath}/rest/v2/V2CSite/users/${encodeURIComponent(String(pk))}/updatepet`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentialsAndCookies,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    // Whats-new pop up with dynamic content - Rahul Solanki 
    /**
     * Set whatsNewFlag
     * 
     * @param username The username
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    
     public setWhatsNewFlag(username: string, t?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public setWhatsNewFlag(username: string, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public setWhatsNewFlag(username: string, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public setWhatsNewFlag(username: string, t?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling setWhatsNewFlag.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (t !== undefined) {
            queryParameters = queryParameters.set('t', <any>t);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        if (this.configuration.apiKeys["csrf_token"]) {
            headers = headers.set('CSRFToken', this.configuration.apiKeys['csrf_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }
        const pk = sessionStorage.getItem('pk');  
        return this.httpClient.put<any>(`${this.basePath}/rest/v2/V2CSite/users/${encodeURIComponent(String(pk))}/gotIt/true`,
            null,
            {
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentialsAndCookies,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

     /**
     * Set whatsNewContent
     * 
     * @param username The username
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    
      public getWhatsNewContent(username: string, t?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
      public getWhatsNewContent(username: string, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
      public getWhatsNewContent(username: string, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
      public getWhatsNewContent(username: string, t?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
          if (username === null || username === undefined) {
              throw new Error('Required parameter username was null or undefined when calling setWhatsNewFlag.');
          }
  
          let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
          if (t !== undefined) {
              queryParameters = queryParameters.set('t', <any>t);
          }
  
          let headers = this.defaultHeaders;
  
          // authentication (petcompanion_auth) required
          if (this.configuration.apiKeys["access_token"]) {
              headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
          }
  
          if (this.configuration.apiKeys["csrf_token"]) {
              headers = headers.set('CSRFToken', this.configuration.apiKeys['csrf_token']);
          }
  
          // to determine the Accept header
          let httpHeaderAccepts: string[] = [
              'application/json'
          ];
          let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
          if (httpHeaderAcceptSelected != undefined) {
              headers = headers.set("Accept", httpHeaderAcceptSelected);
          }
  
          // to determine the Content-Type header
          let consumes: string[] = [
              'application/octet-stream',
              'application/json'
          ];
          let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
          if (httpContentTypeSelected != undefined) {
              headers = headers.set("Content-Type", httpContentTypeSelected);
          }
          const pk = sessionStorage.getItem('pk'); 
          return this.httpClient.get<AllWhatsNewContent>(`${this.basePath}/rest/v2/V2CSite/users/${encodeURIComponent(String(pk))}/cmsImageComponent`,
              {
                  params: queryParameters,
                  withCredentials: this.configuration.withCredentialsAndCookies,
                  headers: headers,
                  observe: observe,
                  reportProgress: reportProgress
              }
          );
      }

    // End

}
