import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cardTypeToImage'
})
export class CardTypeToImage implements PipeTransform {

  transform(value: any, args?: any): any {
    let result = '';
    if (value) {
      switch (value) {
        case 'visa':
          result = 'assets/images/v-card.jpg';
          break;
        case 'master':
          result = 'assets/images/m-card.jpg';
          break;
        case 'amex':
          result = 'assets/images/a-card.jpg';
          break;
      }
    }
    return result;
  }
}
