/**
 * Pet Companion Server
 * This is a sample server Pet Companion server. You can find out more about Swagger at [http://swagger.io](http://swagger.io) or on [irc.freenode.net, #swagger](http://swagger.io/irc/). For this sample, you can use the api key `special-key` to test the authorization filters.
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Address } from '../model/address';
import { CardInfo } from '../model/cardInfo';
import { Payment } from '../model/payment';
import { Profile } from '../model/profile';
import { ProfileAddresses } from '../model/profileAddresses';
import { ProfileCards } from '../model/profileCards';
import { ProfileRegistered } from '../model/profileRegistered';
import { Session } from '../model/session';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ProfileService {

    protected basePath = 'http://localhost:10010/rest';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }
  

    /**
     * Add a new profile address
     * 
     * @param username The username
     * @param body The address
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addProfileAddress(username: string, body: Address, t?: string, observe?: 'body', reportProgress?: boolean): Observable<Address>;
    public addProfileAddress(username: string, body: Address, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Address>>;
    public addProfileAddress(username: string, body: Address, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Address>>;
    public addProfileAddress(username: string, body: Address, t?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling addProfileAddress.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addProfileAddress.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (t !== undefined) {
            queryParameters = queryParameters.set('t', <any>t);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        if (this.configuration.apiKeys["csrf_token"]) {
            headers = headers.set('CSRFToken', this.configuration.apiKeys['csrf_token']);
        } 

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.post<Address>(`${this.basePath}/rest/v2/V2CSite/users/${encodeURIComponent(String(pk))}/addresses`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentialsAndCookies,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add new payment card
     * 
     * @param username The username
     * @param tokenId The stripe token Id
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addProfileCard(username: string, tokenId: string, t?: string, observe?: 'body', reportProgress?: boolean): Observable<CardInfo>;
    public addProfileCard(username: string, tokenId: string, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CardInfo>>;
    public addProfileCard(username: string, tokenId: string, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CardInfo>>;
    public addProfileCard(username: string, tokenId: string, t?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling addProfileCard.');
        }
        if (tokenId === null || tokenId === undefined) {
            throw new Error('Required parameter tokenId was null or undefined when calling addProfileCard.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (tokenId !== undefined) {
            queryParameters = queryParameters.set('tokenId', <any>tokenId);
        }
        if (t !== undefined) {
            queryParameters = queryParameters.set('t', <any>t);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        if (this.configuration.apiKeys["csrf_token"]) {
            headers = headers.set('CSRFToken', this.configuration.apiKeys['csrf_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.post<CardInfo>(`${this.basePath}/rest/v2/V2CSite/users/${encodeURIComponent(String(pk))}/addNewCard`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentialsAndCookies,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a new profile payment
     * 
     * @param username The username
     * @param body The payment
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addProfilePayment(username: string, body: Payment, t?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public addProfilePayment(username: string, body: Payment, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public addProfilePayment(username: string, body: Payment, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public addProfilePayment(username: string, body: Payment, t?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling addProfilePayment.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addProfilePayment.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (t !== undefined) {
            queryParameters = queryParameters.set('t', <any>t);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        if (this.configuration.apiKeys["csrf_token"]) {
            headers = headers.set('CSRFToken', this.configuration.apiKeys['csrf_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.post<any>(`${this.basePath}/rest/v2/V2CSite/users/${encodeURIComponent(String(pk))}/paymentdetails`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentialsAndCookies,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change an existing profile firstname,lastname,phone number, optedIn and Terms&amp;Conditions flag values
     * 
     * @param username The username
     * @param firstName The profile first name
     * @param lastName The profile last name
     * @param phoneNumber The profile phone number
     * @param optedIn The profile opted in
     * @param termsAndConditions Terms And Conditions Flag
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeProfile(username: string, firstName?: string, lastName?: string, phoneNumber?: string, optedIn?: boolean, termsAndConditions?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public changeProfile(username: string, firstName?: string, lastName?: string, phoneNumber?: string, optedIn?: boolean, termsAndConditions?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public changeProfile(username: string, firstName?: string, lastName?: string, phoneNumber?: string, optedIn?: boolean, termsAndConditions?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public changeProfile(username: string, firstName?: string, lastName?: string, phoneNumber?: string, optedIn?: boolean, termsAndConditions?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling changeProfile.');
        }

        // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        // if (firstName !== undefined) {
        //     queryParameters = queryParameters.set('firstName', <any>firstName);
        // }
        // if (lastName !== undefined) {
        //     queryParameters = queryParameters.set('lastName', <any>lastName);
        // }
        // if (phoneNumber !== undefined) {
        //     queryParameters = queryParameters.set('phoneNumber', <any>phoneNumber);
        // }
        // if (optedIn !== undefined) {
        //     queryParameters = queryParameters.set('optedIn', <any>optedIn);
        // }
        // if (termsAndConditions !== undefined) {
        //     queryParameters = queryParameters.set('termsAndConditions', <any>termsAndConditions);
        // }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        if (this.configuration.apiKeys["csrf_token"]) {
            headers = headers.set('CSRFToken', this.configuration.apiKeys['csrf_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
         // Create the request body with the profile data
        const requestBody = {
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            optedIn: optedIn,
            termsAndConditions: termsAndConditions
        };
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.patch<any>(`${this.basePath}/rest/v2/V2CSite/users/${encodeURIComponent(String(pk))}`,
        requestBody,
            {
                withCredentials: this.configuration.withCredentialsAndCookies,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change an existing payment
     * 
     * @param username The username
     * @param id The id
     * @param defaultPaymentInfo Define if default payment
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeProfilePayment(username: string, id: string, defaultPaymentInfo?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public changeProfilePayment(username: string, id: string, defaultPaymentInfo?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public changeProfilePayment(username: string, id: string, defaultPaymentInfo?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public changeProfilePayment(username: string, id: string, defaultPaymentInfo?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling changeProfilePayment.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling changeProfilePayment.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (defaultPaymentInfo !== undefined) {
            queryParameters = queryParameters.set('defaultPaymentInfo', <any>defaultPaymentInfo);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        if (this.configuration.apiKeys["csrf_token"]) {
            headers = headers.set('CSRFToken', this.configuration.apiKeys['csrf_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.patch<any>(`${this.basePath}/rest/v2/V2CSite/users/${encodeURIComponent(String(pk))}/paymentdetails/${encodeURIComponent(String(id))}`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentialsAndCookies,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete an existing address
     * 
     * @param username The username
     * @param id The id
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProfileAddress(username: string, id: string, t?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteProfileAddress(username: string, id: string, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteProfileAddress(username: string, id: string, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteProfileAddress(username: string, id: string, t?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling deleteProfileAddress.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteProfileAddress.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (t !== undefined) {
            queryParameters = queryParameters.set('t', <any>t);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        if (this.configuration.apiKeys["csrf_token"]) {
            headers = headers.set('CSRFToken', this.configuration.apiKeys['csrf_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.delete<any>(`${this.basePath}/rest/v2/V2CSite/users/${encodeURIComponent(String(pk))}/addresses/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentialsAndCookies,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete an existing payment
     * 
     * @param username The username
     * @param id The id
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProfilePayment(username: string, id: string, t?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteProfilePayment(username: string, id: string, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteProfilePayment(username: string, id: string, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteProfilePayment(username: string, id: string, t?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling deleteProfilePayment.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteProfilePayment.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (t !== undefined) {
            queryParameters = queryParameters.set('t', <any>t);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        if (this.configuration.apiKeys["csrf_token"]) {
            headers = headers.set('CSRFToken', this.configuration.apiKeys['csrf_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.delete<any>(`${this.basePath}/rest/v2/V2CSite/users/${encodeURIComponent(String(pk))}/paymentdetails/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentialsAndCookies,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

  /**
     * Get the parent profile by username
     * 
     * @param username The username
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public getProfile(username: string, t?: string, observe?: 'body', reportProgress?: boolean): Observable<Profile>;
  public getProfile(username: string, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Profile>>;
  public getProfile(username: string, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Profile>>;
  public getProfile(username: string, t?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      if (username === null || username === undefined) {
          throw new Error('Required parameter username was null or undefined when calling getProfile.');
      }

      let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
      if (t !== undefined) {
          queryParameters = queryParameters.set('t', <any>t);
      }

      let headers = this.defaultHeaders;

      // authentication (petcompanion_auth) required
      if (this.configuration.apiKeys["access_token"]) {
          headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
      }

      // to determine the Accept header
      let httpHeaderAccepts: string[] = [
          'application/json'
      ];
      let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
          headers = headers.set("Accept", httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      let consumes: string[] = [
          'application/octet-stream',
          'application/json'
      ];
      const pk = sessionStorage.getItem('pk');
      return this.httpClient.get<Profile>(`${this.basePath}/rest/v2/V2CSite/users/${encodeURIComponent(String(pk))}`,
          {
              params: queryParameters,
              withCredentials: this.configuration.withCredentials,
              headers: headers,
              observe: observe,
              reportProgress: reportProgress
          }
      );
  }


       
    /**
     * Get the profile address
     * 
     * @param username The username
     * @param id The address id
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProfileAddress(username: string, id: string, t?: string, observe?: 'body', reportProgress?: boolean): Observable<Address>;
    public getProfileAddress(username: string, id: string, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Address>>;
    public getProfileAddress(username: string, id: string, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Address>>;
    public getProfileAddress(username: string, id: string, t?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling getProfileAddress.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getProfileAddress.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (t !== undefined) {
            queryParameters = queryParameters.set('t', <any>t);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.get<Address>(`${this.basePath}/rest/v2/V2CSite/users/${encodeURIComponent(String(pk))}/addresses/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the profile addresses
     * 
     * @param username The username
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProfileAddresses(username: string, t?: string, observe?: 'body', reportProgress?: boolean): Observable<ProfileAddresses>;
    public getProfileAddresses(username: string, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProfileAddresses>>;
    public getProfileAddresses(username: string, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProfileAddresses>>;
    public getProfileAddresses(username: string, t?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling getProfileAddresses.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (t !== undefined) {
            queryParameters = queryParameters.set('t', <any>t);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.get<ProfileAddresses>(`${this.basePath}/rest/v2/V2CSite/users/${encodeURIComponent(String(pk))}/addresses`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the profile payment
     * 
     * @param username The username
     * @param id The payment id
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProfilePayment(username: string, id: string, t?: string, observe?: 'body', reportProgress?: boolean): Observable<Payment>;
    public getProfilePayment(username: string, id: string, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Payment>>;
    public getProfilePayment(username: string, id: string, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Payment>>;
    public getProfilePayment(username: string, id: string, t?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling getProfilePayment.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getProfilePayment.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (t !== undefined) {
            queryParameters = queryParameters.set('t', <any>t);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.get<Payment>(`${this.basePath}/rest/v2/V2CSite/users/${encodeURIComponent(String(pk))}/paymentdetails/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the profile payments
     * 
     * @param username The username
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProfilePayments(username: string, t?: string, observe?: 'body', reportProgress?: boolean): Observable<ProfileCards>;
    public getProfilePayments(username: string, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProfileCards>>;
    public getProfilePayments(username: string, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProfileCards>>;
    public getProfilePayments(username: string, t?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling getProfilePayments.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (t !== undefined) {
            queryParameters = queryParameters.set('t', <any>t);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.get<ProfileCards>(`${this.basePath}/rest/v2/V2CSite/users/${encodeURIComponent(String(pk))}/paymentdetails`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get if the parent is already registered by username
     * 
     * @param userId The user id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProfileRegistered(userId: string, observe?: 'body', reportProgress?: boolean): Observable<ProfileRegistered>;
    public getProfileRegistered(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProfileRegistered>>;
    public getProfileRegistered(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProfileRegistered>>;
    public getProfileRegistered(userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getProfileRegistered.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];

        return this.httpClient.get<ProfileRegistered>(`${this.basePath}/rest/v2/V2CSite/users`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an existing address
     * 
     * @param username The username
     * @param id The id
     * @param body The address
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProfileAddress(username: string, id: string, body: Address, t?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateProfileAddress(username: string, id: string, body: Address, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateProfileAddress(username: string, id: string, body: Address, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateProfileAddress(username: string, id: string, body: Address, t?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling updateProfileAddress.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateProfileAddress.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateProfileAddress.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (t !== undefined) {
            queryParameters = queryParameters.set('t', <any>t);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        if (this.configuration.apiKeys["csrf_token"]) {
            headers = headers.set('CSRFToken', this.configuration.apiKeys['csrf_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.put<any>(`${this.basePath}/rest/v2/V2CSite/users/${encodeURIComponent(String(pk))}/addresses/${encodeURIComponent(String(id))}`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentialsAndCookies,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an existing profile login
     * 
     * @param username The old username
     * @param newLogin The new username (typically email id)
     * @param password The password
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProfileLogin(username: string, newLogin: string, password: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateProfileLogin(username: string, newLogin: string, password: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateProfileLogin(username: string, newLogin: string, password: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateProfileLogin(username: string, newLogin: string, password: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling updateProfileLogin.');
        }
        if (newLogin === null || newLogin === undefined) {
            throw new Error('Required parameter newLogin was null or undefined when calling updateProfileLogin.');
        }
        if (password === null || password === undefined) {
            throw new Error('Required parameter password was null or undefined when calling updateProfileLogin.');
        }

        // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        // if (newLogin !== undefined) {
        //     queryParameters = queryParameters.set('newLogin', <any>newLogin);
        // }
        // if (password !== undefined) {
        //     queryParameters = queryParameters.set('password', <any>password);
        // }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        if (this.configuration.apiKeys["csrf_token"]) {
            headers = headers.set('CSRFToken', this.configuration.apiKeys['csrf_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
         // Create the request body with the profile update data
        const requestBody = {
            uid: newLogin,
            password: password
        };
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.put<any>(`${this.basePath}/rest/v2/V2CSite/users/${encodeURIComponent(String(pk))}/login`,
        requestBody,
            {
                withCredentials: this.configuration.withCredentialsAndCookies,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an existing password
     * 
     * @param username The username
     * @param old The profile old password
     * @param _new The profile new password
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProfilePassword(username: string, old: string, _new: string, observe?: 'body', reportProgress?: boolean): Observable<Session>;
    public updateProfilePassword(username: string, old: string, _new: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Session>>;
    public updateProfilePassword(username: string, old: string, _new: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Session>>;
    public updateProfilePassword(username: string, old: string, _new: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling updateProfilePassword.');
        }
        if (old === null || old === undefined) {
            throw new Error('Required parameter old was null or undefined when calling updateProfilePassword.');
        }
        if (_new === null || _new === undefined) {
            throw new Error('Required parameter _new was null or undefined when calling updateProfilePassword.');
        }

        // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        // if (old !== undefined) {
        //     queryParameters = queryParameters.set('old', <any>old);
        // }
        // if (_new !== undefined) {
        //     queryParameters = queryParameters.set('new', <any>_new);
        // }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        if (this.configuration.apiKeys["csrf_token"]) {
            headers = headers.set('CSRFToken', this.configuration.apiKeys['csrf_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        const requestBody = {
            oldPassword: old,
            password: _new
        };
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.put<Session>(`${this.basePath}/rest/v2/V2CSite/users/${encodeURIComponent(String(pk))}/password`,
            requestBody,
            {
                withCredentials: this.configuration.withCredentialsAndCookies,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an existing payment
     * 
     * @param username The username
     * @param id The id
     * @param body The payment
     * @param t [Ignore] Code Gen Bug - Query parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProfilePayment(username: string, id: string, body: Payment, t?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateProfilePayment(username: string, id: string, body: Payment, t?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateProfilePayment(username: string, id: string, body: Payment, t?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateProfilePayment(username: string, id: string, body: Payment, t?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling updateProfilePayment.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateProfilePayment.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateProfilePayment.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (t !== undefined) {
            queryParameters = queryParameters.set('t', <any>t);
        }

        let headers = this.defaultHeaders;

        // authentication (petcompanion_auth) required
        if (this.configuration.apiKeys["access_token"]) {
            headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }

        if (this.configuration.apiKeys["csrf_token"]) {
            headers = headers.set('CSRFToken', this.configuration.apiKeys['csrf_token']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/octet-stream',
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }
        const pk = sessionStorage.getItem('pk');
        return this.httpClient.put<any>(`${this.basePath}/rest/v2/V2CSite/users/${encodeURIComponent(String(pk))}/paymentdetails/${encodeURIComponent(String(id))}`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentialsAndCookies,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
/**
 * check / validate a profile address
 * @param body The address
 * @param t [Ignore] Code Gen Bug - Query parameters
 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
 * @param reportProgress flag to report request and response progress.
 */
public validateProfileAddress(body: Address, t ? : string, observe ? : 'body', reportProgress ? : boolean): Observable < Address > ;
public validateProfileAddress(body: Address, t ? : string, observe ? : 'response', reportProgress ? : boolean): Observable < HttpResponse < Address >> ;
public validateProfileAddress(body: Address, t ? : string, observe ? : 'events', reportProgress ? : boolean): Observable < HttpEvent < Address >> ;
public validateProfileAddress(body: Address, t ? : string, observe: any = 'body', reportProgress: boolean = false): Observable < any > {
		if (body === null || body === undefined) {
			throw new Error('Required parameter body was null or undefined when calling addProfileAddress.');
		}

		let queryParameters = new HttpParams({
			encoder: new CustomHttpUrlEncodingCodec()
		});
		if (t !== undefined) {
			queryParameters = queryParameters.set('t', < any > t);
		}

		let headers = this.defaultHeaders;

		// authentication (petcompanion_auth) required
		if (this.configuration.apiKeys["access_token"]) {
			headers = headers.set('Authorization', 'Bearer ' + this.configuration.apiKeys['access_token']);
        }
        
        if (this.configuration.apiKeys["csrf_token"]) {
            headers = headers.set('CSRFToken', this.configuration.apiKeys['csrf_token']);
        }

		// to determine the Accept header
		let httpHeaderAccepts: string[] = [
			'application/json'
		];
		let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set("Accept", httpHeaderAcceptSelected);
		}

		// to determine the Content-Type header
		let consumes: string[] = [
			'application/octet-stream',
			'application/json'
		];
		let httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected != undefined) {
			headers = headers.set("Content-Type", httpContentTypeSelected);
        }
        
        return this.httpClient.post<any>(`${this.basePath}/rest/v2/V2CSite/validateAddressByUPS`, {
            "city": body.town,
            "postalCode": body.postalCode,
            "stateProvinceCode": body.region.isocode.replace('US-', '')
        }, {
            params: queryParameters,
            withCredentials: this.configuration.withCredentialsAndCookies,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        });
}

}
