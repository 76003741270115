import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as fromAuth from '../../reducers';
import * as fromCore from '../../../core/reducers';
import * as AuthActions from '../../actions/auth.actions';
import { Registration } from '../../models/registration';

@Component({
  selector: 'app-register-page',
  template: `
    <app-register
      [username]="username"
      [pending]="pending$ | async"
      [errorMessage]="error$ | async"
      [isMobile]="isMobile$ | async"
      (submitted)="onSubmit($event)">
    </app-register>
  `,
  styles: [],
})
export class RegisterPageComponent implements OnInit, OnDestroy {

  token: string;

  username: string;

  isLoggedIn$ = this.store.pipe(select(fromAuth.getIsLoggedIn));

  pending$ = this.store.pipe(select(fromAuth.getRegisterPending));

  error$ = this.store.pipe(select(fromAuth.getRegisterError));

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  subscriptions: Subscription[] = [];

  constructor(private store: Store<fromAuth.State>,
              private route: ActivatedRoute) {
    this.subscriptions.push(
      route.queryParams.subscribe(params => {
        this.token = params['token'];
       //this.username = params['uid'];
      })
    );
  }

  ngOnInit() {
    this.store.dispatch(new AuthActions.GetCSRFToken());
    this.route.queryParamMap.subscribe(params => {
      var encodedString = params.get('uid');
      try {
        this.username = atob(encodedString);
      } catch (err) {
        this.username = encodedString;
      }
    });
    this.subscriptions.push(
      this.isLoggedIn$
        .pipe(
          tap((isLoggedIn: boolean) => {
            if (isLoggedIn) {
              this.store.dispatch(new AuthActions.LoggedRedirect());
            }
          })
        ).subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
  }

  onSubmit(registration: Registration) {
    registration.username = this.username;
    registration.token = this.token;
    this.store.dispatch(new AuthActions.Register(registration));
  }
}
