import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { PromotionsService } from "../../services/promotions.service";
import { PromotionContent } from 'src/app/api/model/promotionContent';
import { select, Store } from '@ngrx/store';
import * as CoreActions from '../../../core/actions/core.actions';
import * as fromCore from '../../../core/reducers/index';
import { Observable } from 'rxjs';
import { NgModule,NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {take} from 'rxjs/operators'


@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss'],
})

export class PromotionsComponent implements OnInit {

  @Input() promotionTxt: string;
  @Input() promoOn: string;
  @Input() isMobile: boolean | false;


  @Input() onShopPage: boolean | false;

  promotionTxt$ = this.store.pipe(select(fromCore.getPromotionContent));
  constructor(private store: Store<fromCore.State>, private promoService: PromotionsService) {}

  ngOnInit() {
    this.store.dispatch(new CoreActions.GetPromotionContent());
  }




  ngOnChanges() {

  }
}
