<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout d-flex flex-column">
    <div class="header-banner">
    <div class="container d-flex align-items-start pt-4">
      <div class="home-logo">
        <img src="assets/images/060118_HillsToHomeLogo.png">
      </div>
      <button class="visit-btn mt-3" (click)="openUrl()">
        <span class="w700">VISIT HILLSPET.COM</span>
        <i class="fa fa-external-link-square-alt pl-2"></i>
      </button>
    </div>
    <div class="container pt-4 mt-2">
      <div class="text-center">
        <h1 class="ml-5 mr-5 text-white">Complete Registration</h1>
        <p class="ml-5 mr-5 text-white">Create a password for your account</p>
      </div>
    </div>
    <form [formGroup]="authForm" autocomplete="off" class="m-4" (ngSubmit)="register()">
      <div class="form-group" [class.is-invalid]="authForm.controls['username'].dirty && authForm.controls['username'].invalid">
        <label for="email" class="small">Email</label>
        <input id="email" name="email" type="email" class="form-control" placeholder="Email" formControlName="username">
      </div>
      <ul *ngIf="authForm.controls['username'].dirty &&
                 authForm.controls['username'].errors && authForm.controls['username'].errors['pattern']"
        class="list-unstyled app-invalid-feedback small">
        <li *ngIf="authForm.controls['username'].errors['pattern']">
          Email not valid
        </li>
        <i class="app-bottom-arrow"></i>
      </ul>
      <div class="form-group" [class.is-invalid]="authForm.controls['newPassword'].dirty && authForm.controls['newPassword'].invalid">
        <label class="small">Create Password</label>
        <input name="password" [type]="isCreatePasswordVisible ? 'text' : 'password'" class="form-control" placeholder="Password" formControlName="newPassword">
        <span (click)="toggleVisibility('createPassword')" [ngClass]="isCreatePasswordVisible ? 'fa fas fa-eye-slash showSpan' : 'fa fas fa-eye showSpan'"></span>
      </div>
      <ul *ngIf="authForm.controls['newPassword'].dirty &&
                 authForm.controls['newPassword'].errors && authForm.controls['newPassword'].errors['pattern']"
        class="list-unstyled app-invalid-feedback small">
        <li *ngIf="authForm.controls['newPassword'].errors['pattern']">
          Must be between 8-16 characters
        </li>
        <li *ngIf="authForm.controls['newPassword'].errors['pattern']">
          Must contain at least one upper case alphabetical character
        </li>
        <li *ngIf="authForm.controls['newPassword'].errors['pattern']">
          Must contain at least one lower case alphabetical character
        </li>
        <li *ngIf="authForm.controls['newPassword'].errors['pattern']">
          Must contain at least one numeric character
        </li>
        <li *ngIf="authForm.controls['newPassword'].errors['pattern']">
          Must contain at least one of the following characters: @, #, $, %, ^, &
        </li>
        <i class="app-bottom-arrow"></i>
      </ul>
      <div class="form-group" [class.is-invalid]="authForm.controls['confirmPassword'].dirty && authForm.controls['confirmPassword'].invalid">
        <label for="confirmPassword" class="small">Confirm Password</label>
        <input id="confirmPassword" name="confirmPassword" [type]="isConfirmPasswordVisible ? 'text' : 'password'"  class="form-control" placeholder="Password"
          formControlName="confirmPassword">
        <span (click)="toggleVisibility('confirmPassword')" [ngClass]="isConfirmPasswordVisible ? 'fa fas fa-eye-slash showSpan' : 'fa fas fa-eye showSpan'"></span>
      </div>
      <ul *ngIf="authForm.controls['confirmPassword'].dirty &&
                 authForm.controls['confirmPassword'].errors &&
                 authForm.controls['confirmPassword'].errors['matchPassword']"
        class="list-unstyled app-invalid-feedback small">
        <li *ngIf="authForm.controls['confirmPassword'].errors['matchPassword']">
          Password does not match
        </li>
        <i class="app-bottom-arrow"></i>
      </ul>
      <div *ngIf="errorMessage" class="alert alert-danger mt-4 small text-center">
        {{errorMessage}}
      </div>
      <div class="mr-2 ml-2 mt-4">
        <button type="submit" class="btn btn-primary w-100" [disabled]="authForm.invalid || !username">
          Create Password
        </button>
      </div>
    </form>
    </div>
  </div>
</ng-template>

<ng-template #fullLayout>
  <div class="app-full-layout">
    <div class="header-banner">
      <div class="container d-flex align-items-start pt-4">
        <div class="home-logo">
          <img src="assets/images/060118_HillsToHomeLogo.png">
        </div>
        <button class="visit-btn mt-3" (click)="openUrl()">
          <span class="w700">VISIT HILLSPET.COM</span>
          <i class="fa fa-external-link-square-alt pl-2"></i>
        </button>
      </div>
     <div class="container pt-6 pb-6">
      <div>
        <h2 class="text-white">Complete Registration</h2>
        <h5 class="text-white">Create a password for your account</h5>
      </div>
      <div class="d-flex">
        <form [formGroup]="authForm" class="pt-4 w-30" (ngSubmit)="register()">
          <div class="form-group" [class.is-invalid]="authForm.controls['username'].dirty && authForm.controls['username'].invalid">
            <label for="email" class="small">Email</label>
            <input id="email" name="email" type="email" class="form-control" placeholder="Email" formControlName="username">
          </div>
          <ul *ngIf="authForm.controls['username'].dirty &&
          authForm.controls['username'].errors && authForm.controls['username'].errors['pattern']"
          class="list-unstyled app-invalid-feedback small">
          <li *ngIf="authForm.controls['username'].errors['pattern']">
            Email not valid
          </li>
          <i class="app-bottom-arrow"></i>
        </ul>
        <div class="form-group" [class.is-invalid]="authForm.controls['newPassword'].dirty && authForm.controls['newPassword'].invalid">
          <label for="password" class="small">Create Password</label>
          <input id="password" name="password" [type]="isCreatePasswordVisible ? 'text' : 'password'" class="form-control" placeholder="Password"
          formControlName="newPassword">
          <span (click)="toggleVisibility('createPassword')" [ngClass]="isCreatePasswordVisible ? 'fa fas fa-eye-slash showSpan' : 'fa fas fa-eye showSpan'"></span>
        </div>
        <ul *ngIf="authForm.controls['newPassword'].dirty &&
        authForm.controls['newPassword'].errors && authForm.controls['newPassword'].errors['pattern']"
        class="list-unstyled app-invalid-feedback small">
        <li *ngIf="authForm.controls['newPassword'].errors['pattern']">
          Must be between 8-16 characters
        </li>
        <li *ngIf="authForm.controls['newPassword'].errors['pattern']">
          Must contain at least one upper case alphabetical character
        </li>
        <li *ngIf="authForm.controls['newPassword'].errors['pattern']">
          Must contain at least one lower case alphabetical character
        </li>
            <li *ngIf="authForm.controls['newPassword'].errors['pattern']">
              Must contain at least one numeric character
            </li>
            <li *ngIf="authForm.controls['newPassword'].errors['pattern']">
              Must contain at least one of the following characters: @, #, $, %, ^, &
            </li>
            <i class="app-bottom-arrow"></i>
          </ul>
          <div class="form-group" [class.is-invalid]="authForm.controls['confirmPassword'].dirty && authForm.controls['confirmPassword'].invalid">
            <label for="confirmPassword" class="small">Confirm Password</label>
            <input id="confirmPassword" name="confirmPassword" [type]="isConfirmPasswordVisible ? 'text' : 'password'" class="form-control" placeholder="Password"
              formControlName="confirmPassword">
            <span (click)="toggleVisibility('confirmPassword')" [ngClass]="isConfirmPasswordVisible ? 'fa fas fa-eye-slash showSpan' : 'fa fas fa-eye showSpan'"></span>
          </div>
          <ul *ngIf="authForm.controls['confirmPassword'].dirty &&
                     authForm.controls['confirmPassword'].errors &&
                     authForm.controls['confirmPassword'].errors['matchPassword']"
            class="list-unstyled app-invalid-feedback small">
            <li *ngIf="authForm.controls['confirmPassword'].errors['matchPassword']">
              Password does not match
            </li>
            <i class="app-bottom-arrow"></i>
          </ul>
          <div>
            <button type="submit" class="btn btn-primary pl-5 pr-5 btn-lg mr-4 mt-4 mb-4"  [disabled]="authForm.invalid || !username">
              Create Password
            </button>
          </div>
        </form>
        <div class="flex-grow-1 d-flex flex-column align-self-center">
        </div>
      </div>
      <div *ngIf="errorMessage && errorMessage !== 'link-expired'" class="alert alert-danger mt-4 small text-center">
        {{errorMessage}}
      </div>
    </div>
    </div>
  </div>
</ng-template>
<ng-template #registerFailedModal let-closeconfirm="close">
  <div class="modal-header text-center">
    <p class="m-t4 h5 text-center">Link expired</p>
    <button type="button" class="close" aria-label="Close" (click)="closeconfirm()"><span
      aria-hidden="true">&times;</span></button>
  </div>
  <div class="modal-body text-center">
    <p class="m-t4">
      We're sorry, the create password link has expired or has already been used.
    </p>
    <p class="m-t4">
      Please check your e-mail, we have sent a new link to create a password and finish registering.
    </p>
  </div>
  <div class='modal-footer'>
    <button type="button" class="btn btn-primary pl-4 pr-4 btn-md" (click)="closeconfirm()" ngbAutofocus>
      Close
    </button>
  </div>
</ng-template> 
