import { AbstractControl } from '@angular/forms';

export class PasswordValidation {

  static matchPassword(control: AbstractControl,
                       newPasswordName: string = 'newPassword',
                       confirmPasswordName: string = 'confirmPassword') {

    const newPassword = control.get(newPasswordName).value; // to get value in input tag
    const confirmPassword = control.get(confirmPasswordName).value; // to get value in input tag

    if (newPassword !== confirmPassword) {
      control.get(confirmPasswordName).setErrors({ matchPassword: true });
    } else {
      return null;
    }
  }
}
